import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function EcoIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >

      <mask id="mask0_eco" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path d="M3.50393 13.1081C4.43193 13.8721 5.59593 14.2881 6.80793 14.2881C8.19993 14.2881 9.50793 13.7481 10.4919 12.7641C11.2879 11.9681 11.9799 10.9081 12.5559 9.6121C13.0079 8.5921 13.3879 7.4241 13.6879 6.1361C14.1919 3.9561 14.2879 2.2081 14.2919 2.1361C14.2999 1.9921 14.2439 1.8521 14.1439 1.7481C14.0399 1.6521 13.9119 1.6001 13.7759 1.6001C13.6759 1.6041 11.9279 1.7001 9.74793 2.2041C8.45993 2.5001 7.29193 2.8841 6.27193 3.3361C4.97593 3.9121 3.91593 4.6041 3.11993 5.4001C1.22393 7.2961 1.08793 10.3281 2.77993 12.3841C3.04393 12.7401 3.50393 13.1081 3.50393 13.1081ZM3.84793 6.1201C5.45993 4.5081 8.06793 3.6441 9.97193 3.2001C11.3239 2.8881 12.5159 2.7361 13.2199 2.6681C13.1519 3.3721 12.9999 4.5681 12.6879 5.9161C12.2479 7.8201 11.3799 10.4241 9.76793 12.0401C8.97593 12.8321 7.92793 13.2641 6.80793 13.2641C5.87193 13.2641 4.96393 12.9521 4.23193 12.3801L5.49993 11.1121H7.77193C8.05593 11.1121 8.28393 10.8841 8.28393 10.6001C8.28393 10.3161 8.05593 10.0881 7.77193 10.0881H6.52393L7.41593 9.1921H9.68793C9.97193 9.1921 10.1999 8.9641 10.1999 8.6801C10.1999 8.3961 9.97193 8.1681 9.68793 8.1681H8.43993L9.76793 6.8401C9.86393 6.7441 9.91593 6.6161 9.91993 6.4801C9.92393 6.3441 9.86793 6.2161 9.77193 6.1161C9.67593 6.0201 9.54793 5.9641 9.41193 5.9641C9.27593 5.9641 9.14793 6.0161 9.05193 6.1121L7.72393 7.4401V6.1921C7.72393 5.9081 7.49593 5.6801 7.21193 5.6801C6.92793 5.6801 6.69993 5.9081 6.69993 6.1921V8.4641L5.80393 9.3561V8.1081C5.80393 7.8241 5.57593 7.5961 5.29193 7.5961C5.00793 7.5961 4.77993 7.8241 4.77993 8.1081V10.3801L3.51193 11.6481C2.22393 10.0001 2.35593 7.6081 3.85193 6.1121L3.84793 6.1201Z" fill="black" />
      </mask>
      <g mask="url(#mask0_eco)">
        <rect width="16" height="16" fill={color} />
      </g>

    </SVGWrapper>

  );
}

export default EcoIcon;
