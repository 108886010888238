import { h } from 'preact';
import { useStyles } from '../../../hooks';
import { PRIMARY_FILL_COLOR } from '../../../theme/colors';

function Knob({ position, styles, width }) {
  const setStyle = useStyles();
  return (
    <div
      style={setStyle({
        default: {
          ...styles,
          boxShadow: '0px 0px 6px 0px #000000',
          marginTop: 0,
          borderRadius: '50%',
          position: 'absolute',
          backgroundColor: position > 100 - width ? 'red' : 'white',
          left: `${position < 0 ? 0 : position}%`,
          transform: 'translateX(-50%)'
        },
        'largeTv+extraLargeTv': {
          backgroundColor: position > 100 - width ? 'red' : PRIMARY_FILL_COLOR
        }
      })}
    />
  );
}

export { Knob };
