/* eslint-disable react/no-unknown-property, react/style-prop-object */
import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PlaylistActiveIcon({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask_playlist" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="28" height="28">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2C3.34315 2 2 3.34315 2 5V27C2 28.6569 3.34315 30 5 30H27C28.6569 30 30 28.6569 30 27V5C30 3.34315 28.6569 2 27 2H5ZM5 7.40136V12.432C5 12.7363 5.36514 12.9295 5.66452 12.7838L10.5993 10.3811C10.9045 10.2325 10.9129 9.84422 10.6144 9.68523L5.67957 7.05727C5.3801 6.8978 5 7.09026 5 7.40136ZM27.4577 10.2084C27.4577 10.6916 27.0904 11.0834 26.6374 11.0834H13.9863C13.5333 11.0834 13.166 10.6916 13.166 10.2084C13.166 9.72513 13.5333 9.33337 13.9863 9.33337L26.6374 9.33337C27.0904 9.33337 27.4577 9.72512 27.4577 10.2084ZM26.5833 18.0834C27.0364 18.0834 27.4036 17.6916 27.4036 17.2084C27.4036 16.7251 27.0364 16.3334 26.5833 16.3334L5.82031 16.3334C5.36727 16.3334 5 16.7251 5 17.2084C5 17.6916 5.36727 18.0834 5.82031 18.0834L26.5833 18.0834ZM26.5833 24.5C27.0364 24.5 27.4036 24.1082 27.4036 23.625C27.4036 23.1418 27.0364 22.75 26.5833 22.75L5.82031 22.75C5.36727 22.75 5 23.1417 5 23.625C5 24.1082 5.36727 24.5 5.82031 24.5L26.5833 24.5Z" fill="black" />
      </mask>
      <g mask="url(#mask_playlist)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default PlaylistActiveIcon;
