import { PRIMARY_FILL_COLOR } from '../../../../ui/theme/colors';

export const TRACK_WRAPPER = {
  height: 24,
  position: 'relative',
  marginBottom: 20
};

export const TRACK_CONTENT = {
  position: 'absolute',
  top: '50%',
  fontSize: 24,
  width: '100%'
};

export const DOT = {
  height: 6,
  width: 6,
  borderRadius: 6,
  background: PRIMARY_FILL_COLOR,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)'
};

export const LABEL_ICON_WRAPPER = {
  color: PRIMARY_FILL_COLOR,
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: 16
};

export const LABEL = { position: 'relative' };

export const ICON_WRAPPER = {
  position: 'absolute',
  left: '100%',
  top: '50%',
  transform: 'translateY(-50%)',
  marginLeft: 8
};

export const ICON = {
  float: 'right'
};
