import { Subject, pipe, ReplaySubject } from 'rxjs';
import { withLatestFrom, switchMap, map, mapTo, filter } from 'rxjs/operators';
import systemInfo from '../../utils/systemInfo';
import {
  MINI_QUALITY,
  ECO_QUALITY,
  STANDARD_QUALITY,
  SUPERIOR_QUALITY,
  AUTOMATIC_QUALITY
} from './types';
import { Disposable } from '..';

export default class QualityController extends Disposable {
  constructor({ activeRenderer$ }) {
    super();
    this.selectedQuality$ = new Subject();
    this.nextQuality$ = new Subject();
    this.selectedQualityObj$ = new ReplaySubject(1);

    this.qualities$ = activeRenderer$
      .pipe(switchMap(({ renderer }) => renderer.qualities$.pipe(
        map(QualityController.mapQualities),
        map(QualityController.addLevelByDevice)
      )));

    QualityController.createSelectedQualityObjectStream({
      nextQuality$: this.nextQuality$,
      qualities$: this.qualities$
    }).subscribe(this.selectedQualityObj$);

    activeRenderer$.pipe(mapTo(-1)).subscribe(this.selectedQuality$);

    this.nextQuality$
      .pipe(QualityController.createQualityIndexGuard(this.qualities$))
      .subscribe(this.selectedQuality$);

    this.selectedQuality$
      .pipe(withLatestFrom(activeRenderer$, (level, { renderer }) => ({ level, renderer })))
      .subscribe(({ level, renderer }) => renderer.setVideoQuality(level));
  }

  static createSelectedQualityObjectStream({ nextQuality$, qualities$ }) {
    return nextQuality$.pipe(
      withLatestFrom(qualities$),
      /* if no quality found then we take the highest (the latest) */
      map(([nextQuality, qualities]) => (
        qualities.find((q) => ([...q.levels, q.level].includes(nextQuality)))
        || qualities[qualities.length - 1]
      ))
    );
  }

  setVideoQuality(level) {
    this.nextQuality$.next(level);
  }

  static createQualityIndexGuard(availableQualities$) {
    return pipe(
      withLatestFrom(availableQualities$),
      filter(([level, qualities]) => qualities.flatMap(({ levels }) => levels).includes(level)),
      map(([level]) => level)
    );
  }

  static mapQualities(qualities) {
    return qualities
      .filter(({ height }) => Boolean(height))
      .reduce((qualityMapping, { height }, level) => {
        let key = null;

        if (height < 360) {
          key = MINI_QUALITY;
        } else if (height < 720) {
          key = ECO_QUALITY;
        } else if (height < 1080) {
          key = STANDARD_QUALITY;
        } else if (height >= 1080) {
          key = SUPERIOR_QUALITY;
        }

        const category = qualityMapping.find((q) => q.key === key);
        if (category) {
          category.levels.push(level);
        } else {
          qualityMapping.push({
            key,
            levels: [level]
          });
        }

        return qualityMapping;
      }, [{ key: AUTOMATIC_QUALITY, levels: [-1] }]);
  }

  static addLevelByDevice(qualities) {
    return qualities.map(({ key, levels }) => ({ key, levels, level: Math[systemInfo.isMobile ? 'min' : 'max'](...levels) }));
  }
}
