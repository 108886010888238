import { delay, filter, map, withLatestFrom } from 'rxjs';
import TracksController from './index';
import VTTParser from './parsers/vtt';
import TTMLParser from './parsers/ttml';
import { TEXT_TRACKS_RECONCILED } from '../../store/types';
import { RESET_TRACKS_MODE } from './types';

export default class TextTrackController extends TracksController {
  constructor({ tracks$, activeRenderer$, events$, rendererStream$ }) {
    super({ tracks$, activeRenderer$ });

    TracksController.createReconcilerStream({
      type: 'textTracks',
      activeRenderer$,
      availableTracks$: this.availableTracks$
    }).subscribe(({ type, tracks, videoEl }) => {
      TracksController.reconcile({ type, tracks, videoEl });
      events$.next(TEXT_TRACKS_RECONCILED);
    });

    /* empty container on each new video */
    activeRenderer$.subscribe(() => this.emptySubtitleContainer());

    TextTrackController.createResetTextTrackModeStream({ activeRenderer$, rendererStream$, activeTrack$: this.activeTrack$ })
      .subscribe(TextTrackController.resetTextTrackMode);
  }

  static createResetTextTrackModeStream({ rendererStream$, activeTrack$, activeRenderer$ }) {
    return rendererStream$.pipe(
      filter((e) => e === RESET_TRACKS_MODE),
      withLatestFrom(activeTrack$, activeRenderer$),
      delay(50),
      map(([, index, { renderer: { tagElement } }]) => ({ index, tagElement }))
    );
  }

  // eslint-disable-next-line class-methods-use-this
  findTrack(tracks, index) {
    return tracks.find((track) => track.index === index)
      || ({ index: -1, label: 'desactive', language: null });
  }

  setTrack(renderer, index) {
    const { tagElement } = renderer;
    renderer.setTextTrack(index);
    this.handleTextTrack(index, tagElement);
  }

  setSubtitleContainer(domNode) {
    this.subtitleContainer = domNode;
  }

  emptySubtitleContainer() {
    if (this.subtitleContainer) {
      this.subtitleContainer.innerHTML = '';
      this.subtitleContainer.classList.remove('vtt', 'ttml');
    }
  }

  static resetTextTrackMode({ index, tagElement }) {
    Object.keys(tagElement.textTracks)
      .forEach((key, i) => {
      // eslint-disable-next-line no-param-reassign
        tagElement.textTracks[i].mode = 'hidden';
        const track = tagElement.textTracks[key];
        track.mode = TextTrackController.setTrackMode(track.index === index);
      });
  }

  handleTextTrack(index, videoEl) {
    this.emptySubtitleContainer();
    Object.keys(videoEl.textTracks)
      .forEach((key, i) => {
        // eslint-disable-next-line no-param-reassign
        videoEl.textTracks[i].mode = 'hidden';
        const track = videoEl.textTracks[key];
        const selected = track.index === index;
        track.oncuechange = selected ? this.handleCueChange.bind(this, track, videoEl) : null;

        track.mode = TextTrackController.setTrackMode(selected);
      });
  }

  handleCueChange(textTrack, videoEl) {
    this.emptySubtitleContainer();

    const cue = textTrack.activeCues?.[0];

    if (cue?.text || cue?.isd || cue?.cueHTMLElement) {
      const context = {
        h: videoEl.clientHeight,
        w: videoEl.clientWidth
      };
      let parsedCue = '';

      if (cue.isd) {
        this.subtitleContainer.classList.add('ttml');
        parsedCue = TTMLParser.parse(cue.isd, context);
      } else if (cue.text) {
        parsedCue = VTTParser.parse(cue.text);
        this.subtitleContainer.classList.add('vtt');
      } else if (cue.cueHTMLElement) {
        parsedCue = cue.cueHTMLElement;
        parsedCue.style = {}; /* remove top level styles */
      }

      if (parsedCue && parsedCue?.textContent !== '') {
        this.subtitleContainer.appendChild(parsedCue);
      }
    }
  }

  static setTrackMode(selected) {
    return selected ? 'hidden' : 'disabled';
  }
}
