import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function VolumeMuteIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_volume_mute" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="5" width="20" height="16">
          {/* eslint-disable-next-line react/no-unknown-property */}
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1147 8.72943C6.02994 8.78992 5.9284 8.82243 5.82427 8.82243H3C2.44771 8.82243 2 9.27015 2 9.82244C2.00001 11.6087 2 13.3949 2 15.1811C2 15.7334 2.44771 16.1811 3 16.1811H5.82427C5.9284 16.1811 6.02994 16.2136 6.1147 16.2741L11.2096 19.9097C11.5405 20.1459 12 19.9093 12 19.5027V5.50088C12 5.0943 11.5405 4.85771 11.2096 5.09388L6.1147 8.72943Z" fill="black" />
          {/* eslint-disable-next-line react/no-unknown-property */}
          <path d="M20.2959 16.0355C20.5888 16.3284 21.0637 16.3284 21.3566 16.0355C21.6495 15.7426 21.6495 15.2678 21.3566 14.9749L18.8817 12.5L21.3566 10.0251C21.6495 9.73223 21.6495 9.25736 21.3566 8.96447C21.0637 8.67157 20.5888 8.67157 20.2959 8.96447L17.8211 11.4393L15.3462 8.96447C15.0533 8.67157 14.5784 8.67157 14.2855 8.96447C13.9926 9.25736 13.9926 9.73223 14.2855 10.0251L16.7604 12.5L14.2855 14.9749C13.9926 15.2678 13.9926 15.7426 14.2855 16.0355C14.5784 16.3284 15.0533 16.3284 15.3462 16.0355L17.8211 13.5607L20.2959 16.0355Z" fill="black" />
        </mask>
        <g mask="url(#mask0_volume_mute)">
          <rect width="24" height="24" fill={color} />
        </g>
      </svg>

    </SVGWrapper>
  );
}
export default VolumeMuteIcon;
