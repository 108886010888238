/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvRewind16Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM42.3334 46.2514V73.0816C42.3334 74.7408 40.7406 75.7673 39.4857 74.9167L18.8065 60.901C17.5555 60.053 17.5906 57.9823 18.8696 57.1898L39.5487 44.3754C40.8033 43.5979 42.3334 44.6288 42.3334 46.2514ZM66.7778 46.2514V73.0816C66.7778 74.7408 65.3298 75.7673 64.189 74.9167L45.3898 60.901C44.2524 60.053 44.2844 57.9823 45.4471 57.1898L64.2463 44.3754C65.3868 43.5979 66.7778 44.6288 66.7778 46.2514ZM33.5069 21.84L31.7269 19.3H28.6469L31.9669 23.84L28.1869 29H31.2069L33.4669 25.84L35.7069 29H38.7869L35.0069 23.82L38.3269 19.3H35.3069L33.5069 21.84ZM40.5658 17.24V19.8L43.5658 18.12V26.6H39.7658V29H49.5658V26.6H46.2658V15H44.3658L40.5658 17.24ZM58.6791 15H55.6991L52.9791 19.46C51.8591 21.3 50.9391 22.96 50.9391 24.48C50.9391 27.38 53.1991 29.2 56.0791 29.2C58.8591 29.2 61.2391 27.22 61.2391 24.2C61.2391 21.28 58.9791 19.5 56.4391 19.5H55.9591L58.6791 15ZM56.0791 26.8C54.6391 26.8 53.6191 25.8 53.6191 24.3C53.6191 23.44 54.0191 22.6 54.4391 21.98C54.9391 21.82 55.4391 21.7 55.9391 21.7C57.5591 21.7 58.5791 22.84 58.5791 24.2C58.5791 25.8 57.4791 26.8 56.0791 26.8Z" fill="white" />
    </SVGWrapper>
  );
}

export default TvRewind16Icon;
