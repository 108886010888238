export const ECO_DEFAULT_TEXTS = {
  title: 'mode éco',
  title2: 'Le saviez-vous ?',
  body1: 'Activer ce mode modifie la qualité de la vidéo pour réduire votre consommation d’énergie et votre impact sur l’environnement.',
  body2: 'Le visionnage de vidéos en ligne représente 60 % des flux de données numériques.',
  footer: 'Source : The Shift Project'
};

export const ECO_MOBILE_TEXTS = {
  ...ECO_DEFAULT_TEXTS,
  body2: 'La 4G utilise 23 fois plus d’énergie que le wifi.',
  footer: 'Source : Université de Columbia (USA)'
};
