import { h } from 'preact';
import { useStyles } from '../../../hooks';
import Scrollable from '../../../hoc/scrollable';

function SlidableSlide({ children, style = {}, isFullscreen = false }) {
  const setStyles = useStyles();
  return (
    <Scrollable
      {...({
        ...(!isFullscreen ? setStyles({
          default: { maxHeight: 420 },
          small: { maxHeight: 270 }
        }) : {}),
        ...style
      })
      }
    >
      {children}
    </Scrollable>
  );
}

export default SlidableSlide;
