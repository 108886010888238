export const CUE_CSS = `
  ::cue {
    visibility: hidden!important;
    opacity: 0!important;
    background: transparent!important;
  }
  video::-webkit-media-text-track-display-backdrop {
    background-color: transparent!important;
    overflow: hidden!important;
  }
`;

export const createCueStylesheet = (document) => {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(CUE_CSS));

  return style;
};

const cueCSS = createCueStylesheet(document);
export default cueCSS;
