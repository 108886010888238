import { SECONDARY_FILL_COLOR, OVERLAY_COLOR_OPAQUE, PRIMARY_FILL_COLOR } from '../../../../theme/colors';
import { TRACKS_LAYER } from '../../../../theme/constants';

export const TRACKS_WRAPPER_STYLE = {
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  marginTop: 20,
  marginLeft: 24,
  marginRight: 48, // right margin + close button
  marginBottom: 20,
  position: 'relative'
};

export const TRACKS_WRAPPER_RESPONSIVE_STYLE = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  marginLeft: 20,
  marginRight: 40 // right margin + close button
};

export const TRACKS_WRAPPER_TV_STYLE = {
  flexDirection: 'column',
  margin: '40px',
  gap: '70px'
};

export const TRACK_DEFAULT_DIALOG_STYLE = {
  position: 'relative'
};

export const TRACK_DIALOG_CONTENT_STYLE = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'flex-end',
  background: 'transparent',
  color: SECONDARY_FILL_COLOR,
  position: 'absolute',
  zIndex: TRACKS_LAYER,
  bottom: 0,
  right: 0,
  padding: 0,
  maxWidth: 0,
  maxHeight: 0,
  width: 0,
  opacity: 0,
  overflow: 'hidden',
  borderRadius: 30,
  transition: 'none',
  margin: '0px -4px -4px 0px'
};

export const TRACK_DIALOG_OPENED_CONTENT_STYLE = {
  maxHeight: 600,
  maxWidth: 650,
  background: OVERLAY_COLOR_OPAQUE,
  opacity: 1,
  transitionProperty: 'max-width, width, opacity',
  transitionDuration: '.25s',
  transitionTimingFunction: 'ease-in-out'
};

export const TRACK_DEFAULT_DIALOG_RESPONSIVE_STYLE = {
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'flex-end',
  background: 'transparent',
  color: SECONDARY_FILL_COLOR,
  overflow: 'hidden',
  transition: 'opacity .33s ease-in-out'
};

export const TRACK__DIALOG_TV_STYLE = {
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'flex-end',
  background: 'transparent',
  color: SECONDARY_FILL_COLOR,
  overflow: 'hidden',
  transition: 'opacity .33s ease-in-out'
};
export const TRACK_DIALOG_CLOSE_LABEL_STYLE = {
  default: { top: 0, right: 0 },
  'small+medium': { top: 10, right: 20 },
  extraSmall: { top: 10, right: 'unset', left: 'unset', bottom: 'unset' }
};

export const TRACK_DIALOG_CLOSE_BUTTON_SMALL_STYLE = {
  top: '9px',
  right: '9px'
};

export const TRACKLIST_STYLES = {
  sectionItems: {
    padding: '0 10px 15px 0',
    diplay: 'flex',
    flexDirection: 'row',
    margin: 0,
    fontSize: 18,
    fontFamily: 'Brown',
    fontWeight: 400,
    lineHeight: 1.5
  },
  section: {
    flex: 1
  },
  sectionTitle: {
    default: {
      fontFamily: 'Brown',
      fontSize: 20,
      height: 30,
      fontWeight: 700,
      marginBottom: 28,
      display: 'block',
      width: '100%',
      whiteSpace: 'nowrap',
      color: PRIMARY_FILL_COLOR
    },
    small: {
      marginBottom: 20
    },
    extraSmall: {
      marginBottom: 20
    }

  }
};
