import { of, fromEvent, NEVER } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import SeekBlocker from './features/SeekBlocker';
import systemInfo from '../../utils/systemInfo';
import { VIDEO_LAYER } from '../../ui/theme/constants';

export default class AdLayer {
  constructor(domController, videoUnlocker, isAd$) {
    AdLayer.cssHack();
    this.videoDisplayBaseId = AdLayer.generateVideoDisplayBaseId();
    this.layer = this.createAdLayer();
    this.videoObj = videoUnlocker.getVideoTag({ platform: domController.getPlatform() });
    this.videoObj.style.backgroundColor = 'black';

    /**
     * TODO: once adLayer is finalized :
     * move SeekBlocker to more relevant controller
     */
    this.seekBlocker = new SeekBlocker(this.videoObj, isAd$);

    /**
     * IOS BUGFIX:
     * on fullscreen exit, mobile Safari will pause the video on the native player
     * but FW will not catch the pause event which will cause inconsistencies
     * between the actual video state, the store, and the UI.
     */
    this.fullscreenExit$ = AdLayer.createFullscreenExitStream({
      isMobile: systemInfo.isMobile,
      videoObj: this.videoObj
    });

    this.domController = domController;
    this.setupAdLayer(domController);
  }

  setupAdLayer() {
    this.layer.appendChild(this.videoObj);
    const videoLayer = this.domController.getLayer('ad');
    videoLayer.classList.add('ftv-in-customizable', 'magneto-layer');
    videoLayer.style.zIndex = VIDEO_LAYER + 1;
    videoLayer.appendChild(this.layer);
  }

  static cssHack() {
    /* Dirty fix for VPAID creatives */
    const css = `.ad-layer > video,
      .ad-layer .fw_vpaid_slot,
      .ad-layer .fw_vpaid_slot > div,
      .ad-layer .fw_vpaid_slot > div > div,
      .ad-layer .fw_vpaid_slot > div > div > iframe {
          width: 100% !important;
          height: 100% !important;
      };`;

    const style = document.createElement('style');
    style.type = 'text/css';
    style.appendChild(document.createTextNode(css));

    document.head.appendChild(style);
  }

  static generateVideoDisplayBaseId() {
    return `freewheelAd-${parseInt(Math.random() * 100000, 10)}`;
  }

  static createFullscreenExitStream({ isMobile, videoObj }) {
    return of(Boolean(isMobile && videoObj.webkitExitFullscreen))
      .pipe(switchMap((isIOS) => (isIOS ? fromEvent(videoObj, 'webkitendfullscreen') : NEVER)));
  }

  getVideoDisplayBaseId() {
    return this.videoDisplayBaseId;
  }

  createAdLayer() {
    const layer = document.createElement('div');

    layer.className = 'ad-layer';
    layer.style.height = '100%';
    layer.style.width = '100%';
    layer.style.display = 'none';
    layer.id = this.getVideoDisplayBaseId();

    return layer;
  }

  hideUI(tagUnique) {
    this.videoObj.setAttribute('src', '');
    this.layer.style.display = 'none';
    const videoContainer = document.getElementsByClassName('magneto-video-container')[0];
    const adLayer = document.getElementsByClassName('ad-layer')[0];
    if (tagUnique && adLayer && videoContainer && adLayer.contains(this.videoObj)) {
      adLayer.removeChild(this.videoObj);
      videoContainer.appendChild(this.videoObj);
    }
  }

  showUI(adContext, shouldRegister) {
    this.layer.style.display = 'block';
    if (shouldRegister) {
      this.setupAdLayer();
      adContext?.registerVideoDisplayBase(this.getVideoDisplayBaseId());
    }
  }
}
