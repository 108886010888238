import { Fragment, h } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import { computeVideoTitle } from '../../../utils/videoMetaUtils';
import { H1, H2, H3, NEXT_TITLE_WRAPPER, TITLE_WRAPPER, TV_LOGO_WRAPPER } from './styles';
import { selfOrKeyObject } from '../../../utils';

function HeaderInfos({
  comingNext,
  videoAdditionalTitle,
  videoTitle,
  videoPreTitle,
  subTitle,
  forceShowTitle,
  hasComingNext,
  videoLogo,
  isAd,
  isLive,
  isTv,
  isDVR,
  displayComingNextOnStart,
  showTitle,
  showCountdown,
  isLargeTvScreen
}) {
  if (!videoTitle || isAd) return null;
  const { computedTitle, computedSubtitle } = useMemo(
    () => computeVideoTitle(
      videoTitle,
      subTitle,
      videoPreTitle,
      videoAdditionalTitle
    ),
    [videoTitle, subTitle, videoPreTitle, videoAdditionalTitle]
  );
  const shouldShowInfo = !isAd
  && showTitle
  && !showCountdown
  && ((hasComingNext && displayComingNextOnStart) || isDVR || isTv || forceShowTitle);

  const shouldDisplayLogo = Boolean(selfOrKeyObject(videoLogo, 'url')) && shouldShowInfo && isLive;

  const LOGO_STYLE = isLargeTvScreen ? TV_LOGO_WRAPPER.largeTv : TV_LOGO_WRAPPER.extraLargeTv;

  const [containerheight, setContainerHeight] = useState(TITLE_WRAPPER.height);
  const [containerNextheight, setContainerNextHeight] = useState(NEXT_TITLE_WRAPPER.height);

  const COMING_NEXT_HEIGHT = 30;
  const METAS_HEIGHT = 50;

  useEffect(() => {
    let containerSize = (
      hasComingNext ? COMING_NEXT_HEIGHT : 0)
      + (computedTitle.length ? METAS_HEIGHT : 0)
      + (computedSubtitle.length ? METAS_HEIGHT : 0);

    if (!hasComingNext && computedTitle.length && !computedSubtitle.length) {
      containerSize += COMING_NEXT_HEIGHT;
    }
    setContainerHeight(containerSize);
  }, [hasComingNext, computedTitle, computedSubtitle]);

  useEffect(() => {
    if (!hasComingNext) return;

    setContainerNextHeight(
      COMING_NEXT_HEIGHT
      + (comingNext.program.length ? METAS_HEIGHT : 0)
      + (comingNext.title || comingNext.preTitle ? METAS_HEIGHT : 0)
    );
  }, [hasComingNext, comingNext]);

  return (
    <Fragment>
      { shouldDisplayLogo && (
      <div style={LOGO_STYLE}>
        <img
          src={videoLogo?.url || videoLogo}
          alt={videoLogo?.alt || 'channel logo'}
          style={{ width: '100%' }}
        />
      </div>
      )}
      <div style={{ ...(shouldDisplayLogo ? { ...TITLE_WRAPPER, left: LOGO_STYLE.width } : TITLE_WRAPPER), height: containerheight }}>
        {hasComingNext ? <h3 style={H3}>Vous regardez</h3> : <span />}
        <h1 style={H1}>{computedTitle}</h1>
        <h2 style={H2}>{computedSubtitle}</h2>
      </div>

      {hasComingNext ? (
        <div style={{ ...(shouldDisplayLogo ? { ...NEXT_TITLE_WRAPPER, left: LOGO_STYLE.width } : NEXT_TITLE_WRAPPER), height: containerNextheight }}>
          <h3 style={H3}>Prochaine video</h3>
          <h1 style={H1}>{comingNext.program}</h1>
          <h2 style={H2}>
            {`${comingNext.preTitle ? `${comingNext.preTitle} : ` : ''}${
              comingNext.title
            }`}
          </h2>
        </div>
      ) : (
        <span />
      )}
    </Fragment>
  );
}

const selector = ({
  media: {
    comingNext,
    videoAdditionalTitle,
    videoTitle,
    videoPreTitle,
    subTitle,
    videoLogo,
    isAd,
    isLive,
    isDVR,
    isTv
  },
  ui: {
    showTitle,
    forceShowTitle,
    displayComingNextOnStart,
    isLargeTvScreen
  }
}) => ({
  comingNext,
  videoAdditionalTitle,
  videoTitle,
  videoPreTitle,
  subTitle,
  hasComingNext: Boolean(comingNext?.title && comingNext?.program),
  forceShowTitle,
  videoLogo,
  isAd,
  isLive,
  isTv,
  isDVR,
  displayComingNextOnStart,
  showTitle,
  isLargeTvScreen
});

export default connect(selector)(HeaderInfos);
