import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function Chat({ height = 60, width = 60, color = 'white', ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_7588_35675" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="2" width="26" height="28">
        <path d="M8.30565 12.9861C9.16749 12.9861 9.86614 12.2875 9.86614 11.4256C9.86614 10.5638 9.16749 9.86513 8.30565 9.86513C7.44382 9.86513 6.74517 10.5638 6.74517 11.4256C6.74517 12.2875 7.44382 12.9861 8.30565 12.9861Z" fill="white" />
        <path d="M12.4669 12.9861C13.3288 12.9861 14.0274 12.2875 14.0274 11.4256C14.0274 10.5638 13.3288 9.86513 12.4669 9.86513C11.6051 9.86513 10.9065 10.5638 10.9065 11.4256C10.9065 12.2875 11.6051 12.9861 12.4669 12.9861Z" fill="white" />
        <path d="M18.1887 11.4256C18.1887 12.2875 17.4901 12.9861 16.6282 12.9861C15.7664 12.9861 15.0678 12.2875 15.0678 11.4256C15.0678 10.5638 15.7664 9.86513 16.6282 9.86513C17.4901 9.86513 18.1887 10.5638 18.1887 11.4256Z" fill="white" />
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.00003 5.70387C3.00003 4.21002 4.21103 2.99902 5.70488 2.99902H19.2291C20.7229 2.99902 21.9339 4.21002 21.9339 5.70387V8.82481H26.0952C27.589 8.82481 28.8 10.0358 28.8 11.5297V21.9329L28.8 21.9447V29.2151C28.8 29.4669 28.6487 29.6941 28.4164 29.7911C28.184 29.8882 27.9161 29.8361 27.737 29.6591L22.6558 24.6377H12.571C11.0771 24.6377 9.86614 23.4267 9.86614 21.9329V18.8119H9.14418L4.06299 23.8334C3.8839 24.0103 3.61601 24.0624 3.38368 23.9653C3.15134 23.8683 3.00006 23.6412 3.00006 23.3894V16.1189L3.00003 16.1071V5.70387ZM21.9339 16.1071V10.0732H26.0952C26.8996 10.0732 27.5516 10.7253 27.5516 11.5297L27.5516 27.7207L24.4319 24.6377L23.4542 23.6847C23.2599 23.4953 22.9994 23.3893 22.7281 23.3893H12.571C11.7666 23.3893 11.1145 22.7373 11.1145 21.9329V18.8119H19.2291C20.7229 18.8119 21.9339 17.6009 21.9339 16.1071ZM7.36816 18.8119L4.24843 21.8949V5.70387C4.24843 4.8995 4.90051 4.24742 5.70488 4.24742H19.2291C20.0334 4.24742 20.6855 4.8995 20.6855 5.70387V16.1071C20.6855 16.9115 20.0334 17.5635 19.2291 17.5635H9.07199C8.80067 17.5635 8.54008 17.6696 8.34581 17.8589L7.36816 18.8119Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7588_35675)">
        <rect width="32" height="32" fill={color} />
        <rect width="32" height="32" fill={color} />
      </g>
    </SVGWrapper>
  );
}

export { Chat };
