export const HLS_TYPE = 'hls';
export const DASH_TYPE = 'dash';
export const HTML_TYPE = 'html';
export const AUDIO_TYPE = 'audio';

export const RENDERER_CREATED = 'renderer_created';
export const RENDERER_READY = 'renderer_ready';
export const RENDERER_DISPOSE = 'renderer_dispose';
export const RENDERER_LIVE_STREAM_DETECTED = 'renderer_live_stream_detected';
export const RENDERER_INIT_DONE = 'renderer_init_done';

export const HD_BANDWIDTH = 2600000;
export const TIMESHIFTING_DURATION_INCREASE = 1; // 1s
export const LIVE_STALLED_TIMEOUT = 5000;

export function matchExtension(...extensions) {
  return ({ url = '' }) => extensions.some((ext) => url
    .substr(url.lastIndexOf('/') + 1) /* capture everything after last '/' */
    .split('?')[0] /* remove possible query strings */
    .includes(ext));
}

export function matchMSEType(type) {
  return () => window.MediaSource?.isTypeSupported(type);
}

const isSafariIOS = ({ url, browser, isIOS, isMac }) => (
  (Boolean(isIOS) || (isMac && browser === 'safari'))
  && matchExtension('.m3u8')({ url })
);

export const RENDERER_CONFIG = {
  [HTML_TYPE]: [
    ({ url, browser, isIOS, isMobile, isMac }) => (
      matchExtension('.mp4')({ url })
      || (!!isMobile && isSafariIOS({ url, browser, isIOS, isMac }))
      || (/DRM/i.test(url) && isSafariIOS({ url, browser, isIOS, isMac }))
    )
  ],
  [DASH_TYPE]: [
    ({ hasMSE }) => Boolean(hasMSE),
    ({ isIOS }) => Boolean(!isIOS),
    matchExtension('.mpd')
  ],
  [HLS_TYPE]: [
    ({ hasMSE, url, browser, isIOS, isMac }) => (
      (!/DRM/i.test(url) && isSafariIOS({ url, browser, isIOS, isMac }))
      || Boolean(hasMSE)
    ),
    matchMSEType('video/mp4; codecs="avc1.42E01E,mp4a.40.2"'),
    matchExtension('.m3u8')
  ],
  [AUDIO_TYPE]: [
    matchExtension('.mp3')
  ]
};
