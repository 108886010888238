import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PlayIcon({ height = 60, width = 60, color = 'white', ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
      {...props}
    >
      {/* eslint-disable-next-line react/no-unknown-property */}
      <mask id="mask_play" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="56" height="56">
        <path fillRule="evenodd" clipRule="evenodd" d="M30 57.5C45.1878 57.5 57.5 45.1878 57.5 30C57.5 14.8122 45.1878 2.5 30 2.5C14.8122 2.5 2.5 14.8122 2.5 30C2.5 45.1878 14.8122 57.5 30 57.5ZM20.8333 40.5247V19.6027C20.8333 18.3088 22.2434 17.5084 23.3544 18.1717L41.6611 29.1011C42.7687 29.7623 42.7375 31.377 41.6053 31.995L23.2985 41.9876C22.1879 42.5939 20.8333 41.79 20.8333 40.5247Z" fill="black" />
      </mask>
      <g mask="url(#mask_play)">
        <rect width="60" height="60" fill={color} />
      </g>
    </SVGWrapper>
  );
}

export default PlayIcon;
