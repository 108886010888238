import {
  PLAYBACK_CANPLAY,
  PLAYBACK_PLAY,
  PLAYBACK_PLAYING,
  PLAYBACK_PAUSE,
  PLAYBACK_END,
  PLAYBACK_TIMEUPDATE,
  PLAYBACK_LOADSTART,
  PLAYBACK_EMPTIED,
  PLAYBACK_BUFFER,
  PLAYBACK_BITRATECHANGE,
  PLAYBACK_CURRENT_QUALITY,
  PLAYBACK_CURRENT_QUALITY_CHANGES,
  PLAYBACK_SELECTED_QUALITY,
  PLAYBACK_AVAILABLE_QUALITIES,
  PLAYBACK_RATECHANGE
} from '../types';

const initialState = {
  isSeeking: false,
  isPlaying: false,
  currentTime: 0,
  state: '',
  playButton: 'PLAY',
  buffered: 0,
  bitrate: {},
  qualities: {},
  hasQualities: false,
  selectedQualityLevel: null,
  currentQualityLevel: null,
  rate: 1,
  currentQualityLabel: '',
  automaticLevelLabel: 'automatique',
  isAutomaticLevelSelected: false,
  mappedQualities: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case (PLAYBACK_CANPLAY):
    case (PLAYBACK_PLAY): {
      const { button } = payload;
      return { ...state, playButton: button };
    }
    case (PLAYBACK_PLAYING): {
      const { button } = payload;
      return {
        ...state, playButton: button, isPlaying: true
      };
    }
    case (PLAYBACK_PAUSE): {
      const { button } = payload;
      return {
        ...state, playButton: button, isPlaying: false
      };
    }
    case (PLAYBACK_END):
    case (PLAYBACK_LOADSTART):
    case (PLAYBACK_EMPTIED): {
      const { button } = payload;
      return { ...state, playButton: button, isPlaying: false };
    }
    case (PLAYBACK_TIMEUPDATE): {
      const { currentTime } = payload;
      return { ...state, currentTime };
    }
    case (PLAYBACK_BUFFER): {
      const { buffered } = payload;
      return { ...state, buffered };
    }

    case (PLAYBACK_BITRATECHANGE): {
      const { bitrate } = payload;
      return { ...state, bitrate };
    }

    case (PLAYBACK_AVAILABLE_QUALITIES): {
      const { qualities, hasQualities } = payload;
      return { ...state, qualities, hasQualities };
    }

    case (PLAYBACK_SELECTED_QUALITY): {
      const { selectedQualityLevel } = payload;
      return { ...state, selectedQualityLevel };
    }

    case (PLAYBACK_CURRENT_QUALITY_CHANGES): {
      const {
        currentQualityLabel,
        automaticLevelLabel,
        isAutomaticLevelSelected,
        mappedQualities
      } = payload;

      return { ...state,
        currentQualityLabel,
        automaticLevelLabel,
        isAutomaticLevelSelected,
        mappedQualities
      };
    }

    case (PLAYBACK_CURRENT_QUALITY): {
      const { currentQualityLevel } = payload;
      return { ...state, currentQualityLevel };
    }

    case (PLAYBACK_RATECHANGE): {
      const { rate } = payload;
      return { ...state, rate };
    }

    default: {
      return state;
    }
  }
};
