/**
 * Safari / IOS :
 * Check if seconds is finite for edge case where
 * Infinity is supplied during duration conversion to HHMMSS
 */
export const secondsToHHMMSS = (seconds) => (
  Number.isFinite(seconds)
    ? new Date(1000 * seconds).toISOString().substr(11, 8)
    : '00:00:00'
);

/**
 * Format a string date '2018-10-08T20:45:47+02:00' -> 20h45
 * @param {string} date
 */
export const dateToHhS = (date) => {
  const d = new Date(date);
  return `${d.getHours() || '00'}h${(`0${d.getMinutes() || '00'}`).substr(-2)}`;
};

export const dateToSecond = (date) => new Date(date).getTime() / 1000;

export const timeToText = (seconds) => {
  const d = new Date(1000 * seconds);

  /* Use this instead of date.getHours as hours is not accurate due to timezone */
  const getHours = () => parseInt(d.toISOString().substring(11, 13), 10);

  const toText = (fn, unit) => (fn() && `${fn()} ${unit}${fn() > 1 ? 's' : ''}`) || '';

  return `${toText(getHours, 'heure')} ${toText(d.getMinutes.bind(d), 'minute')} ${toText(d.getSeconds.bind(d), 'seconde')}`;
};

// transform format PTxxHxxM (ex: PT1H30M, PT55M, etc) to seconds
export const timeToSeconds = (timeString) => {
  const [,, hours,,, minutes] = timeString.match(/PT((\d+)(H))?((\d+)M)?/);
  return (parseInt(hours, 10) || 0) * 3600 + (parseInt(minutes, 10) || 0) * 60;
};

export const getHumanizedTimeOnlyFromISODate = (d) => {
  const [hm] = d.match(/\d\d:\d\d/);
  return `à ${hm.replace(':', ' h ')} min`;
};
