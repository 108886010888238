import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function RewindIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
    >
      <mask id="mask0_rewind" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="3" width="34" height="36">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8026 8.07221C17.288 7.71595 17.288 6.9508 17.8026 6.59453L22.4639 3.36768C23.0547 2.95869 23.8588 3.38459 23.8588 4.10652V5.60358C32.0965 6.52713 38.5 13.5158 38.5 22C38.5 31.1127 31.1127 38.5 22 38.5C12.8873 38.5 5.5 31.1127 5.5 22C5.5 17.4304 7.35759 13.2947 10.3588 10.3067C10.866 9.8018 11.6815 9.84047 12.1842 10.3498C12.7466 10.9195 12.695 11.8453 12.1372 12.4194C9.73138 14.8957 8.25 18.2749 8.25 22C8.25 29.594 14.4061 35.75 22 35.75C29.5939 35.75 35.75 29.594 35.75 22C35.75 15.0365 30.5736 9.28205 23.8588 8.37461V10.5602C23.8588 11.2822 23.0547 11.7081 22.4639 11.2991L17.8026 8.07221Z" fill="black" />

      </mask>
      <g mask="url(#mask0_rewind)">
        <rect width="44" height="44" fill={color} />
      </g>
    </SVGWrapper>
  );
}

export default RewindIcon;
