import { UI_LAYER } from './theme/constants';

export const START_SCREEN_CONTAINER = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  display: 'flex',
  zIndex: UI_LAYER,
  opacity: 1,
  transition: 'none'
};

export const START_SCREEN_PLAYBUTTON = {
  pointerEvents: 'initial',
  margin: 'auto',
  alignSelf: 'center',
  zIndex: UI_LAYER - 1
};

export const INTRO_WRAPPER_STYLES = {
  display: 'flex',
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  margin: 'auto',
  pointerEvents: 'none',
  backgroundColor: 'rgba(0,0,0,0.8)',
  transition: 'none'
};

export const INTRO_TRANSITION_OUT = {
  transition: 'background-color 0.6s ease-in-out',
  backgroundColor: 'transparent'
};

/**
 * Limit text lines to nbLines
 * @param {Number} nbLines
 */
export const textStyleOnXLines = (nbLines) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'initial',
  overflow: 'hidden',
  display: '-webkit-box',
  '-webkit-line-clamp': nbLines,
  '-webkit-box-orient': 'vertical'
});

export const cropText = () => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

export const TEXT_LEVEL_1_FONT_SIZE_ONLY = {
  extraLarge: { fontSize: 28 },
  large: { fontSize: 28 },
  medium: { fontSize: 20 },
  small: { fontSize: 20 },
  extraSmall: { fontSize: 16 }
};
export const TEXT_LEVEL_1_FONT_SIZE = {
  default: { fontSize: 28, fontWeight: 700, display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' },
  medium: { fontSize: 20 },
  small: { fontSize: 20 },
  extraSmall: { fontSize: 16, maxHeight: 15 }
};

export const TEXT_LEVEL_2_FONT_SIZE = {
  default: { fontWeight: 400, fontSize: 12 },
  extraLarge: { fontSize: 18 },
  large: { fontSize: 18 },
  small: { fontSize: 16 },
  medium: { fontSize: 16 },
  extraSmall: { fontSize: 12 }
};

export const TEXT_LEVEL_3_FONT_SIZE = {
  default: { fontSize: 16, fontWeight: 400 },
  extraSmall: { fontSize: 12 },
  small: { fontSize: 14 },
  medium: { fontSize: 14 },
  large: { fontSize: 16 },
  extraLarge: { fontSize: 16 }
};

export const LABEL_TEXT_FONT_SIZE = {
  extraSmall: { fontSize: 12 },
  small: { fontSize: 12 },
  medium: { fontSize: 14 },
  large: { fontSize: 16 },
  extraLarge: { fontSize: 18 }
};

export const SPACING_A = {
  extraSmall: { size: 20 },
  small: { size: 20 },
  medium: { size: 28 },
  large: { size: 28 },
  extraLarge: { size: 28 }
};

export const SPACING_B = {
  extraSmall: { size: 16 },
  small: { size: 14 },
  medium: { size: 20 },
  large: { size: 20 },
  extraLarge: { size: 20 }
};

export const SPACING_C = {
  extraSmall: { size: 14 },
  small: { size: 14 },
  medium: { size: 14 },
  large: { size: 14 },
  extraLarge: { size: 14 }
};

export const BUTTON_SQUARE_SIZE = {
  extraSmall: { width: 16 },
  small: { width: 24 },
  medium: { width: 36 },
  'large+extraLarge': { width: 44 }
};
