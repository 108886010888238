import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function ReplayIcon({ height = 60, width = 60, color = 'white', frame }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill="none"
    >
      {/* eslint-disable-next-line react/no-unknown-property */}
      <mask id="mask_replay" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="56" height="56">
        {(!Number.isInteger(frame) || frame === 0)
          ? <path fillRule="evenodd" clipRule="evenodd" d="M57.5 30C57.5 45.1878 45.1878 57.5 30 57.5C14.8122 57.5 2.5 45.1878 2.5 30C2.5 14.8122 14.8122 2.5 30 2.5C45.1878 2.5 57.5 14.8122 57.5 30ZM28.4519 14.425L26.1842 16C25.7164 16.3239 25.7164 17.0261 26.1842 17.35L28.4519 18.925L29.7036 19.7828L30.4218 20.28C30.9588 20.6518 31.6898 20.2646 31.6898 19.6083V17.625C33.9372 17.9287 35.9951 18.8281 37.6983 20.1593C40.6214 22.4473 42.5 26.0083 42.5 30.0081C42.5 36.9117 36.9036 42.5081 30 42.5081C23.0964 42.5081 17.5 36.9117 17.5 30.0081C17.5 27.4616 18.2615 25.093 19.5691 23.1175C20.0005 22.4667 20.4913 21.8584 21.0338 21.3C21.2986 21.0275 21.4379 20.6696 21.4378 20.3161C21.4389 19.9893 21.3221 19.6658 21.0766 19.417C20.7734 19.1099 20.3452 18.991 19.9567 19.0797C19.7594 19.1231 19.5724 19.2204 19.4171 19.375C16.6887 22.0913 15 25.8458 15 30C15 38.2843 21.7157 45 30 45C38.2843 45 45 38.2843 45 30C45 22.287 39.1786 15.9396 31.6898 15.1V13.7413C31.6898 13.085 30.9588 12.6978 30.4218 13.0696L29.6474 13.6057L28.4519 14.425Z" fill="black" />
          : ''}
      </mask>
      <g mask="url(#mask_replay)">
        <rect width="60" height="60" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default ReplayIcon;
