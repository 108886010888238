import { createContext } from 'preact';
import { useEffect, useContext, useRef } from 'preact/hooks';
import { usePlayerContext } from '../hooks';
import { UI_IS_INTERACTING, UI_DIALOG_OPENED, UI_OPEN_DIALOG_COUNT_CHANGED } from '../../store/types';
import { USER_CLOSED_MENU, USER_OPENED_MENU } from '../../types';

export const DialogContext = createContext();

export const dialogContextController = {
  dialogs: [],
  get dialogOpened() {
    return this.dialogs.some(({ state: [opened] }) => opened);
  },
  add(dialogRef) {
    if (this.dialogs.some(({ id }) => dialogRef.id === id)) return;
    this.dialogs = [...this.dialogs, dialogRef];
  },
  remove(id) {
    this.dialogs = this.dialogs
      .filter((dialog) => dialog.id !== id);
  },
  closeAll(activeId) {
    this.dialogs
      .forEach(({ id, state: [, setOpen] }) => (activeId !== id && setOpen(false)));
  },
  openDialog(activeId) {
    this.dialogs
      .forEach(({ id, state: [, setOpen] }) => (activeId === id && setOpen(true)));
  },
  get activeId() {
    return this.dialogs.find(({ id, state: [opened] }) => opened && id)?.id;
  }
};

export const useDialogContext = () => useContext(DialogContext);

export const useRegisterDialog = (dialogRef) => {
  const { id, openingMode, state: [opened] } = dialogRef;
  const isValidDialog = openingMode === 'click';

  const dialogCtx = useContext(DialogContext);
  const { store } = usePlayerContext();

  useEffect(() => {
    if (isValidDialog) dialogCtx.add(dialogRef);
    return () => dialogCtx.remove(id);
  });

  useEffect(() => {
    store.dispatch({ type: UI_DIALOG_OPENED, payload: { hasDialogOpened: dialogCtx.dialogOpened } });
  }, [dialogCtx.dialogOpened]);

  useEffect(() => {
    if (isValidDialog) store.dispatch({ type: UI_IS_INTERACTING, payload: { isInteracting: opened } });
    if (isValidDialog && opened) dialogCtx.closeAll(id);
  }, [opened]);
};

export const useOpenDialogsCounter = (opened, isOverMode = false) => {
  const { store, userEvents$ } = usePlayerContext();
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const { ui: { openDialogsCount } } = store.getState();
    store.dispatch({ type: UI_OPEN_DIALOG_COUNT_CHANGED, payload: { displayStatus: opened } });

    // over mode dialogs does not close existing opened dialogs "modals", meaning we need a special check to avoid issues.
    if (!opened && isOverMode && openDialogsCount > 1) return;

    userEvents$.next({ action: opened ? USER_OPENED_MENU : USER_CLOSED_MENU });
  }, [opened]);
};
