import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { SLIDE_WRAPPER_STYLES } from './styles';
import { withBreakPoints } from '../../../hoc';
import { TRANSITION_DURATION } from '../../../theme/colors';

function Slide({
  children,
  active,
  slideIndex,
  onSlide,
  minHeight,
  style
}) {
  const el = useRef(null);

  useEffect(() => {
    const timer = active && setTimeout(() => (el.current && onSlide(el.current)), TRANSITION_DURATION);
    return () => clearTimeout(timer);
  }, [active]);

  return (
    <div
      ref={el}
      style={{
        ...SLIDE_WRAPPER_STYLES,
        ...style,
        opacity: active ? 1 : 0,
        minHeight: active ? 0 : minHeight,
        maxHeight: active ? 450 : minHeight,
        position: active ? 'static' : 'absolute',
        transform: active ? 'translateX(0px)' : `translateX(${slideIndex === 0 ? -120 : 120}%)`,
        transitionDuration: `${TRANSITION_DURATION}ms`
      }}
    >
      {children}
    </div>
  );
}

export default withBreakPoints(Slide);
