/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';
import { BTN_ACTIVE_SCALE } from '../../../../../../ui-tv/components/common/tvbutton/const';

function TVPreviousIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox={`0 0 ${width * BTN_ACTIVE_SCALE} ${height * BTN_ACTIVE_SCALE}`}
      fill="none"
    >
      <mask
        id="mask0_7755_18659"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={25}
        y={27}
        width={41}
        height={37}
      >
        <path
          d="M65.5 59.5362L65.5 31.2915C65.5 29.5833 63.6423 28.4982 62.1192 29.3166L37.0127 42.8066C35.46 43.6409 35.4173 45.8208 36.9362 46.7134L62.0426 61.4681C63.5662 62.3635 65.5 61.2829 65.5 59.5362Z"
          fill="black"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.7857 27.5C29.0481 27.5 30.0714 28.5074 30.0714 29.75L30.0714 61.25C30.0714 62.4926 29.0481 63.5 27.7857 63.5C26.5233 63.5 25.5 62.4926 25.5 61.25L25.5 29.75C25.5 28.5074 26.5234 27.5 27.7857 27.5Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_7755_18659)">
        <rect width={width * BTN_ACTIVE_SCALE} height={height * BTN_ACTIVE_SCALE} fill="#F8F8F8" />
      </g>
    </SVGWrapper>
  );
}

export default TVPreviousIcon;
