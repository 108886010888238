import { PANEL_LIVE_OPTION_BACKGROUND, PRIMARY_FILL_COLOR, SIDEBAR_SIZE_LARGE, SIDEBAR_SIZE_MEDIUM } from '../../../theme/colors';
import { OVERLAY_LAYER } from '../../../theme/constants';

export const ZAP_WRAPPER = {
  position: 'absolute',
  top: 0,
  right: 0,
  width: 'max-content',
  height: '100%',
  pointerEvents: 'none',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  aligntItems: 'center'
};

export const ZAP_CONTENT = {
  default: {
    borderRadius: '10px 0px 0px 10px',
    gap: 14,
    width: 64,
    display: 'flex',
    alignItems: 'center',
    background: PANEL_LIVE_OPTION_BACKGROUND,
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '14px 0px'
  },
  small: {
    width: 44,
    gap: 12,
    padding: '12px 0px'
  },
  'large+extraLarge': {
    width: 80
  }
};

export const ZAP_ICON_SIZE = {
  small: { size: 20 },
  medium: { size: 28 },
  'large+extraLarge': { size: 32 }
};

export const PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED = {
  default: {
    background: PANEL_LIVE_OPTION_BACKGROUND,
    color: 'white',
    zIndex: OVERLAY_LAYER + 2,
    position: 'absolute',
    right: 0,
    width: `${SIDEBAR_SIZE_MEDIUM}%`
  },
  'large+extraLarge': {
    width: `${SIDEBAR_SIZE_LARGE}%`
  },
  small: {
    width: '100%'
  }
};

export const PANEL_TITLE = {
  default: { fontSize: 24, padding: 16, fontWeight: 700, color: PRIMARY_FILL_COLOR, fontFamily: 'Brown' },
  small: { fontSize: 14, padding: 12 },
  'largeTv+extraLargeTv': { fontSize: 32 }
};

export const PANEL_CLOSE_LABEL = {
  default: {
    right: 10,
    top: 90
  },
  small: { top: 80 }
};
export const PANEL_CLOSE_BUTTON = {
  default: {
    position: 'absolute',
    width: 'max-content',
    right: 6,
    top: 18,
    transform: 'translate(-25%, 25%)'
  },
  small: { top: 15 }
};
export const PANEL_CLOSE_BUTTON_SIZE = { small: { width: 16 }, default: { width: 24 } };
