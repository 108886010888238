import { PAYSAGE } from '../../core/dom/types';
import { RENDERER_CREATED } from '../../core/renderer/types';
import {
  UI_CONFIG,
  SIZE_CHANGED,
  FULLSCREEN_CHANGED,
  UI_SEEKING,
  UI_CAN_INTERACT,
  UI_HOVER_TIMELINE,
  UI_IS_INTERACTING,
  UI_COMING_NEXT_SHOW,
  UI_COMING_NEXT_HIDE,
  WAITING_CHANGED,
  IS_INTRO_CHANGED,
  SPINNER_LABEL_CHANGED,
  AUTO_START_CHANGE,
  PLAYBACK_PLAYING,
  MEDIA_CHANGED,
  CONFIG_OVERRIDE,
  AD_STATUS_CHANGE,
  NEW_VIDEO,
  RENDERER_READY,
  ON_PLAY_TRIGGERED,
  PIP_ENTER,
  PIP_LEAVE,
  PLAYBACK_STOPPED,
  UI_FORCE_VISIBILITY,
  EMBED_KEY,
  ZAPPING_VISIBLE,
  ZAPPING_CHANGED,
  FEEDBACK_CHANGED,
  PLAYER_ERROR,
  PLAYER_WARNING,
  PLAYER_WARNING_CLOSE,
  ON_VIEWERS_DISPLAYED,
  UI_TOGGLE_HOVER_LABEL,
  UI_SHOW_REPORTER,
  PLAYBACK_WAITING,
  UI_DIALOG_OPENED,
  UI_SKIP_INTRO_SHOW,
  UI_SKIP_INTRO_HIDE,
  UI_VISIBLE,
  UI_ACCESSIBILITY_VISIBILITY,
  UI_SKIP_RECAP_SHOW,
  UI_SKIP_RECAP_HIDE,
  LABEL_PUB_CHANGED,
  LABEL_LIVE_CHANGED,
  LABEL_TIMELINE_CHANGED,
  UI_TRACKS_MENU_HIDE,
  UI_TRACKS_MENU_SHOW,
  MEDIA_RESTARTED,
  ORIENTATION_CHANGED,
  UI_OPEN_DIALOG_COUNT_CHANGED,
  TOGGLE_SHOW_PANNEL_LIVE_OPTION,
  ZAPPING_CHAT_CHANGED,
  UI_FAST_SPEED
} from '../types';

const initialState = {
  height: null,
  width: null,
  isExtraLargeTvScreen: false,
  isExtraLargeScreen: false,
  isLargeTvScreen: false,
  isLargeScreen: false,
  isMediumScreen: false,
  isSmallScreen: false,
  isExtraSmallScreen: false,
  isFullscreen: false,
  isInteracting: false,
  waiting: true,
  isIntro: false,
  isSeeking: false,
  hoverTimeline: false,
  forceVisible: false,
  forceHidden: false,
  introEnabled: true,
  waitStart: false,
  next: false,
  canInteract: false,
  playButtonReady: false,
  showTitle: true,
  forceShowTitle: false,
  isPIP: false,
  embedKey: null,
  feedback: null,
  keyCode: null,
  spinnerLabelType: null,
  displayedViewers: false,
  showHover: true,
  displayComingNextOnStart: true,
  reporter: false,
  hasDialogOpened: false,
  openDialogsCount: 0,
  UIVisible: false,
  showSkipIntro: false,
  showSkipRecap: false,
  showComingNext: false,
  isLandscape: null,
  displayUiAccessibility: false,
  pubLabel: '',
  liveLabel: '',
  timelineLabel: '',
  orientation: PAYSAGE,
  panelLiveOption: {
    show: false,
    currentTab: '',
    lastTab: ''
  },
  chatAvailable: false,
  currentSpeed: 1,
  fastSpeed: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UI_CONFIG: {
      const {
        showTitle,
        forceShowTitle,
        originUrl,
        origin,
        showViewers,
        displayComingNextOnStart
      } = payload;
      return ({
        ...state,
        showTitle,
        forceShowTitle,
        originUrl,
        origin,
        embedKey: null,
        displayedViewers: false,
        showViewers,
        displayComingNextOnStart
      });
    }

    case SIZE_CHANGED: {
      const {
        width,
        height,
        isExtraLargeTvScreen,
        isExtraLargeScreen,
        isLargeTvScreen,
        isLargeScreen,
        isMediumScreen,
        isSmallScreen,
        isExtraSmallScreen
      } = payload;

      return ({
        ...state,
        width,
        height,
        isLargeScreen,
        isLargeTvScreen,
        isExtraLargeScreen,
        isExtraLargeTvScreen,
        isMediumScreen,
        isSmallScreen,
        isExtraSmallScreen,
        isLandscape: window.innerHeight < window.innerWidth,
        ...(isSmallScreen ? { forceVisible: false } : {}) /* handle resize during forceVisible: true */
      });
    }
    case TOGGLE_SHOW_PANNEL_LIVE_OPTION: {
      const { show, currentTab: newTab } = payload;
      const { currentTab } = state.panelLiveOption;

      return ({
        ...state,
        panelLiveOption: { show, currentTab: newTab, lastTab: currentTab }
      });
    }

    case FULLSCREEN_CHANGED: {
      const { isFullscreen } = payload;
      return ({
        ...state,
        isFullscreen
      });
    }

    case ORIENTATION_CHANGED: {
      const { orientation } = payload;

      return ({
        ...state,
        orientation
      });
    }

    case UI_IS_INTERACTING: {
      const { isInteracting } = payload;
      return ({
        ...state,
        isInteracting
      });
    }

    case UI_CAN_INTERACT: {
      const { canInteract } = payload;
      return ({
        ...state,
        canInteract
      });
    }

    case WAITING_CHANGED: {
      const { waiting } = payload;
      return ({ ...state, waiting });
    }

    case PLAYER_ERROR: {
      return ({ ...state, spinnerLabelType: null });
    }

    case SPINNER_LABEL_CHANGED: {
      const { spinnerLabelType: prevLabelType } = state;
      const { spinnerLabelType } = payload;
      return ({
        ...state,
        spinnerLabelType,
        forceHidden: Boolean(spinnerLabelType),
        /* reset interaction only after a label reset */
        ...(prevLabelType && !spinnerLabelType ? { canInteract: true } : {})
      });
    }

    case IS_INTRO_CHANGED: {
      const { isIntro } = payload;

      /* disable/enable UI interactions when entering/leaving intro animation */
      return ({
        ...state,
        isIntro,
        canInteract: !isIntro && !state.spinnerLabelType,
        forceHidden: isIntro
      });
    }

    case PLAYBACK_STOPPED: {
      /* kills animation if PLAYBACK_STOPPED happens before animation ends */
      return ({
        ...state,
        waiting: false,
        isIntro: false,
        canInteract: true
      });
    }

    case PLAYBACK_WAITING: {
      return ({
        ...state,
        waiting: true
      });
    }

    case UI_SEEKING: {
      const { isSeeking } = payload;
      return ({
        ...state,
        isSeeking
      });
    }

    case UI_HOVER_TIMELINE: {
      const { hoverTimeline, isInteracting } = payload;
      return ({
        ...state,
        hoverTimeline,
        isInteracting
      });
    }

    case UI_COMING_NEXT_SHOW: {
      return ({ ...state, showComingNext: true });
    }

    case UI_COMING_NEXT_HIDE: {
      return ({ ...state, showComingNext: false });
    }

    case PLAYER_WARNING: {
      return { ...state, forceHidden: true };
    }

    case PLAYER_WARNING_CLOSE: {
      return { ...state, forceHidden: false };
    }

    case UI_FORCE_VISIBILITY: {
      const { forceVisible, introEnabled } = payload;
      return ({ ...state, forceVisible, introEnabled });
    }

    case UI_ACCESSIBILITY_VISIBILITY: {
      const { displayUiAccessibility } = payload;
      return ({ ...state, displayUiAccessibility });
    }

    case ZAPPING_VISIBLE: {
      const { visible } = payload;
      return ({ ...state, forceHidden: visible });
    }

    case ZAPPING_CHANGED: {
      return ({ ...state, forceHidden: false });
    }

    case ZAPPING_CHAT_CHANGED: {
      const { chatAvailable } = payload;
      return ({ ...state, chatAvailable });
    }

    case AUTO_START_CHANGE: {
      const { autostart } = payload;
      return ({
        ...state,
        waitStart: !autostart
      });
    }

    case PLAYBACK_PLAYING:
    case ON_PLAY_TRIGGERED: {
      return ({
        ...state,
        waitStart: false
      });
    }

    case MEDIA_CHANGED: {
      return ({
        ...state,
        waiting: true,
        isInteracting: false,
        canInteract: false,
        comingNextVisible: false
      });
    }

    case AD_STATUS_CHANGE: {
      const { isAd, position } = payload;
      /**
       * - resolve interactions when ad has actually started (both for PREROLL & MIDROLL)
       * - block interactions when isAd: false on prerolls
       *   -> will be resolved after intro animation for preroll
       */
      return ({
        ...state,
        canInteract: isAd || (Boolean(position) && position !== 'PREROLL')
      });
    }

    case CONFIG_OVERRIDE: {
      const { config: { next } } = payload;
      return ({
        ...state, next
      });
    }

    case MEDIA_RESTARTED: {
      const { showRestartButtonOnStartScreen } = payload;
      return ({
        ...state, showRestartButtonOnStartScreen
      });
    }

    case NEW_VIDEO: {
      return ({
        ...state,
        playButtonReady: false,
        forceVisible: false
      });
    }

    case RENDERER_CREATED:
    case RENDERER_READY: {
      return ({
        ...state,
        playButtonReady: true
      });
    }

    case PIP_ENTER: {
      return ({
        ...state,
        isPIP: true
      });
    }

    case PIP_LEAVE: {
      return ({
        ...state,
        isPIP: false
      });
    }

    case EMBED_KEY: {
      const { embedKey } = payload;
      return ({
        ...state,
        embedKey
      });
    }

    case FEEDBACK_CHANGED: {
      const { feedback, keyCode } = payload;
      return ({
        ...state,
        feedback,
        keyCode
      });
    }

    case ON_VIEWERS_DISPLAYED: {
      const { displayedViewers } = payload;
      return ({
        ...state,
        displayedViewers
      });
    }

    case UI_TOGGLE_HOVER_LABEL: {
      const { showHover } = payload;
      return ({ ...state, showHover });
    }

    case UI_SHOW_REPORTER: {
      const { reporter } = payload;
      return ({ ...state, reporter });
    }

    case UI_DIALOG_OPENED: {
      const { hasDialogOpened } = payload;

      return ({ ...state, hasDialogOpened });
    }

    case UI_OPEN_DIALOG_COUNT_CHANGED: {
      const { displayStatus } = payload;

      if (displayStatus) { return { ...state, openDialogsCount: state.openDialogsCount + 1 }; }
      if (state.openDialogsCount > 0) { return { ...state, openDialogsCount: state.openDialogsCount - 1 }; }
      return state;
    }

    case UI_VISIBLE: {
      const { hasUiVisible } = payload;
      return ({ ...state, UIVisible: hasUiVisible });
    }
    case UI_SKIP_INTRO_SHOW: {
      return ({ ...state, showSkipIntro: true });
    }

    case UI_SKIP_INTRO_HIDE: {
      return ({ ...state, showSkipIntro: false });
    }

    case UI_SKIP_RECAP_SHOW: {
      return ({ ...state, showSkipRecap: true });
    }
    case UI_SKIP_RECAP_HIDE: {
      return ({ ...state, showSkipRecap: false });
    }

    case UI_TRACKS_MENU_HIDE: {
      return ({ ...state, showTracks: false });
    }
    case UI_TRACKS_MENU_SHOW: {
      return ({ ...state, showTracks: true });
    }

    case LABEL_LIVE_CHANGED: {
      return ({ ...state, liveLabel: payload.label });
    }

    case LABEL_TIMELINE_CHANGED: {
      return ({ ...state, timelineLabel: payload.label });
    }

    case LABEL_PUB_CHANGED: {
      return ({ ...state, pubLabel: payload.label });
    }
    case UI_FAST_SPEED: {
      const { currentSpeed, fastSpeed } = payload;
      return ({ ...state, currentSpeed, fastSpeed });
    }
    default: {
      return state;
    }
  }
};
