/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPlayIconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M45 86.25C67.7817 86.25 86.25 67.7817 86.25 45C86.25 22.2183 67.7817 3.75 45 3.75C22.2183 3.75 3.75 22.2183 3.75 45C3.75 67.7817 22.2183 86.25 45 86.25ZM31.25 60.7871V29.404C31.25 27.4632 33.3651 26.2626 35.0315 27.2575L62.4917 43.6516C64.153 44.6434 64.1063 47.0655 62.4079 47.9925L34.9478 62.9814C33.2818 63.8908 31.25 62.685 31.25 60.7871Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvPlayIconActive;
