import { LABELS_FILL_COLOR, SCROLLBAR_BACKGROUND_COLOR, THIRD_TEXT_COLOR } from '../../../theme/colors';
import { FTV_TIMING_FUNCTION_IN } from '../../../theme/constants';

export const SLIDE_WRAPPER_STYLES = {
  display: 'flex',
  flexDirection: 'column',
  transitionTimingFunction: FTV_TIMING_FUNCTION_IN,
  transitionProperty: 'all',
  width: '100%'
};

export const NAV_BAR_STYLES = {
  default: {
    fontFamily: 'Brown',
    fontWeight: 700,
    height: 28,
    width: '80%',
    position: 'relative',
    marginBottom: 24
  },
  'medium+large+extraLarge': {
    marginBottom: 28
  },
  small: { marginBottom: 20 },
  extraSmall: { marginBottom: 20 }
};

export const NAV_BAR_TITLE_STYLES = {
  default: {
    display: 'flex',
    alignItems: 'center',
    transition: `all .32s ${FTV_TIMING_FUNCTION_IN}`,
    position: 'absolute'
  }
};

export const ROW_ITEM_STYLE = {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Brown',
  fontWeight: 400
};

export const ROW_ITEM_LABEL_STYLE = {
  maxWidth: '65%',
  whiteSpace: 'nowrap'
};

export const ROW_ITEM_SUBLABEL_STYLE = {
  fontSize: '100%',
  color: LABELS_FILL_COLOR,
  display: 'flex',
  alignItems: 'center'
};

export const ACTIVE_BULLET_STYLE = {
  default: {
    height: 6,
    width: 6,
    backgroundColor: LABELS_FILL_COLOR,
    flexShrink: 0,
    borderRadius: 10
  },
  extraSmall: {
    width: 4,
    height: 4
  }
};

export const RADIO_BUTTON_STYLE = {
  default: {
    aspectRatio: '1 / 1',
    width: 16,
    border: '2px solid #B4B4B4',
    boxSizing: 'border-box',
    borderRadius: '50%'
  },
  extraSmall: {
    width: 12
  }
};

export const ACTIVE_RADIO_BUTTON_STYLE = {
  aspectRatio: '1 / 1',
  width: '50%',
  background: THIRD_TEXT_COLOR,
  transform: 'translate(50%, 50%)',
  borderRadius: '50%'
};

export const SELECTABLE_DESCRIPTION_STYLES = {
  default: {
    lineHeight: '1.2',
    marginTop: 2,
    color: LABELS_FILL_COLOR
  }
};

export const SEPARATOR_STYLE = {
  default: {
    backgroundColor: SCROLLBAR_BACKGROUND_COLOR,
    height: 0.9,
    marginTop: 14,
    marginRight: 30
  },
  small: {
    margin: '14px 30px 0px 0'
  },
  extraSmall: { marginTop: 14, marginRight: 24 }
};
