export const PLAY = 'play';
export const STOP = 'stop';
export const FIRST_PLAY = 'first_play';
export const PAUSE = 'pause';
export const INTERRUPTION = 'interruption';
export const SEEK = 'seek';
export const MUTE = 'mute';
export const VOLUME = 'volume';
export const SPEED = 'speed';
export const NO_OP = 'noop';
export const INITIATED_PLAY = 'initiated_play';
