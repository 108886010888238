import { OVERLAY_SCREEN_OPAQUE_BACKGROUND, PLACEHOLDER_FILL_COLOR } from '../../theme/colors';
import { FTV_TIMING_FUNCTION_IN, FTV_TIMING_FUNCTION_OUT, COMING_NEXT_LAYER, OVERLAY_LAYER } from '../../theme/constants';

export const AD_INFOS_WRAPPER_STYLE = {
  position: 'absolute',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  zIndex: OVERLAY_LAYER,
  top: 0
};

export const START_SCREEN_CONTAINER_HIDDEN = {
  transition: 'opacity .3s ease-out',
  opacity: 0,
  pointerEvents: 'none'
};

export const START_SCREEN = {
  backgroundColor: OVERLAY_SCREEN_OPAQUE_BACKGROUND,
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  filter: 'brightness(80%)'
};

export const START_SCREEN_BUTTON_SIZE = 120;
export const START_SCREEN_BUTTON_SIZE_MINI = 60;

export const OVERLAY_WRAPPER = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};

export const TITLE_WRAPPER = {
  position: 'relative'
};

export const HEADER_WRAPPER = {
  default: {
    display: 'flex',
    flexDirection: 'row'
  }
};

export const COMING_NEXT_TITLE = {
  position: 'absolute',
  top: 0,
  left: 30,
  opacity: 0,
  width: '100%'
};

export const COMING_NEXT_TITLE_LARGE = {
  position: 'absolute',
  top: 0,
  opacity: 0.6,
  width: '100%'
};

export const MAIN_TITLE_TRANSITION_OUT = {
  transition: `opacity .2s linear, transform .6s ${FTV_TIMING_FUNCTION_IN}`,
  opacity: 0,
  transform: 'translate3d(-100px, 0, 0)'
};

export const MAIN_TITLE_TRANSITION = {
  transition: 'opacity .2s linear',
  transitionDelay: '.16s',
  opacity: 1
};

export const COMING_NEXT_TITLE_TRANSITION = {
  display: 'block',
  transition: `opacity .2s linear, transform .8s ${FTV_TIMING_FUNCTION_IN}`,
  transitionDelay: '.16s',
  opacity: 1,
  transform: 'translate3d(-30px, 0, 0)'
};

export const COMING_NEXT_TITLE_TRANSITION_OUT = {
  display: 'block',
  transition: 'opacity .2s linear',
  transform: 'translate3d(-30px, 0, 0)',
  opacity: 0
};

export const COMING_NEXT_TITLE_TRANSITION_START = {
  display: 'block',
  transition: `opacity .2s linear, transform .8s ${FTV_TIMING_FUNCTION_IN}`,
  transform: 'translate3d(0px, 0, 0)',
  opacity: 0
};

export const COMING_NEXT_STYLES = {
  initialState: {
    opacity: 0
  },
  enterAnimation: {
    transform: 'translate3d(0,0,0)',
    opacity: 1
  },
  leaveAnimation: {
    transition: `opacity .4s linear .12s, transform .52s ${FTV_TIMING_FUNCTION_OUT}`
  },
  wrapper: {
    default: {
      position: 'absolute',
      right: 40,
      zIndex: COMING_NEXT_LAYER,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column'
    },
    small: { right: 15, top: 15, bottom: 0 },
    extraSmall: { right: 5, top: 0, bottom: 0 }
  },
  backgroundBlock: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    backgroundColor: '#232323',
    transition: `opacity .8s linear, transform .8s ${FTV_TIMING_FUNCTION_IN} .4s`
  },
  textBlock: {
    bottom: 0,
    right: 0,
    zIndex: COMING_NEXT_LAYER + 1,
    fontFamily: 'Brown',
    fontWeight: 400,
    color: 'white',
    transition: `opacity .4s linear .52s, transform .72s ${FTV_TIMING_FUNCTION_IN} .48s`,
    padding: '8px 10px 10px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden'
  },
  imageBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundColor: PLACEHOLDER_FILL_COLOR,
    transition: `opacity .8s linear 0.4s, transform .72s ${FTV_TIMING_FUNCTION_IN} .55s`
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    transition: `opacity .8s linear 0.4s, transform .72s ${FTV_TIMING_FUNCTION_IN} .55s`,
    zIndex: COMING_NEXT_LAYER + 2
  },
  title: {
    lineHeight: 1.3,
    marginBottom: 8,
    webkitLineClamp: 2,
    webkitBoxOrient: 'vertical',
    display: '-webkit-box',
    maxHeight: '2.6em',
    overflow: 'hidden'
  },
  subTitle: {
    lineHeight: 1.43,
    marginTop: 0,
    webkitLineClamp: 2,
    webkitBoxOrient: 'vertical',
    display: '-webkit-box',
    maxHeight: '2.86em',
    overflow: 'hidden'
  }
};

export const EXTRA_INFOS_WRAPPER_STYLE = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
};

export const LOGO_WRAPPER = {
  default: {
    marginRight: 15,
    width: 70,
    height: 70,
    marginTop: 22
  },
  'medium+small': {
    marginTop: 18
  },
  large: {
    width: 90,
    height: 90,
    marginTop: 33
  },
  'small+extraSmall': {
    display: 'none'
  }
};
