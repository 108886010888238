import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function RightArrowIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >

      <mask id="mask0_right_arrow" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="2" width="7" height="12">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.18687 2.14645C5.9916 1.95118 5.67502 1.95118 5.47976 2.14645C5.2845 2.34171 5.2845 2.65829 5.47976 2.85355L10.662 8L5.47976 13.1464C5.2845 13.3417 5.2845 13.6583 5.47976 13.8536C5.67502 14.0488 5.9916 14.0488 6.18687 13.8536L11.7659 8.2357C11.8307 8.17044 11.863 8.08507 11.8627 7.9998C11.8629 7.91466 11.8306 7.82946 11.7659 7.7643L6.18687 2.14645Z" fill="#F2F2F2" />
      </mask>
      <g mask="url(#mask0_right_arrow)">
        <rect width="16" height="16" fill={color} />
      </g>
    </SVGWrapper>
  );
}
export default RightArrowIcon;
