import { h } from 'preact';
import { useStyles } from '../../../hooks';
import Position from '../../../utils/Position';

function SlideContent({ children }) {
  const setStyles = useStyles();
  return (
    <div
      role="listitem"
      style={setStyles({
        default: { fontSize: 16 },
        large: { fontSize: 18 }
      })}
    >
      <Position>{children}</Position>
    </div>
  );
}

export default SlideContent;
