import { FTV_DARK_PRIMARY_ACTIVE_BACKGROUND_COLOR, FTV_DARK_PRIMARY_HOVER_BACKGROUND_COLOR } from '../../../theme/colors';
import { SKIP_BTN_STYLE_FOCUS_HOVER } from '../../common/buttons/styles';

export const SKIP_BUTTON_ACTIVE = {
  default: {
    backgroundColor: FTV_DARK_PRIMARY_ACTIVE_BACKGROUND_COLOR
  },
  'largeTv+extraLargeTv': SKIP_BTN_STYLE_FOCUS_HOVER
};

export const SKIP_BUTTON_HOVER = {
  default: {
    backgroundColor: FTV_DARK_PRIMARY_HOVER_BACKGROUND_COLOR
  }
};

export const SKIP_BUTTON_CUSTOM_FONT = {
  small: {
    fontSize: '12px'
  },
  extraLarge: {
    fontSize: '20px'
  }
};
