import { h } from 'preact';
import { useState } from 'preact/hooks';
import { useStyles } from '../../../hooks';

function SliderBar({
  onSlideStart,
  style: {
    track,
    primaryTrack,
    secondaryTrack,
    cursor
  },
  value,
  buffer = 0,
  targetRef,
  touch,
  vertical,
  primaryTrackStyles,
  secondaryTrackStyles,
  sliderStyle
}) {
  const [animate, setAnimate] = useState(true);
  const setStyles = useStyles();
  return (
    <div style={{ ...track }} ref={targetRef}>
      <div
        role="presentation"
        style={{
          ...primaryTrack,
          ...(vertical ? { height: `${value}%` } : {
            width: `${value}%`,
            ...(animate ? { transition: 'all .20s ease-in-out' } : {}),
            ...primaryTrackStyles
          })
        }}
      />
      <div role="presentation" style={{ ...secondaryTrack, [vertical ? 'height' : 'width']: `${buffer}%`, ...secondaryTrackStyles }} />
      <div
        style={{
          ...(vertical ? {} : {
            ...sliderStyle
          }),
          ...setStyles(cursor)
        }}
        {...{
          [touch ? 'onTouchStart' : 'onMouseDown']: (e) => { setAnimate(false); onSlideStart(e); }
        }}
        {...{
          [touch ? 'ontouchend' : 'onMouseUp']: () => (setAnimate(true))
        }}
      />
    </div>
  );
}
export default SliderBar;
