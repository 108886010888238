import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PauserollResumeIcon({ height = 60, width = 60, color = 'black', ...otherProps }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      {...otherProps}
    >
      {/* eslint-disable-next-line react/no-unknown-property */}
      <mask id="mask0_pauseroll_resume" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="44" height="44">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.0001 26.8333C21.0877 26.8333 26.8334 21.0877 26.8334 14C26.8334 6.91235 21.0877 1.16667 14.0001 1.16667C6.91243 1.16667 1.16675 6.91235 1.16675 14C1.16675 21.0877 6.91243 26.8333 14.0001 26.8333ZM9.7223 18.9115V9.14792C9.7223 8.54412 10.3803 8.17059 10.8988 8.4801L19.4419 13.5805C19.9588 13.8891 19.9443 14.6426 19.4159 14.931L10.8727 19.5942C10.3544 19.8771 9.7223 19.502 9.7223 18.9115Z" fill="white" />
      </mask>
      <g mask="url(#mask0_pauseroll_resume)">
        <rect width="28" height="28" fill={color} />
      </g>
    </SVGWrapper>
  );
}

export default PauserollResumeIcon;
