import { PRIMARY_TEXT_COLOR } from '../../theme/colors';
import { SPINNER_LAYER } from '../../theme/constants';

export const SPINNER_TIME_STEP = 130;
export const SPINNER_SIZE = 100;
export const BASE_DOT_SIZE = 12;

export const BASE_DOT_STYLE = {
  position: 'absolute',
  height: BASE_DOT_SIZE,
  width: BASE_DOT_SIZE,
  borderRadius: BASE_DOT_SIZE * 2,
  display: 'block',
  transform: 'translate3d(0, 0, 0)',
  backfaceVisiblity: 'hidden'
};

export const SPINNER_WRAPPER = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: SPINNER_LAYER
};

export const SPINNER_CONTAINER = {
  width: SPINNER_SIZE,
  height: SPINNER_SIZE,
  position: 'relative',
  display: 'flex',
  transition: 'opacity .25s ease-out',
  transitionDelay: '1s'
};

export const SPINNER_LETTERS_CONTAINER = {
  position: 'absolute',
  width: 195,
  height: 44,
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  margin: 'auto',
  transform: 'translate3d(-44px, 0, 0)',
  backfaceVisiblity: 'hidden'
};

export const SPINNER_LETTER_BASE_STYLE = {
  transition: 'all .12s ease-out',
  transform: 'translate3d(0,0,0)'
};

export const SPINNER_LABEL_STYLE = {
  fontFamily: 'Brown',
  fontWeight: 700,
  color: PRIMARY_TEXT_COLOR,
  fontSize: 22
};
