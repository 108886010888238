import { h } from 'preact';
import { TVSkipButtonWrapper } from '../tvskipbutton';

export function TVSectionOverlay() {
  return (
    <div className="ftv-magneto-section-overlay">
      <TVSkipButtonWrapper />
    </div>
  );
}
