import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { connect, Delayed, withBreakPoints } from '../../../hoc';
import { usePlayerContext, useStyles } from '../../../hooks';
import {
  SKIP_BTN_LAYER_STYLE,
  SKIP_BTN_STYLE
} from '../../common/buttons/styles';
import { LabeledButton } from '../../common';
import { PLAYER_BUTTON_DARK_STYLE } from '../../recommendations/styles';
import { TEXT_LEVEL_3_FONT_SIZE } from '../../../styles';
import { SKIP_BUTTON_ACTIVE, SKIP_BUTTON_HOVER, SKIP_BUTTON_CUSTOM_FONT } from './styles';

function SkipButton({
  show,
  timecode,
  duration,
  isFullscreen,
  tablet,
  label,
  style,
  clickEvent,
  seek = true,
  isTv
}) {
  if ((timecode && duration) === null) {
    return null;
  }
  const player = usePlayerContext();
  const setStyles = useStyles();

  const btn = useCallback((node) => {
    if (node) setTimeout(() => node.focus(), 100);
  }, []);

  return (
    <Delayed isMounted={show} shouldDelay delay={300}>
      {() => (
        <div
          type="button"
          style={{
            ...setStyles(SKIP_BTN_LAYER_STYLE),
            ...(isFullscreen && tablet && { top: '80%' }),
            animation: show
              ? 'ftv-magneto-skip-button-slide-up .3s'
              : 'ftv-magneto-skip-button-slide-down .3s'
          }}
        >
          <LabeledButton
            finalRef={btn}
            onClick={(e) => {
              e.stopPropagation();
              player.trigger(clickEvent);
              if (seek) {
                player.seek(timecode + duration);
              }
            }}
            customStyle={{
              ...setStyles(PLAYER_BUTTON_DARK_STYLE),
              ...setStyles({ ...TEXT_LEVEL_3_FONT_SIZE, ...SKIP_BUTTON_CUSTOM_FONT }),
              ...(isTv ? setStyles(SKIP_BTN_STYLE) : {}),
              ...setStyles(style)
            }}
            customActiveStyle={SKIP_BUTTON_ACTIVE}
            customHoverStyle={SKIP_BUTTON_HOVER}
            label={label}
          />
        </div>
      )}
    </Delayed>
  );
}

const selector = ({ media: { isTv } }) => ({ isTv });
export default withBreakPoints(connect(selector)(SkipButton));
