import { fromEvent } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { Disposable } from '..';

export default class VisibilityController extends Disposable {
  constructor() {
    super();
    /* hidden emits when browser is put in background or during a tab change */
    this.hidden$ = VisibilityController.createVisibilityStream(document);
  }

  static createVisibilityStream(document) {
    return fromEvent(document, 'visibilitychange').pipe(
      map(() => document.hidden),
      filter(Boolean)
    );
  }
}
