/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPlayIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M31.2502 60.7873V29.4042C31.2502 27.4635 33.3654 26.2628 35.0318 27.2577L62.4919 43.6518C64.1532 44.6436 64.1065 47.0657 62.4082 47.9927L34.948 62.9816C33.2821 63.891 31.2502 62.6852 31.2502 60.7873Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvPlayIcon;
