import { h } from 'preact';
import { usePlayerContext, useStyles } from '../../hooks';
import { connect, withBreakPoints } from '../../hoc';
import { Label } from '../common';
import { AD_INFOS_WRAPPER_STYLE } from './styles';
import Position from '../../utils/Position';
import { MAIN_WRAPPER_PADDING } from '../wrappers/styles';
import { PRIMARY_TEXT_COLOR } from '../../theme/colors';

function AdInfos({
  isAd, isDAI, mobile, showCountdown, pubLabel
}) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  return (
    (isAd || showCountdown)
      ? (
        <div style={{ ...AD_INFOS_WRAPPER_STYLE, ...setStyles(MAIN_WRAPPER_PADDING), paddingBottom: 0 }}>
          <Position>
            {pubLabel && <Label message={pubLabel} />}
            {
              mobile && !showCountdown
              && (
              <Label
                message={"+ d'infos"}
                onClick={() => player.adClick(isDAI ? 'dai' : 'freewheel')}
                styleText={{ color: PRIMARY_TEXT_COLOR }}
                opaque
              />
              )
            }
          </Position>
        </div>
      ) : null
  );
}

const selector = ({
  media: { isAd, isDAI },
  ad: { showCountdown },
  ui: { pubLabel }
}) => ({
  isAd,
  isDAI,
  showCountdown,
  pubLabel
});
export default withBreakPoints(connect(selector)(AdInfos));
