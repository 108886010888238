export const PAUSEROLL_BTN_PLAY_STYLES = {
  default: {
    height: 32,
    padding: '6px 16px',
    borderRadius: 60,
    fontSize: 16,
    iconSize: 20,
    gap: 4,
    fontWeight: 'normal',
    display: 'flex',
    color: 'black',
    flexDirection: 'row',
    border: 'unset'
  },
  medium: {
    height: 36,
    iconSize: 24,
    fontSize: 14
  },
  'small+extraSmall': {
    fontSize: 12
  },
  'large+extraLarge': {
    height: 44,
    padding: '8px 20px',
    fontSize: 16,
    iconSize: 28,
    gap: 8
  },
  'largeTv+extraLargeTv': {
    height: 72,
    padding: 36,
    fontSize: 30,
    iconSize: 48,
    gap: 15,
    fontWeight: 400
  }
};
