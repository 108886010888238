import { h } from 'preact';
import { VOID_FN } from '../../../../utils';
import { ROW_ITEM_STYLE, ROW_ITEM_LABEL_STYLE, ROW_ITEM_SUBLABEL_STYLE } from './styles';
import { useStyles } from '../../../hooks';
import { Icon } from '../svg/icons';
import { LABELS_FILL_COLOR } from '../../../theme/colors';

function RowItem({
  label,
  ariaLabel,
  sublabel,
  ariaValue = sublabel,
  link = false,
  onClick = VOID_FN,
  children,
  style,
  ignorePosition = false,
  first = false,
  last = false,
  linkIcon = 'left-arrow',
  linkIconStyle = { transform: 'rotate(180deg)' },
  focusable = false,
  index = 0,
  totalCount = 0
}) {
  const setStyles = useStyles();

  const svg = setStyles({
    extraSmall: { size: 16 },
    default: { size: 24 }
  });

  const ariaTextValue = (typeof (ariaValue) === 'function' ? ariaValue() : ariaValue);

  return (
    <div
      role="button"
      tabIndex={0}
      aria-label={`${ariaLabel || label}, ${ariaTextValue ? `${ariaTextValue}, ` : ''}${index} sur ${totalCount}`}
      className={focusable && 'ftv-magneto--focusable-item'}
      style={{
        ...ROW_ITEM_STYLE,
        marginTop: 0,
        cursor: 'pointer',
        marginBottom: 14,
        ...setStyles({
          default: {
            fontSize: 16,
            paddingRight: 24,
            marginTop: !ignorePosition && first ? 0 : 14
          },
          'medium+large+extraLarge': {
            fontSize: 18,
            marginTop: !ignorePosition && first ? 0 : 14
          },
          small: { marginTop: !ignorePosition && first ? 0 : 14 },
          extraSmall: { marginTop: !ignorePosition && first ? 0 : 14, paddingRight: 20 }
        }),
        ...(last ? { marginBottom: 0 } : {})
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.keyCode === 32) onClick(e);
        if (e.keyCode === 32) e.stopPropagation();
      }}
    >
      <div
        onClick={onClick}
        style={{ ...ROW_ITEM_LABEL_STYLE, ...style, cursor: 'pointer', color: LABELS_FILL_COLOR }}
        role="presentation"
      >
        {label}
      </div>
      <div
        onClick={onClick}
        role="presentation"
        style={{
          ...ROW_ITEM_SUBLABEL_STYLE,
          cursor: link ? 'pointer' : 'unset',
          fontSize: style?.fontSize || 'inherit',
          color: LABELS_FILL_COLOR
        }}
      >
        {sublabel ? <span>{sublabel}</span> : children}
        {
          link
          && (
          <Icon
            name={linkIcon}
            style={linkIconStyle}
            width={svg.size}
            height={svg.size}
          />
          )
        }
      </div>
    </div>
  );
}

export default RowItem;
