/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvRewind64Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM42.3334 46.2514V73.0816C42.3334 74.7408 40.7406 75.7673 39.4857 74.9167L18.8065 60.901C17.5555 60.053 17.5906 57.9823 18.8696 57.1898L39.5487 44.3754C40.8033 43.5979 42.3334 44.6288 42.3334 46.2514ZM66.7778 46.2514V73.0816C66.7778 74.7408 65.3298 75.7673 64.189 74.9167L45.3898 60.901C44.2524 60.053 44.2844 57.9823 45.4471 57.1898L64.2463 44.3754C65.3868 43.5979 66.7778 44.6288 66.7778 46.2514ZM32.6963 21.84L30.9163 19.3H27.8363L31.1563 23.84L27.3763 29H30.3963L32.6563 25.84L34.8963 29H37.9763L34.1963 23.82L37.5163 19.3H34.4963L32.6963 21.84ZM46.7552 15H43.7752L41.0552 19.46C39.9352 21.3 39.0152 22.96 39.0152 24.48C39.0152 27.38 41.2752 29.2 44.1552 29.2C46.9352 29.2 49.3152 27.22 49.3152 24.2C49.3152 21.28 47.0552 19.5 44.5152 19.5H44.0352L46.7552 15ZM44.1552 26.8C42.7152 26.8 41.6952 25.8 41.6952 24.3C41.6952 23.44 42.0952 22.6 42.5152 21.98C43.0152 21.82 43.5152 21.7 44.0152 21.7C45.6352 21.7 46.6552 22.84 46.6552 24.2C46.6552 25.8 45.5552 26.8 44.1552 26.8ZM59.7093 15H57.7093L50.5093 24.2V25.8H57.0693V29H59.7093V25.8H62.0093V23.4H59.7093V15ZM57.0693 23.4H54.3493L57.0693 19.9V23.4Z" fill="white" />
    </SVGWrapper>
  );
}

export default TvRewind64Icon;
