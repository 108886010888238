import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function EcoActiveIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >

      <mask id="mask0_eco_active" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
        <path d="M14.048 1.7481C13.952 1.6521 13.824 1.6001 13.688 1.6001C13.588 1.6041 11.852 1.6961 9.68799 2.2001C8.41199 2.4961 7.24799 2.8721 6.23599 3.3241C4.95199 3.8961 3.89999 4.5841 3.10799 5.3761C1.22799 7.2561 1.09199 10.2681 2.77199 12.3081L3.49599 11.5841L4.71199 10.3681V8.0961C4.71199 7.8121 4.93999 7.5841 5.22399 7.5841C5.50799 7.5841 5.73599 7.8121 5.73599 8.0961V9.3441L6.63199 8.4521V6.1801C6.63199 5.8961 6.85998 5.6681 7.14399 5.6681C7.42799 5.6681 7.65599 5.8961 7.65599 6.1801V7.4281L8.98399 6.1001C9.07999 6.0041 9.20799 5.9481 9.34399 5.9481C9.47999 5.9481 9.60799 6.0001 9.70399 6.1001C9.79999 6.1961 9.85599 6.3241 9.85599 6.4641C9.85599 6.6041 9.80399 6.7281 9.70399 6.8241L8.37599 8.1521H9.62399C9.90799 8.1521 10.136 8.3801 10.136 8.6641C10.136 8.9481 9.90799 9.1761 9.62399 9.1761H7.35199L6.45999 10.0681H7.70799C7.99199 10.0681 8.21999 10.3001 8.21999 10.5801C8.21999 10.8601 7.99199 11.0921 7.70799 11.0921H5.43599L4.21999 12.3081L3.49599 13.0321C4.41599 13.7881 5.57598 14.2041 6.77599 14.2041C8.15598 14.2041 9.45599 13.6641 10.432 12.6881C11.22 11.9001 11.912 10.8441 12.484 9.5601C12.932 8.5481 13.312 7.3881 13.608 6.1081C14.108 3.9441 14.204 2.2081 14.208 2.1361C14.216 1.9921 14.16 1.8521 14.06 1.7521L14.048 1.7481Z" fill="black" />
      </mask>
      <g mask="url(#mask0_eco_active)">
        <rect width="16" height="16" fill={color} />
      </g>

    </SVGWrapper>

  );
}

export default EcoActiveIcon;
