import { filter } from 'rxjs/operators';
import { USER_FAST_FORWARD, USER_FAST_REWIND, USER_CLICK } from '../../types';
import { Disposable } from '..';

export default class FeedBackController extends Disposable {
  constructor({ userEvents$ }) {
    super();
    this.feedback$ = FeedBackController.createFeedBackStream({ userEvents$ });
  }

  static createFeedBackStream({ userEvents$ }) {
    return userEvents$
      .pipe(filter(({ action, source }) => (
        (action === USER_FAST_FORWARD || action === USER_FAST_REWIND)
      && source === USER_CLICK)));
  }
}
