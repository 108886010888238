/* ERRORS */
export const INITIALIZATION_ERROR = 'INITIALIZATION_ERROR';

/* Media errors */
export const MEDIA_ERROR = 'MEDIA_ERROR';

/* Playback errors */
export const AUTO_PLAY_POLICY_ERROR = 'AUTO_PLAY_POLICY_ERROR';

/* WARNINGS */
export const WARNING_TIMER = 3000;

export const EMBED_ERROR_MESSAGE = 'Le chargement du lien de partage a échoué, veuillez réessayer dans quelques minutes';
