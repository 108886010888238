/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvReplayIconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M83.25 42C83.25 64.7817 64.7817 83.25 42 83.25C19.2183 83.25 0.75 64.7817 0.75 42C0.75 19.2183 19.2183 0.75 42 0.75C64.7817 0.75 83.25 19.2183 83.25 42ZM39.6779 18.6375L36.2763 21C35.5746 21.4858 35.5746 22.5392 36.2763 23.025L39.6779 25.3875L41.5554 26.6742L42.6326 27.42C43.4383 27.9777 44.5347 27.3969 44.5347 26.4125V23.4375C47.9058 23.8931 50.9926 25.2422 53.5475 27.2389C57.932 30.671 60.75 36.0125 60.75 42.0122C60.75 52.3675 52.3553 60.7622 42 60.7622C31.6447 60.7622 23.25 52.3675 23.25 42.0122C23.25 38.1924 24.3922 34.6395 26.3537 31.6762C27.0008 30.7 27.737 29.7876 28.5507 28.95C28.9479 28.5412 29.1568 28.0044 29.1567 27.4742C29.1584 26.9839 28.9832 26.4987 28.6148 26.1255C28.1601 25.6648 27.5178 25.4865 26.935 25.6196C26.6391 25.6847 26.3586 25.8306 26.1257 26.0625C22.0331 30.137 19.5 35.7687 19.5 42C19.5 54.4264 29.5736 64.5 42 64.5C54.4264 64.5 64.5 54.4264 64.5 42C64.5 30.4305 55.7679 20.9094 44.5347 19.65V17.612C44.5347 16.6275 43.4383 16.0467 42.6326 16.6044L41.4711 17.4085L39.6779 18.6375Z" fill="white" />
    </SVGWrapper>
  );
}

export default TvReplayIconActive;
