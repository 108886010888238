import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function BackLiveIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <mask id="mask0_back_to_live" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="3" y="3" width="28" height="27">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M16.7034 27.7968C10.4902 27.7968 6.03931 24.0665 6.03931 18.047C6.03931 12.6881 9.42766 9.26262 14.6877 8.34914C15.0739 8.28207 15.0815 8.2765 15.7893 8.2765H26.5295V6.07995H15.7893C15.6838 6.07995 15.1544 6.07321 14.6877 6.14014C8.17932 7.07564 3.78931 11.4813 3.78931 18.047C3.78931 25.2705 9.24753 29.9767 16.7034 29.9767H24.9651C25.3049 29.9767 25.9377 29.6368 25.9377 28.8868C25.9377 28.1368 25.3108 27.7968 24.9651 27.7968H16.7034Z" fill="white" />
        <path d="M30.1998 7.89845C30.6208 7.60696 30.6208 6.98093 30.1998 6.68944L26.386 4.04929C25.9026 3.71466 25.2447 4.06312 25.2447 4.65379V9.9341C25.2447 10.5248 25.9026 10.8732 26.386 10.5386L30.1998 7.89845Z" fill="white" />
        <circle cx="18" cy="18" r="4.5" fill={color} />
      </mask>
      <g mask="url(#mask0_back_to_live)">
        <rect width="36" height="36" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default BackLiveIcon;
