import { h } from 'preact';
import { connect, withBreakPoints } from '../../hoc';
import RecommendationsCard from '../recommendations/RecommendationsCard';
import BabyPlayer from '../recommendations/BabyPlayer';
import { ERROR_STYLES } from '../error/styles';
import { usePlayerContext, useStyles } from '../../hooks';
import { Button } from '../common';
import { EXP_CONTROL_ICON_STYLES } from '../controls/exp/styles';
import { RECOMMANDATIONS_CONTAINER_STYLE, RECOMMANDATIONS_CONTAINER_VERTICAL_STYLE, RECOMMANDATIONS_FULLSCREEN_BUTTON_STYLE } from './styles';
import { PAYSAGE } from '../../../core/dom/types';

function Recommendations({
  playerImage,
  recommendations,
  hideVideo,
  shouldDisplay,
  isFullscreen,
  orientation
}) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  return (
    shouldDisplay && (
    <div role="button" tabIndex={0} onKeyDown={() => {}} onClick={(e) => { e.stopPropagation(); }} style={setStyles(orientation === PAYSAGE ? RECOMMANDATIONS_CONTAINER_STYLE : RECOMMANDATIONS_CONTAINER_VERTICAL_STYLE)}>
      <BabyPlayer image={playerImage} hideVideo={hideVideo} />
      {recommendations.map(({
        image,
        badge,
        iconChannel,
        iconCsa,
        title,
        synopsis,
        subTitle,
        duration,
        metadata
      }, index) => (
        <RecommendationsCard
          image={image}
          badge={badge}
          iconChannel={iconChannel}
          iconCsa={iconCsa}
          title={title}
          synopsis={synopsis}
          subTitle={subTitle}
          duration={duration}
          metadata={metadata}
          index={index}
        />
      ))}
      <div style={ERROR_STYLES.errorIcon}>
        {isFullscreen
        && (
        <Button
          label="quitter le plein écran (f)"
          ariaLabel="quitter le plein écran (f)"
          onClick={() => {
            player.fullscreen(!isFullscreen);
          }}
          type="icon"
          icon="fullscreen-on"
          size={setStyles(EXP_CONTROL_ICON_STYLES).size}
          style={setStyles(RECOMMANDATIONS_FULLSCREEN_BUTTON_STYLE)}
          last
          xs
        />
        )}
      </div>
    </div>
    )
  );
}
const selector = (state) => {
  const {
    ui: { isFullscreen, orientation },
    recommendations: {
      shouldDisplay,
      hideVideo,
      recommendations
    },
    media: { backgroundImage }
  } = state;
  return {
    playerImage: backgroundImage,
    recommendations,
    shouldDisplay,
    hideVideo,
    isFullscreen,
    orientation
  };
};

export default withBreakPoints(connect(selector)(Recommendations));
