import { Subject } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { feedback } from '../../configuration';
import { systemInfo } from '../../utils';
import { Disposable } from '..';

export default class ReporterController extends Disposable {
  constructor({ medias$, activeVideoTag$, errors$ }) {
    super();
    this.feedback$ = new Subject();

    ReporterController
      .mapToRequest({ feedback$: this.feedback$, medias$, activeVideoTag$ })
      .subscribe((req) => fetch(feedback.feedbackUrl, req).catch((error) => errors$.next({ error })));
  }

  report(question, rate) {
    this.feedback$.next({ question, rate });
  }

  static mapToRequest({ feedback$, medias$, activeVideoTag$ }) {
    return feedback$.pipe(withLatestFrom(
      medias$,
      activeVideoTag$,
      ({ question, rate }, { video: { is_live: isLive, is_DVR: isDVR, timeshiftable }, meta: { id: vfId } }, videoEl) => ({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          environment: ReporterController.resolveEnvironment(),
          browser: systemInfo.browser,
          browserVersion: systemInfo.browserVersion,
          os: systemInfo.os,
          osVersion: systemInfo.osVersion,
          device: systemInfo.isMobile ? 'mobile' : 'desktop',
          playerVersion: global.MAGNETO_VERSION,
          networkType: global.navigator.connection.type,
          streamType: ReporterController.resolveStreamType(isLive, isDVR, timeshiftable),
          vfId,
          quality: videoEl.videoHeight,
          createdAt: new Date(),
          question,
          rate
        })
      })
    ));
  }

  static resolveStreamType = (isLive, isDVR, timeshiftable) => {
    if (isLive && !timeshiftable) return 'live';
    if (isDVR || timeshiftable) return 'timeshifting';
    return 'replay';
  };

  static resolveEnvironment = () => {
    if (systemInfo.isIOS) return 'IOS';
    if (systemInfo.isMobile) return 'web-mobile';
    return 'web-desktop';
  };
}
