import { h } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { TITLES_MAPPING, ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';
import { useStyles } from '../../../ui/hooks';
import { PANEL_TITLE } from '../../../ui/components/wrappers/Zap/styles';
import ProgramTypeSwitch from '../../../ui/components/zap/ProgramTypeSwitch';
import { TV_PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED } from './styles';
import Programs from './Programs';
import { connect } from '../../../ui/hoc';

function ProgramList({ panelLiveOption, playerHeight }) {
  const setStyles = useStyles();

  const refTitle = useRef(null);
  const refSwitch = useRef(null);

  const [panelTitleHeight, setPanelTileHeight] = useState(0);
  const [panelSwicthHeight, setPanelSwitchHeight] = useState(0);

  const programListHeight = useMemo(
    () => playerHeight - (panelTitleHeight + panelSwicthHeight + 16), /* 16 px of margin-bottom */
    [playerHeight, panelSwicthHeight, panelTitleHeight]
  );

  useEffect(() => {
    if (refTitle.current) {
      setPanelTileHeight(refTitle.current.getBoundingClientRect().height);
    }
    if (refSwitch.current) {
      setPanelSwitchHeight(refSwitch.current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <div style={setStyles(TV_PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED)}>
      <div
        id="panel"
        style={{
          display: 'block'
        }}
      >
        <div style={setStyles(PANEL_TITLE)} ref={refTitle}>
          {
            TITLES_MAPPING[panelLiveOption.currentTab]
            || TITLES_MAPPING[ZAPPING_NAME]
          }
        </div>
        <div name="program-list-view" style={{ height: programListHeight }}>
          <ProgramTypeSwitch wrapperRef={refSwitch} />
          <Programs />
        </div>
      </div>
    </div>
  );
}

const selector = ({ ui: { height: playerHeight } }) => ({ playerHeight });

export default connect(selector)(ProgramList);
