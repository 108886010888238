/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvForward16Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM23.2222 73.0819V46.2517C23.2222 44.5925 24.6703 43.566 25.8111 44.4165L44.6103 58.4323C45.7476 59.2803 45.7157 61.3509 44.553 62.1435L25.7538 74.9579C24.6132 75.7353 23.2222 74.7045 23.2222 73.0819ZM47.6667 73.0819V46.2517C47.6667 44.5925 49.2595 43.566 50.5144 44.4165L71.1935 58.4323C72.4446 59.2803 72.4094 61.3509 71.1305 62.1435L50.4514 74.9579C49.1968 75.7353 47.6667 74.7045 47.6667 73.0819ZM33.5069 21.84L31.7269 19.3H28.6469L31.9669 23.84L28.1869 29H31.2069L33.4669 25.84L35.7069 29H38.7869L35.0069 23.82L38.3269 19.3H35.3069L33.5069 21.84ZM40.5658 17.24V19.8L43.5658 18.12V26.6H39.7658V29H49.5658V26.6H46.2658V15H44.3658L40.5658 17.24ZM58.6791 15H55.6991L52.9791 19.46C51.8591 21.3 50.9391 22.96 50.9391 24.48C50.9391 27.38 53.1991 29.2 56.0791 29.2C58.8591 29.2 61.2391 27.22 61.2391 24.2C61.2391 21.28 58.9791 19.5 56.4391 19.5H55.9591L58.6791 15ZM56.0791 26.8C54.6391 26.8 53.6191 25.8 53.6191 24.3C53.6191 23.44 54.0191 22.6 54.4391 21.98C54.9391 21.82 55.4391 21.7 55.9391 21.7C57.5591 21.7 58.5791 22.84 58.5791 24.2C58.5791 25.8 57.4791 26.8 56.0791 26.8Z" fill="white" />

    </SVGWrapper>
  );
}

export default TvForward16Icon;
