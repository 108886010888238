import slugify from './slugify';

export function computeVideoTitle(videoTitle, subTitle, videoPreTitle, videoAdditionalTitle) {
  if (slugify(videoTitle) === slugify(videoAdditionalTitle)) {
    return ({
      computedTitle: videoPreTitle ? `${videoPreTitle} : ${videoTitle}` : videoTitle || '',
      computedSubtitle: ''
    });
  }
  return ({
    computedTitle: videoTitle || subTitle || '',
    computedSubtitle: (videoPreTitle && videoAdditionalTitle)
      ? `${videoPreTitle} : ${videoAdditionalTitle}`
      : (videoPreTitle || videoAdditionalTitle || '')
  });
}

export function computeInfoMain(waitStart, extraSmall, isLive, hasComingNext, displayComingNextOnStart) {
  /* Do not display info if autostart=false (waitstart = true) */
  return !waitStart && !isLive && hasComingNext && displayComingNextOnStart && !extraSmall ? 'vous regardez' : null;
}

export function computeInfoAfter(waitStart, hasComingNext) {
  return !waitStart && hasComingNext ? 'prochaine vidéo' : null;
}
