import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function VolumeModerateIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="mask0_volume_moderate" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="5" width="18" height="16">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M15.8429 7.50584C15.4318 7.45459 15.0571 7.74624 15.0058 8.15727C14.9546 8.5683 15.2462 8.94306 15.6573 8.99431C17.2525 9.19324 18.5001 10.5861 18.5001 12.2501C18.5001 13.9141 17.2525 15.3069 15.6573 15.5058C15.2462 15.5571 14.9546 15.9319 15.0058 16.3429C15.0571 16.7539 15.4318 17.0456 15.8429 16.9943C18.1939 16.7011 20.0001 14.6657 20.0001 12.2501C20.0001 9.83444 18.1939 7.79901 15.8429 7.50584Z" fill="black" />
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M14.25 9.5C13.8358 9.5 13.5 9.83579 13.5 10.25C13.5 10.6642 13.8358 11 14.25 11C14.9404 11 15.5 11.5596 15.5 12.25C15.5 12.9404 14.9404 13.5 14.25 13.5C13.8358 13.5 13.5 13.8358 13.5 14.25C13.5 14.6642 13.8358 15 14.25 15C15.7688 15 17 13.7688 17 12.25C17 10.7312 15.7688 9.5 14.25 9.5Z" fill="black" />
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.1147 8.72943C6.02994 8.78992 5.9284 8.82243 5.82427 8.82243H3C2.44771 8.82243 2 9.27015 2 9.82244C2.00001 11.6087 2 13.3949 2 15.1811C2 15.7334 2.44771 16.1811 3 16.1811H5.82427C5.9284 16.1811 6.02994 16.2136 6.1147 16.2741L11.2096 19.9097C11.5405 20.1459 12 19.9093 12 19.5027V5.50088C12 5.0943 11.5405 4.85771 11.2096 5.09388L6.1147 8.72943Z" fill="black" />
      </mask>
      <g mask="url(#mask0_volume_moderate)">
        <rect width="24" height="24" fill={color} />
      </g>
    </SVGWrapper>
  );
}
export default VolumeModerateIcon;
