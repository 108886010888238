import { h, render, createContext } from 'preact';

import { dialogContextController, DialogContext } from '../ui/context/DialogContext';

import '../ui/theme/styles/global.css';

import LayerTop from '../ui/components/common/layers/LayerTop';

import { connect } from '../ui/hoc';

import TvMainWrapper from './components/wrappers/Main';
import CoreUI from '../ui/CoreUI';
import InteractionHandler from '../ui/components/wrappers/InteractionHandler';
import withError from './hoc/withError';
import { TVSectionOverlay } from './components/wrappers/TVSectionOverlay';

export const Player = createContext({});

function PlayerApp() {
  return (
    <DialogContext.Provider value={dialogContextController}>
      <InteractionHandler>
        <TvMainWrapper />
      </InteractionHandler>
      <TVSectionOverlay />
    </DialogContext.Provider>
  );
}

const PlayerAppWithStore = withError(connect(
  () => ({})
)(PlayerApp));

export default class TvPlayerUI extends CoreUI {
  init() {
    this.wrapper = document.createElement('div');
    this.container.appendChild(this.wrapper);

    render(
      <LayerTop player={this.player}>
        <PlayerAppWithStore />
      </LayerTop>,
      this.wrapper
    );
  }
}
