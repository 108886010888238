import { fromEvent, of, pipe, merge, BehaviorSubject } from 'rxjs';
import { map, switchMap, withLatestFrom, share, shareReplay, skip } from 'rxjs/operators';
import { systemInfo } from '../../utils';
import '../../../css/fullscreen.css';
import { Disposable } from '..';

const engineMap = [
  [
    'requestFullscreen',
    'exitFullscreen',
    'fullscreenElement',
    'fullscreenchange'
  ], [
    'webkitRequestFullscreen',
    'webkitExitFullscreen',
    'webkitFullscreenElement',
    'webkitfullscreenchange'
  ], [
    'mozRequestFullScreen',
    'mozCancelFullScreen',
    'mozFullScreenElement',
    'mozfullscreenchange'
  ], [
    'msRequestFullscreen',
    'msExitFullscreen',
    'msFullscreenElement',
    'MSFullscreenChange'
  ], [
    /**
     * iOS Safari specific
     * https://developer.apple.com/documentation/webkitjs/htmlvideoelement
     */
    'webkitEnterFullscreen',
    'webkitExitFullscreen',
    'webkitDisplayingFullscreen',
    'webkitbeginfullscreen webkitendfullscreen'
  ]
];

export default class FullscreenController extends Disposable {
  constructor({ container, activeVideoTag$ }) {
    super();
    const { fullscreenSupported, browser } = systemInfo;
    /* on IOS, fullscreen API is available on the tagElement and not the document */
    const engine$ = of(systemInfo)
      .pipe(
        switchMap(({ isIOS }) => (isIOS && !fullscreenSupported ? activeVideoTag$ : of(container))),
        map((target) => ({
          ...FullscreenController.getCurrentEngine(document, target),
          target
        })),
        shareReplay(1)
      );

    const withEngine = pipe(withLatestFrom(engine$));

    this.fullscreen$ = new BehaviorSubject(false);
    this.fullscreenChange$ = FullscreenController.createFullscreenChangeStream(document, engine$, browser === 'ie');

    this.fullscreenChange$.pipe(withEngine).subscribe(FullscreenController.handleFullscreenChange);
    this.fullscreen$.pipe(
      skip(1), /* Avoid 1st handle as player always start on normal screen */
      withEngine
    ).subscribe(FullscreenController.handleFullscreenRequest);
  }

  fullscreen(active) {
    this.fullscreen$.next(active);
  }

  static getCurrentEngine(doc, target = {}) {
    const baseMap = engineMap[0];
    /**
     * we match against document for exitFullscreen
     * and against target (container or video tag) for request/enterFullScreen (cf IOS)
     */
    const match = engineMap.find((engine) => engine[1] in doc || engine[0] in target) || baseMap;
    return match.reduce((engine, func, i) => ({ ...engine, [baseMap[i]]: func }), {});
  }

  static createFullscreenChangeStream(doc, engine$, isIE) {
    return engine$.pipe(
      switchMap((engine) => merge(...engine.fullscreenchange.split(' ').map((evt) => fromEvent(isIE ? doc : engine.target, evt).pipe(map(() => Boolean(doc[engine.fullscreenElement]
              || engine.target[engine.fullscreenElement])))))),
      share()
    );
  }

  static handleFullscreenChange([active, { target }]) {
    const method = active ? 'add' : 'remove';
    target.classList[method]('ftv-magneto-fullscreen');
  }

  static handleFullscreenRequest([active, engine]) {
    if (!active) {
      const target = engine.exitFullscreen in engine.target ? engine.target : document; /* IOS */
      return target[engine.exitFullscreen]();
    }

    return engine.target[engine.requestFullscreen]();
  }
}
