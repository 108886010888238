import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function FlagActive({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_7876_4857" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="7" y="1" width="18" height="30">
        <path d="M7.24997 2C7.24997 1.58579 7.58576 1.25 7.99997 1.25C8.41418 1.25 8.74997 1.58579 8.74997 2V2.32688C8.74997 2.42862 8.81162 2.52021 8.90588 2.5585L24.2823 8.80515C24.5685 8.92142 24.7541 9.20128 24.7499 9.51017C24.7457 9.81906 24.5526 10.0938 24.2633 10.2022L8.91219 15.9589C8.81461 15.9955 8.74997 16.0888 8.74997 16.193L8.74997 30C8.74997 30.4142 8.41418 30.75 7.99997 30.75C7.58576 30.75 7.24997 30.4142 7.24997 30V2Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7876_4857)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export { FlagActive };
