import { h } from 'preact';
import {
  TIMELINE_BUBBLE_SEEK_STYLE,
  TIMELINE_BUBBLE_SEEK_CONTENT_STYLE,
  TIMELINE_THUMBNAIL_STYLE,
  TIMELINE_POPUP_STYLE,
  TIMELINE_BUBBLE_LIVE_CONTENT_STYLE,
  TIMELINE_BUBBLE_LIVE_STYLE,
  TIMELINE_LIVE_STYLE_TOOLTIP,
  HIGHLIGHTS_LABEL_TEXT_STYLES
} from './styles';
import { useStyles } from '../../../hooks';
import { VOID_FN } from '../../../../utils';
import { TOOLTIP_LAYER, UI_LAYER } from '../../../theme/constants';
import { withBreakPoints } from '../../../hoc';
import { Label } from '../../common';
import { LABEL_TEXT_FONT_SIZE } from '../../../styles';

export const TYPE_CURRENT = 'current';
export const TYPE_AUTO = 'auto';
export const TYPE_LIVE = 'live';
export const TYPE_DEFAULT = 'default';

function Tooltip({
  hidden,
  hover,
  isSliding,
  bubblePosition,
  thumbnail,
  dimensions,
  type = TYPE_DEFAULT,
  live,
  label,
  labelFn = (s) => s,
  onClick = VOID_FN,
  style = {},
  children,
  withBackgroundPosition,
  large,
  small,
  extraLarge,
  styleThumbnail = {},
  badge = null,
  styleBadge = {}
}) {
  const thumbnailStyle = {
    ...TIMELINE_THUMBNAIL_STYLE,
    ...{
      width: dimensions.thumbnail.width,
      height: dimensions.thumbnail.height
    },
    ...thumbnail?.url && { background: `url("${thumbnail?.url}") no-repeat`, backgroundSize: 'cover' },
    ...(withBackgroundPosition && { backgroundPosition: `-${thumbnail?.x || 0}px -${thumbnail?.y || 0}px` }),
    ...(thumbnail?.url && { border: '1px solid #fff', borderRadius: small ? 4 : 8 }),
    ...styleThumbnail
  };

  const dimensionsThumbnail = dimensions.thumbnail?.url ? {
    width: dimensions.thumbnail.width,
    height: dimensions.thumbnail.height
  } : {};

  const setStyles = useStyles();
  const { fontSize: bubbleFontSize } = setStyles(LABEL_TEXT_FONT_SIZE);
  const bubbleMapStyle = {
    [TYPE_DEFAULT]: {
      ...setStyles(TIMELINE_BUBBLE_SEEK_STYLE),
      width: dimensions.tooltip.width + 2,
      height: dimensions.tooltip.height,
      borderRadius: dimensions.tooltip.width,
      transform: 'translateY(-50%)',
      zIndex: UI_LAYER
    },
    [TYPE_LIVE]: {
      ...TIMELINE_BUBBLE_LIVE_STYLE,
      transform: 'translateY(calc(50%+12px))',
      transition: 'all 0.3s linear 0s',
      ...setStyles(TIMELINE_LIVE_STYLE_TOOLTIP)
    },

    [TYPE_AUTO]: {
      ...TIMELINE_BUBBLE_LIVE_STYLE,
      transition: 'all 0.3s linear 0s'
    }
  };

  const translateXNoImage = dimensions.thumbnail.width === 0 ? 'translateX(-50%)' : '';

  const wrapperStyle = {
    zIndex: TOOLTIP_LAYER,
    ...dimensionsThumbnail,
    ...TIMELINE_POPUP_STYLE,
    ...bubblePosition,
    ...style,
    ...{ opacity: hover || isSliding || live ? 1 : 0 },
    ...(type !== TYPE_AUTO && thumbnail?.url && {
      ...{ transform: 'translateY(-90%)' },
      ...(large || extraLarge) && { transform: 'translateY(-105%)' }
    }),
    ...(type !== TYPE_AUTO && !thumbnail?.url && {
      transform: `${(large || extraLarge) ? 'translateY(calc(-96% - 6px))' : 'translateY(calc(-92% - 10px))'} ${translateXNoImage}`
    })
  };

  return (
    !hidden && (children || label) && (
      <div
        role="presentation"
        style={wrapperStyle}
        onClick={(e) => { e.stopPropagation(); onClick(); }}
      >
        { badge && (
        <Label
          message={badge}
          style={styleBadge}
          styleText={setStyles(HIGHLIGHTS_LABEL_TEXT_STYLES)}
        />
        )}
        <div style={{ ...thumbnailStyle }} />
        <div
          style={{
            ...bubbleMapStyle[type],
            fontSize: bubbleFontSize,
            ...(TYPE_LIVE === type ? setStyles(TIMELINE_BUBBLE_LIVE_CONTENT_STYLE) : TIMELINE_BUBBLE_SEEK_CONTENT_STYLE),
            display: 'flex', flexDirection: 'column'
          }}
        >
          {children || labelFn(label) }
        </div>
      </div>
    )
  );
}

export default withBreakPoints(Tooltip);
