import { useState, useEffect, useRef } from 'preact/hooks';
import { VOID_FN } from '../../utils';

/**
 * HOC to delay unmounting of component to handle transitions
 * ---------------------------------------------------------
 * CompToDelay should pass the following props :
 * isMounted : controls if CompToDelay should show/appear
 * delay : delay time in ms
 * onFinish? : fn triggered when CompToDelay has effectively been unmounted
 * shouldDelay? : useful to override delay
 * ---------------------------------------------------------
 * CompToDelay will be passed the following props :
 * isLeaving : boolean indicating if CompToDelay is transitioning out
 */

function Delayed({
  isMounted,
  delay,
  shouldDelay,
  onFinish = VOID_FN,
  children
}) {
  const transition = useRef(null);
  const [state, setState] = useState({ render: isMounted, leaving: false });
  const { render, leaving } = state;

  useEffect(() => {
    if (isMounted) {
      if (leaving) onFinish();
      setState({ render: true, leaving: false });
    } else {
      setState((prev) => {
        if (prev.render) transition.current = setTimeout(() => setState({ render: false, leaving: false }), shouldDelay ? delay : 0);
        return ({ ...prev, leaving: !prev.leaving });
      });
    }
  }, [isMounted]);

  useEffect(() => {
    if (!render && !leaving) onFinish();
    if (render && !leaving) clearInterval(transition.current);
  }, [render, leaving]);

  useEffect(() => () => {
    clearInterval(transition.currrent);
    onFinish();
  }, []);

  return render ? children({ isLeaving: leaving, isMounted, shouldDelay }) : null;
}

export default Delayed;
