import { h } from 'preact';
import { LABEL_WRAPPER_STYLE, MARGIN_WRAPPER_STYLE } from './styles';
import { useStyles } from '../../../hooks';
import { PRIMARY_FILL_COLOR } from '../../../theme/colors';

function Label({
  message,
  onClick,
  opaque = false,
  icon,
  first,
  last,
  style,
  styleText,
  styleWrapper,
  focusable = false,
  name = 'label'
}) {
  const setStyles = useStyles();

  return (
    <div
      {...(onClick ? { onClick } : {})}
      style={{
        ...setStyles(LABEL_WRAPPER_STYLE),
        backgroundColor: opaque ? 'transparent' : PRIMARY_FILL_COLOR,
        transformOrigin: `top ${(last && !first) ? 'right' : 'left'}`, /* in case first & last both true */
        ...style
      }}
      name={name}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={focusable ? 0 : -1}
    >
      <span style={setStyles({ ...MARGIN_WRAPPER_STYLE, ...styleWrapper })}>
        {icon || null}
        <span style={{
          ...setStyles({ default: { marginTop: -1 /* TODO: check if necessary */ }, medium: { marginTop: 0 } }),
          ...styleText
        }}
        >
          {message}
        </span>
      </span>
    </div>
  );
}

export default Label;
