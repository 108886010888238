export const INTERRUPTOR_INIT = 'interruptor_init';
export const INTERRUPTOR_READY = 'interruptor_ready';
export const INTERRUPTION_WILL_START = 'interruption_will_start';
export const INTERRUPTION_DID_START = 'interruption_did_start';
export const INTERRUPTION_END = 'interruption_end';
export const INTERRUPTION_CANCELED = 'interruption_canceled';

export const BEFORE_HOOK = 'before_hook';
export const AFTER_HOOK = 'after_hook';
export const ADBREAK_HOOK = 'adbreak_hook';
