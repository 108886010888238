import { h } from 'preact';
import { usePlayerContext } from '../../hooks';
import { connect, withBreakPoints, Delayed } from '../../hoc';
import SpinnerFranceTV from './spinner';

import { INTRO_WRAPPER_STYLES, INTRO_TRANSITION_OUT } from '../../styles';
import { onFinishCreator } from '../../utils/tools';

function Spinner({
  isIntro,
  waiting
}) {
  const player = usePlayerContext();
  if (!waiting && !isIntro) return null;

  return (
    <div style={{
      ...INTRO_WRAPPER_STYLES,
      ...(waiting || isIntro ? {} : INTRO_TRANSITION_OUT)
    }}
    >
      <Delayed
        isMounted={waiting}
        shouldDelay={isIntro}
        delay={2000}
        onFinish={onFinishCreator(player, isIntro)}
      >
        {(delayedState) => <SpinnerFranceTV {...delayedState} />}
      </Delayed>
    </div>
  );
}

const selector = ({
  ui: {
    waiting,
    isIntro
  }
}) => ({
  waiting,
  isIntro
});

export default withBreakPoints(connect(selector)(Spinner));
