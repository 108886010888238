import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function FullscreenOffIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask id="mask0_fullscreen_off" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="10">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.66671 3.8335C5.66671 3.55735 5.44285 3.3335 5.16671 3.3335C4.11115 3.3335 3.0556 3.3335 2.00004 3.3335C1.63185 3.3335 1.33337 3.63197 1.33337 4.00016V5.50016C1.33337 5.77631 1.55723 6.00016 1.83337 6.00016C2.10952 6.00016 2.33337 5.77631 2.33337 5.50016V4.3335H5.16671C5.44285 4.3335 5.66671 4.10964 5.66671 3.8335ZM5.66671 12.1668C5.66671 11.8907 5.44285 11.6668 5.16671 11.6668H2.33337V10.1668C2.33337 9.89069 2.10952 9.66683 1.83337 9.66683C1.55723 9.66683 1.33337 9.89069 1.33337 10.1668V12.0002C1.33337 12.3684 1.63185 12.6668 2.00004 12.6668C3.0556 12.6668 4.11115 12.6668 5.16671 12.6668C5.44285 12.6668 5.66671 12.443 5.66671 12.1668ZM10.5 12.6668C10.2239 12.6668 10 12.443 10 12.1668C10 11.8907 10.2239 11.6668 10.5 11.6668H13.6667V10.1668C13.6667 9.89069 13.8906 9.66683 14.1667 9.66683C14.4429 9.66683 14.6667 9.89069 14.6667 10.1668C14.6667 10.7779 14.6667 11.3891 14.6667 12.0002C14.6667 12.3684 14.3682 12.6668 14 12.6668H10.5ZM10.5 4.3335C10.2239 4.3335 10 4.10964 10 3.8335C10 3.55735 10.2239 3.3335 10.5 3.3335C11.6667 3.3335 12.8334 3.3335 14 3.3335C14.3682 3.3335 14.6667 3.63197 14.6667 4.00016V5.50016C14.6667 5.77631 14.4429 6.00016 14.1667 6.00016C13.8906 6.00016 13.6667 5.77631 13.6667 5.50016V4.3335H10.5Z" fill="black" />
      </mask>
      <g mask="url(#mask0_fullscreen_off)">
        <rect width="16" height="16" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default FullscreenOffIcon;
