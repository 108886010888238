import { h } from 'preact';

import { useEffect, useRef } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import TrackList from '../tracks/track-list';
import { TV_SIDE_OVERLAY_WRAPPER, TV_SIDE_OVERLAY_WRAPPER_PROGRAM_LIST } from './styles';
import ProgramList from '../programlist';
import { usePlayerContext } from '../../../ui/hooks';
import { USER_PANEL_CLOSED, USER_PANEL_OPENED } from '../../../types';
import { PLAYLIST_NAME, TRACKS_NAME, ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';

function TVSideOverlay(props) {
  const {
    subtitlesAvailable,
    subtitleSelected,
    audiosAvailable,
    audioSelected,
    panelLiveOption
  } = props;
  const player = usePlayerContext();
  const renderCount = useRef(0);

  useEffect(() => {
    /* We skip event emitter for the 1srt render */
    if (renderCount.current > 0) {
      const tabName = panelLiveOption.show ? panelLiveOption.currentTab : panelLiveOption.lastTab;
      player.userEvents$.next({ ...{
        action: panelLiveOption.show ? USER_PANEL_OPENED : USER_PANEL_CLOSED,
        feature: tabName
      },
      ...panelLiveOption.show
      // eslint-disable-next-line react/destructuring-assignment
        ? { itemsCount: props[tabName]?.length ?? null }
        : { button: null }
      });
    }
  }, [panelLiveOption.currentTab, panelLiveOption.show]);
  if (!panelLiveOption.currentTab || !panelLiveOption.show) return null;
  renderCount.current += 1;

  return (
    <div
      style={
        panelLiveOption.currentTab !== TRACKS_NAME
          ? TV_SIDE_OVERLAY_WRAPPER_PROGRAM_LIST
          : TV_SIDE_OVERLAY_WRAPPER
      }
    >
      {panelLiveOption.currentTab !== TRACKS_NAME
        ? <ProgramList panelLiveOption={panelLiveOption} />
        : (
          <TrackList
            subtitlesAvailable={subtitlesAvailable}
            subtitleSelected={subtitleSelected}
            audiosAvailable={audiosAvailable}
            audioSelected={audioSelected}
          />
        )}
    </div>
  );
}

const selector = ({
  media: { subtitlesAvailable, subtitleSelected, audiosAvailable, audioSelected },
  ui: { showTracks, panelLiveOption },
  zapping: { list, zappingEnabled = list.length > 0 },
  playlist: { playlist, playlistEnabled = playlist.length > 0 }
}) => ({
  subtitlesAvailable,
  subtitleSelected,
  audiosAvailable,
  audioSelected,
  showTracks,
  panelLiveOption,
  showProgramList: !showTracks && (playlistEnabled || zappingEnabled) && panelLiveOption.show,
  [PLAYLIST_NAME]: playlist,
  [ZAPPING_NAME]: list
});

export default connect(selector)(TVSideOverlay);
