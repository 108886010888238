import { h, Fragment, toChildArray, cloneElement } from 'preact';

function Position({
  children,
  disableFirstProp = false,
  disableLastProp = false
}) {
  const contents = toChildArray(children);
  return (
    <Fragment>
      {contents.map((child, i) => cloneElement(child, {
        first: !disableFirstProp && i === 0,
        last: !disableLastProp && i === contents.length - 1
      }))}
    </Fragment>
  );
}

export default Position;
