import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../../hooks';
import { Button, Slider } from '../../../common';
import { getVolumeIcon } from '../../../../utils/tools';
import {
  VOLUME_SLIDER_DEFAULT_DIALOG_STYLE,
  VOLUME_SLIDER_DIALOG_CONTENT_STYLE,
  VOLUME_SLIDER_OPENED_DIALOG_STYLE,
  VOLUME_SLIDER_OPENED_DIALOG_CONTENT_STYLE,
  VOLUME_SLIDER_SIZE,
  VOLUME_BUTTON_SIZE
} from './styles';

import { RESIZE_FACTOR_EXP_CTRL_RESPONSIVE, TIMELINE_LAYER, VOLUME_DIALOG_LAYER } from '../../../../theme/constants';
import { USER_MUTE, USER_CLICK, USER_VOLUME_CHANGE } from '../../../../../types';

const dialogStyles = {
  container: VOLUME_SLIDER_DEFAULT_DIALOG_STYLE,
  openedContainer: VOLUME_SLIDER_OPENED_DIALOG_STYLE,
  content: VOLUME_SLIDER_DIALOG_CONTENT_STYLE,
  openedContent: VOLUME_SLIDER_OPENED_DIALOG_CONTENT_STYLE,
  buttonOverride: { marginRight: 0 }
};

function Volume({
  volume,
  muted,
  medium,
  extraSmall,
  small,
  name,
  disableFocus,
  ...extraProps
}) {
  const resolveStateToSliderVal = (vol) => Math.ceil((100 * vol)).toFixed(2);

  const player = usePlayerContext();
  const [volumeState, setVolumeState] = useState(volume);
  const [sliderVal, setSliderVal] = useState(resolveStateToSliderVal(volumeState));
  const [sliding, setSliding] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const setStyles = useStyles();

  useEffect(() => !sliding && setVolumeState(volume), [volume]);

  useEffect(() => {
    setSliderVal(resolveStateToSliderVal(volumeState));

    if (volumeState !== volume) player.volume(volumeState);
  }, [volumeState]);

  useEffect(() => (sliding && muted && player.mute(false)), [sliding]);

  const toggleMute = () => {
    player.userEvents$.next({ action: USER_MUTE, value: !muted, source: USER_CLICK });
    player.mute(!muted);
  };

  const resizeFactor = (medium || small ? RESIZE_FACTOR_EXP_CTRL_RESPONSIVE : 1);
  const miniResizeFactor = resizeFactor / (small ? 1.80 : 1);

  const size = setStyles(VOLUME_SLIDER_SIZE);

  const handleSlide = (pos, maxPos) => {
    const val = pos / maxPos;

    setVolumeState(parseFloat(val.toFixed(2)));
  };

  const handleStopSlide = (pos, maxPos) => {
    player.userEvents$.next({ action: USER_VOLUME_CHANGE, source: USER_CLICK, value: pos / maxPos });
    setSliding(false);
  };

  const onSliderFocus = () => {
    setShowSlider(true);
  };

  const onSliderBlur = () => {
    setShowSlider(false);
  };

  return (
    <Button
      type="dialog"
      label="son (m)"
      forceLabel
      style={!extraSmall && dialogStyles}
      onClick={toggleMute}
      icon={getVolumeIcon({ volume, muted })}
      openingMode="over"
      forceDialog={sliding}
      xs
      name={name}
      showChildren={showSlider}
      disableFocus={disableFocus}
      zIndex={VOLUME_DIALOG_LAYER}
      {...extraProps}
      size={setStyles(VOLUME_BUTTON_SIZE).width}
    >
      <Slider
        value={muted ? 0 : sliderVal}
        strokeSize={6}
        cursorSize={size.cursorSize}
        trackSize={size.trackSize}
        onSlide={handleSlide}
        onStartSlide={() => setSliding(true)}
        onStopSlide={handleStopSlide}
        styles={{
          container: {
            paddingTop: 20 * resizeFactor,
            marginBottom: (size.cursorSize / 2) * miniResizeFactor,
            zIndex: TIMELINE_LAYER
          }
        }}
        vertical
        onFocus={onSliderFocus}
        onBlur={onSliderBlur}
        name="slider-volume"
        accessibleName="volume"
        disableFocus={disableFocus}
        showSlider={!extraSmall}
      />
    </Button>
  );
}

export default Volume;
