import { BehaviorSubject, NEVER, ReplaySubject, combineLatest, fromEvent, merge } from 'rxjs';
import { debounceTime, startWith, map, share, filter, shareReplay, distinctUntilChanged, combineLatestWith } from 'rxjs/operators';
import { UI_WRAPPER_STYLE } from '../../ui/components/wrappers/styles';
import {
  NEW_VIDEO, USER_PANEL_LIVE_OPTIONS_CLOSE, USER_REMOTE_CHAT_CLICKED, USER_REMOTE_HIGHLIGHTS_CLICKED, USER_REMOTE_PLAYLIST_CLICKED,
  USER_REMOTE_ZAPPING_CLICKED, VIDEO_START
} from '../../types';
import { systemInfo } from '../../utils';
import cueCSS from '../tracks/cueCSS';
import { resizeObservable } from '../../utils/rx-utils';
import { PAYSAGE, PORTRAIT, TV_PLATFORMS } from './types';
import { Disposable } from '..';
import { SIDEBAR_SIZE_LARGE, SIDEBAR_SIZE_MEDIUM, TRANSITION_DURATION } from '../../ui/theme/colors';

export const EXTRALARGE_BREAKPOINT = 1920;
export const LARGE_BREAKPOINT = 1200;
export const MEDIUM_BREAKPOINT = 730;
export const SMALL_BREAKPOINT = 375;
export const EXTRASMALL_BREAKPOINT = 375;

export const VIDEO_CONTAINER_STYLE = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  transition: `opacity .2s ease-in-out, width ${TRANSITION_DURATION}ms ease-in-out, height ${TRANSITION_DURATION}ms ease-in-out`,
  overflow: 'hidden'
};

export const LAYER_STYLE = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  height: '100%'
};

export default class DomController extends Disposable {
  constructor({ domNode, events$, setupReady$, shouldDisplayReco$, userEvents$, config: { platform } }) {
    super();
    this.container = domNode;
    this.platform = platform;
    this.container.style.position = 'relative';
    this.layers = {};
    this.videoContainer = DomController.createVideoContainer();

    combineLatest(shouldDisplayReco$, setupReady$).subscribe(([{ shouldDisplay }]) => {
      if (shouldDisplay) {
        // 0 timeout to force the callback to be at the end of functions calls stack, ensure the baby player dom element will exist
        setTimeout(() => {
          const babyPlayerContainer = document.getElementById('baby-player-container');
          this.container.removeChild(this.videoContainer);
          if (babyPlayerContainer) {
            this.videoContainer.style.position = 'relative';
            babyPlayerContainer.appendChild(this.videoContainer);
          }
        }, 0);
      } else {
        this.videoContainer.style.position = 'absolute';
        this.container.appendChild(this.videoContainer);
      }
    });
    this.dom$ = new BehaviorSubject(this.videoContainer);
    this.orientation$ = new ReplaySubject(1);

    /* UI stream */
    this.dimensions$ = new ReplaySubject(1);

    DomController.createPlayerSizeStream(domNode, platform)
      .pipe(
        map(({ width, height }) => ({
          width,
          height,
          isExtraLargeTvScreen: TV_PLATFORMS.includes(platform) && EXTRALARGE_BREAKPOINT <= width,
          isLargeTvScreen: TV_PLATFORMS.includes(platform) && LARGE_BREAKPOINT <= width && EXTRALARGE_BREAKPOINT > width,
          isExtraLargeScreen: EXTRALARGE_BREAKPOINT <= width,
          isLargeScreen: LARGE_BREAKPOINT <= width && EXTRALARGE_BREAKPOINT > width,
          isMediumScreen: MEDIUM_BREAKPOINT <= width && LARGE_BREAKPOINT > width,
          isSmallScreen: SMALL_BREAKPOINT <= width && MEDIUM_BREAKPOINT > width,
          isExtraSmallScreen: EXTRASMALL_BREAKPOINT > width
        })),
        shareReplay(1)
      )
      .subscribe(this.dimensions$);

    /* toggle videoContainer visibility on VIDEO_START to avoid flickering on ad transition */
    DomController
      .createVideoVisibilityStream(events$)
      .subscribe((val) => this.setContainerOpacity(val));

    DomController.handleSafariCSS(document, systemInfo);
    DomController
      .createPlayerOrientationTypeStream(this.dimensions$)
      .subscribe(this.orientation$);

    DomController.computeVideoSizeForPanelLiveOptions({
      orientation$: this.orientation$,
      dimensions$: this.dimensions$,
      userEvents$
    }).subscribe(this.udpateVideoContainerSize.bind(this));
  }

  udpateVideoContainerSize({ width, height }) {
    this.videoContainer.style.width = width;
    this.videoContainer.style.height = height;
  }

  static computeVideoSizeForPanelLiveOptions({ userEvents$, dimensions$, orientation$ }) {
    return userEvents$.pipe(
      filter(({ action }) => [
        USER_REMOTE_ZAPPING_CLICKED, USER_REMOTE_CHAT_CLICKED,
        USER_REMOTE_HIGHLIGHTS_CLICKED, USER_PANEL_LIVE_OPTIONS_CLOSE,
        USER_REMOTE_PLAYLIST_CLICKED
      ].includes(action)),
      map(({ value }) => value),
      combineLatestWith(dimensions$, orientation$),
      map(([showPannel, { isMediumScreen, width }, orientation]) => (showPannel && orientation !== PORTRAIT
        ? {
          width: isMediumScreen ? `${100 - SIDEBAR_SIZE_MEDIUM}%` : `${100 - SIDEBAR_SIZE_LARGE}%`,
          height: '100%'
        }
        : {
          width: '100%',
          height: showPannel ? `${(width / 16) * 9}px` : '100%'
        })),
      distinctUntilChanged()
    );
  }

  static createPlayerOrientationTypeStream(dimensions$) {
    return dimensions$.pipe(
      map(({ width, height }) => (width > height ? PAYSAGE : PORTRAIT)),
      distinctUntilChanged()
    );
  }

  static createVideoVisibilityStream(events$) {
    const EVENT_TO_OPACITY = {
      [NEW_VIDEO]: 0,
      [VIDEO_START]: 1
    };

    return events$.pipe(
      filter((evt) => Object.keys(EVENT_TO_OPACITY).includes(evt)),
      map((evt) => EVENT_TO_OPACITY[evt])
    );
  }

  static createVideoContainer() {
    const videoContainer = document.createElement('div');
    videoContainer.className = 'ftv-in-customizable magneto-video-container';

    Object.keys(VIDEO_CONTAINER_STYLE).forEach((prop) => { videoContainer.style[prop] = VIDEO_CONTAINER_STYLE[prop]; });

    return videoContainer;
  }

  static createPlayerSizeStream(domNode, platform) {
    return merge(
      !TV_PLATFORMS.includes(platform) ? resizeObservable(domNode).pipe(debounceTime(150)) : NEVER,
      fromEvent(window, 'load')
    ).pipe(
      startWith('init'),
      /** NB: we need to debounceTime,
       * due to   domNode.clientWidth  when domNode is empty it return 0 as width
       * */
      debounceTime(100),
      map(() => ({
        width: domNode.clientWidth,
        height: domNode.clientHeight
      })),
      share()
    );
  }

  static handleSafariCSS(document, { browser, isIOS }) {
    if (browser === 'safari' && !isIOS) document.head.appendChild(cueCSS);
  }

  getLayer(name) {
    if (this.layers[name]) {
      return this.layers[name];
    }

    const layer = document.createElement('div');
    layer.classList.add(`ftv-magneto--${name}`);

    Object.keys(LAYER_STYLE).forEach((prop) => { layer.style[prop] = LAYER_STYLE[prop]; });

    this.container.appendChild(layer);

    this.layers[name] = layer;

    return layer;
  }

  reset() {
    if (this.videoContainer) this.videoContainer.innerHTML = '';
  }

  setContainerOpacity(val) {
    this.videoContainer.style.opacity = val;
  }

  static applyCustomInnerWrapperStyle(playerContainer, customStyle = {}) {
    Array.from(playerContainer.getElementsByClassName('ftv-in-customizable')).forEach((el) => Object.keys(customStyle).forEach((prop) => { el.style[prop] = customStyle[prop]; })); // eslint-disable-line no-param-reassign
  }

  static resetInnerWrapperStyle(playerContainer) {
    const elems = [{
      elem: playerContainer.getElementsByClassName('ftv-in-customizable magneto-video-container')[0],
      style: VIDEO_CONTAINER_STYLE
    }, {
      elem: playerContainer.getElementsByClassName('ftv-in-customizable magneto-wrapper')[0],
      style: UI_WRAPPER_STYLE
    }, {
      elem: playerContainer.getElementsByClassName('ftv-in-customizable magneto-layer')[0],
      style: LAYER_STYLE
    }];

    elems.forEach(({ elem, style }) => {
      elem.style.cssText = Object.entries(style).reduce((acc, [prop, value]) => `${acc}${prop}: ${value};`, ''); // eslint-disable-line no-param-reassign
    });
  }

  getPlatform() {
    return this.platform;
  }
}
