import { h, cloneElement } from 'preact';
import { VOID_FN, deepMerge } from '../../../../utils';
import { useDialogContext } from '../../../context/DialogContext';
import { useStyles } from '../../../hooks';
import { ROW_ITEM_STYLE, ACTIVE_BULLET_STYLE, ACTIVE_RADIO_BUTTON_STYLE, RADIO_BUTTON_STYLE, SELECTABLE_DESCRIPTION_STYLES } from './styles';
import { LABELS_FILL_COLOR, SECONDARY_FILL_COLOR, THIRD_TEXT_COLOR } from '../../../theme/colors';
import { TEXT_LEVEL_2_FONT_SIZE, TEXT_LEVEL_3_FONT_SIZE } from '../../../styles';
import { Icon } from '../svg/icons';

const resolveSelectable = ({ radioButton, active }) => {
  const setStyles = useStyles();
  if (radioButton) {
    return (
      <div style={{ ...setStyles(RADIO_BUTTON_STYLE), borderColor: active ? THIRD_TEXT_COLOR : '#B4B4B4' }}>
        {active && <div style={ACTIVE_RADIO_BUTTON_STYLE} />}
      </div>
    );
  }

  return <div style={{ ...setStyles(ACTIVE_BULLET_STYLE), opacity: Number(active) }} />;
};

function Selectable({
  active,
  radioButton,
  label,
  ariaLabel,
  description,
  first,
  last,
  icon,
  onSelect = VOID_FN,
  closeOnActiveSelect = true,
  index,
  focusable = false,
  totalCount
}) {
  const dialogCtx = useDialogContext();
  const setStyles = useStyles();

  const handleSelect = () => {
    if (closeOnActiveSelect && active) dialogCtx.closeAll();
    onSelect();
  };

  const selectable = resolveSelectable({ radioButton, active });
  const iconSize = setStyles({
    extraSmall: { width: 14, height: 14 },
    small: { width: 18, height: 18 },
    'medium+large+extraLarge': { width: 20, height: 20 }
  });

  return (
    <div
      style={{
        ...ROW_ITEM_STYLE,
        ...setStyles({
          default: { marginLeft: 0, minWidth: '100%' }
        }),
        ...setStyles(TEXT_LEVEL_3_FONT_SIZE),
        ...(last ? { marginBottom: 0 } : {})
      }}
    >
      <div
        className={`ftv-magneto--selectable ${focusable ? 'ftv-magneto--focusable-item' : ''}`}
        onClick={handleSelect}
        role="radio"
        aria-checked={active}
        aria-label={`${ariaLabel || label}, ${description ? `${description}, ` : ''}${index} sur ${totalCount}`}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.keyCode === 32) handleSelect(e);
          if (e.keyCode === 32) e.stopPropagation();
        }}
        tabIndex={0}
        style={setStyles({
          default: {
            cursor: 'pointer',
            lineHeight: '1.8',
            marginLeft: radioButton ? 3 : 14,
            width: '100%',
            ...(radioButton ? { color: 'PRIMARY_TEXT_COLOR' } : {}),
            ...(active && !radioButton ? { color: 'white' } : {}),
            paddingRight: 24,
            marginTop: first ? 0 : 14
          },
          small: { marginTop: first ? 0 : 14 },
          extraSmall: { paddingRight: 20, marginTop: first ? 0 : 14 }
        })}
      >
        <div style={{ display: 'flex', alignItems: 'center', color: LABELS_FILL_COLOR

        }}
        >
          <div style={{ paddingBottom: radioButton ? 2 : 0 }}>
            {cloneElement(selectable, {
              ...selectable.props,
              style: setStyles({
                default: {
                  ...selectable.props.style, transform: `translate(${radioButton ? 0 : -14}px, 0px)`
                },
                'small+large': { transform: `translate(${radioButton ? 0 : -14}px, 0px)` }
              })
            })}
          </div>
          <span
            style={{
              ...setStyles({
                default: {
                  lineHeight: '1.8',
                  transform: `translateX(${radioButton ? 10 : -6}px)`,
                  fontFamily: 'Brown',
                  fontWeight: `${active && !radioButton ? 'bold' : 'normal'}`
                },
                small: {
                  maxWidth: 'unset'
                }
              }),
              ...setStyles(TEXT_LEVEL_2_FONT_SIZE)
            }}
          >
            {label}
          </span>

          {icon && (
            <Icon
              tabIndex={0}
              className="ftv-magneto--selectable"
              name={icon}
              color={SECONDARY_FILL_COLOR}
              width={iconSize.width}
              height={iconSize.height}
            />
          )}
        </div>
        {description && (
        <div name="selectable-item-description" style={setStyles(deepMerge(SELECTABLE_DESCRIPTION_STYLES, TEXT_LEVEL_3_FONT_SIZE))}>
          {description}
        </div>
        )}
      </div>
    </div>
  );
}

export default Selectable;
