/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TVZappingIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <mask
        id="mask0_8306_37754"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={28}
        y={16}
        width={34}
        height={58}
      >
        <path
          d="M39 58.125C39 59.6975 39.605 61.1288 40.5949 62.1987L39.5336 63.26C38.2725 61.9181 37.5 60.1118 37.5 58.125C37.5 56.3071 38.1468 54.6403 39.2227 53.3421L40.2892 54.4086C39.4819 55.4305 39 56.7215 39 58.125Z"
          fill="white"
        />
        <path
          d="M40.2171 63.9023L41.2836 62.8358C42.3056 63.6431 43.5965 64.125 45 64.125C46.4035 64.125 47.6945 63.6431 48.7164 62.8358L49.7829 63.9023C48.4847 64.9782 46.8179 65.625 45 65.625C43.1821 65.625 41.5153 64.9782 40.2171 63.9023Z"
          fill="white"
        />
        <path
          d="M50.4665 63.26L49.4052 62.1987C50.395 61.1288 51 59.6975 51 58.125C51 56.7215 50.5181 55.4306 49.7108 54.4086L50.7773 53.3421C51.8532 54.6403 52.5 56.3071 52.5 58.125C52.5 60.1118 51.7275 61.9181 50.4665 63.26Z"
          fill="white"
        />
        <path
          d="M50.135 52.6586L49.0737 53.7199C48.0038 52.73 46.5725 52.125 45 52.125C43.4275 52.125 41.9962 52.73 40.9263 53.7198L39.865 52.6585C41.2069 51.3975 43.0133 50.625 45 50.625C46.9868 50.625 48.7931 51.3975 50.135 52.6586Z"
          fill="white"
        />
        <path
          d="M45 61.875C47.0711 61.875 48.75 60.1961 48.75 58.125C48.75 56.0539 47.0711 54.375 45 54.375C42.9289 54.375 41.25 56.0539 41.25 58.125C41.25 60.1961 42.9289 61.875 45 61.875Z"
          fill="white"
        />
        <path
          d="M35.625 26.25C35.625 25.2145 36.4645 24.375 37.5 24.375H39.375C40.4105 24.375 41.25 25.2145 41.25 26.25C41.25 27.2855 40.4105 28.125 39.375 28.125H37.5C36.4645 28.125 35.625 27.2855 35.625 26.25Z"
          fill="white"
        />
        <path
          d="M48.75 26.25C48.75 25.2145 49.5895 24.375 50.625 24.375H52.5C53.5355 24.375 54.375 25.2145 54.375 26.25C54.375 27.2855 53.5355 28.125 52.5 28.125H50.625C49.5895 28.125 48.75 27.2855 48.75 26.25Z"
          fill="white"
        />
        <path
          d="M50.625 33.75C49.5895 33.75 48.75 34.5895 48.75 35.625C48.75 36.6605 49.5895 37.5 50.625 37.5H52.5C53.5355 37.5 54.375 36.6605 54.375 35.625C54.375 34.5895 53.5355 33.75 52.5 33.75H50.625Z"
          fill="white"
        />
        <path
          d="M35.625 35.625C35.625 34.5895 36.4645 33.75 37.5 33.75H39.375C40.4105 33.75 41.25 34.5895 41.25 35.625C41.25 36.6605 40.4105 37.5 39.375 37.5H37.5C36.4645 37.5 35.625 36.6605 35.625 35.625Z"
          fill="white"
        />
        <path
          d="M50.625 43.125C49.5895 43.125 48.75 43.9645 48.75 45C48.75 46.0355 49.5895 46.875 50.625 46.875H52.5C53.5355 46.875 54.375 46.0355 54.375 45C54.375 43.9645 53.5355 43.125 52.5 43.125H50.625Z"
          fill="white"
        />
        <path
          d="M35.625 45C35.625 43.9645 36.4645 43.125 37.5 43.125H39.375C40.4105 43.125 41.25 43.9645 41.25 45C41.25 46.0355 40.4105 46.875 39.375 46.875H37.5C36.4645 46.875 35.625 46.0355 35.625 45Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.875 16.875C29.8039 16.875 28.125 18.5539 28.125 20.625V69.375C28.125 71.4461 29.8039 73.125 31.875 73.125H58.125C60.1961 73.125 61.875 71.4461 61.875 69.375V20.625C61.875 18.5539 60.1961 16.875 58.125 16.875H31.875ZM58.125 19.6875H31.875C31.3572 19.6875 30.9375 20.1072 30.9375 20.625V69.375C30.9375 69.8928 31.3572 70.3125 31.875 70.3125H58.125C58.6428 70.3125 59.0625 69.8928 59.0625 69.375V20.625C59.0625 20.1072 58.6428 19.6875 58.125 19.6875Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_8306_37754)" transform="scale(0.66)">
        <rect width={width * 1.5} height={height * 1.5} fill="black" />
        <rect width={width * 1.5} height={height * 1.5} fill="#F8F8F8" />
      </g>
    </SVGWrapper>
  );
}

export default TVZappingIcon;
