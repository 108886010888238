import { h } from 'preact';
import EmbedButton from '../controls/extra/EmbedButton';
import { EXTRA_INFOS_WRAPPER_STYLE } from './styles';
import SourceButton from '../controls/extra/SourceButton';
import Settings from '../controls/extra/Settings';
import EcoButton from '../controls/extra/EcoButton';
import { useStyles } from '../../hooks';
import { useDialogContext } from '../../context/DialogContext';
import { OVERLAY_LAYER } from '../../theme/constants';
import { LABEL_AUDIO_ET_SOUS_SITRES } from '../controls/exp/tracks/TracksMenu';

function ExtraInfos() {
  const setStyles = useStyles();
  const dialogCtx = useDialogContext();

  return (
    <div
      style={{
        ...EXTRA_INFOS_WRAPPER_STYLE,
        zIndex: OVERLAY_LAYER + (dialogCtx.dialogOpened && dialogCtx.activeId !== LABEL_AUDIO_ET_SOUS_SITRES ? 2 : 1),
        ...setStyles({
          extraSmall: { gap: '12px' },
          'small+medium': { gap: '16px' },
          'large+extraLarge': { gap: '24px' }
        })
      }}
    >
      <SourceButton />
      <EcoButton />
      <EmbedButton />
      <Settings />
    </div>
  );
}

export default ExtraInfos;
