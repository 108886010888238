/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvReplayIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2763 26.02C38.5746 25.5341 38.5746 24.4908 39.2763 24.0049L45.6326 19.6047C46.4383 19.047 47.5347 19.6277 47.5347 20.6122V22.6536C58.7679 23.913 67.5 33.443 67.5 45.0124C67.5 57.4389 57.4264 67.5124 45 67.5124C32.5736 67.5124 22.5 57.4389 22.5 45.0124C22.5 38.7812 25.0331 33.1415 29.1257 29.067C29.8173 28.3785 30.9293 28.4312 31.6148 29.1258C32.3817 29.9027 32.3114 31.165 31.5507 31.948C28.2701 35.3247 26.25 39.9327 26.25 45.0124C26.25 55.3678 34.6447 63.7624 45 63.7624C55.3553 63.7624 63.75 55.3678 63.75 45.0124C63.75 35.5168 56.6913 27.6697 47.5347 26.4323V29.4127C47.5347 30.3972 46.4383 30.9779 45.6326 30.4202L39.2763 26.02Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvReplayIcon;
