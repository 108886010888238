import { h } from 'preact';
import { usePlayerContext, useStyles } from '../../../hooks';
import { connect } from '../../../hoc';
import Selectable from '../../common/menu/Selectable';
import { USER_QUALITY_CHANGED, USER_CLICK } from '../../../../types';
import Position from '../../../utils/Position';
import { PIANO_VALUE_POSITION_VIDEO_QUALITY } from '../../../../monitoring/piano/types';

function Quality({
  selectedQualityLevel,
  focusable = false,
  currentQualityLabel: currentLabel,
  mappedQualities,
  isAutomaticLevelSelected,
  automaticLevelLabel
}) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  const onSelect = ({ level, previousLabel, newLabel }) => {
    player.setVideoQuality(level);
    player.userEvents$.next({ action: USER_QUALITY_CHANGED, source: USER_CLICK,
      value: { previousLabel, newLabel, level, position: PIANO_VALUE_POSITION_VIDEO_QUALITY } });
  };

  return (
    <div
      style={setStyles({
        default: { fontSize: 16 },
        large: { fontSize: 18 }
      })}
    >
      <Position>
        <Selectable
          label={automaticLevelLabel}
          index={1}
          focusable={focusable}
          totalCount={mappedQualities.length + 1}
          active={isAutomaticLevelSelected}
          onSelect={() => onSelect({
            level: -1,
            newLabel: automaticLevelLabel,
            previousLabel: currentLabel
          })}
        />
        {mappedQualities.map(({
          label, description, levels, level, icon, iconActive
        }, i) => (
          <Selectable
            label={label}
            description={description}
            icon={levels.includes(selectedQualityLevel) && iconActive ? iconActive : icon}
            focusable={focusable}
            active={levels.includes(selectedQualityLevel)}
            onSelect={() => onSelect({
              level,
              newLabel: label,
              previousLabel: currentLabel
            })}
            index={i + 2}
            totalCount={mappedQualities.length + 1}
          />
        ))}
      </Position>
    </div>
  );
}

const selector = ({
  playback: {
    selectedQualityLevel,
    currentQualityLabel,
    mappedQualities,
    isAutomaticLevelSelected,
    automaticLevelLabel
  }
}) => ({
  selectedQualityLevel,
  currentQualityLabel,
  mappedQualities,
  isAutomaticLevelSelected,
  automaticLevelLabel
});

export default connect(selector)(Quality);
