/* eslint-disable react/no-unknown-property */
import { Fragment, h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvForward10Icon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <Fragment>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M55 45.0249C55 41.1187 53.2371 38.7749 50.4544 38.7749C47.6718 38.7749 45.9089 41.1187 45.9089 45.0249C45.9089 48.9312 47.6718 51.2749 50.4544 51.2749C53.2371 51.2749 55 48.9312 55 45.0249ZM52.6667 45.0249C52.6667 47.5423 51.8544 49.1916 50.4544 49.1916C49.0545 49.1916 48.2422 47.5423 48.2422 45.0249C48.2422 42.5075 49.0545 40.8582 50.4544 40.8582C51.8544 40.8582 52.6667 42.5075 52.6667 45.0249ZM36.9413 43.1152L39.5339 41.6569V49.018H36.25V51.1013H44.7189V49.018H41.8671V38.9485H40.2252L36.9413 40.893V43.1152Z" fill="#F8F8F8" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M50.7237 23.9932C51.4254 24.4777 51.4254 25.5184 50.7237 26.003L44.3674 30.3918C43.5617 30.9481 42.4653 30.3688 42.4653 29.3869V26.4143C33.3087 27.6485 26.25 35.4751 26.25 44.9461C26.25 55.2746 34.6447 63.6474 45 63.6474C55.3553 63.6474 63.75 55.2746 63.75 44.9461C63.75 39.8795 61.7299 35.2835 58.4493 31.9156C57.6886 31.1347 57.6183 29.8756 58.3852 29.1007C59.0707 28.408 60.1827 28.3554 60.8743 29.0421C64.9669 33.106 67.5 38.731 67.5 44.9461C67.5 57.3403 57.4264 67.3877 45 67.3877C32.5736 67.3877 22.5 57.3403 22.5 44.9461C22.5 33.4067 31.2321 23.9015 42.4653 22.6454V20.6093C42.4653 19.6274 43.5617 19.0481 44.3674 19.6044L50.7237 23.9932Z" fill="#F8F8F8" />
      </Fragment>
    </SVGWrapper>
  );
}

export default TvForward10Icon;
