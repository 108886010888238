import { h } from 'preact';
import { BUTTON_LABEL_STYLES, BUTTON_LABEL_TEXT_STYLES, BUTTON_LABEL_WRAPPER_STYLES } from './styles';
import { useStyles } from '../../../hooks';

const resolveAlignStyle = (align, zone) => {
  switch (align) {
    case 'left':
      return ({ left: 0 });
    case 'right':
      return ({ right: 0 });
    case 'center':
    default:
      return ({
        ...(zone === 'panel' ? {} : { left: '50%' }),
        transform: 'translateX(-50%)'
      });
  }
};

const resolveZoneTopStyle = (zone, xs) => {
  if (zone === 'top') return (xs ? 50 : 60);
  if (zone === 'panel') return '100%';
  return (xs ? -4 : -8);
};

const resolveZoneRightStyle = (zone) => {
  if (zone === 'panel') return '100%';
  return null;
};

function ButtonLabel({
  label,
  visible,
  alignX = 'center',
  zone,
  xs,
  style,
  ariaHidden = 'true'
}) {
  const setStyles = useStyles();

  return (
    <div
      style={{
        ...BUTTON_LABEL_WRAPPER_STYLES,
        transform: `translateY(${xs ? -4 : 0}px)`,
        left: ['center', 'left'].includes(alignX) ? 0 : 'unset',
        right: resolveZoneRightStyle(zone) || (['center', 'right'].includes(alignX) ? 0 : 'unset'),
        top: resolveZoneTopStyle(zone, xs),
        ...style
      }}
      ariaHidden={ariaHidden}
    >
      <div style={{
        ...BUTTON_LABEL_STYLES,
        ...resolveAlignStyle(alignX, zone),
        opacity: visible ? 1 : 0,
        transition: visible ? 'opacity .10s ease-in .13s' : 'none'
      }}
      >
        <div style={setStyles(BUTTON_LABEL_TEXT_STYLES)}>{label}</div>
      </div>
    </div>
  );
}

export default ButtonLabel;
