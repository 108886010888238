import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

export function Rewind10Icon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <mask id="mask0_4939_120076" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="2" width="28" height="30">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.5658 6.60457C14.1447 6.31308 14.1447 5.68704 14.5658 5.39556L18.3796 2.7554C18.863 2.42077 19.5208 2.76924 19.5208 3.35991V4.58477C26.2607 5.34041 31.5 11.0584 31.5 18.0001C31.5 25.4559 25.4558 31.5001 18 31.5001C10.5442 31.5001 4.5 25.4559 4.5 18.0001C4.5 14.2613 6.01985 10.8775 8.47541 8.43281C8.89038 8.01969 9.55755 8.05132 9.9689 8.46805C10.429 8.9342 10.3868 9.69162 9.93043 10.1614C7.96204 12.1874 6.75 14.9522 6.75 18.0001C6.75 24.2133 11.7868 29.2501 18 29.2501C24.2132 29.2501 29.25 24.2133 29.25 18.0001C29.25 12.3026 25.0148 7.59443 19.5208 6.85199V8.64021C19.5208 9.23088 18.863 9.57935 18.3796 9.24472L14.5658 6.60457Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M20.8977 14.2501C22.5673 14.2501 23.625 15.6563 23.625 18.0001C23.625 20.3438 22.5673 21.7501 20.8977 21.7501C19.2281 21.7501 18.1703 20.3438 18.1703 18.0001C18.1703 15.6563 19.2281 14.2501 20.8977 14.2501ZM20.8977 20.5001C21.7376 20.5001 22.225 19.5105 22.225 18.0001C22.225 16.4896 21.7376 15.5001 20.8977 15.5001C20.0577 15.5001 19.5703 16.4896 19.5703 18.0001C19.5703 19.5105 20.0577 20.5001 20.8977 20.5001ZM14.3453 15.9792L12.7898 16.8542V15.5209L14.7601 14.3542H15.7453V20.3959H17.4563V21.6459H12.375V20.3959H14.3453V15.9792Z" fill="black" />
      </mask>
      <g mask="url(#mask0_4939_120076)">
        <rect width="36" height="36" fill={color} />
      </g>
    </SVGWrapper>
  );
}
