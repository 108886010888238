export const MODAL_BACK_BUTTON_NAME = 'btn-back';

export const MODAL_BASIC_STYLE = {
  default: { width: 494, right: 80, top: 12 },
  medium: { width: 494, right: 64, top: 12 },
  'small+extraSmall': { width: '100%', maxWidth: '100%', height: '100%', maxHeight: '100%', right: 0, top: 0, borderRadius: 0 },
  large: { width: 494, right: 100, top: 12 }
};

export const MODAL_CLOSING_BUTTON_STYLE = {
  default: { width: 30, position: 'absolute' },
  extraSmall: { right: 6, top: 23.5 },
  small: { right: 18, top: 25 },
  'medium+large+extraLarge': { right: 18, top: 23 }
};
