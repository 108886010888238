/* eslint-disable no-param-reassign */
export class RadioGroup {
  constructor(groupNode, id, isTv) {
    this.groupNode = groupNode;

    this.radioButtons = [];

    this.firstRadioButton = null;
    this.lastRadioButton = null;

    const rbs = isTv ? Array.from(document.getElementsByClassName('ftv-magneto--selectable')) : this.groupNode.querySelectorAll(`[data-parentid=${id}]`);

    rbs.forEach((rb) => {
      rb.tabIndex = -1;

      // if (rb.ariaChecked === 'true') {
      //   this.selectItem(rb);
      // } else {
      //   rb.setAttribute('aria-checked', 'false');
      // }

      rb.addEventListener('keydown', this.handleKeydown.bind(this));
      rb.addEventListener('click', this.handleClick.bind(this));
      rb.addEventListener('focus', RadioGroup.handleFocus);
      rb.addEventListener('blur', RadioGroup.handleBlur);

      this.radioButtons.push(rb);

      if (!this.firstRadioButton) {
        this.firstRadioButton = rb;
        this.firstRadioButton.tabIndex = 0;
      }
      this.lastRadioButton = rb;
    });
  }

  selectItem(currentItem, setChecked = false) {
    if (setChecked) {
      this.radioButtons.forEach((rb) => {
        rb.setAttribute('aria-checked', 'false');
        rb.tabIndex = -1;
      });
      currentItem.setAttribute('aria-checked', 'true');
      currentItem.tabIndex = 0;
    }
    currentItem.focus();
  }

  setCheckedToPreviousItem(currentItem) {
    let index;

    if (currentItem === this.firstRadioButton) {
      this.selectItem(this.lastRadioButton);
    } else {
      index = this.radioButtons.indexOf(currentItem);
      this.selectItem(this.radioButtons[index - 1]);
    }
  }

  setCheckedToNextItem(currentItem) {
    let index;

    if (currentItem === this.lastRadioButton) {
      this.selectItem(this.firstRadioButton);
    } else {
      index = this.radioButtons.indexOf(currentItem);
      this.selectItem(this.radioButtons[index + 1]);
    }
  }

  /* EVENT HANDLERS */

  handleKeydown(event) {
    const tgt = event.currentTarget;
    let flag = false;

    switch (event.key) {
      case ' ':
      case 'Enter':
        this.selectItem(tgt, true);
        flag = true;
        break;

      case 'Up':
      case 'ArrowUp':
      case 'Left':
      case 'ArrowLeft':
        this.setCheckedToPreviousItem(tgt);
        flag = true;
        break;

      case 'Down':
      case 'ArrowDown':
      case 'Right':
      case 'ArrowRight':
        this.setCheckedToNextItem(tgt);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleClick(event) {
    this.selectItem(event.currentTarget, true);
    event.currentTarget.classList.remove('focus');
  }

  static handleFocus(event) {
    event.currentTarget.classList.add('focus');
  }

  static handleBlur(event) {
    event.currentTarget.classList.remove('focus');
  }
}
