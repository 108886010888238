import { h } from 'preact';
import { TV_PAUSEROLL_BTN_PLAY_STYLES } from './styles';
import { usePlayerContext } from '../../../ui/hooks';
import { SKIP_BTN_LAYER } from '../../../ui/theme/constants';
import { USER_PLAY, USER_PLAY_PAUSEROLL } from '../../../types';
import { FTV_DARK_PRIMARY_BACKGROUND_COLOR, PRIMARY_FILL_COLOR } from '../../../ui/theme/colors';
import { TVButton } from '../common/tvbutton';

function TVPauserollResume({ innerRef, style = {}, disableFocus = false }) {
  const player = usePlayerContext();
  const customStyle = TV_PAUSEROLL_BTN_PLAY_STYLES;

  return (
    <div
      className="pauseroll-retake"
      type="button"
      style={{
        ...style,
        zIndex: SKIP_BTN_LAYER
      }}
      ref={innerRef}
    >
      <TVButton
        name="pauseroll-retake"
        disableFocus={disableFocus}
        disableScale
        wrapChildren
        onClick={(e) => {
          e.stopPropagation();
          player.userEvents$.next({
            action: USER_PLAY_PAUSEROLL,
            source: USER_PLAY
          });
          player.play({ userGesture: true });
        }}
        icon="tv-pauserollPlay"
        iconStyle={{ verticalAlign: 'middle' }}
        style={{
          ...customStyle,
          ...{
            backgroundColor: `${FTV_DARK_PRIMARY_BACKGROUND_COLOR}1A`,
            color: PRIMARY_FILL_COLOR
          }
        }}
        labelWrapperStyle={{ display: 'table-cell', paddingLeft: 15, verticalAlign: 'middle' }}
        iconWrapperStyle={{ display: 'table-cell', verticalAlign: 'middle' }}
        customActiveStyle={
          {
            backgroundColor: FTV_DARK_PRIMARY_BACKGROUND_COLOR, color: 'black' }
        }
        label="reprendre la vidéo"
        ariaLabel="Fermer la publicité et reprendre la vidéo"
        iconSize={customStyle.iconSize}
      />
    </div>
  );
}

export default TVPauserollResume;
