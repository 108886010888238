import { h } from 'preact';
import { useStyles } from '../../../hooks';
import { Knob } from '../knob';

function SlicedSliderBar({
  slices: segmentsWidths,
  style: {
    track,
    cursor
  },
  value,
  targetRef,
  currentSegment
}) {
  const setStyles = useStyles();
  return (
    <div
      style={{
        ...track,
        display: '-webkit-flex',
        '-webkit-align-items': 'center',
        backgroundColor: 'none',
        ...setStyles({ default: { height: 8 }, extraSmall: { height: 6 } })
      }}
      ref={targetRef}
    >
      {segmentsWidths.map((sliceWidth, i) => {
        const borderRadiusSize = i === currentSegment ? '8px' : '4px';
        let borderRadius;
        if (i === (segmentsWidths.length - 1)) {
          borderRadius = `${borderRadiusSize} 0 0 ${borderRadiusSize}`;
        } else if (i === 0) {
          borderRadius = `0 ${borderRadiusSize} ${borderRadiusSize} 0`;
        } else {
          borderRadius = borderRadiusSize;
        }

        return (
          <div
            style={{
              backgroundColor: i === (segmentsWidths.length - 1) ? 'red' : 'white',
              borderRadius,
              height: i === currentSegment ? '200%' : '100%',
              width: `${sliceWidth}%`,
              margin: i === (segmentsWidths.length - 1) ? '1px 0px 0px 4px' : '0px 4px 0px 4px'
            }}
          />
        );
      })}
      <Knob
        position={value}
        styles={{
          ...setStyles(cursor),
          marginLeft: 'unset'
        }}
        width={segmentsWidths[segmentsWidths.length - 1]}
      />
    </div>
  );
}
export default SlicedSliderBar;
