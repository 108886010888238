import { h, cloneElement } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { useStateRef, useStyles } from '../hooks';
import { SCROLLABLE_WRAPPER_STYLES, SCROLLABLE_CONTENT_STYLES, SCROLLABLE_SCROLLBAR_WRAPPER_STYLES, SCROLLABLE_SCROLLBAR_STYLES } from './styles';
import '../theme/styles/scrollbar.css';
import { VOID_FN } from '../../utils';

const SCROLLBAR_AUTOHIDE_DELAY = 1200;
const pageYSelector = (isTouch) => (evt) => (isTouch ? evt.touches[0] : evt).pageY;

function Scrollable({
  height = '100%',
  width = '100%',
  maxHeight = '100%',
  showScrollBar = true,
  onScroll = VOID_FN,
  onScrollEnd = VOID_FN,
  children,
  autoHide = false,
  forceHide = false,
  /* Flexboxes affect the size and behavior of their children.
  If the parent of a component is a flexbox, a specific CSS behavior is slighty different: the overflow.
  In a flexbox, if the content of an element exceeds its width/height, the element will grow.
  To prevent this, we apply overflow: 'hidden' along with flex: 1.
  The flex: 1 ensures that the element takes up all available space, and overflow allows us to prevent overflow in the flex context.
  Instead of hiding the overflow, it delegates this task to the child div with overflow: 'hidden'.
  TLDR using this option can be useful when <Scrollable /> is used within a flexbox context */
  forceInnerScroll = false
}) {
  const elRef = useRef(null);
  const pageY = useRef(0);
  const timer = useRef(null);
  const scrollEndTimer = useRef(null);
  const containerRef = useRef(null);
  const setStyles = useStyles();

  const [{ grabbed, hide, hover }, setScrollBarState] = useState({
    grabbed: false,
    hover: false,
    hide: false
  });
  const [computedProps, setComputedProps, computedPropsRef] = useStateRef({});

  const resetTimer = (shouldAutoHide) => {
    clearTimeout(timer.current);
    if (shouldAutoHide) {
      timer.current = setTimeout(
        () => setScrollBarState((state) => ({ ...state, hide: autoHide })),
        SCROLLBAR_AUTOHIDE_DELAY
      );
    }
  };

  const updateScrollBar = () => {
    const { scrollHeight, clientHeight, scrollTop } = elRef.current;
    const currentScrollRatio = clientHeight / scrollHeight;
    const scrollEnabled = currentScrollRatio < 1;

    setComputedProps({
      scrollRatio: currentScrollRatio,
      height: scrollEnabled ? `${Math.max(currentScrollRatio * 100, 10)}%` : 0,
      top: scrollEnabled ? `${(scrollTop * 100) / scrollHeight}%` : 'unset'
    });
  };

  const toggleScrollBar = ({ shouldHide, autoHide: autoHideMe }) => {
    updateScrollBar();
    setScrollBarState((state) => ({ ...state, hide: shouldHide }));
    resetTimer(autoHideMe);
  };

  const handleOnScrollEnd = () => {
    const { scrollHeight, clientHeight, scrollTop } = elRef.current;
    onScroll(false);
    if (scrollHeight !== clientHeight) {
      onScrollEnd({
        percent: Math.round((100 * scrollTop) / (scrollHeight - clientHeight))
      });
    }
  };

  const handleOnScroll = () => {
    onScroll(true);

    clearTimeout(scrollEndTimer.current);
    scrollEndTimer.current = setTimeout(() => handleOnScrollEnd(), 250);
    return showScrollBar && toggleScrollBar({ shouldHide: false, autoHide });
  };

  const handleDrag = ({ isTouch } = {}, event) => {
    event.preventDefault(); /* prevents mousedown to trigger after touchstart on touch device */
    const moveEvent = isTouch ? 'touchmove' : 'mousemove';
    const endEvent = isTouch ? 'touchend' : 'mouseup';

    const drag = (evt) => {
      toggleScrollBar({ shouldHide: false, autoHide: false });
      const currentPageY = pageYSelector(isTouch)(evt);
      const delta = currentPageY - pageY.current;
      pageY.current = currentPageY;
      elRef.current.scrollTop += delta / computedPropsRef.current.scrollRatio;
    };

    const release = () => {
      setScrollBarState((state) => ({ ...state, grabbed: false }));
      toggleScrollBar({ shouldHide: false, autoHide });
      document.removeEventListener(moveEvent, drag);
      document.removeEventListener(endEvent, release);
    };

    document.addEventListener(moveEvent, drag);
    document.addEventListener(endEvent, release);

    setScrollBarState((state) => ({ ...state, grabbed: true }));
    pageY.current = pageYSelector(isTouch)(event);
  };

  useEffect(() => {
    if (autoHide) resetTimer(true);
    updateScrollBar();
    return () => clearTimeout(timer.current);
  }, [maxHeight]);

  useEffect(() => () => clearTimeout(scrollEndTimer.current), []);

  // Update scrollbar height to the right value when did mount
  useEffect(() => containerRef?.current && updateScrollBar());

  return (
    <div
      ref={containerRef}
      style={{
        height,
        width,
        maxHeight,
        ...(forceInnerScroll ? { flex: 1, overflow: 'hidden' } : {})
      }}
    >
      <div /* scroll-wrapper */
        onMouseEnter={() => showScrollBar && toggleScrollBar({ shouldHide: false, autoHide })}
        onMouseMove={() => showScrollBar && toggleScrollBar({ shouldHide: false, autoHide })}
        onMouseLeave={() => showScrollBar
          && !grabbed
          && toggleScrollBar({ shouldHide: forceHide, autoHide })}
        style={SCROLLABLE_WRAPPER_STYLES}
      >
        <div /* scroll-content */
          className="ftv-magneto--scrollable-content"
          /* if maxHeight is calculated based on a percentage, reset content maxHeight to 100% */
          style={{
            ...SCROLLABLE_CONTENT_STYLES,
            maxHeight:
              typeof maxHeight === 'string' && maxHeight.includes('calc')
                ? '100%'
                : maxHeight
          }}
          ref={elRef}
          onScroll={handleOnScroll}
        >
          {cloneElement(children, {
            style: children.props?.style ? children.props.style : {}
          })}
        </div>
        <div /* scroll-bar-wrapper */
          className="ftv-magneto--scrollable-scrollbar"
          onMouseEnter={() => setScrollBarState((state) => ({ ...state, hover: true }))}
          onMouseLeave={() => setScrollBarState((state) => ({ ...state, hover: false }))}
          onMouseDown={(e) => handleDrag({ isTouch: false }, e)}
          onTouchStart={(e) => handleDrag({ isTouch: true }, e)}
          role="presentation"
          style={{
            ...setStyles(SCROLLABLE_SCROLLBAR_WRAPPER_STYLES),
            display: computedProps.scrollRatio >= 1 ? 'none' : 'unset',
            top: computedProps.top,
            height: computedProps.height,
            ...(hide ? { opacity: 0 } : {}),
            ...(grabbed || hover ? { opacity: 1 } : {})
          }}
        >
          <div style={SCROLLABLE_SCROLLBAR_STYLES} /* scroll-bar */ />
        </div>
      </div>
    </div>
  );
}

export default Scrollable;
