import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { createAnimation } from '../../../utils/animation';
import ControlButton from './ControlButton';

function MorphButton({
  svg, toggle, duration = 120,
  ...props
}) {
  const [frame, setFrame] = useState(0);

  useEffect(() => {
    const totalFrames = (svg.length - 1);
    createAnimation({
      duration,
      fn: (p) => setFrame(() => Math.abs(Math.round((toggle ? 0 : totalFrames) - (p * totalFrames))))
    });
  }, [toggle]);

  return <ControlButton svg={svg} frame={frame} {...props} />;
}

export default MorphButton;
