import {
  VOLUME_CHANGE,
  VOLUME_MUTE,
  AD_VOLUME_CHANGE
} from '../types';

const initialState = {
  muted: false,
  level: 0.5
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case (VOLUME_MUTE): {
      const { muted } = payload;
      return { ...state, muted };
    }
    case (VOLUME_CHANGE):
    case (AD_VOLUME_CHANGE): {
      const { level } = payload;
      return { ...state, level };
    }
    default: {
      return state;
    }
  }
};
