/* eslint-disable react/style-prop-object, react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PlaylistIcon({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask_playlist" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="7" width="23" height="18">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4036 17.2084C27.4036 17.6916 27.0364 18.0834 26.5833 18.0834L5.82031 18.0834C5.36727 18.0834 5 17.6916 5 17.2084C5 16.7251 5.36727 16.3334 5.82031 16.3334L26.5833 16.3334C27.0364 16.3334 27.4036 16.7251 27.4036 17.2084Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4036 23.625C27.4036 24.1082 27.0364 24.5 26.5833 24.5L5.82031 24.5C5.36727 24.5 5 24.1082 5 23.625C5 23.1417 5.36727 22.75 5.82031 22.75L26.5833 22.75C27.0364 22.75 27.4036 23.1418 27.4036 23.625Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.4577 10.2084C27.4577 10.6916 27.0904 11.0834 26.6374 11.0834L13.9863 11.0834C13.5333 11.0834 13.166 10.6916 13.166 10.2084C13.166 9.72513 13.5333 9.33337 13.9863 9.33337L26.6374 9.33337C27.0904 9.33337 27.4577 9.72512 27.4577 10.2084Z" fill="black" />
        <path d="M5 12.432V7.40136C5 7.09026 5.3801 6.8978 5.67957 7.05727L10.6144 9.68523C10.9129 9.84422 10.9045 10.2325 10.5993 10.3811L5.66452 12.7838C5.36514 12.9295 5 12.7363 5 12.432Z" fill="black" />
      </mask>
      <g mask="url(#mask_playlist)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default PlaylistIcon;
