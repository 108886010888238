import { h } from 'preact';

import { useState, useEffect, useRef } from 'preact/hooks';
import { useDialogContext, useOpenDialogsCounter, useRegisterDialog } from '../../../context/DialogContext';
import { DIALOG_CLOSE_BUTTON_STYLE } from './styles';
import { Button } from '..';
import { connect } from '../../../hoc';
import IconButton from './IconButton';
import { BACK_BTN_SHORTCUTS, ENTER_BUTTTONS_LIST } from '../../../../core/shortcut/types';

function DialogButton({
  svg,
  svgActive = svg, /* 👀 */
  size,
  style,
  styleOverride,
  onClick,
  onKeyDown,
  onHover,
  onInteract,
  onClose,
  onOpen,
  openingMode,
  forceLabel,
  forceDialog = false,
  id,
  children,
  closeBtnSize,
  closeBtnStyleOverride,
  closeBtnLabelZone = 'top',
  closeBtnLabelStyle = {},
  hideLabelForCloseBtn,
  name,
  disableFocus,
  showChildren,
  label,
  activeIconRef,
  iconRef,
  showTracks,
  icon,
  activeIcon,
  dialogAriaLabel,
  ...extraProps
}) {
  const isOverMode = openingMode === 'over';
  const [opened, setOpened] = useState(false);
  const [hover, setHover] = useState(false);

  const toggleDialog = () => setOpened((state) => !state);

  useRegisterDialog({ id, openingMode, state: [opened, setOpened] });

  const initialRender = useRef(true);
  useEffect(() => {
    if (!showTracks) setOpened(showTracks);
  }, [showTracks]);

  useOpenDialogsCounter(opened, isOverMode);

  useEffect(() => {
    /* We want to trigger onOpen and onClose on render update and not on mount,
       to avoid trigger an opened = false every time we display the ui */
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    if (!isOverMode && opened) onHover(false);
    (opened ? onOpen : onClose)();
  }, [opened]);

  useEffect(() => {
    onInteract(hover || forceDialog);
    setOpened(hover || forceDialog);
  }, [hover, forceDialog]);

  const ctnProps = isOverMode ? {
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false)
  } : {
    onMouseEnter: () => onInteract(true),
    onMouseLeave: () => !opened && onInteract(false) /* keep isInteracting: true when dialog is opened */
  };

  const btnProps = {
    label,
    onClick: isOverMode ? onClick : () => {
      toggleDialog();
      onClick();
    },
    /**
     * pass parent onHover function to ControlButton
     * in order to force label visibility
     */
    ...(((!isOverMode && !opened) || forceLabel) ? { onHover } : {})
  };

  const dialogCtx = useDialogContext();
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      {...ctnProps}
      onClick={(e) => e.stopPropagation()}
      style={{
        ...{ userSelect: 'auto' },
        ...style.container,
        ...((opened || showChildren) ? style.openedContainer : {}),
        ...styleOverride
      }}
      tabIndex="-1"
      onKeyDown={(e) => {
        if ([27/* Escape */, ...BACK_BTN_SHORTCUTS].includes(e.keyCode) && dialogCtx.dialogOpened) {
          e.preventDefault();
          dialogCtx.closeAll();
          activeIconRef?.current?.focus();
        }
      }}
      name="close-dialog"
    >
      <IconButton
        svg={opened ? svgActive : svg}
        icon={opened ? activeIcon || icon : icon}
        style={style.buttonOverride}
        size={size}
        name={name}
        onKeyDown={onKeyDown}
        disableFocus={disableFocus}
        iconRef={iconRef}
        activeIconRef={activeIconRef}
        {...btnProps}
        {...extraProps}
      />
      <div
        style={{
          right: -size, /* placement of default dialog relative to button size */
          ...style.content,
          ...((opened || showChildren) ? style.openedContent : { pointerEvents: 'none' })
        }}
        role="dialog"
        aria-modal="true"
        aria-label={dialogAriaLabel}
      >
        {children}

        {
            opened && style.showClose
            && (
            <div
              style={{ ...DIALOG_CLOSE_BUTTON_STYLE, ...closeBtnStyleOverride }}
            >
              <Button
                label="fermer"
                zone={closeBtnLabelZone}
                last
                type="icon"
                icon="close"
                onClick={() => setOpened(false)}
                onKeyDown={(e) => {
                  if ([13, 32 /* Space or Enter */, ...ENTER_BUTTTONS_LIST].includes(e.keyCode) && dialogCtx.dialogOpened) {
                    e.preventDefault();
                    dialogCtx.closeAll();
                    activeIconRef?.current?.focus();
                  }
                  onKeyDown(e);
                }}
                size={closeBtnSize || size}
                labelStyle={{ top: 6, ...closeBtnLabelStyle }}
                ariaLabel={`fermer ${id}`}
                disableFocus={disableFocus}
                name={`close ${id}`}
              />
            </div>
            )
          }

      </div>
    </div>
  );
}

const selector = ({ ui: { showTracks } }) => ({ showTracks });
export default connect(selector)(DialogButton);
