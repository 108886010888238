import {
  BehaviorSubject,
  combineLatest,
  merge,
  of,
  race
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  switchMap,
  throttleTime,
  withLatestFrom,
  startWith
} from 'rxjs/operators';
import { TIME_LEFT } from '../../ad/freewheel/types';
import {
  TIMESHIFTING_AUTO_CONTROLLER_NAME,
  TIMESHIFTING_CONTROLLER_NAME,
  TIMESHIFTING_TYPE_ADSWITCHING
} from '../timeshifting/types';
import { Disposable } from '..';

export class LabelController extends Disposable {
  constructor(player) {
    super();
    const {
      rendererController: { currentTime$, duration$ },
      timeshiftable$,
      events$,
      medias$,
      freewheelController: { adCountdown$, isAd$ }
    } = player;

    this.liveLabel$ = new BehaviorSubject('');
    this.timelineLabel$ = new BehaviorSubject('');
    this.pubLabel$ = new BehaviorSubject('');

    medias$.pipe(switchMap(({ video: { is_live: isLive } }) => (isLive
      ? merge(
        of('en direct'),
        LabelController.getTimeshiftingControllerName({ events$ })
          .pipe(switchMap((controllerName) => LabelController.createLiveLabel({
            isTimeshiftingStreamLive$: player[controllerName].isTimeshiftingStreamLive$,
            timeshiftable$,
            timelineLabel$: this.timelineLabel$
          })))
      )
      : of(''))))
      .subscribe(this.liveLabel$);

    /**
     * we don't check on media.video.is_live here
     * because the event on timeshifting controller will never emit if it's a simple live
     */
    LabelController.getTimeshiftingControllerName({ events$ })
      .pipe(switchMap((controllerName) => LabelController.createTimelineLabel({
        currentTime$,
        duration$,
        isTimeshiftingStreamLive$: player[controllerName].isTimeshiftingStreamLive$
      })))
      .subscribe(this.timelineLabel$);

    LabelController.createPubLabel({
      adCountdown$,
      isAd$,
      adTimeLeft$: LabelController.createAdTimeLeftStream({ events$ })
    }).subscribe(this.pubLabel$);

    events$.pipe(
      filter((name) => name === 'daiControllerLoaded'),
      switchMap(() => LabelController.createDaiPubLabel({
        /* use startWith to emit default value because DaiController don't do it (createIsAdStream) */
        isAd$: player.daiController.isAd$.pipe(startWith({ isAd: false })),
        adCountdown$: player.daiController.adCountdown$,
        medias$
      }))
    ).subscribe(this.pubLabel$);
  }

  static createAdTimeLeftStream({ events$ }) {
    return events$.pipe(
      filter((evt) => evt.name === TIME_LEFT),
      map(({ remainingTime }) => remainingTime),
      distinctUntilChanged()
    );
  }

  static createPubLabel({ adCountdown$, isAd$, adTimeLeft$ }) {
    return isAd$.pipe(
      switchMap(({ isAd }) => (isAd
        ? adTimeLeft$.pipe(map((adTimeLeft) => (`publicité - ${adTimeLeft} sec`)))
        : merge(
          of(''),
          adCountdown$.pipe(map(({ time }) => (time > 0
            ? `publicité dans ${time} sec`
            : '')))
        )
      ))
    );
  }

  static createDaiPubLabel({ isAd$, adCountdown$, medias$ }) {
    return isAd$.pipe(
      withLatestFrom(medias$),
      switchMap(([
        { isAd }, { video: { dai_type: daiType } }
      ]) => {
        const adLabel$ = of('publicité');

        if (isAd) return adLabel$;
        if (daiType === TIMESHIFTING_TYPE_ADSWITCHING) return adLabel$;
        return LabelController.createDaiPubLabelCountdown({ adCountdown$ });
      })
    );
  }

  static createDaiPubLabelCountdown({ adCountdown$ }) {
    return merge(of(''), adCountdown$.pipe(
      map(({ time }) => (!!time || time > 0 ? `publicité dans ${time} sec` : ''))
    ));
  }

  static getTimeshiftingControllerName({ events$ }) {
    return race(
      events$.pipe(
        filter((eventName) => eventName === `${TIMESHIFTING_CONTROLLER_NAME}Loaded`),
        mapTo(TIMESHIFTING_CONTROLLER_NAME)
      ),
      events$.pipe(
        filter((eventName) => eventName === `${TIMESHIFTING_AUTO_CONTROLLER_NAME}Loaded`),
        mapTo(TIMESHIFTING_AUTO_CONTROLLER_NAME)
      )
    );
  }

  static createLiveLabel({
    isTimeshiftingStreamLive$,
    timeshiftable$,
    timelineLabel$
  }) {
    return combineLatest(isTimeshiftingStreamLive$, timeshiftable$).pipe(
      switchMap(([onLive, timeshiftable]) => (onLive || !timeshiftable
        ? of('en direct')
        : timelineLabel$.pipe(map((timelineLabel) => `en différé ${timelineLabel}`))
      )),
      distinctUntilChanged()
    );
  }

  static createDelayedTimeStream({ currentTime$, duration$ }) {
    return combineLatest(
      duration$,
      /* Throttle to avoid mutiple calcul on delayed time */
      currentTime$.pipe(throttleTime(200))
    ).pipe(
      map(([currentDuration, currentTime]) => LabelController.resolveDelayedTimeLabel({ duration: currentDuration, currentTime }))
    );
  }

  static createTimelineLabel({ isTimeshiftingStreamLive$, currentTime$, duration$ }) {
    return isTimeshiftingStreamLive$.pipe(
      switchMap((onLive) => (!onLive
        ? LabelController.createDelayedTimeStream({ currentTime$, duration$ })
        : of(''))),
      distinctUntilChanged()
    );
  }

  static resolveDelayedTimeLabel({ duration, currentTime }) {
    const computedDelayedTime = Math.floor((duration - currentTime));
    const minutes = Math.floor((computedDelayedTime % 3600) / 60);
    if (computedDelayedTime > 3600) {
      const hour = Math.floor(computedDelayedTime / 3600);
      if (hour >= 4) { return `- ${hour} h`; }
      return `- ${hour} h${minutes > 0 ? ` ${minutes} min` : ''}`;
    } if (computedDelayedTime > 60) return `- ${minutes} min`;
    return '- 1 min';
  }
}
