const TEST_KEY = '__TEST';
const STORAGES = ['localStorage', 'sessionStorage'];

export class StorageFallback {
  constructor() {
    this.data = {};
  }

  getItem(key) {
    return this.data[key] || null;
  }

  setItem(key, value) {
    this.data[key] = String(value);
    return this.data[key];
  }

  removeItem(key) {
    return delete this.data[key];
  }

  clear() {
    this.data = {};
    return this.data;
  }
}

export const hasStorage = (window, name) => {
  try {
    window[name].setItem(TEST_KEY, '1');
    window[name].removeItem(TEST_KEY);
    return true;
  } catch (e) {
    return false;
  }
};

export const getStorage = (window, storages) => {
  const storageKey = storages.find((storage) => hasStorage(window, storage));
  return storageKey ? window[storageKey] : new StorageFallback();
};

export const getSessionStorage = getStorage(window, ['sessionStorage']);

export default getStorage(window, STORAGES);
