import { Subject } from 'rxjs';

export class Disposable {
  // eslint-disable-next-line class-methods-use-this
  dispose() {
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(this)) {
      if (value instanceof Subject) {
        value.complete();
      }
    }
  }
}
