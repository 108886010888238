import { of, ReplaySubject, Subject, merge, defer, asyncScheduler, NEVER, BehaviorSubject } from 'rxjs';
import { switchMap, withLatestFrom, filter, map, takeUntil, share, catchError, delay } from 'rxjs/operators';

import RendererHTML from './html/html';
import AudioTracksController from '../tracks/audio';
import TextTrackController from '../tracks/text';
import QualityController from '../quality';
import { systemInfo } from '../../utils';
import error from '../../error';

import { RENDERER_CONFIG, RENDERER_DISPOSE, HLS_TYPE, DASH_TYPE, HTML_TYPE, AUDIO_TYPE, RENDERER_INIT_DONE } from './types';
import { FIRST_PLAY, PLAY, PAUSE, INTERRUPTION, SEEK, MUTE, VOLUME, SPEED, INITIATED_PLAY } from '../command/types';
import RendererAudio from './audio';
import { BEFORE_HOOK, INTERRUPTION_CANCELED, INTERRUPTION_END } from '../interruptor/types';
import { TV_PLATFORMS } from '../dom/types';

const match = {
  [HLS_TYPE]: async (media, playerConfig, videoUnlocker, isAd$) => {
    const { default: RendererHLS } = await import(/* webpackChunkName: "hls" */'./html/hls');
    return new RendererHLS(media, playerConfig, videoUnlocker, isAd$);
  },
  [DASH_TYPE]: async (media, playerConfig, videoUnlocker, isAd$) => {
    const { default: RendererDash } = await import(/* webpackChunkName: "dash" */'./html/dash');
    return new RendererDash(media, playerConfig, videoUnlocker, isAd$);
  },
  [HTML_TYPE]: (media, playerConfig, videoUnlocker, isAd$) => new RendererHTML(media, playerConfig, videoUnlocker, isAd$),
  [AUDIO_TYPE]: (media) => new RendererAudio(media)
};

export const TV_DELAY_INIT_RENDERER = 40;

export default class RendererController {
  constructor({
    currentVideo$,
    medias$,
    commands$,
    dom$,
    events$,
    isAd$,
    playerConfig$,
    platform,
    showAd,
    interruptions$,
    videoUnlocker
  }) {
    this.state$ = new Subject();
    this.activeRenderer$ = new ReplaySubject(1);
    this.errors$ = new Subject();
    this.currentTime$ = new BehaviorSubject(0);

    /* dispose current renderer as soon as a new video is requested */
    currentVideo$.pipe(
      withLatestFrom(merge(this.activeRenderer$, of(null))),
      filter(([, prevRenderer]) => Boolean(prevRenderer))
    ).subscribe(([, prevRenderer]) => RendererController.disposeRenderer(prevRenderer));

    this.nextRenderer$ = this.createNextRendererStream(medias$, this.state$, playerConfig$, events$, videoUnlocker, isAd$);

    this.nextRenderer$.subscribe(this.activeRenderer$);
    this.cmd$ = RendererController.createCommandStream(this.activeRenderer$, commands$);
    this.duration$ = RendererController.mapRendererStream(this.activeRenderer$, 'duration$');
    this.currentTime$ = RendererController.mapRendererStream(this.activeRenderer$, 'currentTime$');
    this.muted$ = RendererController.mapRendererStream(this.activeRenderer$, 'muted$');
    this.volume$ = RendererController.mapRendererStream(this.activeRenderer$, 'volume$');
    this.buffered$ = RendererController.mapRendererStream(this.activeRenderer$, 'buffered$');
    this.audioTracks$ = RendererController.mapRendererStream(this.activeRenderer$, 'audioTracks$');
    this.textTracks$ = RendererController.mapRendererStream(this.activeRenderer$, 'textTracks$');
    this.bitrate$ = RendererController.mapRendererStream(this.activeRenderer$, 'bitrate$');
    this.qualities$ = RendererController.mapRendererStream(this.activeRenderer$, 'qualities$');
    this.playbackRate$ = RendererController.mapRendererStream(this.activeRenderer$, 'playbackRate$');
    this.rendererStream$ = RendererController.mapRendererStream(this.activeRenderer$, 'stream$');

    this.audioTracksController = new AudioTracksController({
      tracks$: this.audioTracks$,
      activeRenderer$: this.activeRenderer$,
      events$
    });

    this.textTracksController = new TextTrackController({
      tracks$: this.textTracks$,
      activeRenderer$: this.activeRenderer$,
      events$,
      rendererStream$: this.rendererStream$
    });

    this.state$.subscribe(events$);

    this.qualityController = new QualityController({ activeRenderer$: this.activeRenderer$ });

    const commandHandler = RendererController.createCommandHandler();

    isAd$.pipe(switchMap(({ isAd }) => (isAd ? NEVER : this.cmd$)), withLatestFrom(medias$))
      .subscribe(([{ command, renderer }, { video }]) => commandHandler(command, renderer, video));

    if (showAd && TV_PLATFORMS.includes(platform)) {
      interruptions$.pipe(
        filter(({ type, status }) => (
          type === BEFORE_HOOK
          && [INTERRUPTION_END, INTERRUPTION_CANCELED].includes(status)
        )),
        withLatestFrom(this.activeRenderer$.pipe(withLatestFrom(dom$))),
        map(([, activeRenderer]) => activeRenderer),
        delay(TV_DELAY_INIT_RENDERER)
      ).subscribe(
        RendererController.initRenderer.bind(null, events$, interruptions$)
      );
    } else {
      this.activeRenderer$
        .pipe(withLatestFrom(dom$))
        .subscribe(RendererController.initRenderer.bind(null, events$, interruptions$));
    }
  }

  selectAudioTrack(index) {
    this.audioTracksController.nextTrack(index);
  }

  selectTextTrack(index) {
    this.textTracksController.nextTrack(index);
  }

  setSubtitleContainer(node) {
    this.textTracksController.subtitleContainer = node;
  }

  setVideoQuality(level) {
    this.qualityController.setVideoQuality(level);
  }

  createNextRendererStream(medias$, state$, playerConfig$, events$, videoUnlocker, isAd$) {
    return medias$
      .pipe(
        withLatestFrom(playerConfig$),
        switchMap(([media, playerConfig]) => defer(() => RendererController.createRenderer({
          state$,
          media,
          errors$: this.errors$,
          playerConfig,
          events$,
          videoUnlocker,
          isAd$
        }), asyncScheduler)
          .pipe(catchError(({ message }) => {
            this.errors$.next({ error: error({ message, fatal: true }) });
            return NEVER;
          })))
      );
  }

  static matchRenderer(matchConfig) {
    return (rendererConfig) => Object.keys(rendererConfig)
      .find((type) => rendererConfig[type]
        .reduce((support, fn) => support && fn(matchConfig), true));
  }

  static async createRenderer({ state$, media, errors$, playerConfig, events$, videoUnlocker, isAd$ }) {
    const type = RendererController.matchRenderer({
      ...systemInfo,
      url: media.video.url.toLowerCase(),
      isDVR: media.video.is_DVR
    })(RENDERER_CONFIG);

    if (!type) throw new Error('NO RENDERER FOUND');

    events$.next({ name: 'renderer_type', payload: { type } });

    /* pass media to new renderer */
    const renderer = await match[type](media, playerConfig, videoUnlocker, isAd$);
    const subscription = renderer.state$.subscribe(state$);
    const errorSubscription = renderer.errors$.subscribe(errors$);

    return {
      renderer,
      media,
      subscription,
      errorSubscription
    };
  }

  static async initRenderer(target$, interruptions$, [activeRenderer, container]) {
    if (!activeRenderer) return;

    const { renderer, media } = activeRenderer;
    const {
      url,
      is_DVR: isDVR,
      timeshiftable
    } = media.video;

    await renderer.init(container, target$, media, interruptions$);
    await renderer.attachSource(url, { isDVR, timeshiftable });
    renderer.attachTracks();
    target$.next(RENDERER_INIT_DONE);
  }

  static disposeRenderer(prevRenderer) {
    prevRenderer.renderer.dispose();
    prevRenderer.subscription.unsubscribe();
    prevRenderer.errorSubscription.unsubscribe();
  }

  static createCommandStream(activeRenderer$, commands$) {
    return activeRenderer$.pipe(
      switchMap(({ renderer }) => commands$
        .pipe(
          takeUntil(renderer.state$.pipe(filter((state) => state === RENDERER_DISPOSE))),
          map((command) => ({ command, renderer }))
        )),
      share()
    );
  }

  static createCommandHandler() {
    let playPromise = Promise.resolve();
    let videosUnlocked = Promise.resolve();

    return async function wrappedCommandHandler(command, renderer, video) {
      switch (command.type) {
        case FIRST_PLAY: {
          videosUnlocked = Promise.resolve(command.userGesture && renderer.videoUnlocker.unlock());
          if (!video.is_live) renderer.startLoad();
          break;
        }
        case INITIATED_PLAY: {
          await renderer.startLoad();
          await videosUnlocked;
          await playPromise;
          /* Detect if tagElement.play() returns promise or not */
          playPromise = Promise
            .resolve(renderer.play())
            .catch(() => renderer.pause());
          break;
        }
        case PLAY: {
          await videosUnlocked;
          await playPromise;
          /* Detect if tagElement.play() returns promise or not */
          playPromise = Promise
            .resolve(renderer.play())
            .catch(() => renderer.pause());
          break;
        }
        case PAUSE: {
          renderer.pause();
          break;
        }
        case INTERRUPTION: {
          await playPromise;
          if (!video.is_live) renderer.pause();
          break;
        }
        case SEEK: {
          await playPromise;
          renderer.seek(command.position);
          break;
        }
        case MUTE: {
          renderer.mute(command.value);
          break;
        }
        case VOLUME: {
          renderer.volume(command.value);
          break;
        }
        case SPEED: {
          if (!video.is_live) renderer.speed(command.value);
          break;
        }
        default: {
          break;
        }
      }
    };
  }

  static mapRendererStream(activeRenderer$, streamName) {
    return activeRenderer$
      .pipe(switchMap(({ renderer }) => renderer[streamName]));
  }
}
