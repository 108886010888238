import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PipIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask id="mask0_pip" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="10">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.33337 4.3335H13.6667V7.66683H10.3334C9.96519 7.66683 9.66671 7.96531 9.66671 8.3335V11.6668L2.33337 11.6668L2.33337 4.3335ZM1.33337 4.00016C1.33337 3.63197 1.63185 3.3335 2.00004 3.3335H14C14.3682 3.3335 14.6667 3.63197 14.6667 4.00016V12.0002C14.6667 12.3684 14.3682 12.6668 14 12.6668H2.00004C1.63185 12.6668 1.33337 12.3684 1.33337 12.0002V4.00016Z" fill="black" />
      </mask>
      <g mask="url(#mask0_pip)">
        <rect width="16" height="16" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default PipIcon;
