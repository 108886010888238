import { h, Fragment } from 'preact';
import Warning from '../warning';
import TapLayer from './TapLayer';
import FeedbackOverlays from '../feedback/FeedbackOverlay';
import { connect, withBreakPoints } from '../../hoc';
import { PIPView } from '../info';
import { usePlayerContext } from '../../hooks';
import { SkipButton } from '../controls';
import { SKIP_BTN_INTRO_OVERRIDE } from '../common/buttons/styles';
import { UI_SKIP_INTRO_CLICKED, UI_SKIP_RECAP_CLICKED } from '../../../store/types';
import { COMING_NEXT, NEXT } from '../../../types';
import Spinner from '../spinner';

function SectionOverlay({
  waitStart,
  handleTap,
  containerVisible,
  forceHidden,
  mobile,
  extraSmall,
  isAd,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next
}) {
  const player = usePlayerContext();
  return (
    <Fragment>
      <PIPView />
      <TapLayer
        /* TapLayer handles initial click to start player in autostart: false
        * and "tap to hide UI" behavior on mobile devices */
        isMobile={mobile}
        ariaLabel="lecture"
        onTap={waitStart ? () => player.play({ userGesture: true }) : handleTap}
        visible={(waitStart || (mobile && !containerVisible)) && !forceHidden}
      />
      <Spinner />
      <Warning />
      <FeedbackOverlays />
      {(!extraSmall && !isAd && (
      <SkipButton
        duration={skipIntroDuration}
        timecode={skipIntroTimeCode}
        label="passer le générique"
        show={showSkipIntro}
        style={SKIP_BTN_INTRO_OVERRIDE}
        clickEvent={UI_SKIP_INTRO_CLICKED}
      />
      )) }
      {(!extraSmall && !isAd && (
      <SkipButton
        duration={skipRecapDuration}
        timecode={skipRecapTimeCode}
        label="passer le récap"
        show={showSkipRecap}
        clickEvent={UI_SKIP_RECAP_CLICKED}
      />
      )) }
      {(!extraSmall && !isAd && next && (
      <SkipButton
        label="vidéo suivante"
        show={showComingNext}
        clickEvent={{ name: NEXT, payload: COMING_NEXT }}
        seek={false}
      />
      ))}
    </Fragment>
  );
}

const selector = ({
  ui: {
    comingNextVisible,
    isFullscreen,
    forceHidden
  },
  media: { isAd },
  media: {
    skipIntro: { timecode: skipIntroTimeCode, duration: skipIntroDuration },
    skipRecap: { timecode: skipRecapTimeCode, duration: skipRecapDuration }
  },
  ui: { showSkipIntro, showSkipRecap, showComingNext, next }
}) => ({
  forceHidden,
  isAd,
  isFullscreen,
  comingNextVisible,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next
});

export default withBreakPoints(connect(selector)(SectionOverlay));
