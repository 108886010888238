import { h, cloneElement } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import Slide from './Slide';
import { NAV_BAR_STYLES, NAV_BAR_TITLE_STYLES } from './styles';
import { DIALOG_BUTTON_TRANSITION_DURATION } from '../buttons/styles';
import { useStyles } from '../../../hooks';
import { VOID_FN } from '../../../../utils';
import { UNDER_LAYER } from '../../../theme/constants';
import { MODAL_BACK_BUTTON_NAME } from '../modal/types';
import { Icon } from '../svg/icons';
import { TEXT_LEVEL_1_FONT_SIZE } from '../../../styles';
import { PRIMARY_FILL_COLOR } from '../../../theme/colors';

function SlideMenu({
  children,
  opened,
  styles,
  focusable = false,
  onNavigate = VOID_FN
}) {
  const setStyles = useStyles();
  const [activeSlide, setState] = useState(0);

  const navigateTo = (state) => { onNavigate(state); setState(state); };

  const [minHeight, setMinHeight] = useState(0);
  const navbarRef = useRef(null);
  const wrapperChild = children({ navigateTo });
  const slides = wrapperChild.props.children;
  const isSubSlide = activeSlide > 0;
  const svg = setStyles({ extraSmall: { size: 16 }, default: { size: 24 }, medium: { size: 24 } });

  const backIconsRef = useRef({});

  useEffect(() => {
    if (backIconsRef.current[activeSlide]) backIconsRef.current[activeSlide].focus();
  }, [activeSlide]);

  /* navigate back to first slide on close. note than if you choose to unmount the component on clos
   in the parent component, this effect will never fire on close */
  useEffect(() => !opened && setTimeout(() => navigateTo(0), DIALOG_BUTTON_TRANSITION_DURATION), [opened]);

  return !(wrapperChild && slides) ? null : (
    <div style={{
      height: '100%',
      display: 'flex'
    }}
    >
      <div
        style={setStyles({
          default: {
            flex: 1,
            marginTop: 20,
            marginBottom: 20,
            marginRight: 0,
            marginLeft: 24
          },
          'small+extraSmall': { position: 'relative' },
          extraSmall: { marginLeft: 20 }
        })}
      >
        <div
          className="ftv-slidemenu-nav"
          onClick={() => navigateTo(0)}
          role="presentation"
          ref={navbarRef}
          style={{
            ...setStyles(NAV_BAR_STYLES),
            ...styles.navbar,
            cursor: isSubSlide ? 'pointer' : 'unset'
          }}
        >
          {slides.map(
            ({ props }, i) => props && (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
              key={props.title}
              style={{
              /* override title animation on first slide */
                opacity: i === activeSlide ? 1 : 0,
                transform: `translateX(${i === 0 ? '-' : ''}${
                  i === activeSlide ? 0 : 30
                }px)`,
                transitionDelay: `${i !== activeSlide ? 0 : 0.1}s`,
                zIndex: i === activeSlide ? 'unset' : UNDER_LAYER,
                ...setStyles(NAV_BAR_TITLE_STYLES),
                ...styles.navbarTitle
              }}
              tabIndex="-1"
              onKeyDown={(e) => {
                if (e.keyCode === 13 || e.keyCode === 32) { // Enter or Spacebar
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            >
              {i > 0 && props.title && (
              <div
                ref={(el) => {
                // no navigation back arrow on the slide 5
                  if (i === 5) return;

                  backIconsRef.current[i] = el;
                }}
                aria-label={`Retour aux ${slides[0].props.title}`}
                role="button"
                className={focusable && opened && i > 0 && i === activeSlide && 'ftv-magneto--focusable-item'}
                tabIndex={opened && i > 0 && i === activeSlide ? 0 : -1}
                aria-hidden={i === 0}
                onClick={() => navigateTo(0)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.keyCode === 32) navigateTo(0);
                  if (e.keyCode === 32) e.stopPropagation();
                }}
                name={MODAL_BACK_BUTTON_NAME}
                style={setStyles({ default: { display: 'flex', alignItems: 'center', marginRight: 10 }, extraSmall: { marginRight: 6 } })}
              >

                <Icon
                  name="left-arrow"
                  width={svg.size}
                  height={svg.size}
                />
              </div>
              )}

              <span
                ref={(el) => {
                // focus title on first slide
                  if (i === 0) backIconsRef.current[i] = el;
                }}
                tabIndex={opened && i === activeSlide ? 0 : -1}
                className={focusable && 'ftv-magneto--focusable-item'}
                aria-label={`${props.title}`}
                role="heading"
                name={MODAL_BACK_BUTTON_NAME}
                aria-level={2}
                style={{ ...setStyles(TEXT_LEVEL_1_FONT_SIZE), color: PRIMARY_FILL_COLOR }}
              >
                {props.title}
              </span>
            </div>
            )
          )}
        </div>
        {slides.map((child, i) => (
          <Slide
          key={`slide-${i}`/* eslint-disable-line */}
            onSlide={(slideEl) => setMinHeight(slideEl.offsetHeight) /* needed for smooth height animation */}
            style={setStyles({
              default: { top: navbarRef.current?.offsetHeight, paddingBottom: 10 },
              'small+extraSmall': {
                height: `calc(100% - ${navbarRef.current?.offsetHeight}px`,
                paddingBottom: 15
              }
            })}
            minHeight={minHeight}
            active={activeSlide === i}
            slideIndex={i}
          >
            {cloneElement(child, { ...child.props, active: activeSlide === i })}
          </Slide>
        ))}
      </div>
    </div>
  );
}

export default SlideMenu;
