import { h } from 'preact';
import { getStyles } from '../../common/slider/getStyles';
import { VOID_FN } from '../../../../utils';
import { useStyles } from '../../../hooks';

function LiveCursor({
  position,
  strokeSize,
  cursorSize,
  live,
  timeshifting,
  hidden,
  onClick = VOID_FN,
  style = {}
}) {
  const styles = getStyles({
    strokeSize,
    cursorSize
  });

  return (!hidden && (
    <div
      onClick={(e) => { e.stopPropagation(); onClick(); }}
      style={{
        ...position,
        ...(live && timeshifting ? useStyles()(styles.expectedCursor) : styles.liveCursor),
        ...style
      }}
      role="presentation"
    />
  ));
}

export default LiveCursor;
