import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PinsIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 48 40"
      fill="none"
    >
      <g clipPath="url(#clip_pins)">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M24.0333 0C24.7697 0 25.3666 0.58203 25.3666 1.3V1.72494L45.8685 9.84559C46.3773 10.0471 46.7073 10.5322 46.6998 11.0676C46.6924 11.603 46.349 12.0792 45.8348 12.2672L25.3666 19.7509L25.3666 41.2C25.3666 41.918 24.7697 42.5 24.0333 42.5C23.2969 42.5 22.7 41.918 22.7 41.2V1.3C22.7 0.58203 23.2969 0 24.0333 0Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip_pins">
          <rect width="48" height="40" fill={color} />
        </clipPath>
      </defs>
    </SVGWrapper>

  );
}

export default PinsIcon;
