export const TV_PAUSEROLL_BTN_PLAY_STYLES = {
  borderRadius: '72px',
  color: 'black',
  border: 'unset',
  height: '72px',
  padding: '8px 36px',
  fontSize: '30px',
  lineHeight: '56px',
  iconSize: 48,
  fontWeight: 400,
  fontFamily: 'Brown',
  display: 'table'
};
