import { h } from 'preact';
import { useState } from 'preact/hooks';
import { createAnimation } from '../../../utils/animation';
import { FEEDBACK_SVG_WRAPPER } from './styles';
import IconButton from './IconButton';
import { Icon } from '../svg/icons';

const bounceFn = (t) => Math.sin(Math.PI * t);

function FeedbackButton({
  onClick,
  style,
  styleOverride,
  svgFeedback,
  fnFeedback,
  transition,
  size,
  ...props
}) {
  const [count, setCount] = useState(0);
  const [angle, setAngle] = useState(0);
  const [animation, setAnimation] = useState(null);
  const [timer, setTimer] = useState(null);

  const handleOnClick = () => {
    if (animation) animation.cancel();
    if (timer) clearTimeout(timer);

    setCount((val) => val + 1);
    setAnimation(createAnimation({
      duration: Math.max(100, 250 - (10 * count)),
      fn: (t) => setAngle(Math.round(bounceFn(t) * transition.max)),
      finish: () => {
        setTimer(setTimeout(() => {
          setAnimation(null);
          setTimeout(() => setCount(0), 150);
        }, 600));
      }
    }));
    onClick(); /* call original onClick fn */
  };

  return (
    <div style={{
      position: 'relative',
      width: size,
      height: size,
      ...styleOverride
    }}
    >
      <IconButton
        onClick={handleOnClick}
        style={{
          ...style,
          transformOrigin: 'center center',
          ...transition.apply(angle)
        }}
        size={size}
        {...props}
      />
      {svgFeedback && (
        <div
          style={FEEDBACK_SVG_WRAPPER}
          ariaHidden="true"
        >
          <span
            style={{
              position: 'absolute',
              opacity: animation ? 1 : 0,
              transform: `translate3d(0,-${animation ? size * 0.75 : 0}px,0)`,
              fontSize: size / 3,
              transition: 'all .2s ease-in-out'
            }}
          >
            {fnFeedback(count)}
          </span>
          <Icon
            name={svgFeedback}
            width={size}
            height={size}
            style={{
              transition: 'opacity .2s ease-in-out',
              opacity: animation ? 0 : 1
            }}
          />
        </div>
      )}
    </div>
  );
}

export default FeedbackButton;
