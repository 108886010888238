import { h } from 'preact';
import { withBreakPoints } from '../../hoc';
import { LabeledButton } from '../common';
import { usePlayerContext, useStyles } from '../../hooks';

import { USER_CLICK, BLOCK_RECOMMENDATIONS, BACKGROUND, USER_REPLAY, USER_BACK_TO_CREDITS } from '../../../types';
import { PLAYER_BUTTON_ICON_STYLE, PLAYER_BUTTON_STYLE, PLAYER_CONTAINER_STYLE, PLAYER_IMAGE_STYLE, PLAYER_WRAPPER_STYLE, REPLAY_SVG_SIZE } from './styles';

import { publicPath } from '../../../configuration';
import { TEXT_LEVEL_3_FONT_SIZE } from '../../styles';

function BabyPlayer({ image, hideVideo }) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  const clickHandler = (e) => {
    e.stopPropagation();
    if (hideVideo) {
      player.restart({ autostart: true });
    } else {
      player.userEvents$.next({ action: BLOCK_RECOMMENDATIONS, source: USER_CLICK });
    }

    player.userEvents$.next({
      action: hideVideo ? USER_REPLAY : USER_BACK_TO_CREDITS, source: USER_CLICK
    });
  };
  const videoPlaceHolder = () => {
    const defaultImagePath = `${publicPath}${BACKGROUND}`;
    return (
      hideVideo
        ? (<div style={{ ...setStyles(PLAYER_IMAGE_STYLE), backgroundImage: `url("${image || defaultImagePath}")` }} />)
        : (<div id="baby-player-container" style={setStyles(PLAYER_CONTAINER_STYLE)} />)
    );
  };

  return (
    <div
      tabIndex={0}
      role="button"
      onKeyDown={() => {}}
      onClick={clickHandler}
      style={setStyles(PLAYER_WRAPPER_STYLE)}
    >
      {videoPlaceHolder()}

      <LabeledButton
        label={hideVideo ? 'regarder à nouveau' : 'retourner au générique'}
        icon={hideVideo ? 'rewind' : undefined}
        iconSize={setStyles(REPLAY_SVG_SIZE).width}
        iconCustomStyle={setStyles(PLAYER_BUTTON_ICON_STYLE)}
        onClick={clickHandler}
        customStyle={{ ...setStyles(PLAYER_BUTTON_STYLE), ...setStyles(TEXT_LEVEL_3_FONT_SIZE) }}
      />
    </div>
  );
}

export default withBreakPoints(BabyPlayer);
