import { Logger } from '../utils';
import { DEFAULT_TYPE, MESSAGE_DEFAULT } from './definitions';

export default function error(
  original = {},
  override = {}
) {
  const {
    code,
    message,
    type,
    fatal,
    canRecover,
    canRetry,
    showCode,
    payload,
    refresh,
    description,
    orangeTitle,
    orangeErrorCode,
    orangeUserCode,
    orangeMessage
  } = { ...original, ...override };
  const e = Object.create(new Error());
  e.code = code || 5000;
  e.message = message || MESSAGE_DEFAULT;
  e.fatal = fatal || false;
  e.type = type || DEFAULT_TYPE;
  e.canRecover = canRecover || false;
  e.canRetry = typeof canRetry === 'boolean' ? canRetry : true;
  e.showCode = typeof showCode === 'boolean' ? showCode : true;
  e.payload = payload || {};
  e.refresh = refresh || null;
  e.description = description || 'no information';
  e.orangeErrorCode = orangeErrorCode || null;
  e.orangeUserCode = orangeUserCode || null;
  e.orangeMessage = orangeMessage || 'no message';
  e.orangeTitle = orangeTitle || 'no orange title';

  Logger[e.fatal ? 'error' : 'warn'](e.type, e);
  return e;
}
