import { Subject, merge, of, NEVER, combineLatest, ReplaySubject } from 'rxjs';
import { map, mapTo, startWith, scan, switchMap, bufferCount, filter, withLatestFrom, distinctUntilChanged, share, shareReplay } from 'rxjs/operators';
import { VIDEO_START } from '../../types';
import { adBreak as adBreakCfg } from '../../configuration';

export default class MidrollManager {
  constructor(player) {
    const { events$: playerEvent$ } = player;
    const { duration$, currentTime$ } = player.rendererController;
    const { medias$ } = player.mediaController;

    this.nbMidroll$ = new ReplaySubject(1);
    this.currentMidrollIndex$ = new ReplaySubject(1);
    this.cuepoint$ = MidrollManager.createCuepointStream({ nbMidroll$: this.nbMidroll$, medias$ });
    this.nextCuepoint$ = new Subject();

    this.adBreakTriggered$ = MidrollManager.createAdBreakStream(medias$, this.nextCuepoint$, currentTime$);
    this.nbMidrollLeft$ = MidrollManager.createNbMidrollLeftStream(this.adBreakTriggered$, this.nbMidroll$);
    this.viewedTime$ = MidrollManager.createViewedTimeStream(playerEvent$, currentTime$, this.adBreakTriggered$, this.nbMidrollLeft$);

    /* We want to send a different time before midroll for the first adBreak, then default to the minimal duration between midroll */
    this.timeBeforeMidroll$ = MidrollManager.createTimeBeforeMidrollStream(duration$, this.adBreakTriggered$);
    this.isAdBreakPlayable$ = MidrollManager.createIsAdBreakPlayableStream(this.viewedTime$, this.timeBeforeMidroll$);

    MidrollManager
      .createNextCuepointStream(this.isAdBreakPlayable$, this.cuepoint$, playerEvent$, currentTime$, this.nbMidrollLeft$)
      .subscribe(this.nextCuepoint$);
  }

  static midrollFromDuration(duration) {
    if (duration < 20 * 60) return 0;
    if (duration >= (20 * 60) && duration < 3600) return 1;

    let nbMidroll = Math.ceil(duration / 3600);
    if ((duration % 3600) === 0) nbMidroll += 1;

    return nbMidroll;
  }

  static createNbMidrollLeftStream(adBreakTriggered$, nbMidroll$) {
    return nbMidroll$.pipe(
      switchMap((nbMidroll) => adBreakTriggered$.pipe(
        startWith(nbMidroll),
        scan((acc) => acc - 1),
        filter((left) => left >= 0)
      )),
      shareReplay(1)
    );
  }

  static createCuepointStream({ nbMidroll$, medias$ }) {
    return nbMidroll$.pipe(
      withLatestFrom(medias$.pipe(map(({ markers: { pub: { midroll_timecode: cuepoints } = {} } }) => cuepoints))),
      filter(([nbMidroll, cuepoints]) => nbMidroll > 0 && cuepoints?.length > 0),
      map(([, cuepoints]) => cuepoints)
    );
  }

  static createTimeBeforeMidrollStream(duration$, adBreakTriggered$) {
    return duration$.pipe(switchMap((duration) => merge(
      of(MidrollManager.getTimeBeforeFirstMidroll(duration)),
      adBreakTriggered$
        .pipe(mapTo(adBreakCfg.minimalTimeBetweenCuepoint))
    )));
  }

  static createAdBreakStream(medias$, nextCuepoint$, currentTime$) {
    /* reset on each media to avoid distinctUntilChanged collision */
    return medias$.pipe(
      switchMap(() => currentTime$.pipe(
        bufferCount(2, 1),
        filter(([previous, current]) => current > previous && current < previous + 1),
        map(([, current]) => current),
        withLatestFrom(nextCuepoint$),
        filter(([currentTime, nextCuepoint]) => nextCuepoint && currentTime > nextCuepoint),
        map(([, nextCuepoint]) => nextCuepoint),
        distinctUntilChanged()
      )),
      share()
    );
  }

  static createIsAdBreakPlayableStream(viewedTime$, timeBeforeMidroll$) {
    return viewedTime$.pipe(
      startWith(false),
      withLatestFrom(timeBeforeMidroll$),
      switchMap(([viewedTime, timeBeforeMidroll]) => of(viewedTime > timeBeforeMidroll)),
      distinctUntilChanged()
    );
  }

  static createViewedTimeStream(playerEvent$, currentTime$, adBreakTriggered$, nbMidrollLeft$) {
    return merge(
      playerEvent$.pipe(filter((e) => e === VIDEO_START)),
      adBreakTriggered$
    ).pipe(
      withLatestFrom(nbMidrollLeft$, (_, nbMidroll) => nbMidroll > 0),
      switchMap((enabled) => {
        if (!enabled) return NEVER;

        return currentTime$.pipe(
          bufferCount(2, 1),
          filter(([previous, current]) => current > previous && current < previous + 1),
          scan((acc, [previous, current]) => acc + (current - previous), 0)
        );
      })
    );
  }

  static getTimeBeforeFirstMidroll(duration) {
    return (duration > 3600 ? 3600 : duration) * adBreakCfg.percentBeforeFirstMidroll;
  }

  static createNextCuepointStream(isAdBreakPlayable$, cuepoint$, playerEvent$, currentTime$, nbMidrollLeft$) {
    const disableDuringSeek$ = merge(
      playerEvent$.pipe(filter((e) => e === 'seeked'), mapTo(true)),
      playerEvent$.pipe(filter((e) => e === 'seeking'), mapTo(false))
    ).pipe(startWith(true));

    return combineLatest(
      isAdBreakPlayable$,
      disableDuringSeek$,
      nbMidrollLeft$
    ).pipe(switchMap(([enabled, seeked, nbMidrollLeft]) => {
      if (!enabled || !seeked || nbMidrollLeft === 0) return of(null);

      return combineLatest(cuepoint$, currentTime$)
        .pipe(map(([cuepoints, currentTime]) => cuepoints.find((cuepoint) => cuepoint >= currentTime)));
    }));
  }
}
