import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function TracksActiveIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask id="mask0_tracks_active" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="12">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33337 11.6667V3.66667C1.33337 3.29848 1.63185 3 2.00004 3H14C14.3682 3 14.6667 3.29848 14.6667 3.66667V11.6667C14.6667 12.0349 14.3682 12.3333 14 12.3333H13.1667V14.0286C13.1667 14.3256 12.8077 14.4743 12.5977 14.2643L10.6667 12.3333H2.00004C1.63185 12.3333 1.33337 12.0349 1.33337 11.6667ZM10 9.83333C10 9.55719 10.2239 9.33333 10.5 9.33333H12.1667C12.4429 9.33333 12.6667 9.55719 12.6667 9.83333C12.6667 10.1095 12.4429 10.3333 12.1667 10.3333H10.5C10.2239 10.3333 10 10.1095 10 9.83333ZM3.50004 7.66667C3.2239 7.66667 3.00004 7.89052 3.00004 8.16667C3.00004 8.44281 3.2239 8.66667 3.50004 8.66667H5.16671C5.44285 8.66667 5.66671 8.44281 5.66671 8.16667C5.66671 7.89052 5.44285 7.66667 5.16671 7.66667H3.50004ZM3.00004 9.83333C3.00004 9.55719 3.2239 9.33333 3.50004 9.33333H8.50004C8.77618 9.33333 9.00004 9.55719 9.00004 9.83333C9.00004 10.1095 8.77618 10.3333 8.50004 10.3333H3.50004C3.2239 10.3333 3.00004 10.1095 3.00004 9.83333ZM7.16671 7.66667C6.89057 7.66667 6.66671 7.89052 6.66671 8.16667C6.66671 8.44281 6.89056 8.66667 7.16671 8.66667H12.1667C12.4429 8.66667 12.6667 8.44281 12.6667 8.16667C12.6667 7.89052 12.4429 7.66667 12.1667 7.66667H7.16671Z" fill="black" />

      </mask>
      <g mask="url(#mask0_tracks_active)">
        <rect width="16" height="16" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default TracksActiveIcon;
