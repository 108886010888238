import { h } from 'preact';
import { useDialogContext } from '../../../context/DialogContext';
import { useStyles } from '../../../hooks';
import { LABELS_FILL_COLOR, SECONDARY_FILL_COLOR } from '../../../theme/colors';
import { ACTIVE_BULLET_STYLE } from './styles';
import { Icon } from '../svg/icons';

export function SelectableRadio({
  label: { label, ariaLabel, icon },
  active,
  parentId,
  onSelect,
  closeOnActiveSelect = true,
  radioButton
}) {
  const dialogCtx = useDialogContext();
  const handleSelect = () => {
    if (closeOnActiveSelect && active) {
      const asBtn = document.getElementsByName('btn-tracks')[0];
      setTimeout(() => asBtn.focus(), 20);
      dialogCtx.closeAll();
    }
    onSelect();
  };

  const setStyles = useStyles();
  const { size } = setStyles({
    extraSmall: { size: 16 },
    default: { size: 24 }
  });
  return (
    <div
      role="radio"
      aria-checked={active ? 'true' : 'false'}
      tabIndex="0"
      aria-label={ariaLabel}
      data-parentid={parentId}
      data-label={label}
      onKeyDown={({ keyCode }) => (keyCode === 13 || keyCode === 32) /* Enter or Spacebar */ && handleSelect()}
      onClick={handleSelect}
      className="ftv-magneto--selectable"
      style={setStyles({
        default: {
          cursor: 'pointer',
          whiteSpace: 'nowrap',
          lineHeight: '1.8',
          marginTop: -8,
          width: '100%',
          padding: '0px 0px 16px 0px',
          color: LABELS_FILL_COLOR,
          ...(active && !radioButton ? { fontWeight: 'bold' } : {})
        }
      })}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 6, height: 6 }}>
          <div style={{ ...setStyles({ ...ACTIVE_BULLET_STYLE }), opacity: Number(active) }} />
        </div>

        <div style={{ display: 'flex', marginLeft: 8, marginRight: 8 }}>{label}</div>
        {icon && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>

            <Icon
              tabIndex={0}
              className="ftv-magneto--selectable"
              name={icon}
              color={SECONDARY_FILL_COLOR}
              width={size}
              height={size}
            />

          </div>
        )}
      </div>
    </div>
  );
}
