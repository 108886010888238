import { withLatestFrom } from 'rxjs/operators';
import { handleZapping, handleDAI, handleTimeshifting, handleNavigation, handleTimeshiftingAuto, handleSkipButton, handleHighlights } from './store';
import {
  TIMESHIFTING_CONTROLLER_NAME,
  TIMESHIFTING_TYPE_MANUEL,
  TIMESHIFTING_AUTO_CONTROLLER_NAME,
  TIMESHIFTING_TYPE_AUTO
} from './core/timeshifting/types';
import { LOAD_MAP } from './core/monitoring';
import { MONITORING_OPTOUT, MONITORING_UNCONSENTED } from './core/monitoring/types';

const PEER_CONTROLLERS = [
  {
    name: 'pianoController',
    load: () => import(/* webpackChunkName:  "piano" */'./core/monitoring'),
    match: ({ media: { markers } }) => Boolean(markers?.piano?.isTrackingEnabled),
    setup(monitor, player) {
      monitor.init({ load: LOAD_MAP.piano.load, name: 'pianoMonitoring', args: [player] });
    }
  },
  {
    name: 'IOSController',
    load: () => import(/* webpackChunkName: "IOS" */'./core/mobile/IOSController'),
    match: ({ systemInfo: { isIOS } }) => isIOS,
    setup: (ctrl, player) => {
      ctrl.forceTrack$.subscribe(({ textTrack, audioTrack }) => {
        player.setSubtitleTrack(textTrack);
        player.setAudioTrack(audioTrack);
      });

      ctrl.forceFullscreen$.subscribe(() => player.fullscreen(true));

      /** force pause when headphones are plugged out to sync Magneto with the actual playback state and trigger correct eStat hit */
      ctrl.headphonesPluggedOut$
        .pipe(withLatestFrom(player.isLive$, (_, isLive) => isLive))
        .subscribe((isLive) => (isLive ? player.stop() : player.pause()));
    }
  },
  {
    name: 'visibilityController',
    load: () => import(/* webpackChunkName: "visibility" */'./core/mobile/visibilityController'),
    match: ({ systemInfo: { isMobile } }) => isMobile,
    setup: (ctrl, player) => {
      ctrl.hidden$
        .pipe(withLatestFrom(player.isLive$, (_, isLive) => isLive))
        .subscribe((isLive) => (isLive ? player.stop() : player.pause()));
    }
  },
  {
    name: 'pipController',
    load: () => import(/* webpackChunkName: "pip" */'./core/pip'),
    match: ({ media: { config: { showAd, pip }, isLive } }) => (pip || !showAd || isLive),
    setup: (ctrl, player) => {
      ctrl.events$.subscribe(player.events$);
      ctrl.pip$
        .pipe(withLatestFrom(player.fullscreenController.fullscreen$, (_, fullscreen) => fullscreen))
        .subscribe((isFullscreen) => isFullscreen && player.fullscreen(false));
    }
  },
  {
    name: 'metadataController',
    load: () => import(/* webpackChunkName: "metadata" */'./core/metadata'),
    match: ({ config: { zapping } }) => Boolean(zapping.length),
    setup: (ctrl, { store, mediaController: { medias$ } }) => {
      handleZapping(store, { metadata$: ctrl.metadata$, medias$ });
    }
  },
  {
    name: TIMESHIFTING_CONTROLLER_NAME,
    load: () => import(/* webpackChunkName: "timeshiftingController" */'./core/timeshifting'),
    match: ({ media: { video: { timeshiftable } } }) => timeshiftable === TIMESHIFTING_TYPE_MANUEL,
    setup: (ctrl, player) => {
      handleTimeshifting(player, {
        isTimeshiftingStreamLive$: ctrl.isTimeshiftingStreamLive$,
        canDisplayStartOverStream$: ctrl.canDisplayStartOverStream$
      });
      const { events$ } = player;
      ctrl.request$.subscribe(events$);
    }
  },
  {
    name: 'daiController',
    load: () => import(/* webpackChunkName: "dai" */'./ad/dai'),
    match: ({ media: { video: { token, workflow } } }) => Object.keys(token).includes('dai') || workflow === 'dai',
    setup(ctrl, player) {
      player.handleAd.call(player, ctrl.isAd$, player.freewheelController.isAd$);
      handleDAI(player.store, ctrl, player.events$);
    }
  },
  {
    name: 'orangeController',
    load: () => import(/* webpackChunkName:  "orange" */'./core/monitoring'),
    match: ({ media: { markers } }) => Boolean(markers?.orange),
    setup(monitor, player) {
      monitor.init({ load: LOAD_MAP.orange.load, name: 'orangeMonitoring', args: [player] });
    }
  },
  {
    name: 'eStatController',
    load: () => import(/* webpackChunkName:  "eStat" */'./core/monitoring'),
    match: ({ config: { consent: { estat } } }) => estat !== MONITORING_OPTOUT,
    setup(monitor, player) {
      monitor.init({ load: LOAD_MAP.eStat.load, name: 'eStatMonitoring', args: [player] });
    }
  },
  {
    name: 'nielsenController',
    load: () => import(/* webpackChunkName:  "nielsen" */'./core/monitoring'),
    match: ({ config: { consent, nielsen } }) => !!nielsen && consent?.nielsen !== MONITORING_UNCONSENTED,
    setup(monitor, player) {
      monitor.init({ load: LOAD_MAP.nielsen.load, name: 'nielsenMonitoring', args: [player] });
    }
  },
  {
    name: TIMESHIFTING_AUTO_CONTROLLER_NAME,
    load: () => import(/* webpackChunkName: "timeshiftingAuto" */'./core/timeshifting/auto'),
    match: ({ media: { video: { timeshiftable } } }) => timeshiftable === TIMESHIFTING_TYPE_AUTO,
    setup: (ctrl, player) => {
      handleTimeshifting(player, {
        isTimeshiftingStreamLive$: ctrl.isTimeshiftingStreamLive$,
        canDisplayStartOverStream$: ctrl.canDisplayStartOverStream$
      });
      const { events$ } = player;
      player.epgController.request$.subscribe(events$);
      handleTimeshiftingAuto(
        player,
        {
          programPositions$: player.epgController.programPositions$,
          segmentPositions$: player.epgController.segmentPositions$,
          duration$: player.rendererController.duration$,
          isDirty$: player.epgController.isDirty$
        }
      );
    }
  },
  {
    name: 'highlightsController',
    load: () => import(/* webpackChunkName: "highlights" */ './core/highlights'),
    match: ({ media: { video: { is_highlightable: isHighlightable } } }) => isHighlightable,
    setup: (ctrl, player) => {
      handleHighlights(player.store, ctrl.highlights$, player.rendererController.duration$, player.timeshiftable$);
    }
  },
  {
    name: 'accessibilityController',
    load: () => import(/* webpackChunkName: "accessibility" */'./core/accessibility'),
    match: ({ systemInfo: { isMobile } }) => !isMobile,
    setup(ctrl, player) {
      handleNavigation(player, ctrl);
    }
  },

  {

    name: 'adwaysController',
    load: () => import(/* webpackChunkName: "adways" */'./ad/adways'),
    match: ({ config: { adways } }) => Boolean(adways),
    setup: () => {}
  },
  {
    name: 'skipController',
    load: () => import(/* webpackChunkName: "skip" */'./core/skipbutton'),
    match: ({
      media: {
        video: {
          skip_intro: { timecode, duration, time_before_dismiss: tbdms },
          previously: { timecode: pTimecode, duration: pDuration, time_before_dismiss: pTbdms },
          coming_next: { timecode: comingNextTimecode, duration: comingNextDuration, time_before_dismiss: comingNextTbdms }
        }
      }
    }) => (
      !!(timecode && duration && tbdms)
      || !!(pTimecode && pDuration && pTbdms)
      || !!(comingNextTimecode && comingNextDuration && comingNextTbdms)
    ),
    setup: (ctrl, player) => {
      ctrl.request$.subscribe(player.events$);
      handleSkipButton(player.store, ctrl.request$);
    }
  },
  {
    name: 'usabillaController',
    load: () => import(/* webpackChunkName: "usabilla" */ './core/usabilla'),
    match: ({ config: { notifyOnWatchtime } }) => Boolean(notifyOnWatchtime),
    setup: () => {}
  }
];

export default PEER_CONTROLLERS;
