import { h } from 'preact';
import { usePlayerContext } from '../../../ui/hooks';
import { UNDER_LAYER } from '../../../ui/theme/constants';
import { PRIMARY_TEXT_COLOR } from '../../../ui/theme/colors';
import { connect } from '../../../ui/hoc';

const style = {
  zIndex: UNDER_LAYER,
  left: 0,
  right: 0,
  fontFamily: 'Brown',
  fontWeight: 400,
  textAlign: 'center',
  color: PRIMARY_TEXT_COLOR,
  pointerEvents: 'none'

};

const TARGET_RATIO = 16 / 9;
const calculateSubtitleStyles = ({ width, height }) => {
  const targetSize = (width / height) < TARGET_RATIO
    ? { height: (1 / TARGET_RATIO) * width }
    : { height };

  return ({
    fontSize: `${Math.floor(targetSize.height / 20)}px`,
    lineHeight: `${Math.floor(targetSize.height / 18)}px`
  });
};

function SubtitleContainer({ width, height }) {
  const player = usePlayerContext();
  return (
    <div
      className="ftv-magneto--subtitles"
      ref={(node) => player.setSubtitleContainer(node)}
      style={{ ...style, ...calculateSubtitleStyles({ width, height }) }}
    />
  );
}

const selector = ({ ui: { width, height } }) => ({ width, height });
export default connect(selector)(SubtitleContainer);
