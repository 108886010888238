export const MODAL_CONTAINER_STYLES = {
  default: {
    pointerEvents: 'initial',
    lineHeight: '21px',

    fontSize: 16,
    right: '16px',
    top: '12px'
  },
  extraSmall: {
    lineHeight: '17px',
    height: '100%',
    right: 0,
    top: 0
  },
  medium: { fontSize: 14 },
  large: { fontSize: 18 }
};
