/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPauseIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M32.4998 59.3752C32.4998 61.1011 33.8989 62.5002 35.6248 62.5002C37.3506 62.5002 38.7498 61.1011 38.7498 59.3752V30.6252C38.7498 28.8994 37.3506 27.5002 35.6248 27.5002C33.8989 27.5002 32.4998 28.8994 32.4998 30.6252V59.3752ZM51.2498 59.3752C51.2498 61.1011 52.6489 62.5002 54.3748 62.5002C56.1006 62.5002 57.4998 61.1011 57.4998 59.3752V30.6252C57.4998 28.8994 56.1006 27.5002 54.3748 27.5002C52.6489 27.5002 51.2498 28.8994 51.2498 30.6252V59.3752Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvPauseIcon;
