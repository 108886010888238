import { BehaviorSubject, fromEvent, merge, timer, zip, race } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import { INITIATED_PLAY } from '../core/command/types';
import { UPDATE_SETTING } from '../store/types';
import {
  USER_AUDIO_TRACK_CHANGED,
  USER_MUTE,
  USER_PLAYBACKRATE_CHANGE,
  USER_QUALITY_CHANGED,
  USER_SETTINGS_UPDATED,
  USER_TEXT_TRACK_CHANGED,
  USER_TUNNEL_ACTIVATED,
  USER_VOLUME_CHANGE
} from '../types';
import { deepMerge } from '../utils';
import { DEFAULT_SETTINGS, DEFAULT_TIMEOUT_TRACKS, LOCAL_PLAYER_PREFERENCES, FRENCH_TRACKS } from './types';
import { Disposable } from '../core';
import { BEFORE_HOOK, INTERRUPTION_CANCELED, INTERRUPTION_END } from '../core/interruptor/types';
import systemInfo from '../utils/systemInfo';

export default class SettingsController extends Disposable {
  constructor({
    player,
    medias$,
    userEvents$,
    audioTracks$,
    textTracks$,
    commands$,
    events$,
    interruptions$
  }) {
    super();
    this.player = player;

    this.userSettings = SettingsController.getUserSettings() || {};
    this.hasSettings$ = new BehaviorSubject(!!this.userSettings);

    interruptions$.pipe(
      filter(({ type, status }) => type === BEFORE_HOOK && [INTERRUPTION_END, INTERRUPTION_CANCELED].includes(status)),
      switchMap(() => zip(
        medias$,
        race(timer(DEFAULT_TIMEOUT_TRACKS).pipe(mapTo([])), audioTracks$),
        race(timer(DEFAULT_TIMEOUT_TRACKS).pipe(mapTo([])), textTracks$),
        commands$.pipe(filter(({ type }) => type === INITIATED_PLAY))
      ))
    ).subscribe(([media, audioTracks, textTracks]) => {
      this.applyInitValues(media, audioTracks, textTracks);
    });

    SettingsController.createPrefStream(
      userEvents$,
      player
    ).subscribe((item) => {
      this.savePrefValue(item);
      events$.next(item);
    });
  }

  static getUserSettings() {
    const rawPrefs = window.localStorage.getItem(LOCAL_PLAYER_PREFERENCES);
    return rawPrefs && JSON.parse(rawPrefs);
  }

  applyInitValues(
    { config: { preferences: optionsPrefs = {}, mute: retroMute } },
    audioTracks,
    textTracks
  ) {
    // We start by locally saving the integrator values, then use the local settings everywhere
    this.savePrefValue(
      deepMerge(
        retroMute !== undefined ? { mute: retroMute } : {},
        optionsPrefs
      )
    );

    if (this.userSettings) {
      const { volume, mute, speed, quality, tunnelActivated, languages } = this.userSettings;
      this.player.volume(volume);
      this.player.mute(!!mute);
      this.player.speed(speed);
      this.player.setVideoQuality(quality);
      // Do not apply language because of audio bug on safari mobile with .aac audio
      if (!systemInfo.isIOS) {
        this.setLanguages(languages, audioTracks, textTracks);
      }
      this.player.trigger(USER_SETTINGS_UPDATED, { autoplay: tunnelActivated, fromCore: true });
      this.player.store.dispatch({
        type: UPDATE_SETTING,
        payload: {
          comingNext: tunnelActivated
        }
      });
    }
  }

  setLanguages(languages, audioTracks, textTracks) {
    let audioTrack = audioTracks.find(({ lang }) => languages.audio === lang);
    if (FRENCH_TRACKS.includes(languages.audio) && !audioTrack) {
      audioTrack = audioTracks.find(({ lang }) => FRENCH_TRACKS.find((frTrack) => frTrack === lang));
    }

    let textTrack = languages.subtitles === null
      ? { index: -1 }
      : textTracks.find(({ lang }) => languages.subtitles === lang);
    if (FRENCH_TRACKS.includes(languages.subtitles) && !textTrack) {
      textTrack = textTracks.find(({ lang }) => FRENCH_TRACKS.find((frTrack) => frTrack === lang));
    }

    if (!!audioTrack && !!textTrack) {
      this.player.setAudioTrack(audioTrack.index);
      this.player.setSubtitleTrack(textTrack.index);
    } else {
      const defaultAudio = audioTracks.find(({ lang }) => lang === DEFAULT_SETTINGS.languages.audio);
      this.player.setAudioTrack(defaultAudio?.index ?? 0);
      this.player.setSubtitleTrack(-1);
    }
  }

  savePrefValue(item) {
    const toSave = { ...item };
    delete toSave.name;
    delete toSave.payload;

    const updatedPrefs = deepMerge(DEFAULT_SETTINGS, this.userSettings, toSave);

    this.userSettings = updatedPrefs;
    this.hasSettings$.next(true);
    window.localStorage.setItem(
      LOCAL_PLAYER_PREFERENCES,
      JSON.stringify(updatedPrefs)
    );
  }

  static createPrefStream(userEvents$, player) {
    const volume$ = userEvents$.pipe(
      filter(({ action }) => action === USER_VOLUME_CHANGE),
      map(({ value }) => ({ volume: value, name: USER_VOLUME_CHANGE, payload: value }))
    );
    const mute$ = userEvents$.pipe(
      filter(({ action }) => action === USER_MUTE),
      map(({ value }) => ({ mute: value, name: USER_MUTE, payload: value }))
    );
    const speed$ = userEvents$.pipe(
      filter(
        ({ action, value }) => action === USER_PLAYBACKRATE_CHANGE && value?.newRate
      ),
      map(({ value, value: { newRate } }) => ({ speed: newRate, name: USER_PLAYBACKRATE_CHANGE, payload: value }))
    );
    const quality$ = userEvents$.pipe(
      filter(({ action }) => action === USER_QUALITY_CHANGED),
      map(({ value: { level } }) => ({ quality: level, name: USER_QUALITY_CHANGED, payload: level }))
    );
    const audio$ = userEvents$.pipe(
      filter(({ action }) => action === USER_AUDIO_TRACK_CHANGED),
      map(({ value }) => ({ languages: { audio: value.language }, name: USER_AUDIO_TRACK_CHANGED, payload: value.language }))
    );
    const subtitles$ = userEvents$.pipe(
      filter(({ action }) => action === USER_TEXT_TRACK_CHANGED),
      map(({ value }) => ({ languages: { subtitles: value.language }, name: USER_TEXT_TRACK_CHANGED, payload: value.language }))
    );
    const tunnelActivated$ = fromEvent(player, USER_SETTINGS_UPDATED).pipe(
      map(([, { autoplay }]) => ({ tunnelActivated: autoplay, name: USER_TUNNEL_ACTIVATED, payload: autoplay }))
    );

    return merge(
      volume$,
      mute$,
      speed$,
      quality$,
      audio$,
      subtitles$,
      tunnelActivated$
    );
  }
}
