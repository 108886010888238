import { UNKNOWN } from '../../utils/types';

export const MAXWELL_PLAYER_EVENT = 'player-monitoring';
export const MAXWELL_TRACKER_UNKNOWN = 2;
export const MAXWELL_TRACKER_BLOCKED = 1;
export const MAXWELL_TRACKER_NOT_BLOCKED = 0;
export const MAXWELL_AD_MODE_ACTIVATED = 1;
export const MAXWELL_AD_MODE_DEACTIVATED = 0;

export const MAXWELL_TRACKER_BLOCKING_STATUS_MAP = [
  { expectedIsServerReachableValue: true, mappedTo: MAXWELL_TRACKER_NOT_BLOCKED },
  { expectedIsServerReachableValue: false, mappedTo: MAXWELL_TRACKER_BLOCKED },
  { expectedIsServerReachableValue: UNKNOWN, mappedTo: MAXWELL_TRACKER_UNKNOWN }
];
