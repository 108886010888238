import { Subject, of, merge, asyncScheduler } from 'rxjs';
import { filter, switchMap, map, pairwise, take, withLatestFrom, subscribeOn, exhaustMap, takeUntil } from 'rxjs/operators';

import { PLAY, PAUSE, INTERRUPTION, SEEK, FIRST_PLAY, VOLUME, MUTE, SPEED, NO_OP, INITIATED_PLAY } from './types';
import { INTERRUPTION_WILL_START, INTERRUPTION_END, INTERRUPTION_CANCELED, INTERRUPTOR_INIT, INTERRUPTION_DID_START, BEFORE_HOOK } from '../interruptor/types';
import { NEXT } from '../../types';
import { Disposable } from '..';
import { RENDERER_CREATED, RENDERER_INIT_DONE, RENDERER_READY } from '../renderer/types';
import { TV_PLATFORMS } from '../dom/types';

export const interruptionMap = {
  [INTERRUPTION_WILL_START]: () => ({ type: NO_OP }),
  [INTERRUPTION_DID_START]: () => ({ type: INTERRUPTION }),
  [INTERRUPTION_END]: ({ type, didStart }) => ({ type: type === BEFORE_HOOK || didStart ? INITIATED_PLAY : PLAY }),
  [INTERRUPTION_CANCELED]: ({ type, didStart }) => ({ type: type === BEFORE_HOOK || didStart ? INITIATED_PLAY : NO_OP })
};

export default class CommandController extends Disposable {
  constructor({
    events$,
    interruptions$,
    medias$,
    playerConfig$
  }) {
    super();
    this.stream$ = new Subject(); /* unfiltered commands stream passed to controller */
    this.commands$ = new Subject(); /* filtered commands based on renderer state */

    CommandController
      .createCommandStream(this.stream$, interruptions$, medias$, events$)
      .subscribe(this.commands$);

    merge(
      CommandController.createAutoStart(medias$, events$, playerConfig$),
      CommandController.handleSeekEnd(this.commands$)
        /* ensures mapping from SEEK to PLAY happens sequentially */
        .pipe(subscribeOn(asyncScheduler))
    ).subscribe(this.stream$);
  }

  static createCommandStream(stream$, interruptions$, medias$, events$) {
    /**
     * we need to map each first play command to a specific FIRST_PLAY command :
     * in order to avoid calling play() then pause() on a video in the case
     * of a BEFORE_HOOK interruption.
     */
    return medias$.pipe(switchMap((media) => stream$.pipe(
      filter(({ type }) => type === PLAY),
      take(1),
      switchMap((cmd) => merge(
        of({ ...cmd, type: FIRST_PLAY }),
        interruptions$
          .pipe(
            takeUntil(events$.pipe(filter(({ name }) => name === NEXT))),
            filter(({ type }) => type !== INTERRUPTOR_INIT),
            exhaustMap(({ status, didStart, type }) => of(interruptionMap[status]({ type, didStart }))),
            switchMap((data) => (TV_PLATFORMS.includes(media.config.platform)
              ? events$.pipe(
                filter((event) => [RENDERER_INIT_DONE, RENDERER_READY].includes(event)),
                map(() => data)
              )
              : of(data)
            ))
          ),
        stream$
      ))
    )));
  }

  static createAutoStart(medias$, events$, playerConfig$) {
    return medias$.pipe(switchMap(() => events$.pipe(
      withLatestFrom(playerConfig$),
      filter(([evt, { autostart = false, platform }]) => (
        (RENDERER_READY === evt && autostart) || (TV_PLATFORMS.includes(platform) && [RENDERER_CREATED, RENDERER_READY].includes(evt))
      )),
      take(1),
      map(() => ({ type: PLAY }))
    )));
  }

  static handleSeekEnd(commands$) {
    /* Handle seek command recovery if seek from UI */
    const wasPlaying$ = commands$.pipe(
      filter(({ type }) => [INITIATED_PLAY, PLAY, PAUSE].includes(type)),
      pairwise(), /* seek will trigger a pause, we need to target the command before */
      map(([{ type }]) => type === PLAY || type === INITIATED_PLAY)
    );

    return commands$
      .pipe(
        filter(({ type, userGesture }) => type === SEEK && userGesture),
        withLatestFrom(wasPlaying$, (_, wasPlaying) => wasPlaying),
        filter(Boolean),
        map(() => ({ type: PLAY }))
      );
  }

  play({ userGesture }) {
    this.stream$.next({ type: PLAY, userGesture });
  }

  pause() {
    this.stream$.next({ type: PAUSE });
  }

  seek(position, userGesture) {
    this.stream$.next({ type: SEEK, position, userGesture });
  }

  mute(value) {
    this.stream$.next({ type: MUTE, value });
  }

  volume(value) {
    this.stream$.next({ type: VOLUME, value });
  }

  speed(value) {
    this.stream$.next({ type: SPEED, value });
  }
}
