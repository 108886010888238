export default function getElementByNamesAsync(ids) {
  return new Promise((resolve) => {
    const getElement = () => {
      const element = ids.map((id) => document.getElementsByName(id)[0]).filter(Boolean)[0];

      if (element) resolve(element);
      else requestAnimationFrame(getElement);
    };

    getElement();
  });
}
