/* eslint-disable react/no-unknown-property */
import { Fragment, h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvRewind10IconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
    >
      <Fragment>
        <path d="M49.0417 42.0253C49.0417 44.5427 48.2294 46.192 46.8294 46.192C45.4295 46.192 44.6172 44.5427 44.6172 42.0253C44.6172 39.508 45.4295 37.8587 46.8294 37.8587C48.2294 37.8587 49.0417 39.508 49.0417 42.0253Z" fill="#F8F8F8" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.25 42C83.25 64.7817 64.7817 83.25 42 83.25C19.2183 83.25 0.75 64.7817 0.75 42C0.75 19.2183 19.2183 0.75 42 0.75C64.7817 0.75 83.25 19.2183 83.25 42ZM36.2763 21.0047C35.5746 21.4905 35.5746 22.5339 36.2763 23.0197L42.6326 27.42C43.4383 27.9777 44.5347 27.3969 44.5347 26.4125V23.4321C53.6913 24.6695 60.75 32.5165 60.75 42.0122C60.75 52.3675 52.3553 60.7622 42 60.7622C31.6447 60.7622 23.25 52.3675 23.25 42.0122C23.25 36.9324 25.2701 32.3244 28.5507 28.9478C29.3114 28.1648 29.3817 26.9024 28.6148 26.1255C27.9293 25.431 26.8173 25.3782 26.1257 26.0668C22.0331 30.1413 19.5 35.7809 19.5 42.0122C19.5 54.4386 29.5736 64.5122 42 64.5122C54.4264 64.5122 64.5 54.4386 64.5 42.0122C64.5 30.4427 55.7679 20.9128 44.5347 19.6534V17.612C44.5347 16.6275 43.4383 16.0467 42.6326 16.6044L36.2763 21.0047ZM51.375 42.0253C51.375 38.1191 49.6121 35.7753 46.8294 35.7753C44.0468 35.7753 42.2839 38.1191 42.2839 42.0253C42.2839 45.9316 44.0468 48.2753 46.8294 48.2753C49.6121 48.2753 51.375 45.9316 51.375 42.0253ZM33.3163 40.1156L35.9089 38.6573V46.0184H32.625V48.1017H41.0939V46.0184H38.2421V35.9489H36.6002L33.3163 37.8934V40.1156Z" fill="#F8F8F8" />
      </Fragment>
    </SVGWrapper>
  );
}

export default TvRewind10IconActive;
