/* eslint-disable no-new */
import { Fragment, h } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { RadioGroup } from '../../../../ui/utils/RadioGroup';
import Track from '../track';
import { FIRST_TRACKS_SECTION, GAP, TRACKS_DIVIDER, TRACKS_SECTION_MAIN_TITLE, TRACKS_SECTION_TITLES } from './styles';
import Seperator from '../../../../ui/components/common/menu/Seperator';
import { connect } from '../../../../ui/hoc';

import Scrollable from '../../../../ui/hoc/scrollable';
import PanelCloseButton from '../../panel-close';

function TrackList({
  subtitlesAvailable,
  subtitleSelected,
  audiosAvailable,
  audioSelected,
  isExtraLargeTvScreen,
  playerHeight
}) {
  const refTitle = useRef(null);

  const [panelTitleHeight, setPanelTileHeight] = useState(0);

  const programListHeight = useMemo(
    () => playerHeight - (panelTitleHeight + 80),
    /*
     32 px for title height
     32 px for title margin-bottom
     16 px of margin-bottom
      */
    [playerHeight, panelTitleHeight]
  );

  useEffect(() => {
    if (refTitle.current) {
      setPanelTileHeight(refTitle.current.getBoundingClientRect().height);
    }
  }, []);

  useEffect(() => {
    new RadioGroup([], 'audio-subtitles', true);
  }, []);

  return (
    <div
      name="tracks-view"
      className="ftv-magneto--tracks"
      style={{
        display: 'block',
        height: '100%'
      }}
    >
      <h2 ref={refTitle} style={TRACKS_SECTION_MAIN_TITLE}>Versions disponibles</h2>
      <div style={{ height: programListHeight }}>
        <Scrollable forceInnerScroll>
          <Fragment>
            <div style={GAP} />

            <div style={FIRST_TRACKS_SECTION}>
              <h3 style={TRACKS_SECTION_TITLES}>audio</h3>

              {audiosAvailable.map(({ index, language, label: { label, icon } }) => (
                <Track
                  index={index}
                  language={language}
                  label={label}
                  isActive={index === audioSelected}
                  id="audio"
                  icon={icon}
                />
              ))}
            </div>

            <Seperator style={TRACKS_DIVIDER} />
            <div style={GAP} />

            <div>
              <h3 style={TRACKS_SECTION_TITLES}>sous-titres</h3>
              <Track
                index={-1}
                language="n/a"
                label="aucun"
                isActive={subtitleSelected === -1}
                id="subtitle"
              />

              {subtitlesAvailable.map(
                ({ index, language, label: { label, icon } }) => (
                  <Track
                    index={index}
                    language={language}
                    label={label}
                    isActive={index === subtitleSelected}
                    id="subtitle"
                    icon={icon}
                  />
                )
              )}
              <div>

                <div style={GAP} />

                <PanelCloseButton isExtraLargeTvScreen={isExtraLargeTvScreen} />
              </div>
            </div>
          </Fragment>
        </Scrollable>

      </div>

    </div>
  );
}

const selector = ({ ui: { isExtraLargeTvScreen, height: playerHeight } }) => (
  { isExtraLargeTvScreen, playerHeight }
);

export default connect(selector)(TrackList);
