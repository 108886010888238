import { h } from 'preact';
import { connect, withBreakPoints } from '../../hoc';
import { DEFAULT_STYLE, DEFAULT_ICON } from './styles';
import { SVGCreator } from '../common';
import {
  PLAY_PAUSE_SHORTCUT, MUTE_SHORTCUT, FORWARD_SHORTCUT,
  REWIND_SHORTCUT, VOLUME_UP_SHORTCUT, VOLUME_DOWN_SHORTCUT, SPEED_SHORTCUT, SHORTCUT_MAP, PLAY_PAUSE_CLICK_SHORTCUT
} from '../../../core/shortcut/types';
import {
  VOLUME_FILL_MODERATE, PAUSE_BUTTON_PATH, PLAY_BUTTON_PATH, FORWARD_BUTTON_FILL_PATH,
  BACKWARD_BUTTON_FILL_PATH, VOLUME_FILL_MUTE,
  PLAYBACK_SPEED_1_FILL_PATH, PLAYBACK_SPEED_1_25_FILL_PATH, PLAYBACK_SPEED_1_5_FILL_PATH,
  PLAYBACK_SPEED_1_75_FILL_PATH
} from '../../theme/svg';
import '../../theme/styles/feedback.css';
import { getVolumeIcon, getScale, getSpeedIcon } from '../../utils/tools';

const speedIcons = {
  normal: PLAYBACK_SPEED_1_FILL_PATH,
  x25: PLAYBACK_SPEED_1_25_FILL_PATH,
  x50: PLAYBACK_SPEED_1_5_FILL_PATH,
  x75: PLAYBACK_SPEED_1_75_FILL_PATH
};

const KEYCODE_MAP = {
  [PLAY_PAUSE_SHORTCUT]: ({ isPlaying }) => (isPlaying ? PLAY_BUTTON_PATH : PAUSE_BUTTON_PATH),
  [PLAY_PAUSE_CLICK_SHORTCUT]: ({ isPlaying }) => (isPlaying ? PLAY_BUTTON_PATH : PAUSE_BUTTON_PATH),
  [MUTE_SHORTCUT]: ({ muted }) => (muted ? VOLUME_FILL_MUTE : VOLUME_FILL_MODERATE),
  [FORWARD_SHORTCUT]: () => FORWARD_BUTTON_FILL_PATH,
  [REWIND_SHORTCUT]: () => BACKWARD_BUTTON_FILL_PATH,
  [VOLUME_UP_SHORTCUT]: (props) => (getVolumeIcon({ ...props }, true)),
  [VOLUME_DOWN_SHORTCUT]: (props) => (getVolumeIcon({ ...props }, true)),
  [SPEED_SHORTCUT]: (props) => (getSpeedIcon({ ...props, speedIcons }))
};

const matchSvgByKeyCode = ({ keyCode, rate }) => {
  if (SHORTCUT_MAP[keyCode] === SPEED_SHORTCUT && rate < 1) return null;
  return (KEYCODE_MAP[SHORTCUT_MAP[keyCode]] ? KEYCODE_MAP[SHORTCUT_MAP[keyCode]] : null);
};

const resolveStyle = (keycode) => {
  if (keycode === FORWARD_BUTTON_FILL_PATH) {
    return DEFAULT_STYLE.iconRight;
  } if (keycode === BACKWARD_BUTTON_FILL_PATH) {
    return DEFAULT_STYLE.iconLeft;
  }
  return DEFAULT_STYLE.iconCenter;
};

function FeedbackOverlays(props) {
  const { feedback } = props;
  const resolveSVG = matchSvgByKeyCode(props);
  return (
    resolveSVG ? (
      <div style={DEFAULT_STYLE.container}>
        {feedback && (
        <SVGCreator
          paths={resolveSVG(props)}
          width={DEFAULT_ICON.width * getScale(props)}
          height={DEFAULT_ICON.height * getScale(props)}
          style={resolveStyle(resolveSVG(props))}
        />
        )}
      </div>
    ) : null
  );
}

const selector = ({
  playback: { isPlaying, rate },
  ui: { feedback, keyCode },
  volume: { muted, level }
}) => ({
  feedback,
  keyCode,
  isPlaying,
  muted,
  rate,
  volume: level
});

export default withBreakPoints(connect(selector)(FeedbackOverlays));
