import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { useDialogContext } from '../../../context/DialogContext';
import { useStyles } from '../../../hooks';
import { SETTINGS_WRAPPER_STYLE } from '../../controls/extra/styles';
import { DIALOG_LAYER_OPEN } from '../../../theme/constants';
import { MODAL_BACK_BUTTON_NAME } from './types';
import { MODAL_CONTAINER_STYLES } from './styles';

export function Modal({ show, closeComponent, styles, children, onChange, closingButtonStyle, attachedTo, ...props }) {
  const setStyles = useStyles();
  const dialogContext = useDialogContext();

  const modalBodyRef = useRef(null);
  const { activeElement } = document;

  useEffect(() => {
    if (!show) return;
    if (activeElement.getAttribute('name') === null) return;

    const activeElementIsOutsideTheModal = activeElement.getAttribute('name') !== attachedTo && activeElement.getAttribute('name') !== MODAL_BACK_BUTTON_NAME && !modalBodyRef.current.contains(activeElement) && modalBodyRef.current !== activeElement;
    if (activeElementIsOutsideTheModal) onChange(false);
  }, [activeElement, show]);

  return show && (

    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      aria-modal="false"
      ref={modalBodyRef}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.keyCode === 27 /* ESC */ && dialogContext.dialogOpened) {
          e.preventDefault();
          dialogContext.closeAll();
        }
      }}
      style={{
        opacity: show ? 1 : 0,
        ...SETTINGS_WRAPPER_STYLE,
        ...setStyles(MODAL_CONTAINER_STYLES),
        ...setStyles(styles),
        overflow: 'hidden',
        zIndex: DIALOG_LAYER_OPEN
      }}
      role="dialog"
      {...props}
    >
      {/* Using a role here cause VoiceOver issues */ }
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        style={{ height: '100%' }}
        tabIndex={-1}
        onClick={(e) => e.stopPropagation()}
        onKeyPress={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div style={closingButtonStyle}>{closeComponent}</div>
        {children}
      </div>
    </div>
  );
}
