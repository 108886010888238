import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { connect, withBreakPoints } from '../../../hoc';
import { usePlayerContext } from '../../../hooks';
import { CHAT_DISPLAYED, CHAT_HIDDEN } from '../../../../types';

/*
  This is an empty container for the parent page to inject the chat in.
  The user will listen to events when the player chat box is opened or closed.
  Accordingly, they will inject a chat here, or destroy it to put it back in the
*/
function Chat({ show }) {
  const refChat = useRef(null);
  const player = usePlayerContext();
  useEffect(() => {
    player.events$.next({
      name: show ? CHAT_DISPLAYED : CHAT_HIDDEN,
      payload: { chatDom: refChat.current }
    });

    return () => player.events$.next({
      name: CHAT_HIDDEN,
      payload: { chatDom: null }
    });
  }, [show]);

  return show && <div style={{ padding: 16, height: '100%' }} ref={refChat} />;
}

const selector = ({
  ui: { chatAvailable, waitStart, isSmallScreen, isExtraSmallScreen, isFullscreen }
}) => ({
  show: chatAvailable && isFullscreen && (!waitStart || !isExtraSmallScreen || !isSmallScreen)
});

export default withBreakPoints(connect(selector)(Chat));
