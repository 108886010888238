import { h } from 'preact';
import { connect } from '../../ui/hoc/rxjs-connect';
import { publicPath } from '../../configuration';
import { BACKGROUND } from '../../types';
import TVError from '../components/error';
import { OverlayScreen } from '../../ui/components/common';

const selector = ({
  error,
  media: { backgroundImage },
  ui: { isFullscreen, width, height },
  systemInfo: { platform }
}) => ({ error, isFullscreen, backgroundImage, width, height, platform });
const withError = (Comp) => connect(selector)(({ error, isFullscreen, backgroundImage, width, height, platform, ...p }) => (
  error.code ? (
    <div className="ftv-magneto--container-error">
      <OverlayScreen
        component={(props) => (
          <TVError
            code={error.code}
            message={error.message}
            canRetry={error.canRetry}
            showCode={error.showCode}
            orangeErrorCode={error.orangeErrorCode}
            orangeUserCode={error.orangeUserCode}
            orangeMessage={error.orangeMessage}
            orangeTitle={error.orangeTitle}
            isFullscreen={isFullscreen}
            platform={platform}
            {...props}
          />
        )}
        style={{
          backgroundImage: `url("${publicPath}${BACKGROUND}")`,
          zoom: 1.009,
          backgroundSize: width < height ? 'contain' : 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundColor: 'black'
        }}
      />
    </div>
  ) : <Comp {...p} />
));

export default withError;
