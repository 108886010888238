import { h } from 'preact';
import Tooltip, { TYPE_AUTO } from '../Tooltip';
import { withBreakPoints } from '../../../../hoc';
import { HIGHLIGHTS_LABEL_STYLES, SPRITES_METADATA_TITLE_PROGRAM_STYLES, SPRITES_METADATA_TITLE_VIDEO_STYLES, TOOLTIP_AUTO_CONTAINER_STYLES } from '../styles';
import { useStyles } from '../../../../hooks';
import { Titles } from '../../../common';

function HighlightVisual({ mini, height, width, thumbnailSize, thumbnail, hoverTimeline, isSeeking, left }) {
  const setStyles = useStyles();
  return (
    <div className="ftv--magneto-highlight-container">
      <Tooltip
        hidden={mini}
        live
        hover={hoverTimeline}
        isSliding={isSeeking}
        thumbnail={thumbnail}
        dimensions={{
          thumbnail: thumbnailSize,
          tooltip: {
            width,
            height
          }
        }}
        type={TYPE_AUTO}
        bubblePosition={{ left }}
        style={{ ...setStyles(TOOLTIP_AUTO_CONTAINER_STYLES) }}
        badge="temps fort"
        styleBadge={setStyles(HIGHLIGHTS_LABEL_STYLES)}
        styleThumbnail={{ border: thumbnail?.url ? '4px solid #fff' : 'inherit' }}
      >
        <Titles
          title={thumbnail.title}
          containerStyle={{ display: 'flex', flexDirection: 'column', gap: 2 }}
          subTitle={thumbnail.additionalTitle}
          titleStyle={setStyles(SPRITES_METADATA_TITLE_PROGRAM_STYLES)}
          subTitleStyle={setStyles(SPRITES_METADATA_TITLE_VIDEO_STYLES)}
        />
      </Tooltip>
    </div>
  );
}

export default withBreakPoints(HighlightVisual);
