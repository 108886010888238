import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function CheckIcon({ height = 60, width = 60, color = 'white', ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 10 7"
      fill="none"
      {...props}
    >
      <mask id="mask0_ckeck" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="7">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M3.42872 5.40254L3.78228 5.75609L4.13583 5.40254L8.83514 0.703227C8.90837 0.630004 9.02708 0.630004 9.10031 0.703227C9.17353 0.77645 9.17353 0.895169 9.10031 0.968392L3.91486 6.15384C3.84163 6.22706 3.72292 6.22706 3.64969 6.15384L1.05697 3.56112C0.983745 3.48789 0.983745 3.36917 1.05697 3.29595C1.13019 3.22273 1.24891 3.22273 1.32213 3.29595L3.42872 5.40254Z" fill="white" stroke="white" />
      </mask>
      <g mask="url(#mask0_ckeck)">
        <rect width="10" height="7" fill={color} />
      </g>

    </SVGWrapper>

  );
}

export default CheckIcon;
