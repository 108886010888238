import youbora from 'youboralib';
import { combineLatest, merge, NEVER } from 'rxjs';
import { switchMap, map, withLatestFrom, filter, distinctUntilChanged, mapTo, take, skip, scan } from 'rxjs/operators';
import { Adapter } from './adapter';
import { TIMESHIFTING_DIFFERED, TIMESHIFTING_DIFFERED_PAST, TIMESHIFTING_LIVE, TIMESHIFTING_LIVE_BACK } from '../../core/timeshifting/types';
import { MONITORING_OPTIN, MONITORING_OPTOUT } from '../../core/monitoring/types';
import TimeShiftingController from '../../core/timeshifting';
import { YOUBORA_TRIGGER_START_SESSION, TIMESHIFTING_NPAW_SHIFTING_TIME_MARGIN, TIMESHIFTING_NPAW_LIVE_TIME_MARGIN } from './types';
import { TIMESHIFTING_AUTO_CONTROLLER_LOADED, TIMESHIFTING_CONTROLLER_LOADED, USER_TIMESHIFTING_BACK_TO_LIVE, USER_TIMESHIFTING_SEEK } from '../../types';
import { FIRST_PLAY } from '../../core/command/types';
import { DAI_END, DAI_IN, DAI_OUT, DAI_START } from '../../ad/dai/types';

const METADATA_MAP = {
  title: 'title',
  title_episode: 'episodeTitle',
  program: 'program',
  season: 'season',
  content_id: 'id',
  drm_type: 'drm',
  channel: 'channel',
  content_type: 'type',
  content_genre: 'genre',
  content_saga: 'saga'
};

export default class Npaw {
  constructor({ config, player }) {
    this.plugin = new youbora.Plugin(config);
    this.setupHit(player);

    player.playerConfig$.pipe(filter((cfg) => cfg.debug === true))
      .subscribe(() => {
        youbora.Log.logLevel = youbora.Log.Level.DEBUG;
      });
  }

  dispose() {
    this.plugin.getAdapter().dispose();
  }

  async configure({ player, ad }) {
    this.plugin.setAdapter(new Adapter(player));
    if (ad) {
      const { AdAdapter } = await import(/* webpackChunkName: "npawAdAdapter" */'./adAdapter');

      this.plugin.setAdsAdapter(new AdAdapter(player));
    }
  }

  setupHit(player) {
    const { medias$, timeshiftable$, events$, rendererController: { currentTime$ }, playerConfig$ } = player;

    Npaw.createNewMediaHitStream({ medias$, timeshiftable$ }).pipe(withLatestFrom(playerConfig$))
      .subscribe(([options, { consent, embedCode }]) => {
        this.plugin.options.setOptions(Npaw.buildOptions(consent, options, embedCode));
      });

    Npaw.createTimeshiftingHitStream(player)
      .pipe(withLatestFrom(playerConfig$))
      .subscribe(([options, { consent }]) => {
        this.plugin.fireStop();
        this.plugin.options.setOptions(Npaw.buildOptions(consent, options));
        /**
       * We can not call directly this.plugin.fireStart() here
       * as it's not available
       * We will fire on the adapter
       */
        player.events$.next(YOUBORA_TRIGGER_START_SESSION);
      });

    Npaw.customEvents({ currentTime$, medias$, events$ })
      .subscribe(({ dimensions, eventName, values }) => this.plugin.getAdapter().fireEvent(eventName, dimensions, values));
  }

  static timeshiftingPassedAndDifferedCD8$({ currentTime$, duration$, medias$, currentProgramIndex$, userEvents$ }) {
    const isLive$ = TimeShiftingController
      .createIsStreamLive(currentTime$, duration$, medias$, TIMESHIFTING_NPAW_SHIFTING_TIME_MARGIN, TIMESHIFTING_NPAW_LIVE_TIME_MARGIN);

    return combineLatest(currentProgramIndex$, isLive$).pipe(
      filter(([, isLive]) => !isLive),
      withLatestFrom(userEvents$),
      /*
      * We dont want to track the USER_TIMESHIFTING_BACK_TO_LIVE as it is already tracked by
      * the timeshiftingLiveCD8()
      */
      filter(([, { action }]) => action !== USER_TIMESHIFTING_BACK_TO_LIVE),
      map(([[currentProgramIndex]]) => (currentProgramIndex >= 0 ? TIMESHIFTING_DIFFERED : TIMESHIFTING_DIFFERED_PAST))
    );
  }

  /**
   * Listen to user seek as the currentProgramIndex is not changing when we seek in the current program
   * @param {*} param0
   * @returns
   */
  static createSeekInCurrentProgramStreamC8$({ userEvents$, currentTime$, duration$, medias$, currentProgramIndex$ }) {
    return userEvents$.pipe(
      filter(({ action }) => action === USER_TIMESHIFTING_SEEK),
      withLatestFrom(currentProgramIndex$, TimeShiftingController
        .createIsStreamLive(currentTime$, duration$, medias$, TIMESHIFTING_NPAW_SHIFTING_TIME_MARGIN, TIMESHIFTING_NPAW_LIVE_TIME_MARGIN)),
      filter(([, currentProgramIndex, isLive]) => currentProgramIndex === 0 && !isLive),
      mapTo(TIMESHIFTING_DIFFERED)
    );
  }

  static timeshiftingLiveCD8$({ currentTime$, duration$, medias$, commands$ }) {
    const isLive$ = TimeShiftingController
      .createIsStreamLive(currentTime$, duration$, medias$, TIMESHIFTING_NPAW_SHIFTING_TIME_MARGIN, TIMESHIFTING_NPAW_LIVE_TIME_MARGIN)
      .pipe(filter(Boolean));
    return merge(
      isLive$.pipe(
        take(1),
        // here we want to check if the first play was triggered in order to avoid the stop event
        withLatestFrom(commands$),
        filter(([, { type }]) => type === FIRST_PLAY),
        mapTo(TIMESHIFTING_LIVE)
      ),
      isLive$.pipe(skip(1), mapTo(TIMESHIFTING_LIVE_BACK))
    );
  }

  static createTimeshiftingHitStream(player) {
    const { medias$, events$, userEvents$,
      rendererController: { duration$, currentTime$ },
      commandController: { commands$ }
    } = player;

    return events$.pipe(
      filter((evt) => evt === TIMESHIFTING_AUTO_CONTROLLER_LOADED || evt === TIMESHIFTING_CONTROLLER_LOADED),
      switchMap(() => {
        const { currentProgramIndex$ } = player.timeshiftingAutoController || player.timeshiftingController;

        return merge(
          Npaw.timeshiftingPassedAndDifferedCD8$({ currentTime$, duration$, medias$, currentProgramIndex$, userEvents$ }),
          Npaw.timeshiftingLiveCD8$({ currentTime$, duration$, medias$, commands$ }),
          Npaw.createSeekInCurrentProgramStreamC8$({ userEvents$, currentTime$, duration$, medias$, currentProgramIndex$ })
        );
      }),
      distinctUntilChanged(),
      withLatestFrom(medias$),
      map(([customDimension8, { markers: { npaw = {} } }]) => ({ ...npaw, customDimension8 }))
    );
  }

  static createNewMediaHitStream({ medias$, timeshiftable$ }) {
    return medias$.pipe(
      withLatestFrom(timeshiftable$),
      map(([{ markers: { npaw = {} } }, timeshiftable]) => ({
        ...npaw,
        ...(
          npaw?.customDimension8
            ? { customDimension8: timeshiftable ? TIMESHIFTING_LIVE : npaw.customDimension8 }
            : {}
        )
      }))
    );
  }

  /**
   * @param {Object} config.consent
   * @param {Object} rawData
   * @returns {Object}
   */
  static buildOptions({ npaw: userConsent }, rawData, embedCode = null) {
    const userNoConsent = userConsent !== MONITORING_OPTIN;
    return {
      'user.privacyProtocol': userNoConsent ? MONITORING_OPTOUT : MONITORING_OPTIN,
      'user.obfuscateIp': userNoConsent,
      'device.isAnonymous': userNoConsent,
      ...Npaw.buildCustomDimensions(rawData, embedCode),
      ...Npaw.buildYouboraMetaData(rawData)
    };
  }

  static buildCustomDimensions(rawData, embedCode = null) {
    const customDimensions = Object.entries(rawData)
      .filter(([k, v]) => /^customDimension[0-9]+$/.test(k) && v)
      .reduce((acc, [key, value]) => {
        acc[key.replace('customDimension', 'content.customDimension.')] = value;

        return acc;
      }, {});

    return { ...customDimensions, 'content.customDimension.4': embedCode };
  }

  static buildYouboraMetaData(rawData) {
    return Object.keys(rawData)
      .filter((curr) => METADATA_MAP[curr])
      .reduce((acc, curr) => ({
        ...acc,
        ...({ [`content.${METADATA_MAP[curr]}`]: rawData[curr] })
      }), {});
  }

  static customEvents({ events$, currentTime$, medias$ }) {
    return merge(medias$.pipe(
      filter(({ isDAI }) => isDAI),
      switchMap(() => merge(
        Npaw.createEventDAIStart(events$),
        Npaw.createEventDAISEnd({ currentTime$, events$ })
      ))
    ));
  }

  static createEventDAIStart(events$) {
    return events$.pipe(
      filter((e) => e === DAI_START),
      mapTo({
        eventName: DAI_IN,
        dimensions: { dai: DAI_IN },
        values: { position: 0 }
      })
    );
  }

  static createEventDAISEnd({ currentTime$, events$ }) {
    const effectivePlayTime$ = Npaw.createEffectivePlayTime({ currentTime$, events$ });
    return events$.pipe(
      filter((e) => e === DAI_END),
      withLatestFrom(effectivePlayTime$),
      map(([, effectivePlayTime]) => ({
        eventName: DAI_OUT,
        dimensions: { dai: DAI_OUT },
        values: { position: effectivePlayTime }
      }))
    );
  }

  static createEffectivePlayTime({ currentTime$, events$ }) {
    return events$.pipe(
      filter((e) => [DAI_START, DAI_END].includes(e)),
      switchMap((e) => (e === DAI_END ? NEVER
        : currentTime$.pipe(
          map((currentTime) => Math.round(currentTime)),
          distinctUntilChanged(),
          /**
           * we start from -1000 because when the DAI_START
           * the position is at 0ms
           */
          scan((acc) => acc + 1000, -1000)
        )
      ))
    );
  }
}
