import '@maxwell/maxwell';
import { map, forkJoin, switchMap, withLatestFrom } from 'rxjs';
import Config from '../../configuration';
import {
  MAXWELL_AD_MODE_ACTIVATED,
  MAXWELL_AD_MODE_DEACTIVATED,
  MAXWELL_PLAYER_EVENT,
  MAXWELL_TRACKER_BLOCKING_STATUS_MAP,
  MAXWELL_TRACKER_UNKNOWN
} from './types';
import { mapDomain } from './utils';
import FreewheelController from '../../ad/freewheel';
import { isUrlReachable } from '../../utils';
import { FREEWHEEL_DEFAULT_QUERIES } from '../../ad/freewheel/types';

export default class MaxwellController {
  constructor({ medias$, playerConfig$ }) {
    /**
     *
     * Branch all others trackers
     */
    const TRACKER_URLS = {
      dc1: `https://${Config.eStat.pingUrl}`,
      dc4: `https://${Config.freewheel.server}${FREEWHEEL_DEFAULT_QUERIES}`,
      dc2: 'https://tag.aticdn.net/piano-analytics.js'
    };

    MaxwellController.createMaxwellStream({ medias$, trackerUrls: TRACKER_URLS, playerConfig$ })
      .subscribe(MaxwellController.emit);
  }

  static checkAllTrackerStream(trackerUrls) {
    return forkJoin(Object.keys(trackerUrls).reduce(
      (trackers, key) => ({ ...trackers, [key]: MaxwellController.checkTrackerStream(trackerUrls[key]) }),
      {}
    ));
  }

  static checkTrackerStream(url) {
    return isUrlReachable(url).pipe(
      map((reachable) => {
        const currentStatus = MAXWELL_TRACKER_BLOCKING_STATUS_MAP.find(
          ({ expectedIsServerReachableValue }) => expectedIsServerReachableValue === reachable
        );

        return currentStatus?.mappedTo ?? MAXWELL_TRACKER_UNKNOWN;
      })
    );
  }

  static createMaxwellStream({ medias$, trackerUrls, playerConfig$ }) {
    return medias$.pipe(
      withLatestFrom(playerConfig$),
      switchMap(([
        {
          markers: {
            maxwell: { channel, region, contentStatus, contentType, category, contentId, broadcastingType, daiStatus, originServer, event }
          },
          isLive
        },
        { showAd }
      ]) => MaxwellController.checkAllTrackerStream(trackerUrls).pipe(
        map((dcs) => ({
          ...mapDomain(window.location.hostname),
          dcs,
          options: {
            contentStatus,
            contentType,
            category,
            channel,
            region,
            contentId,
            broadcastingType,
            daiStatus,
            originServer,
            contentAd: MaxwellController.isPubable({ showAd, isLive }),
            event
          }
        }))
      ))
    );
  }

  static emit({ offer, product, dcs, options }) {
    window.Maxwell.initialize({
      offer,
      product,
      config: {
        baseUrl: Config.maxwell.url
      }
    });
    window.Maxwell.stat({ name: MAXWELL_PLAYER_EVENT, customOptions: dcs, options });
  }

  static isPubable({ showAd, isLive }) {
    return showAd && !(FreewheelController.getCappingId() && isLive)
      ? MAXWELL_AD_MODE_ACTIVATED
      : MAXWELL_AD_MODE_DEACTIVATED;
  }
}
