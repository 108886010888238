import { Fragment, h } from 'preact';

import { useState, useEffect, useRef } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../hooks';
import { withBreakPoints, connect } from '../../../hoc';
import { LINK_SVG } from '../../../theme/svg';
import { Button, ToggleButton } from '../../common';
import { UPDATE_SETTING } from '../../../../store/types';
import { USER_QUALITY_MENU_OPENED, USER_SETTINGS_UPDATED, USER_CLICK, USER_PLAYBACKRATE_CHANGE, USER_REPORT_PROBLEM, USER_REPORT_QUALITY, SETTINGS_OPENED, SETTINGS_CLOSED, USER_KEYBOARD } from '../../../../types';
import { FEEDBACK_THANKS_TEXT_STYLE, NAVBAR_STYLE } from './styles';
import SlideMenu from '../../common/menu/SlideMenu';
import RowItem from '../../common/menu/RowItem';
import Quality from './Quality';
import Selectable from '../../common/menu/Selectable';
import Seperator from '../../common/menu/Seperator';
import Reporter from './Reporter';
import SlideContent from '../../common/menu/SlideContent';
import SlidableSlide from '../../common/menu/SlidableSlide';
import { useOpenDialogsCounter, useRegisterDialog } from '../../../context/DialogContext';
import { Modal } from '../../common/modal';
import { BUTTON_SQUARE_SIZE, TEXT_LEVEL_2_FONT_SIZE } from '../../../styles';
import { isValidateKeyboard } from '../../../utils/keyboard';

const PLAYBACK_SPEED_MAP = [
  { label: 'x 0.25', ariaLabel: 'fois 0.25', value: 0.25 },
  { label: 'x 0.5', ariaLabel: 'fois 0.5', value: 0.5 },
  { label: 'x 0.75', ariaLabel: 'fois 0.75', value: 0.75 },
  { label: 'normale', ariaLabel: 'normale', value: 1 },
  { label: 'x 1.25', ariaLabel: 'fois 1.25', value: 1.25 },
  { label: 'x 1.5', ariaLabel: 'fois 1.25', value: 1.5 },
  { label: 'x 1.75', ariaLabel: 'fois 1.25', value: 1.75 }
];

const REPORT_PROBLEM_MAP = [
  { label: 'l\'écran de la vidéo est noir', value: 1 },
  { label: 'la vidéo ne se lance pas', value: 2 },
  { label: 'le son et l\'image sont désynchronisés', value: 3 },
  { label: 'la vidéo est saccadée / coupure', value: 4 },
  { label: 'les sous-titres sont mal calés', value: 5 },
  { label: 'il n\'y a pas de son', value: 6 }
];

const RATE_VIDEO_MAP = [
  { label: 'excellente', value: 7 },
  { label: 'bonne', value: 8 },
  { label: 'standard', value: 9 },
  { label: 'insuffisante', value: 10 }
];

const toggleAutoPlay = (player, comingNext) => {
  player.store.dispatch({
    type: UPDATE_SETTING,
    payload: {
      comingNext: !comingNext.value
    }
  });

  player.trigger(
    USER_SETTINGS_UPDATED,
    { autoplay: !comingNext.value }
  );
};

const useAvalaibleSettingsList = (props) => {
  const [totalAvalaibleSettingsListItem, setTotalAvalaibleSettingsListItem] = useState(0);
  const setting = { index: -1, getIsEnabled: () => null, isEnabled: false, count: 1 };

  const [settings, setSettings] = useState({
    autoPlay: { ...setting, getIsEnabled: ({ comingNext: { enabled } }) => enabled },
    quality: { ...setting, getIsEnabled: ({ hasQualities }) => hasQualities },
    speed: { ...setting, getIsEnabled: ({ isDVR, isLive }) => !isDVR && !isLive },
    reports: { ...setting, getIsEnabled: ({ reporter }) => reporter, count: 2 },
    embed: { ...setting, getIsEnabled: ({ isEmbed }) => isEmbed }
  });

  useEffect(() => {
    const { total, settingsWithIndex } = Object.keys(settings).reduce((acc, settingKey) => {
      const settingValue = settings[settingKey];

      settingValue.isEnabled = Boolean(settingValue.getIsEnabled(props));

      if (settingValue.isEnabled) {
        settingValue.index = acc.total + 1;
        acc.total += settingValue.count;
      }

      acc.settingsWithIndex[settingKey] = settingValue;

      return acc;
    }, { total: 0, settingsWithIndex: {} });

    setTotalAvalaibleSettingsListItem(total);
    setSettings(settingsWithIndex);
  }, [...Object.values(props)]);

  return [totalAvalaibleSettingsListItem, settings];
};

function Settings(props) {
  const {
    isAd,
    isDVR,
    isLive,
    waitStart,
    comingNext,
    small,
    extraSmall,
    mobile,
    hasQualities,
    rate,
    reporter,
    currentQualityLabel,
    isFullscreen
  } = props;

  const player = usePlayerContext();
  const setStyles = useStyles();

  const [opened, setOpened] = useState(false);
  const [isOutsideModal, setIsOutsideModal] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const timerRef = useRef(null);
  const closeIconRef = useRef(null);
  const iconRef = useRef(null);
  const [neverOpened, setNeverOpened] = useState(true);
  const [openCloseIconState, setOpenCloseIconState] = useState({ label: 'Paramètres', describedBy: undefined });

  const [enableAccessOutline, setEnableAccessOutline] = useState(false);

  useRegisterDialog({ id: 'settings', openingMode: 'click', state: [opened, setOpened] });
  const [total, settingsList] = useAvalaibleSettingsList(props);

  useEffect(() => {
    if (activeIndex === 5) {
      if (timerRef.current) clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setOpened(false);
        setActiveIndex(0);
      }, 10000);
    } else {
      clearInterval(timerRef.current);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!opened) {
      setOpenCloseIconState({ label: 'Paramètres' });
      /* We reset active slide on close to 0 here.
         Since SlideMenu is unmounted on close, his own useEffect will never fire. */
      setActiveIndex(0);
      setEnableAccessOutline(false);
      return;
    }

    if (activeIndex === 5) setOpenCloseIconState({ label: 'Merci pour votre retour, fermer la fenêtre de signalement', describedBy: 'feedback-thanks-text' });
    else setOpenCloseIconState({ label: 'Fermer la fenêtre de Paramètres et retourner au lecteur vidéo' });
  }, [opened, activeIndex]);

  useOpenDialogsCounter(opened);

  useEffect(() => {
    if (neverOpened) return;

    if (!opened && iconRef.current) {
      if (!isOutsideModal) iconRef.current.focus();
    }

    if (opened && closeIconRef.current) {
      setIsOutsideModal(false);
      closeIconRef.current.focus();
    }
  }, [opened, iconRef.current, closeIconRef.current, neverOpened, isOutsideModal]);

  /* Avoid error when manipulating playback rate manually */
  const selectedRate = PLAYBACK_SPEED_MAP.find((speed) => speed.value === Math.max(0.25, Math.min(rate, 1.75)));

  const rowItemStyle = setStyles(TEXT_LEVEL_2_FONT_SIZE);

  return (
    <Fragment>
      {opened || (
      <Button
        type="icon"
        openingMode="click"
        label="paramètres"
        forceLabel
        zone="top"
        last
        onClick={(e) => {
          if (e instanceof KeyboardEvent || /* NVDA-synthetic-click detection */ e.buttons === 1) setEnableAccessOutline(true);
          setOpened(true);
          setNeverOpened(false);

          player.userEvents$.next({
            action: SETTINGS_OPENED,
            source: USER_CLICK
          });
        }}
        xs={(small || extraSmall)}
        iconRef={iconRef}
        name="btn-settings"
        ariaHidden={false}
        ariaLabel="Paramètres"
        hidden={isAd || waitStart || ((isLive || isDVR) && (!comingNext.enabled && !hasQualities && !reporter))}
        icon="settings"
        size={
            (setStyles(BUTTON_SQUARE_SIZE)).width
          }
      />
      )}

      {opened && (
        <Modal
          closeComponent={(
            <Button
              type="icon"
              openingMode="click"
              label="fermer"
              ariaLabel={openCloseIconState.label}
              forceLabel
              disableFocus={false}
              last
              zone="top"
              onClick={() => {
                player.userEvents$.next({
                  action: SETTINGS_CLOSED,
                  source: USER_CLICK
                });
                setOpened(false);
              }}
              onKeyDown={(e) => {
                player.userEvents$.next({
                  action: SETTINGS_CLOSED,
                  source: USER_KEYBOARD
                });

                if (isValidateKeyboard(e)) {
                  setOpened(false);
                }
              }}
              icon="close"
              xs
              iconRef={closeIconRef}
              name="btn-close-settings"
              ariaHidden={false}
              size={setStyles({
                extraSmall: { width: 16 },
                default: { width: 24 }
              }).width}
              styleOverride={setStyles({
                default: { marginTop: 25, marginRight: -11 },
                extraLarge: { marginTop: 28 }
              })}
            />
              )}
          show={opened}
          attachedTo="btn-settings"
          onChange={(openedState) => {
            setIsOutsideModal(true);
            setOpened(openedState);
          }}
          closingButtonStyle={
            setStyles({
              default: { position: 'absolute' },
              extraSmall: { right: 31 },
              small: { right: 32 },
              'medium+large+extraLarge': { right: 32 }
            })
          }
          styles={{
            default: { width: '494px', top: '12px', right: '12px' },
            'small+extraSmall': { width: '100%', maxWidth: '100%', maxHeight: '100%', height: '100%', right: 0, top: 0, borderRadius: 0 }
          }}

        >
          <SlideMenu
            styles={{
              navbar: setStyles(NAVBAR_STYLE),
              navbarTitle: setStyles({
                default: { height: 40 },
                medium: { height: 30 },
                small: { height: 33 },
                extraSmall: { height: 24 }
              })
            }}
            opened={opened}
            focusable={enableAccessOutline}
            onNavigate={(index) => setActiveIndex(index)}
          >
            {({ navigateTo }) => (!opened ? <div /> : (
              <Fragment>

                {/* MAIN SETTINGS OPTIONS SLIDE */}
                <SlidableSlide title="paramètres" isFullscreen={isFullscreen} style={!isFullscreen ? setStyles({ extraSmall: { maxHeight: 130 } }) : {}}>
                  <div
                    style={{
                      ...setStyles({
                        default: { height: '100%' },
                        extraSmall: { height: 'unset' }
                      })
                    }}
                  >
                    <SlideContent>
                      {settingsList.autoPlay.isEnabled && activeIndex === 0 && (
                        <RowItem
                          label={`lecture automatique (${
                            comingNext.value ? 'activé' : 'désactivé'
                          })`}
                          focusable={enableAccessOutline}
                          ariaLabel="lecture automatique"
                          index={settingsList.autoPlay.index}
                          totalCount={total}
                          ariaValue={() => (comingNext.value ? 'activé' : 'désactivé')}
                          onClick={() => toggleAutoPlay(player, comingNext)}
                          style={rowItemStyle}
                        >
                          <ToggleButton
                            val={comingNext.value}
                            onToggle={() => toggleAutoPlay(player, comingNext)}
                          />
                        </RowItem>
                      )}
                      {settingsList.quality.isEnabled && activeIndex === 0 && (
                        <RowItem
                          label="qualité vidéo"
                          focusable={enableAccessOutline}
                          index={settingsList.quality.index}
                          totalCount={total}
                          sublabel={currentQualityLabel}
                          onClick={() => {
                            navigateTo(1);
                            player.userEvents$.next({
                              action: USER_QUALITY_MENU_OPENED,
                              source: USER_CLICK,
                              value: currentQualityLabel
                            });
                          }}
                          link
                          style={rowItemStyle}
                        />
                      )}
                      {settingsList.speed.isEnabled && activeIndex === 0 && (
                        <RowItem
                          label={`vitesse de lecture ${!mobile ? '(x)' : ''}`}
                          focusable={enableAccessOutline}
                          ariaLabel="vitesse de lecture"
                          index={settingsList.speed.index}
                          totalCount={total}
                          sublabel={selectedRate.label}
                          onClick={() => {
                            navigateTo(2);
                            player.userEvents$.next({
                              action: USER_PLAYBACKRATE_CHANGE,
                              value: { prevRate: rate }
                            });
                          }}
                          link
                          style={rowItemStyle}
                        />
                      )}
                    </SlideContent>
                    {settingsList.reports.isEnabled && activeIndex === 0 && (
                      <Fragment>
                        <Seperator />
                        <SlideContent>
                          <RowItem
                            index={settingsList.reports.index}
                            focusable={enableAccessOutline}
                            totalCount={total}
                            label="signaler un problème"
                            onClick={() => navigateTo(3)}
                            link
                            style={rowItemStyle}
                            ignorePosition
                          />
                          <RowItem
                            index={settingsList.reports.index + 1}
                            focusable={enableAccessOutline}
                            totalCount={total}
                            label="noter la qualité de la vidéo"
                            onClick={() => navigateTo(4)}
                            link
                            ignorePosition
                            style={rowItemStyle}
                          />
                        </SlideContent>
                      </Fragment>
                    )}
                    {settingsList.embed.isEnabled && activeIndex === 0 && (
                      <Fragment>
                        <Seperator />
                        <SlideContent>
                          <RowItem
                            label="politique de gestion des traceurs France Télevisions"
                            index={settingsList.embed.index}
                            focusable={enableAccessOutline}
                            totalCount={total}
                            onClick={() => {
                              window.open(
                                'https://www.francetelevisions.fr/groupe/confidentialite/gestion-des-cookies',
                                '_blank'
                              );
                            }}
                            link
                            linkIcon={LINK_SVG}
                            linkIconStyle={{}}
                            ignorePosition
                            style={rowItemStyle}
                          />
                        </SlideContent>
                      </Fragment>
                    )}
                  </div>
                </SlidableSlide>

                {/* VIDEO QUALITY */}
                {hasQualities && activeIndex === 1 && (
                <SlidableSlide title="qualité vidéo" isFullscreen={isFullscreen}>
                  <SlideContent>
                    <Quality focusable={enableAccessOutline} />
                  </SlideContent>
                </SlidableSlide>
                )}
                {/* PLAYBACK RATE */}
                { activeIndex === 2 && (
                  <SlidableSlide title={`vitesse de lecture ${!mobile ? '(x)' : ''}`} isFullscreen={isFullscreen}>
                    <SlideContent>
                      { PLAYBACK_SPEED_MAP.map(({ label, ariaLabel, value }, i) => (
                        <Selectable
                          key={label}
                          label={label}
                          ariaLabel={ariaLabel}
                          index={i + 1}
                          value={value}
                          totalCount={PLAYBACK_SPEED_MAP.length}
                          active={rate === value}
                          focusable={enableAccessOutline}
                          onSelect={() => {
                            player.speed(value); player.userEvents$.next({
                              action: USER_PLAYBACKRATE_CHANGE,
                              source: USER_CLICK,
                              value: { prevRate: rate, newRate: value }
                            });
                          }}
                        />
                      ))}

                    </SlideContent>
                  </SlidableSlide>
                )}

                {/* REPORT PROBLEM */}
                {activeIndex === 3 && (
                <Reporter
                  closeIconRef={closeIconRef}
                  title="signaler un problème"
                  source={REPORT_PROBLEM_MAP}
                  focusable={enableAccessOutline}
                  onClick={() => {
                    player.userEvents$.next({ action: USER_REPORT_PROBLEM });
                  }}
                  submit={{ label: 'signaler', onSubmit: (value) => { player.report(1, value); navigateTo(5); } }}
                  isFullscreen={isFullscreen}
                />
                )}

                {/* REPORT VIDEO QUALITY */}
                {activeIndex === 4 && (
                <Reporter
                  closeIconRef={closeIconRef}
                  title="noter la qualité vidéo"
                  source={RATE_VIDEO_MAP}
                  focusable={enableAccessOutline}
                  onClick={() => {
                    player.userEvents$.next({ action: USER_REPORT_QUALITY });
                  }}
                  submit={{ label: 'envoyer', onSubmit: (value) => { player.report(2, value); navigateTo(5); } }}
                  isFullscreen={isFullscreen}
                />
                )}

                {activeIndex === 5 && (
                <SlideContent>
                  <div style={{ ...FEEDBACK_THANKS_TEXT_STYLE, ...setStyles(TEXT_LEVEL_2_FONT_SIZE) }}>
                    <span role="heading" aria-level="3" id="feedback-thanks-text" aria-label="Merci pour votre retour">merci pour votre retour</span>
                  </div>
                </SlideContent>
                )}

              </Fragment>
            ))}
          </SlideMenu>
        </Modal>
      )}

    </Fragment>
  );
}

const selector = ({
  playback: { hasQualities, rate, currentQualityLabel },
  ui: { waitStart, reporter, isFullscreen },
  settings: { comingNext },
  media: { isAd, isDVR, isLive },
  ui: { originUrl }
}) => ({
  waitStart,
  comingNext,
  isAd,
  hasQualities,
  rate,
  isDVR,
  isLive,
  reporter,
  isEmbed: Boolean(originUrl),
  currentQualityLabel,
  isFullscreen
});

export default connect(selector)(withBreakPoints(Settings));
