import { map } from 'rxjs/operators';
import { FRENCH_CODE_LIST } from '../ui/components/controls/exp/tracks/constants';
import * as TRACKS from '../core/tracks/assets/tracks.json';

const LABEL_LANG_ORIGINAL = 'version originale';

const DEFAULT_ORIGINAL = { label: LABEL_LANG_ORIGINAL, ariaLabel: LABEL_LANG_ORIGINAL, icon: null };

export const TRACKS_PRIORITIES = [
  {
    priority: 1,
    languages: ['fr', 'fre', 'fra', 'qsa']
  },
  {
    priority: 2,
    languages: ['qaa', 'ger', 'de', 'it', 'mul', 'en', 'eng']
  },
  {
    priority: 3,
    languages: ['qad', 'qtz', 'qsm']
  },
  {
    priority: 4,
    languages: ['qgd', 'qgs']
  }
  // priority 5 for others
];

export const resolveLabel = ({ lang }) => TRACKS[lang?.toLowerCase()] || DEFAULT_ORIGINAL;

export const mapTracks = () => map((tracks) => tracks
  .map(({ lang, index, default: def }) => ({
    index,
    language: lang,
    label: resolveLabel({ lang }),
    default: def
  })));

export const getLanguagePriority = (language) => {
  const { priority } = TRACKS_PRIORITIES.find(({ languages }) => languages.includes(language)) || { priority: 5 };// default value is 5
  return priority;
};

export const isMultilang = (audios, subtitles) => (
  [...audios, ...subtitles].some(({ language }) => (
    language !== null && !FRENCH_CODE_LIST.includes(language)
  ))
);
