export const RESIZE_FACTOR_BUTTON_RESPONSIVE = 3 / 4;
export const RESIZE_FACTOR_BUTTON_LARGE_SCREEN = 4 / 3;
export const RESIZE_FACTOR_BUTTON_MOBILE = 4 / 3;
export const BUTTON_SVG_RATIO = 0.666; /* Jesus forgive me */

export const RESIZE_FACTOR_EXP_CTRL_RESPONSIVE = 2 / 3;

export const FTV_TIMING_FUNCTION_IN = 'cubic-bezier(0.167, 0.167, 0, 1)';
export const FTV_TIMING_FUNCTION_OUT = 'cubic-bezier(1, 0, 0.833, 0.833)';

export const ZAPPING_HOVER_TRANSITION = 'all cubic-bezier(0.16, 1, 0.99, 0.99) .2s';

/* z index layers */
export const UNDER_LAYER = -1;
export const VIDEO_LAYER = 0;
export const SKIP_BTN_LAYER = 1;
export const UI_LAYER = 2;
export const ZAPPING_LAYER = 50;
export const TIMELINE_LAYER = 10;
export const SETTINGS_LAYER = 20;
export const TRACKS_LAYER = 70;
export const BUTTON_LAYER = 40;
export const TOOLTIP_LAYER = BUTTON_LAYER + 5;

export const OVERLAY_LAYER = 130;
export const DIALOG_LAYER = OVERLAY_LAYER + 1;
export const DIALOG_LAYER_OPEN = DIALOG_LAYER + 1;
export const VOLUME_DIALOG_LAYER = DIALOG_LAYER - 1;
export const PIP_DIALOG_LAYER = DIALOG_LAYER - 2;
export const FULLSCREEN_DIALOG_LAYER = DIALOG_LAYER - 3;

export const DIALOG_BUTTON_LAYER = 70;
export const EMBED_LAYER = 90;
export const SPINNER_LAYER = 100;
export const TAP_LAYER = 110;
export const COMING_NEXT_LAYER = 120;

export const FTV_OUTLINE = '#5687F8 0 0 0 2px inset';
