import { h } from 'preact';
import { svgDefaultDescriptor } from '../../../../utils';

/*   should be moved or replaced by/inside SVG creator */
function SVGWrapper({
  width = 40,
  height = 40,
  children,
  ...props
}) {
  return (
    <svg
      width={width}
      height={height}
      {...svgDefaultDescriptor}
      {...props}
    >
      { children }
    </svg>
  );
}

export default SVGWrapper;
