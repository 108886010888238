import { FTV_DARK_PRIMARY_BACKGROUND_COLOR } from '../../../../ui/theme/colors';

export const ACTIVE_LABELED_BUTTON_STYLE = {
  background: FTV_DARK_PRIMARY_BACKGROUND_COLOR,
  borderColor: FTV_DARK_PRIMARY_BACKGROUND_COLOR,
  color: 'black'
};

export const BUTTON_STYLE = {
  transition: 'transform 0.2s ease-in-out'
};
