import { Subject } from 'rxjs';

export const createLogger = (console) => {
  const logs$ = new Subject();

  return class Logger {
    static get logs$() {
      return logs$;
    }

    static info(message, ...details) {
      Logger.emitLogMessage('info', message, details);
    }

    static warn(message, ...details) {
      Logger.emitLogMessage('warn', message, details);
    }

    static error(message, ...details) {
      Logger.emitLogMessage('error', message, details);
    }

    static emitLogMessage(type, message, details) {
      const prefix = `[Magneto] ${Logger.getCurrentTime()}:`;

      console[type](...[prefix, message, ...details]);

      Logger.logs$.next({ type, message, details });
    }

    static getCurrentTime() {
      const date = new Date();
      const dd = (`${date.getDate()}`).padStart(2, 0);
      const mmm = (`${date.getMilliseconds()}`).slice(-2);
      return `[${dd}:${date.toLocaleTimeString('fr-FR')}:${mmm}]`;
    }
  };
};

export default createLogger(console);
