import { Fragment, h } from 'preact';
import Program from './Program';
import { connect } from '../../hoc';
import Scrollable from '../../hoc/scrollable';
import { HIGHLIGHTS_NAME, PLAYLIST_NAME, ZAPPING_NAME } from '../wrappers/Zap/constants';

function Programs({ activeTab, list, activeChannel, activeChannelId, currentTab, id }) {
  const { channels = [] } = list[activeTab] || {};
  return (
    <Scrollable forceInnerScroll>
      <Fragment>
        {channels.map((metadata, i) => (
          <Program
            metadata={metadata}
            active={[activeChannelId, id].includes(metadata.id)}
            activeTabMetadata={channels[activeChannel] || null}
            currentTab={currentTab}
            programNumber={i}
            channels={channels}
          />
        ))}
      </Fragment>
    </Scrollable>
  );
}

const highlightToChannels = (imagesHL, duration) => [
  {
    channels: imagesHL.map(({ highlightedAt: preTitle, additionalTitle, imageUrl: image, programDistance }) => ({
      logo: null,
      image,
      title: additionalTitle,
      preTitle,
      programDistance,
      duration
    })).reverse()
  }
];

const playlistToChannels = (playlist) => [
  {
    channels: playlist.map(({ src: id, config, cardInfo: { iconChannel, iconCsa, title, subTitle, image } }) => ({
      id,
      logo: iconChannel,
      image,
      title,
      preTitle: subTitle,
      iconCsa,
      config
    }))
  }
];

const getList = (tab, list, playlist, imagesHL, duration) => {
  switch (tab) {
    case ZAPPING_NAME:
      return list;
    case PLAYLIST_NAME:
      return playlistToChannels(playlist);
    case HIGHLIGHTS_NAME:
      return highlightToChannels(imagesHL, duration);
    default:
      return list;
  }
};

const selector = ({
  media: { duration, id },
  highlights: { imagesHL },
  ui: {
    panelLiveOption: { currentTab }
  },
  playlist: { playlist },
  zapping: {
    list,
    current: { tabIndex: activeTab, activeChannel, activeChannelId },
    metaOpened
  }
}) => ({
  activeTab: currentTab === ZAPPING_NAME ? activeTab : 0,
  activeChannel,
  metaOpened,
  highlights: imagesHL.length ? imagesHL : [],
  currentTab,
  duration,
  list: getList(currentTab, list, playlist, imagesHL, duration),
  activeChannelId,
  id
});
export default connect(selector)(Programs);
