import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function HDIcon({ height = 60, width = 60, color = 'white', ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <mask id="mask0_hd" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="4" width="14" height="7">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M4.48505 8.15V11H6.21007V4H4.48505V6.55H1.72502V4H0V11H1.72502V8.15H4.48505Z" fill="#B4B4B4" />
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M9.41638 9.4V5.6H10.3035C10.9541 5.6 11.3484 5.75 11.654 6.02C12.0483 6.37 12.275 6.91 12.275 7.5C12.275 8.09 12.0483 8.63 11.654 8.98C11.3484 9.25 10.9541 9.4 10.3035 9.4H9.41638ZM7.69136 11H10.2542C11.3484 11 12.068 10.76 12.6298 10.34C13.4776 9.7 14 8.66 14 7.5C14 6.34 13.4776 5.3 12.6298 4.66C12.068 4.24 11.3484 4 10.2542 4H7.69136V11Z" fill="#B4B4B4" />
      </mask>
      <g mask="url(#mask0_hd)">
        <rect width="14" height="14" fill={color} />
      </g>

    </SVGWrapper>

  );
}

export default HDIcon;
