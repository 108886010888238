import { PRIMARY_FILL_COLOR, SECONDARY_FILL_COLOR } from '../../../../ui/theme/colors';

export const FIRST_TRACKS_SECTION = { marginBottom: 20 };

export const TRACKS_SECTION_MAIN_TITLE = {
  color: SECONDARY_FILL_COLOR,
  fontSize: 32,
  height: 32,
  margin: 0,
  marginBottom: 32,
  whiteSpace: 'nowrap'
};

export const TRACKS_SECTION_TITLES = {
  color: SECONDARY_FILL_COLOR,
  fontSize: 30,
  height: 30,
  margin: 0,
  marginBottom: 30
};

export const GAP = {
  height: 40
};

export const TRACKS_DIVIDER = { marginTop: 40, backgroundColor: PRIMARY_FILL_COLOR };
