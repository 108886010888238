import { h } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import SliderBar from './SliderBar';
import { getStyles } from './getStyles';
import { VOID_FN, deepMerge } from '../../../../utils';
import SlicedSliderBar from './SlicedSliderBar';

const resolveTouchEvents = (touch) => ({
  touchMove: touch ? 'touchmove' : 'mousemove',
  touchEnd: touch ? 'touchend' : 'mouseup'
});

const getCursorPosition = (e, { touch, target: { current }, vertical }) => {
  /* use touchEvent in case of mobile useage */
  const eventTarget = touch ? (e.changedTouches.length && e.changedTouches[0]) : e;

  const { clientX, clientY } = eventTarget; /* mouse/tap position */
  const { offsetWidth, offsetHeight } = current; /* container dimensions */
  const { bottom, left } = current.getBoundingClientRect(); /* container position */

  const relativeCursorPosition = vertical ? bottom - clientY : clientX - left;
  const max = vertical ? offsetHeight : offsetWidth;
  const position = Math.max(Math.min(relativeCursorPosition, max), 0);

  return [position, max];
};

function Slider({
  onMouseEnter = VOID_FN,
  onMouseLeave = VOID_FN,
  onMouseMove = VOID_FN,
  onSlide = VOID_FN,
  onStartSlide = VOID_FN,
  onStopSlide = VOID_FN,
  trackSize,
  strokeSize,
  cursorSize,
  value,
  slices = [],
  buffer = 0,
  touch,
  vertical,
  children,
  styles = {},
  primaryTrackStyles = {},
  secondaryTrackStyles = {},
  sliderStyle = {},
  onFocus,
  onBlur,
  name,
  disableFocus,
  accessibleName,
  ariaValueText = '',
  currentIndex = 0,
  currentSegment,
  multiSegments = false,
  showSlider = true
}) {
  const target = useRef(null);
  const [isSliding, setIsSliding] = useState(false);
  const slide = (e) => onSlide(...getCursorPosition(e, { touch, vertical, target }));
  const startSlide = (e) => {
    /**
     * stopPropagation : avoid duplicated startSlide call when clicking on the slider's cursor
     * since we can either startSlide from a click on the cursor or the slider's container.
     * preventDefault : avoid triggering unwanted scroll on mobile
     */
    e.stopPropagation(); e.preventDefault();

    /* startSlide triggers mouseenter action on mobile */
    if (touch) onMouseEnter();
    onStartSlide(...getCursorPosition(e, { touch, vertical, target }));
    setIsSliding(true);
  };

  const stopSlide = (e) => {
    if (!isSliding) return;
    if (touch) onMouseLeave();
    onStopSlide(...getCursorPosition(e, { touch, vertical, target }));
    setIsSliding(false);
  };

  const slideAndStart = (e) => {
    slide(e);
    startSlide(e);
  };

  useEffect(() => {
    const { touchMove, touchEnd } = resolveTouchEvents(touch);
    const listenerFn = isSliding ? 'addEventListener' : 'removeEventListener';
    document[listenerFn](touchMove, slide);
    document[listenerFn](touchEnd, stopSlide);

    return () => {
      document.removeEventListener(touchMove, slide);
      document.removeEventListener(touchEnd, stopSlide);
    };
  }, [isSliding]);

  const sliderStyles = deepMerge(getStyles({
    vertical,
    trackSize,
    strokeSize,
    cursorSize
  }), styles);

  return (
    <div
      hidden={!showSlider}
      style={{ ...styles.container }}
    >
      <div
        style={sliderStyles.outerTrack}
        role="slider"
        aria-valuemin="0"
        aria-valuemax="100"
        aria-valuenow={value}
        aria-valuetext={ariaValueText
          ? `${accessibleName}, ${ariaValueText}`
          : `${accessibleName} ${Math.floor(value)} %`}
        aria-live="polite"
        aria-atomic="true"
        tabIndex={disableFocus ? '-1' : '0'}
        className="ftv-magneto--slider"
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        {...(
          touch ? {
            onTouchStart: slideAndStart,
            onTouchMove: (e) => onMouseMove(...getCursorPosition(e, { touch, vertical, target }))
          } : {
            onMouseEnter,
            onMouseLeave,
            onMouseMove: (e) => onMouseMove(...getCursorPosition(e, { touch, vertical, target })),
            onMouseDown: slideAndStart
          }
        )}
      >
        {children}
        {showSlider && (multiSegments
          ? (
            <SlicedSliderBar
              vertical={vertical}
              onSlideStart={startSlide}
              style={sliderStyles}
              value={value}
              buffer={buffer}
              slices={slices}
              targetRef={target}
              touch={touch}
              sliderStyle={sliderStyle}
              primaryTrackStyles={primaryTrackStyles}
              secondaryTrackStyles={secondaryTrackStyles}
              currentIndex={currentIndex}
              currentSegment={currentSegment}
            />
          )
          : (
            <SliderBar
              vertical={vertical}
              onSlideStart={startSlide}
              style={sliderStyles}
              value={value}
              buffer={buffer}
              targetRef={target}
              touch={touch}
              sliderStyle={sliderStyle}
              primaryTrackStyles={primaryTrackStyles}
              secondaryTrackStyles={secondaryTrackStyles}
            />
          ))}
      </div>
    </div>
  );
}

export default Slider;
