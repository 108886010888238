/* global WebKitMediaKeys */
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

const DRM_KEY_SYSTEM = 'com.apple.fps.3_0';

function waitFor(target, type) {
  return new Promise((resolve) => {
    target.addEventListener(type, resolve, { once: true });
  });
}

function base64DecodeUint8Array(input) {
  return Uint8Array.from(atob(input), (c) => c.charCodeAt(0));
}

async function getResponse(event, spcPath, token) {
  const licenseResponse = await fetch(spcPath, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/octet-stream',
      'nv-authorizations': token
    },
    body: event.message
  });
  const license = await licenseResponse.text();
  const responseObject = JSON.parse(license.trim());
  return base64DecodeUint8Array(responseObject.CkcMessage);
}

export default class FairPlay {
  static isFairPlaySupported(tagElement) {
    return (('webkitKeys' in tagElement)
      && ('webkitSetMediaKeys' in tagElement)
      && typeof WebKitMediaKeys !== 'undefined'
      && WebKitMediaKeys.isTypeSupported(DRM_KEY_SYSTEM, 'video/mp4'));
  }

  static async getCertificate(token, fairplayLaUrlCertificates) {
    return fetch(fairplayLaUrlCertificates, {
      method: 'GET',
      headers: {
        'nv-authorizations': token
      }
    })
      .then((res) => res.arrayBuffer())
      .then((bufferCertificate) => new Uint8Array(bufferCertificate));
  }

  static getLicense({
    token,
    url,
    certificate,
    tagElement
  }) {
    return fromEvent(tagElement, 'encrypted')
      .pipe(
        debounceTime(200),
        map(async (event) => {
          const { initDataType } = event;

          if (!tagElement.mediaKeys) {
            const access = await navigator.requestMediaKeySystemAccess('com.apple.fps.1_0', [{
              initDataTypes: [initDataType],
              videoCapabilities: [{ contentType: 'application/vnd.apple.mpegurl', robustness: '' }],
              distinctiveIdentifier: 'not-allowed',
              persistentState: 'not-allowed',
              sessionTypes: ['temporary']
            }]);

            const keys = await access.createMediaKeys();
            await keys.setServerCertificate(certificate);
            await tagElement.setMediaKeys(keys);
          }

          const { initData } = event;

          const session = tagElement.mediaKeys.createSession();

          session.generateRequest(initDataType, initData);

          const message = await waitFor(session, 'message');
          const license = await getResponse(message, url, token);
          await session.update(license);

          return session;
        })
      );
  }
}
