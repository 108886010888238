import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function EmbedIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >

      <mask id="mask0_embed" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="4" width="21" height="15">
        <path d="M12.8055 5.0952C12.8916 4.69003 13.2899 4.4314 13.695 4.51752C14.1002 4.60364 14.3588 5.0019 14.2727 5.40706L11.6163 17.9044C11.5302 18.3095 11.1319 18.5682 10.7268 18.482C10.3216 18.3959 10.063 17.9977 10.1491 17.5925L12.8055 5.0952ZM6.46255 8.53035C6.16966 8.23746 5.69479 8.23746 5.40189 8.53035L2.21991 11.7123C1.92702 12.0052 1.92702 12.4801 2.21991 12.773L5.40189 15.955C5.69479 16.2479 6.16966 16.2479 6.46255 15.955C6.75545 15.6621 6.75545 15.1872 6.46255 14.8943L4.16446 12.5962C3.9692 12.401 3.9692 12.0844 4.16446 11.8891L6.46255 9.59101C6.75545 9.29812 6.75545 8.82325 6.46255 8.53035ZM18.6003 15.9697C18.3074 16.2626 17.8326 16.2626 17.5397 15.9697C17.2468 15.6768 17.2468 15.2019 17.5397 14.909L19.8378 12.6109C20.033 12.4157 20.033 12.0991 19.8378 11.9038L17.5397 9.60573C17.2468 9.31284 17.2468 8.83797 17.5397 8.54507C17.8326 8.25218 18.3074 8.25218 18.6003 8.54507L21.7801 11.7249L21.7823 11.727C21.8474 11.7922 21.8981 11.8663 21.9343 11.9454C21.9997 12.0887 22.0177 12.2483 21.9883 12.4006C21.9608 12.5424 21.8922 12.6779 21.7823 12.7877L21.7801 12.7899L18.6003 15.9697Z" fill="black" />
      </mask>
      <g mask="url(#mask0_embed)">
        <rect width="24" height="24" fill={color} />
      </g>

    </SVGWrapper>

  );
}

export default EmbedIcon;
