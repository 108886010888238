import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { TRACKLIST_STYLES } from './styles';
import { useStyles } from '../../../../hooks';
import Position from '../../../../utils/Position';
import { RadioGroup } from '../../../../utils/RadioGroup';
import { SelectableRadio } from '../../../common/menu/SelectableRadio';

function TrackList({
  list,
  selected,
  title,
  method,
  first,
  ariaHidden = 'false',
  id,
  isTv
}) {
  const setStyles = useStyles();

  if (list.length === 0) return null;

  const className = `radiogroup_${id}`;

  useEffect(() => {
    const radios = document.querySelectorAll(`.${className}`);
    radios.forEach((radio) => new RadioGroup(radio, id, isTv));
  }, []);

  return (
    <div
      style={{
        ...setStyles({
          default: TRACKLIST_STYLES.section
        }),
        ...(first ? setStyles({ extraSmall: { marginRight: 20 }, default: { marginRight: 24 } }) : {})
      }}
      ariaHidden={ariaHidden}
      role="radiogroup"
      ariaLabel={id}
      aria-labelledby={id}
      className={className}
      id={id}
    >
      <span
        style={setStyles({
          ...TRACKLIST_STYLES.sectionTitle,
          small: { ...TRACKLIST_STYLES.sectionTitle.small },
          extraSmall: { ...TRACKLIST_STYLES.sectionTitle.extraSmall, fontSize: 16, height: 24 },
          medium: { marginBottom: 14 },
          large: { fontSize: 28, height: 42 },
          extraLarge: { fontSize: 28, height: 42 }
        })}
        ariaHidden
      >
        {title}
      </span>
      <div style={setStyles({
        default: TRACKLIST_STYLES.sectionItems,
        medium: { fontSize: 16 },
        extraSmall: { fontSize: 12, padding: '0px 0 10px 0px' },
        small: { fontSize: 16 },
        'largeTv+extraLargeTv': { flexDirection: 'column' }
      })}
      >
        <Position>
          {list
            .map((track) => ({ ...track, active: selected === track.index }))
            .map(({ index, label, active, language }) => (
              <SelectableRadio
                label={label}
                active={active}
                parentId={id}
                onSelect={() => method({ label, index, language })}
              />
            ))}
        </Position>
      </div>
    </div>
  );
}

export default TrackList;
