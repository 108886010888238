import { h } from 'preact';
import { connect, withBreakPoints } from '../../../hoc';
import { usePlayerContext, useStyles } from '../../../hooks';
import { Button } from '../../common';
import TracksMenu from './tracks/TracksMenu';
import Volume from './volume/Volume';
import { USER_PIP, USER_FULLSCREEN, USER_CLICK } from '../../../../types';
import { NO_LABEL } from '../../../../core/tracks/types';
import { FULLSCREEN_DIALOG_LAYER, PIP_DIALOG_LAYER } from '../../../theme/constants';
import { EXP_CONTROL_CONTAINER_STYLES, EXP_CONTROL_ICON_STYLES } from './styles';

function ExpControl({
  isAd,
  isDAI,
  isTv,
  volume,
  muted,
  subtitlesAvailable,
  subtitleSelected,
  audiosAvailable,
  audioSelected,
  medium,
  extraSmall,
  mobile,
  small,
  ios,
  isFullscreen,
  pipSupported
}) {
  const player = usePlayerContext();
  const setStyle = useStyles();

  const { size: iconSize } = setStyle(EXP_CONTROL_ICON_STYLES);

  return (
    <div style={setStyle(EXP_CONTROL_CONTAINER_STYLES)}>
      <TracksMenu /* AudioSubtitles Button */
        hidden={(isAd && !isDAI)}
        subtitlesAvailable={[
          ...(subtitlesAvailable.length
            ? [
              {
                language: null,
                index: -1,
                label: { label: NO_LABEL, ariaLabel: NO_LABEL }
              }
            ]
            : []),
          ...subtitlesAvailable
        ]}
        subtitleSelected={subtitleSelected}
        audiosAvailable={audiosAvailable}
        audioSelected={audioSelected}
        small={small}
        mobile={mobile}
        name="btn-tracks"
        disableFocus={false}
        ariaLabel="audio et sous-titres"
        ariaHidden="false"
        isTv={isTv}
      />

      <Volume
        hidden={isTv}
        volume={volume}
        muted={muted}
        medium={medium}
        extraSmall={extraSmall}
        small={small}
        name="btn-volume"
        disableFocus={false}
        ariaLabel={muted ? 'volume désactivé' : 'volume activé'}
        ariaHidden="false"
      />

      <Button
        hidden={!pipSupported || (ios && !pipSupported) || isAd || isTv}
        label="lecteur réduit (i)"
        onClick={() => {
          player.userEvents$.next({
            action: USER_PIP,
            value: true,
            source: USER_CLICK
          });
          player.requestPIP(true);
        }}
        xs
        name="btn-pip"
        type="icon"
        icon="pip"
        disableFocus
        zIndex={PIP_DIALOG_LAYER}
        size={iconSize}
      />

      <Button
        hidden={isTv}
        label={`${isFullscreen ? 'quitter le ' : ''}plein écran (f)`}
        ariaLabel={isFullscreen ? 'quitter le mode plein écran' : 'plein écran'}
        ariaHidden="false"
        onClick={() => {
          player.userEvents$.next({
            action: USER_FULLSCREEN,
            value: !isFullscreen,
            source: USER_CLICK
          });
          player.fullscreen(!isFullscreen);
        }}
        last
        xs
        name="btn-fullscreen"
        disableFocus={false}
        zIndex={FULLSCREEN_DIALOG_LAYER}
        type="icon"
        icon={isFullscreen ? 'fullscreen-on' : 'fullscreen-off'}
        size={iconSize}
      />
    </div>
  );
}

const selector = ({
  ui,
  media,
  volume,
  systemInfo
}) => {
  const { isFullscreen } = ui;
  const { pipSupported } = systemInfo;
  const {
    level,
    muted
  } = volume;
  const {
    subtitlesAvailable,
    subtitleSelected,
    audiosAvailable,
    audioSelected,
    isAd,
    isDAI,
    isTv
  } = media;

  return ({
    isAd,
    isDAI,
    isTv,
    volume: level,
    muted,
    subtitlesAvailable,
    subtitleSelected,
    audiosAvailable,
    audioSelected,
    isFullscreen,
    pipSupported
  });
};

export default withBreakPoints(connect(selector)(ExpControl));
