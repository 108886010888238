import { h } from 'preact';

import { useMemo } from 'preact/hooks';
import { ERROR_STYLES as styles } from '../error/styles';
import Centrer from '../common/center';
import { TVButton } from '../common/tvbutton';
import { emitEscape } from '../../../ui/utils/events';

function PanelCloseButton({ isExtraLargeTvScreen = false }) {
  const btnStyle = useMemo(
    () => ({
      ...styles.errorButton.default,
      ...(isExtraLargeTvScreen ? styles.errorButton.extraLarge : styles.errorButton.large),
      marginTop: 32,
      marginBottom: 50
    }),
    [isExtraLargeTvScreen]
  );

  return (
    <Centrer>
      <TVButton
        className="ftv-magneto--selectable"
        id="btn-bottom-close-panel"
        size={isExtraLargeTvScreen ? 72 : 48}
        label="fermer"
        style={btnStyle}
        onClick={() => {
          emitEscape();
        }}
      />
    </Centrer>
  );
}

export default PanelCloseButton;
