export const EXP_CONTROL_CONTAINER_STYLES = {
  default: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  extraSmall: { gap: '12px' },
  small: { gap: '16px' },
  medium: { gap: '16px', paddingBottom: '6px' },
  'large+extraLarge': { gap: '24px', paddingBottom: '8px' },
  'largeTv+extraLargeTv': { gap: '76px', paddingBottom: '0px' }
};
export const EXP_CONTROL_ICON_STYLES = {
  extraSmall: { size: 16 },
  small: { size: 24 },
  medium: { size: 36 },
  'large+extraLarge': { size: 44 }
};
