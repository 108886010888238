/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function RemoteControlActive({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_8615_4979" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="7" y="1" width="18" height="30">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 3C7 1.89543 7.89543 1 9 1H23C24.1046 1 25 1.89543 25 3V29C25 30.1046 24.1046 31 23 31H9C7.89543 31 7 30.1046 7 29V3ZM11 6C11 5.44772 11.4477 5 12 5H13.2C13.7523 5 14.2 5.44772 14.2 6V6.2C14.2 6.75229 13.7523 7.2 13.2 7.2H12C11.4477 7.2 11 6.75229 11 6.2V6ZM18 6C18 5.44772 18.4477 5 19 5H20.2C20.7523 5 21.2 5.44772 21.2 6V6.2C21.2 6.75229 20.7523 7.2 20.2 7.2H19C18.4477 7.2 18 6.75229 18 6.2V6ZM19 10C18.4477 10 18 10.4477 18 11V11.2C18 11.7523 18.4477 12.2 19 12.2H20.2C20.7523 12.2 21.2 11.7523 21.2 11.2V11C21.2 10.4477 20.7523 10 20.2 10H19ZM11 11C11 10.4477 11.4477 10 12 10H13.2C13.7523 10 14.2 10.4477 14.2 11V11.2C14.2 11.7523 13.7523 12.2 13.2 12.2H12C11.4477 12.2 11 11.7523 11 11.2V11ZM19 15C18.4477 15 18 15.4477 18 16V16.2C18 16.7523 18.4477 17.2 19 17.2H20.2C20.7523 17.2 21.2 16.7523 21.2 16.2V16C21.2 15.4477 20.7523 15 20.2 15H19ZM11 16C11 15.4477 11.4477 15 12 15H13.2C13.7523 15 14.2 15.4477 14.2 16V16.2C14.2 16.7523 13.7523 17.2 13.2 17.2H12C11.4477 17.2 11 16.7523 11 16.2V16ZM16 28C18.2091 28 20 26.2091 20 24C20 21.7909 18.2091 20 16 20C13.7909 20 12 21.7909 12 24C12 26.2091 13.7909 28 16 28ZM16 26C17.1046 26 18 25.1046 18 24C18 22.8954 17.1046 22 16 22C14.8954 22 14 22.8954 14 24C14 25.1046 14.8954 26 16 26Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_8615_4979)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export { RemoteControlActive };
