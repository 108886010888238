import { publicPath } from '../../../configuration';
import { SPINNER_LAYER } from '../../../ui/theme/constants';

const TV_LOADER_IMAGE = 'images/tv-loader.gif';
export const TV_LOADER_IMAGE_PATH = `${publicPath}${TV_LOADER_IMAGE}`;

export const TV_SPINNER_WRAPPER_STYLE = {
  zIndex: SPINNER_LAYER,
  height: '100%',
  position: 'relative'
};

export const TV_SPINNER = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translateY(-50%) translateX(-50%)'
};
