/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvStopIconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M45 86.25C67.7817 86.25 86.25 67.7817 86.25 45C86.25 22.2183 67.7817 3.75 45 3.75C22.2183 3.75 3.75 22.2183 3.75 45C3.75 67.7817 22.2183 86.25 45 86.25ZM29.6875 27.5C28.4794 27.5 27.5 28.4794 27.5 29.6875V60.3125C27.5 61.5206 28.4794 62.5 29.6875 62.5H60.3125C61.5206 62.5 62.5 61.5206 62.5 60.3125V29.6875C62.5 28.4794 61.5206 27.5 60.3125 27.5H29.6875Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvStopIconActive;
