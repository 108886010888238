import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function NextIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <mask id="mask0_next" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="2" width="28" height="30">
        <path d="M4.5 8.64251V27.4723C4.5 28.6111 5.7191 29.3346 6.71867 28.789L23.1948 19.7956C24.2138 19.2394 24.2418 17.7862 23.245 17.191L6.76892 7.35458C5.76908 6.75766 4.5 7.47804 4.5 8.64251Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.25 30C28.4216 30 27.75 29.3284 27.75 28.5V7.5C27.75 6.67157 28.4216 6 29.25 6C30.0784 6 30.75 6.67157 30.75 7.5V28.5C30.75 29.3284 30.0784 30 29.25 30Z" fill="black" />
      </mask>
      <g mask="url(#mask0_next)">
        <rect width="36" height="36" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default NextIcon;
