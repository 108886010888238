import { h } from 'preact';
import { TAP_LAYER, UNDER_LAYER } from '../../theme/constants';

const styles = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
};

/**
 * TapLayer is needed to absorb tap events and
 * stop propagation on mobile devices.
 * ie: Avoid clicking on hidden subtitles button
 * when tapping video to show playback controls.
 *
 * Also used as an overlay click layer when StartScreen is visible
 */
function TapLayer({ isMobile, onTap, visible, ariaLabel = '' }) {
  return (
    <div
      role="presentation"
      style={{
        ...styles,
        ...(isMobile ? {} : { cursor: 'pointer' }),
        zIndex: visible ? TAP_LAYER : UNDER_LAYER
      }}
      ariaLabel={ariaLabel}
      onClick={(e) => {
        e.stopPropagation();
        onTap();
      }}
    />
  );
}

export default TapLayer;
