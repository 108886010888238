import { PRIMARY_TEXT_COLOR } from '../../../theme/colors';

export const BASE_STYLE = {
  default: {
    fontFamily: 'Brown',
    fontWeight: 700,
    color: PRIMARY_TEXT_COLOR,
    lineHeight: 1.33,
    letterSpacing: '-0.6px',
    display: 'flex',
    flexDirection: 'column'
  },
  'largeTv+extraLargeTv': {
    gap: '24px'
  }
};

export const RESPONSIVE_STYLE = {
  letterSpacing: '-0.3px'
};

export const BASE_SUBTITLE_STYLE = {
  lineHeight: '120%',
  fontFamily: 'Brown',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};

export const BASE_TITLE_STYLE = {
  lineHeight: '120%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};

export const BASE_INFO_STYLE = {
  fontFamily: 'Brown',
  paddingBottom: 8,
  lineHeight: '120%',
  whiteSpace: 'nowrap'
};

export const TITLE_STYLE_ADJUST_SPECIFIC_FONT = {
  verticalAlign: 'text-top'
};

export const INFO_STYLES = {
  small: { fontSize: '12px' },
  medium: { fontSize: '14px' },
  'large+extraLarge': { fontSize: '20px' },
  largeTv: { fontSize: '28px' },
  extraLargeTv: { fontSize: '34px' }
};

export const TITLE_STYLES = {
  small: { fontSize: '22px', fontWeight: 'bold' },
  medium: { fontSize: '26px', fontWeight: 'bold' },
  'large+extraLarge': { fontSize: '32px' },
  largeTv: { fontSize: '50px' },
  extraLargeTv: { fontSize: '70px' }
};

export const SUBTITLE_STYLES = {
  small: { fontSize: '16px' },
  medium: { fontSize: '20px' },
  'large+extraLarge': { fontSize: '24px' },
  largeTv: { fontSize: '30px' },
  extraLargeTv: { fontSize: '38px' }
};
