export default function slugify(text, separator = '-') {
  if (!text || !text.toString) return '';
  return text
    .toString()
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/\s+/g, separator) // Replace spaces with separator
    /**
     * Previously we used the function normalize. To ensure compatibility with IE it is replaced by regex
     */
    .replace(/[àáâãäå]/g, 'a')
    .replace(/[èéêë]/g, 'e')
    .replace(/[ìíîï]/g, 'i')
    .replace(/[òóôõö]/g, 'o')
    .replace(/[ùúûü]/g, 'u')
    .replace(/[ýÿ]/g, 'y')
    .replace(/æ/g, 'ae')
    .replace(/ç/g, 'c')
    .replace(/ñ/g, 'n')
    .replace(/œ/g, 'oe')
    .replace(new RegExp(`[^\\w\\${separator}]`, 'g'), '') // Remove all non-word chars
    .replace(new RegExp(`\\${separator}\\${separator}`, 'g'), separator) // Replace multiple separator with single one
    .replace(new RegExp(`^\\${separator}+`), '') // Trim - from start of text
    .replace(new RegExp(`\\${separator}+$`), ''); // Trim - from end of text
}
