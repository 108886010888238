/* eslint-disable react/no-unknown-property */
import { Fragment, h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvRewind10Icon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <Fragment>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.2763 24.0049C38.5746 24.4908 38.5746 25.5341 39.2763 26.02L45.6326 30.4202C46.4383 30.9779 47.5347 30.3972 47.5347 29.4127V26.4323C56.6913 27.6697 63.75 35.5168 63.75 45.0124C63.75 55.3678 55.3553 63.7624 45 63.7624C34.6447 63.7624 26.25 55.3678 26.25 45.0124C26.25 39.9327 28.2701 35.3247 31.5507 31.948C32.3114 31.165 32.3817 29.9027 31.6148 29.1258C30.9293 28.4312 29.8173 28.3785 29.1257 29.067C25.0331 33.1415 22.5 38.7812 22.5 45.0124C22.5 57.4389 32.5736 67.5124 45 67.5124C57.4264 67.5124 67.5 57.4389 67.5 45.0124C67.5 33.443 58.7679 23.913 47.5347 22.6536V20.6122C47.5347 19.6277 46.4383 19.047 45.6326 19.6047L39.2763 24.0049Z" fill="#F8F8F8" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M54.375 45.0249C54.375 41.1187 52.6121 38.7749 49.8294 38.7749C47.0468 38.7749 45.2839 41.1187 45.2839 45.0249C45.2839 48.9312 47.0468 51.2749 49.8294 51.2749C52.6121 51.2749 54.375 48.9312 54.375 45.0249ZM52.0417 45.0249C52.0417 47.5423 51.2294 49.1916 49.8294 49.1916C48.4295 49.1916 47.6172 47.5423 47.6172 45.0249C47.6172 42.5075 48.4295 40.8582 49.8294 40.8582C51.2294 40.8582 52.0417 42.5075 52.0417 45.0249ZM36.3163 43.1152L38.9089 41.6568V49.018H35.625V51.1013H44.0939V49.018H41.2421V38.9485H39.6002L36.3163 40.893V43.1152Z" fill="#F8F8F8" />
      </Fragment>
    </SVGWrapper>
  );
}

export default TvRewind10Icon;
