import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function TracksIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask id="mask0_tracks" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="12">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.33337 4.3335H13.6667V11.6668H2.33337L2.33337 4.3335ZM1.33337 4.00016C1.33337 3.63197 1.63185 3.3335 2.00004 3.3335H14C14.3682 3.3335 14.6667 3.63197 14.6667 4.00016V12.0002C14.6667 12.3684 14.3682 12.6668 14 12.6668H13.1667V14.3621C13.1667 14.6591 12.8077 14.8078 12.5977 14.5978L10.6667 12.6668H2.00004C1.63185 12.6668 1.33337 12.3684 1.33337 12.0002V4.00016ZM10.5 9.66683C10.2239 9.66683 10 9.89069 10 10.1668C10 10.443 10.2239 10.6668 10.5 10.6668H12.1667C12.4429 10.6668 12.6667 10.443 12.6667 10.1668C12.6667 9.89069 12.4429 9.66683 12.1667 9.66683H10.5ZM3.00004 8.50016C3.00004 8.22402 3.2239 8.00016 3.50004 8.00016H5.16671C5.44285 8.00016 5.66671 8.22402 5.66671 8.50016C5.66671 8.77631 5.44285 9.00016 5.16671 9.00016H3.50004C3.2239 9.00016 3.00004 8.77631 3.00004 8.50016ZM3.50004 9.66683C3.2239 9.66683 3.00004 9.89069 3.00004 10.1668C3.00004 10.443 3.2239 10.6668 3.50004 10.6668H8.50004C8.77618 10.6668 9.00004 10.443 9.00004 10.1668C9.00004 9.89069 8.77618 9.66683 8.50004 9.66683H3.50004ZM6.66671 8.50016C6.66671 8.22402 6.89057 8.00016 7.16671 8.00016H12.1667C12.4429 8.00016 12.6667 8.22402 12.6667 8.50016C12.6667 8.77631 12.4429 9.00016 12.1667 9.00016H7.16671C6.89056 9.00016 6.66671 8.77631 6.66671 8.50016Z" fill="black" />
      </mask>
      <g mask="url(#mask0_tracks)">
        <rect width="16" height="16" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default TracksIcon;
