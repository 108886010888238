import { HIGHLIGHTS_CHANGED, HIGHLIGHTS_VISUSAL_CHANGED } from '../types';

const initialState = {
  pinsPosition: [],
  imagesHL: [],
  highlightThumbnail: {
    url: '',
    title: '',
    additionalTitle: '',
    pinsIndex: -1
  },
  pinsHovered: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case HIGHLIGHTS_CHANGED: {
      const { pinsPosition, imagesHL } = payload;
      return ({ ...state, pinsPosition, imagesHL });
    }
    case HIGHLIGHTS_VISUSAL_CHANGED: {
      const { highlightThumbnail, pinsHovered } = payload;

      return pinsHovered !== undefined
        ? { ...state, highlightThumbnail, pinsHovered }
        : { ...state, highlightThumbnail };
    }
    default:
      break;
  }
  return state;
};
