import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks';
import { setAnimationFrameInterval } from '../../utils';
import { useStateRef } from '../../hooks';
import { withBreakPoints } from '../../hoc';
import Dots from './dots';
import { RESIZE_FACTOR_BUTTON_RESPONSIVE } from '../../theme/constants';
import { SPINNER_TIME_STEP, SPINNER_WRAPPER, SPINNER_CONTAINER } from './styles';

/* Opacities for spinner dots - target dot: index 0 */
const BASE_STATE = [0, 0.25, 0.50, 0.75, 1, 0];
const FINISH_STATE = [1, 0, 0, 0, 0, 0];
const INITIAL_STATE = {
  opacities: BASE_STATE,
  canFinish: false,
  delta: 1,
  end: false
};

const computeNextState = (state) => {
  /**
   * flickering animation via array shifting :
   * [0,1,2,3,4,6] => [6,1,2,3,4,5] => [5,6,1,2,3,4] ...
   */
  const opacities = [
    ...state.opacities.slice(-1),
    ...state.opacities.slice(0, state.opacities.length - 1)
  ];

  /* delta is used for initial spinner fade-in */
  const delta = Math.max(0, state.delta - 0.2);
  const canFinish = ((opacities[5] === 0.75) && delta === 0);

  return ({
    ...state,
    opacities,
    canFinish,
    delta
  });
};

function SpinnerFranceTV({
  isMounted,
  isLeaving,
  shouldDelay = false,
  medium,
  extraSmall
}) {
  const scale = (medium ? RESIZE_FACTOR_BUTTON_RESPONSIVE : 1) / (extraSmall ? 1.5 : 1);
  const [state, setState, ref] = useStateRef(INITIAL_STATE);
  const transition = useRef(null);

  useEffect(() => setState((prevState) => ({ ...prevState, end: shouldDelay && !isMounted })), [isMounted, shouldDelay]);
  useEffect(() => (isMounted && isLeaving) && setState(INITIAL_STATE), [isMounted, isLeaving]);

  useEffect(() => {
    transition.current = setAnimationFrameInterval({
      check: () => ref.current.canFinish && ref.current.end,
      update: () => setState((prevState) => computeNextState(prevState)),
      finish: () => {
        transition.current.cancelAnimationFrameInterval();
        setState((prevState) => ({ ...prevState, opacities: FINISH_STATE }));
      }
    }, SPINNER_TIME_STEP);
    return () => transition.current?.cancelAnimationFrameInterval();
  }, []);

  return (
    <div style={SPINNER_WRAPPER}>
      <div style={{
        ...SPINNER_CONTAINER,
        opacity: (state.canFinish && state.end) ? 0 : 1,
        transform: `scale(${scale})`
      }}
      >
        <Dots
          opacities={state.opacities}
          delta={state.delta}
          ended={state.end && state.canFinish}
        />
      </div>
    </div>
  );
}

export default withBreakPoints(SpinnerFranceTV);
