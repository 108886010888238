import { h } from 'preact';
import {
  PLAYBACK_DEFAULT_WRAPPER_STYLE,
  PLAYBACK_CENTERED_WRAPPER_STYLE,
  PLAYBACK_HIDDEN
} from './styles';
import Position from '../../../utils/Position';
import { useStyles } from '../../../hooks';

function PlaybackWrapper({
  children,
  centered,
  styles = {},
  hidden
}) {
  const setStyle = useStyles();
  const visibleButtons = children.filter((child) => !child.props.hidden);

  return (
    <div
      style={{
        ...(centered ? PLAYBACK_CENTERED_WRAPPER_STYLE : PLAYBACK_DEFAULT_WRAPPER_STYLE),
        ...(hidden ? PLAYBACK_HIDDEN : {}),
        ...setStyle({
          default: { alignItems: 'center' },
          extraSmall: { gap: '12px' },
          'small+medium': { gap: '16px' },
          'large+extraLarge': { gap: '24px' },
          'largeTv+extraLargeTv': { gap: '76px' }
        }),
        ...styles
      }}
    >
      <Position disableFirstProp={centered} disableLastProp>
        {visibleButtons}
      </Position>
    </div>
  );
}

export default PlaybackWrapper;
