import { h } from 'preact';
import { connect } from '../../hoc';
import { usePlayerContext } from '../../hooks';
import { PRIMARY_TEXT_COLOR } from '../../theme/colors';
import '../../theme/styles/subtitles.css';
import { UNDER_LAYER } from '../../theme/constants';

const styles = {
  subtitleContainer: {
    zIndex: UNDER_LAYER,
    left: 0,
    right: 0,
    fontFamily: 'Brown',
    fontWeight: 400,
    textAlign: 'center',
    color: PRIMARY_TEXT_COLOR,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'columns',
    pointerEvents: 'none'
  }
};

const TARGET_RATIO = 16 / 9;

const calculateSubtitleStyles = ({ width, height }) => {
  const targetSize = (width / height) < TARGET_RATIO
    ? { width, height: (1 / TARGET_RATIO) * width }
    : { width, height };

  return ({
    fontSize: `${Math.floor(targetSize.height / 20)}px`,
    lineHeight: `${Math.floor(targetSize.height / 18)}px`
  });
};

function SubtitleContainer({ width, height }) {
  const player = usePlayerContext();
  return (
    <div
      className="ftv-magneto--subtitles"
      ref={(node) => player.setSubtitleContainer(node)}
      style={{ ...styles.subtitleContainer, ...calculateSubtitleStyles({ width, height }) }}
    />
  );
}

const selector = ({ ui: { width, height } }) => ({ width, height });
export default connect(selector)(SubtitleContainer);
