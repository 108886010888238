import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function OutIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >

      <mask id="mask0_out" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="3" width="17" height="17">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M14.125 3.99988C13.7108 3.99988 13.375 4.33566 13.375 4.74988C13.375 5.16409 13.7108 5.49988 14.125 5.49988H17.5777L12.2142 10.975C11.9244 11.2709 11.9293 11.7458 12.2252 12.0356C12.5211 12.3255 12.9959 12.3206 13.2858 12.0247L18.625 6.57425V9.99988C18.625 10.4141 18.9608 10.7499 19.375 10.7499C19.7892 10.7499 20.125 10.4141 20.125 9.99988V5.49988C20.125 4.67145 19.4534 3.99988 18.625 3.99988H14.125Z" fill="black" />
        <path d="M10 4.74988C10 4.33566 9.66421 3.99988 9.25 3.99988H5.75C4.7835 3.99988 4 4.78338 4 5.74988V18.2499C4 19.2164 4.7835 19.9999 5.75 19.9999H18.25C19.2165 19.9999 20 19.2164 20 18.2499V14.7499C20 14.3357 19.6642 13.9999 19.25 13.9999C18.8358 13.9999 18.5 14.3357 18.5 14.7499V18.2499C18.5 18.3879 18.3881 18.4999 18.25 18.4999H5.75C5.61193 18.4999 5.5 18.3879 5.5 18.2499V5.74988C5.5 5.61181 5.61193 5.49988 5.75 5.49988H9.25C9.66421 5.49988 10 5.16409 10 4.74988Z" fill="black" />
      </mask>
      <g mask="url(#mask0_out)">
        <rect width="24" height="24" fill={color} />
      </g>
    </SVGWrapper>
  );
}
export default OutIcon;
