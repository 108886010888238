import { race, fromEvent, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export function scriptLoader(url) {
  const es = document.createElement('script');
  es.type = 'text/javascript';
  es.async = true;
  es.src = url;

  const headScript = document.getElementsByTagName('script')[0];
  headScript.parentNode.insertBefore(es, headScript);

  return race(
    fromEvent(es, 'error')
      .pipe(switchMap((e) => throwError(() => e))),
    fromEvent(es, 'load')
  );
}
