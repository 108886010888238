/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Button, ToggleButton } from '../../common';
import { usePlayerContext, useStyles } from '../../../hooks';
import { connect, withBreakPoints } from '../../../hoc';
import { ECO_CLOSED, ECO_OPENED, USER_CLICK, USER_ECO_TOGGLED, USER_KEYBOARD, USER_QUALITY_CHANGED } from '../../../../types';
import { LABEL_ECO_QUALITY } from '../../../../core/quality/types';
import { Modal } from '../../common/modal';

import '../../../theme/styles/eco.css';
import { useOpenDialogsCounter, useRegisterDialog } from '../../../context/DialogContext';
import { ECO_DEFAULT_TEXTS, ECO_MOBILE_TEXTS } from './types';
import { PIANO_VALUE_POSITION_ECO } from '../../../../monitoring/piano/types';
import Scrollable from '../../../hoc/scrollable';
import { ECO_STYLES_FOOTER } from './styles';
import { BUTTON_SQUARE_SIZE, TEXT_LEVEL_1_FONT_SIZE, TEXT_LEVEL_2_FONT_SIZE, TEXT_LEVEL_3_FONT_SIZE } from '../../../styles';
import { Icon } from '../../common/svg/icons';
import { LABELS_FILL_COLOR, PRIMARY_FILL_COLOR, SCROLLBAR_BACKGROUND_COLOR } from '../../../theme/colors';
import { MODAL_BASIC_STYLE, MODAL_CLOSING_BUTTON_STYLE } from '../../common/modal/types';
import { isValidateKeyboard } from '../../../utils/keyboard';

function EcoButton({
  waitStart,
  isAd,
  hasQualities,
  mappedQualities,
  currentQualityLabel,
  mobile,
  tablet,
  isFullscreen,
  width: magnetoWidth,
  height: magnetoHeight,
  small,
  extraSmall
}) {
  const [ecoQuality] = hasQualities
    ? mappedQualities.filter(({ label }) => label === LABEL_ECO_QUALITY)
    : [];

  const [isOpened, setIsOpened] = useState(false);
  const [isOutsideModal, setIsOutsideModal] = useState(null);
  const [isFocusable, setIsFocusable] = useState(true);
  const [isEco, setIsEco] = useState(false);
  const [maxHeight, setMaxHeight] = useState('100%');
  const closeIconRef = useRef(null);
  const iconRef = useRef(null);
  const wrapperRef = useRef(null);
  const player = usePlayerContext();
  const setStyles = useStyles();

  const { title, title2, body1, body2, footer } = mobile || tablet ? ECO_MOBILE_TEXTS : ECO_DEFAULT_TEXTS;

  const setEcoMode = ({ action, source }) => {
    const qualityLevel = !isEco ? ecoQuality.level : -1;
    const newLabel = !isEco ? LABEL_ECO_QUALITY : 'automatique';

    player.setVideoQuality(qualityLevel);
    player.userEvents$.next({ action, source, value: !isEco });
    player.userEvents$.next({
      action: USER_QUALITY_CHANGED,
      source,
      value: {
        position: PIANO_VALUE_POSITION_ECO,
        previousLabel: currentQualityLabel,
        newLabel,
        level: qualityLevel
      }
    });
    setIsEco(!isEco);
  };

  useRegisterDialog({
    id: 'eco',
    openingMode: 'click',
    state: [isOpened, setIsOpened]
  });
  useOpenDialogsCounter(isOpened);

  const ecoModeActivated = isEco || currentQualityLabel === LABEL_ECO_QUALITY;
  const focusableItem = isFocusable ? 'ftv-magneto--focusable-item' : '';
  const closingButtonSize = setStyles({
    extraSmall: { width: 16 },
    'small+medium+large+extraLarge': { width: 24 }
  });

  useEffect(() => {
    if (currentQualityLabel === LABEL_ECO_QUALITY) {
      setIsEco(true);
    } else {
      setIsEco(false);
    }
  }, [currentQualityLabel]);

  useEffect(() => {
    if (isOpened && closeIconRef.current) {
      setIsOutsideModal(false);
      closeIconRef.current.focus();
    }
  }, [isOpened, iconRef.current, closeIconRef.current, isOutsideModal]);

  // configure scrollarea for small breakpoint
  useEffect(
    // 104px is the size of the modal header we have to substract
    () => {
      if (magnetoWidth < magnetoHeight) setMaxHeight('max-content');
      else if (isOpened) setMaxHeight(`${magnetoHeight - 104}px`);
    },
    [isOpened, magnetoWidth]
  );

  const modalEcoIconStyle = {
    display: 'inline',
    ...setStyles({
      default: { width: 24, height: 24 },
      extraSmall: { width: 16, height: 16 }
    })
  };

  return isOpened ? (
    <Modal
      closeComponent={(
        <Button
          type="icon"
          openingMode="click"
          label="fermer"
          ariaLabel="fermer"
          forceLabel
          disableFocus={false}
          last
          zone="top"
          onClick={() => {
            player.userEvents$.next({
              action: ECO_CLOSED,
              source: USER_CLICK
            });
            setIsOpened(false);
          }}
          onKeyDown={(e) => {
            player.userEvents$.next({
              action: ECO_CLOSED,
              source: USER_CLICK
            });
            if (isValidateKeyboard(e)) setIsOpened(false);
          }}
          size={closingButtonSize.width}
          icon="close"
          xs
          iconRef={closeIconRef}
          name="btn-close-eco"
          ariaHidden={false}
        />
      )}
      show={isOpened}
      attachedTo="btn-eco"
      styles={MODAL_BASIC_STYLE}
      onChange={(openedState) => {
        setIsOutsideModal(true);
        setIsOpened(openedState);
      }}
      closingButtonStyle={setStyles(MODAL_CLOSING_BUTTON_STYLE)}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        ref={wrapperRef}
        className="ftv-magneto--eco-wrapper"
        style={setStyles({
          extraSmall: { padding: '20px 0 20px 20px', lineHeight: 'inherit' },
          default: { padding: '20px 0 20px 24px', color: LABELS_FILL_COLOR }
        })}
        onKeyDown={() => setIsFocusable(true)}
        onClick={() => setIsFocusable(false)}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div
          className={`ftv-magneto--eco-header ${focusableItem}`}
          tabIndex="0"
          style={setStyles({ default: { gap: 10 }, extraSmall: { gap: 6 } })}
        >
          <Icon
            width={modalEcoIconStyle.width}
            height={modalEcoIconStyle.height}
            color={PRIMARY_FILL_COLOR}
            name={ecoModeActivated ? 'eco-activated' : 'eco'}
          />

          <div
            style={{
              ...setStyles(TEXT_LEVEL_1_FONT_SIZE),
              color: PRIMARY_FILL_COLOR
            }}
          >
            {title}
          </div>
        </div>

        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div
          className={`ftv-magneto--eco-status ${focusableItem}`}
          style={setStyles({
            // Marge A & B
            default: { marginTop: '20px', marginBottom: '20px' },
            small: { marginBottom: '16px' },
            extraSmall: { marginBottom: '14px' },
            'medium+large+extraLarge': { marginTop: '28px' }
          })}
          role="button"
          tabIndex="0"
          onKeyDown={(e) => {
            if (isValidateKeyboard(e)) {
              setEcoMode({
                action: USER_ECO_TOGGLED,
                source: USER_KEYBOARD
              });
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <div
            className={`ftv-magneto--eco-status-label ${focusableItem}`}
            style={setStyles(TEXT_LEVEL_2_FONT_SIZE)}
            aria-live="assertive"
          >
            {title}
            (
            {ecoModeActivated ? 'activé' : 'désactivé'}
            )
          </div>
          <div>
            <div
              className="ftv-magneto--eco-status-toggle"
              style={setStyles({ extraSmall: { marginRight: '20px' } })}
            >
              <ToggleButton
                val={ecoModeActivated}
                onToggle={() => {
                  setEcoMode({
                    action: USER_ECO_TOGGLED,
                    source: USER_CLICK
                  });
                }}
              />
            </div>
          </div>
        </div>

        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <div className="ftv-magneto--eco-body">
          <Scrollable
            {...(!isFullscreen
              ? setStyles({
                default: { maxHeight: 350 },
                medium: { maxHeight: 320 },
                large: { maxHeight: 420 },
                extraLarge: { maxHeight: 420 },
                'extraSmall+small': { maxHeight }
              })
              : {})}
          >
            <div
              style={setStyles({
                default: { marginBottom: '20px', paddingRight: '24px' },
                extraSmall: { paddingRight: '20px' }
              })}
            >
              <div
                className={`ftv-magneto--eco-text-1 ${focusableItem}`}
                role="presentation"
                tabIndex="0"
                style={setStyles(TEXT_LEVEL_2_FONT_SIZE)}
              >
                {body1}
              </div>
              <div
                className={`ftv-magneto--separator-line ${focusableItem}`}
                style={{ backgroundColor: SCROLLBAR_BACKGROUND_COLOR }}
              />

              <div
                className={`ftv-magneto--eco-text-2-title ${focusableItem}`}
                role="presentation"
                tabIndex="0"
                style={{
                  ...setStyles(TEXT_LEVEL_2_FONT_SIZE),
                  fontWeight: 700
                }}
              >
                {title2}
              </div>

              <div
                className={`ftv-magneto--eco-text-2-body ${focusableItem}`}
                role="presentation"
                tabIndex="0"
                style={setStyles(TEXT_LEVEL_2_FONT_SIZE)}
              >
                {body2}
              </div>

              <div
                className={`ftv-magneto--eco-text-2-footer ${focusableItem}`}
                role="presentation"
                tabIndex="0"
                style={{
                  ...setStyles(TEXT_LEVEL_3_FONT_SIZE),
                  ...ECO_STYLES_FOOTER
                }}
              >
                {footer}
              </div>
            </div>
          </Scrollable>
        </div>
      </div>
    </Modal>
  ) : (
    hasQualities && (
      <Button
        hidden={isAd || waitStart}
        type="icon"
        openingMode="click"
        label="mode éco"
        xs={small || extraSmall}
        ariaLabel="mode éco"
        forceLabel
        zone="top"
        name="btn-eco"
        onClick={() => {
          player.userEvents$.next({
            action: ECO_OPENED,
            value: ecoModeActivated,
            source: USER_CLICK
          });
          setIsOpened(true);
        }}
        size={setStyles(BUTTON_SQUARE_SIZE).width}
        icon={ecoModeActivated ? 'eco-activated' : 'eco'}
        iconRef={iconRef}
      />
    )
  );
}

const selector = ({
  playback: { hasQualities, currentQualityLabel, mappedQualities },
  media: {
    isAd
  },
  ui: { waitStart, isFullscreen }
}) => ({
  isAd, waitStart, hasQualities, currentQualityLabel, mappedQualities, isFullscreen
});

export default withBreakPoints(connect(selector)((EcoButton)));
