import { cropText } from '../../../ui/styles';
import { LABELS_FILL_COLOR, OVERLAY_COLOR_OPAQUE, PRIMARY_FILL_COLOR } from '../../../ui/theme/colors';
import { OVERLAY_LAYER } from '../../../ui/theme/constants';

export const TV_PANEL_LIVE_OPTION_WRAPPER_STYLE_OPENED = {
  default: {
    background: OVERLAY_COLOR_OPAQUE,
    color: 'white',
    zIndex: OVERLAY_LAYER,
    height: '100%'
  }
};

export const TV_ZAP_PROGRAM_WRAPPER = {
  padding: 16,
  fontFamily: 'Brown',
  width: '100%',
  position: 'relative',
  verticalAlign: 'middle'
};

export const TV_ZAP_PROGRAM_PREVIEW = {
  width: 100,
  minWidth: 100,
  backgroundColor: '#5C6770',
  borderRadius: 3,
  overflow: 'hidden',
  aspectRatio: '16/9',
  display: 'inline-block'
};

export const TV_ZAP_PROGRAM_TITLES = {
  paddingTop: 6,
  paddingBottom: 6,
  marginLeft: 16,
  maxWidth: 'calc(100% - 116px)',
  display: 'inline-block'
};

export const TV_ZAP_PROGRAM_SECOND_TITLE = {
  fontSize: 20,
  marginTop: 6,
  fontWeight: 400,
  color: LABELS_FILL_COLOR,
  fontFamily: 'Brown',
  ...cropText()
};

export const TV_ZAP_PROGRAM_MAIN_TITLE = {
  fontSize: 24,
  color: PRIMARY_FILL_COLOR,
  fontFamily: 'Brown',
  ...cropText()
};

export const TV_ZAP_PROGRAM_GRADIENT_BACKDROP = {
  width: '100%',
  height: '100%',
  backgroundImage: 'linear-gradient(90deg, rgba(0,0,0,0.7) 0%,  rgba(0,0,0,0.6) 5%, rgba(0,0,0,0) 35%, rgba(0,212,255,0) 100%)',
  display: 'table',
  backgroundSize: 'cover',
  verticalAlign: 'center'
};

export const TV_ZAP_PROGRAM_LOGO = {
  display: 'table-cell',
  verticalAlign: 'middle'
};

export const TV_ZAP_PROGRAM_CSA = {
  position: 'relative',
  top: 35,
  left: 10,
  height: 18,
  width: 18,
  display: 'block'
};
