import { h } from 'preact';
import { withBreakPoints } from '../../../hoc';
import { useStyles } from '../../../hooks';
import {
  BASE_STYLE,
  RESPONSIVE_STYLE,
  BASE_TITLE_STYLE,
  BASE_INFO_STYLE,
  BASE_SUBTITLE_STYLE,
  TITLE_STYLE_ADJUST_SPECIFIC_FONT,
  SUBTITLE_STYLES,
  TITLE_STYLES,
  INFO_STYLES
} from './styles';

function Titles({
  info,
  title,
  subTitle,
  medium,
  titleStyle = {},
  subTitleStyle = {},
  containerStyle = {}
}) {
  const setStyles = useStyles();

  return !title ? null : (
    <div
      style={{
        ...setStyles(BASE_STYLE),
        ...(medium ? RESPONSIVE_STYLE : {}),
        ...containerStyle
      }}
    >
      {info && (
        <div
          style={{ ...BASE_INFO_STYLE, ...setStyles(INFO_STYLES) }}
        >
          {info}
        </div>
      )}

      <div
        style={{
          ...BASE_TITLE_STYLE,
          ...TITLE_STYLE_ADJUST_SPECIFIC_FONT,
          ...setStyles(TITLE_STYLES),
          ...titleStyle
        }}
      >
        {title}
      </div>

      {subTitle && (
        <div
          style={{
            ...BASE_SUBTITLE_STYLE,
            ...setStyles(SUBTITLE_STYLES),
            ...subTitleStyle
          }}
        >
          {subTitle}
        </div>
      )}
    </div>
  );
}

export default withBreakPoints(Titles);
