import { Subject, BehaviorSubject } from 'rxjs';
import { scan } from 'rxjs/operators';
import reducer from '../reducers';

export function createStore() {
  const actions$ = new Subject();
  const state$ = new BehaviorSubject({});

  actions$
    .pipe(scan(reducer))
    .subscribe(state$);

  /**
   * Init Magneto internal store.
   */
  actions$.next({ type: '@@MAGNETO__STORE__INIT' });
  return {
    source$: state$,
    getState: () => state$.getValue(),
    subscribe: (...args) => {
      const { unsubscribe } = state$.subscribe(...args);
      return unsubscribe;
    },
    dispatch: (...args) => actions$.next(...args)
  };
}
