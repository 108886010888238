import { h } from 'preact';
import { connect } from '../../../ui/hoc';
import { Icon } from '../../../ui/components/common/svg/icons';
import { INTERACTION_ICON_STYLE, SPEED_ICONS } from './types';
import { START_SCREEN_BUTTON_SIZE } from '../../../ui/components/info/styles';

function InteractionIcon({ currentSpeed, fastSpeed }) {
  if (!fastSpeed) return null;
  return (fastSpeed && SPEED_ICONS[currentSpeed]) ? (
    <div style={INTERACTION_ICON_STYLE}>
      <Icon name={SPEED_ICONS[currentSpeed]} height={START_SCREEN_BUTTON_SIZE} width={START_SCREEN_BUTTON_SIZE} />
    </div>
  ) : null;
}

const selector = ({
  playback: { playButton, isPlaying },
  ui: { UIVisible, currentSpeed, fastSpeed }
}) => ({ isPlaying, UIVisible, playButton, currentSpeed, fastSpeed });

export default connect(selector)(InteractionIcon);
