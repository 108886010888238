import { h } from 'preact';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { useEffect, useState } from 'preact/hooks';
import { usePlayerContext } from '../hooks';
import { shallowDiffers } from '../utils';

export function observeStore(store, selector, onChange) {
  return store.source$
    .pipe(
      map(selector),
      distinctUntilChanged((a, b) => !shallowDiffers(a, b))
    )
    .subscribe(onChange);
}

export function connect(mapState = () => ({})) {
  return (ComponentToConnect) => function (props) {
    const { store } = usePlayerContext();
    const [state, setState] = useState(mapState(store.getState()));

    useEffect(() => {
      const subscription = observeStore(store, mapState, (newState) => setState({ ...newState }));
      return () => subscription.unsubscribe();
    }, []);

    return <ComponentToConnect {...props} {...state} />;
  };
}
