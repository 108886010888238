import { Fragment, h } from 'preact';
import { useEffect, useMemo, useRef } from 'preact/hooks';
import { usePlayerContext } from '../../../ui/hooks';
import { ERROR_STYLES as styles } from './styles';
import { TVButton } from '../common/tvbutton';
import { PLAYER_CLOSE } from '../../../store/types';
import { withBreakPoints } from '../../../ui/hoc';
import { ORANGEOTT } from '../../../core/media/types';

function TVError({
  code,
  message,
  canRetry,
  showCode,
  orangeTitle,
  orangeMessage,
  orangeUserCode,
  extraLargeTv,
  platform
}) {
  const player = usePlayerContext();
  const retryBtn = useRef(null);
  const backBtn = useRef(null);
  const mainDiv = useRef(null);

  useEffect(() => {
    if (backBtn.current) return backBtn.current.base.focus();
    if (retryBtn.current) return retryBtn.current.base.focus();
    return mainDiv.current.focus();
  }, []);

  const btnStyle = useMemo(
    () => ({
      ...styles.errorButton.default,
      ...(extraLargeTv ? styles.errorButton.extraLarge : styles.errorButton.large)
    }),
    [extraLargeTv]
  );

  return (
    <div ref={mainDiv} className="ftv-magneto--error">

      <div
        role="status"
        tabIndex="0" /* eslint-disable-line */ ariaLabel={`${message.replace(/(<([^>]+)>)/gi,'')}${showCode ? ` (erreur ${code})` : ''}`}
        style={styles.wrapper}
      >
        {(platform === ORANGEOTT) ? (
          <Fragment>
            <div style={{ fontSize: extraLargeTv ? '38px' : ' 32px' }}>
              {orangeTitle}
            </div>
            <span
              ariaHidden
              style={{ ...styles.errorMessage, fontSize: extraLargeTv ? '32px' : ' 24px' }}
          dangerouslySetInnerHTML={{ __html: orangeMessage.replace(/\bH\d{2}-\dX\d{2}\b|\bH\d{2}-\d{2}X\d\b/g, `${orangeUserCode}-${code}`)} /* eslint-disable-line */}
            />
          </Fragment>
        ) : (
          <span
            ariaHidden
            style={{ ...styles.errorMessage, fontSize: extraLargeTv ? '32px' : ' 24px' }}
          >
            {message}
            <br />
            {'Code d\'erreur : '}
            {code}
          </span>
        )}
      </div>

      <div style={styles.errorButtons}>
        <TVButton
          id="btn-error-back"
          ref={backBtn}
          size={extraLargeTv ? 72 : 48}
          style={btnStyle}
          label="retour"
          onClick={() => player.events$.next(PLAYER_CLOSE)}
        />

        {canRetry && (
        <TVButton
          id="btn-error-retry"
          ref={retryBtn}
          size={extraLargeTv ? 72 : 48}
          style={btnStyle}
          label="réessayer"
          onClick={() => player.retry()}
        />
        )}
      </div>
    </div>
  );
}

export default withBreakPoints(TVError);
