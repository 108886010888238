import { h } from 'preact';
import { TIME_DISPLAY_WRAPPER_STYLE } from './styles';
import { secondsToHHMMSS } from '../../../utils';
import { useStyles } from '../../../hooks';

function TimeDisplay({
  time,
  small,
  hidden,
  last,
  displayFn = secondsToHHMMSS,
  style = {},
  ariaHidden = 'true'
}) {
  const setStyles = useStyles();
  return hidden ? null : (
    <div
      className="ftv-magneto-time"
      style={{
        ...setStyles(TIME_DISPLAY_WRAPPER_STYLE),
        ...{
          fontSize: small ? 14 : 20,
          width: small ? 62 : 87,
          textAlign: last ? 'right' : 'left'
        },
        ...style
      }}
      ariaHidden={ariaHidden}
    >
      {displayFn(time)}
    </div>
  );
}

export default TimeDisplay;
