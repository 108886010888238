import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import TVLabel from '../common/label/TVLabel';
import { ADPAUSE_SCREEN, ADPAUSE_SCREEN_AD_LABEL, ADPAUSE_SCREEN_BACKGROUND, ADPAUSE_SCREEN_CONTROLS_WRAPPER, ADPAUSE_SCREEN_RESUME_BUTTON_WRAPPER } from '../../../ui/components/wrappers/styles';
import { connect, withBreakPoints } from '../../../ui/hoc';
import { useStyles } from '../../../ui/hooks';
import { DIALOG_LAYER } from '../../../ui/theme/constants';
import { MAIN_GRADIENT_TV } from '../../../ui/theme/colors';
import TVPauserollResume from '../pauserollresume';

function TVAdPauseOverlay({ pauseRollIsDisplayable, pauseRollContent }) {
  const setStyles = useStyles();

  const [show, setShow] = useState(false);
  const resumeButtonRef = useRef();

  useEffect(() => {
    if (show) resumeButtonRef.current?.children[0]?.focus();
  }, [show, resumeButtonRef.current]);

  useEffect(
    () => setTimeout(() => setShow(pauseRollIsDisplayable), 0),
    [pauseRollIsDisplayable]
  );

  return pauseRollIsDisplayable && (
    <div
      className="ftv-in-customizable adpause"
      style={{
        zIndex: DIALOG_LAYER,
        ...ADPAUSE_SCREEN,
        width: show ? '100%' : '0px',
        height: show ? '100%' : '0px',
        opacity: Number(show),
        backgroundColor: show ? 'black' : 'transparent',
        transition: show
          ? 'background-color 0.5s ease-in, opacity 0.5s ease-in'
          : ''
      }}
    >
      <div
        style={{
          ...ADPAUSE_SCREEN_BACKGROUND,
          display: 'block',
          opacity: Number(show),
          transition: show
            ? 'background-image 2s ease-in, opacity 2s ease-in'
            : '',
          backgroundImage: pauseRollContent && show ? `url("${pauseRollContent.image}")` : 'none',
          cursor: 'pointer'
        }}
        role="presentation"
      >
        <div style={{ backgroundImage: MAIN_GRADIENT_TV, width: '100%', height: '100%' }}>
          <div style={ADPAUSE_SCREEN_CONTROLS_WRAPPER}>

            <TVLabel
              message="Publicité"
              style={setStyles(ADPAUSE_SCREEN_AD_LABEL)}
            />

            <div style={setStyles(ADPAUSE_SCREEN_RESUME_BUTTON_WRAPPER)}>
              <TVPauserollResume innerRef={resumeButtonRef} disableFocus={!show} />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

const selector = (state) => {
  const {
    ad: {
      pauseRollIsDisplayable,
      pauseRollContent
    }
  } = state;
  return {
    pauseRollIsDisplayable,
    pauseRollContent
  };
};

export default withBreakPoints(connect(selector)(TVAdPauseOverlay));
