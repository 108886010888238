import { PRIMARY_FILL_COLOR, SECONDARY_FILL_COLOR, SLIDER_OFF_COLOR, SLIDER_TIMELINE_DEFAULT_COLOR } from '../../../theme/colors';
import { TIMELINE_LAYER } from '../../../theme/constants';

export function getStyles({
  vertical, trackSize, strokeSize, cursorSize
}) {
  let orientedStyle;
  const commonTrack = {
    display: 'flex',
    borderRadius: `${strokeSize / 2}px`
  };
  const cursorBoundary = (cursorSize - strokeSize) / 2;
  if (vertical) {
    orientedStyle = {
      track: {
        flexDirection: 'column',
        justifyContent: 'flex-end',
        height: trackSize,
        margin: '0 auto',
        background: SLIDER_OFF_COLOR,
        transition: 'background .2s ease-in-out',
        zIndex: TIMELINE_LAYER
      },
      primaryTrack: {
        bottom: 0,
        background: SECONDARY_FILL_COLOR,
        transition: 'background .2s ease-in-out'
      },
      cursor: {
        default: {
          marginBottom: `${-(cursorSize / 2) + (strokeSize / 2)}px`,
          marginLeft: `${-(cursorSize / 2) + (strokeSize / 2)}px`,
          order: -1,
          background: SECONDARY_FILL_COLOR,
          transition: 'background .2s ease-in-out'
        },
        'largeTv+extraLargeTv': {
          background: PRIMARY_FILL_COLOR
        }
      }
    };
    commonTrack.width = `${strokeSize}px`;
  } else {
    orientedStyle = {
      track: {
        width: trackSize,
        backgroundColor: SLIDER_TIMELINE_DEFAULT_COLOR
      },
      cursor: {
        default: {
          marginLeft: `-${(cursorSize / 2)}px`,
          marginTop: `-${cursorBoundary}px`,
          backgroundColor: SECONDARY_FILL_COLOR
        },
        'largeTv+extraLargeTv': {
          backgroundColor: PRIMARY_FILL_COLOR
        }
      }
    };
    commonTrack.height = `${strokeSize}px`;
  }

  return {
    outerTrack: {
      cursor: 'pointer',
      padding: `${cursorSize / 2}px 0` /* make clickable zone bigger */,
      zIndex: TIMELINE_LAYER
    },
    track: {
      position: 'relative',
      ...orientedStyle.track,
      ...commonTrack
    },
    primaryTrack: {
      backgroundColor: SECONDARY_FILL_COLOR,
      flexShrink: 0,
      // zIndex in order to put the current timeline over the live timeline
      zIndex: TIMELINE_LAYER,
      ...orientedStyle.primaryTrack,
      ...commonTrack
    },
    cursor: {
      ...orientedStyle.cursor,
      default: {
        zIndex: TIMELINE_LAYER + 1,
        width: `${cursorSize}px`,
        height: `${cursorSize}px`,
        borderRadius: `${cursorSize / 2}px`,
        cursor: 'pointer',
        outline: 'none',
        flexShrink: 0,
        ...orientedStyle.cursor.default
      }
    },
    secondaryTrack: {
      position: 'absolute',
      borderRadius: strokeSize / 2,
      height: strokeSize
    },
    liveCursor: {
      position: 'absolute',
      border: '4px solid red',
      padding: `${Math.floor((cursorSize - strokeSize) / 3)}px`,
      backgroundColor: 'white',
      zIndex: TIMELINE_LAYER + 1,
      borderRadius: '50%',
      cursor: 'default',
      transform: 'translate(-50%, -28%)'
    },
    timeshiftingCursor: {
      position: 'absolute',
      backgroundColor: 'white',
      marginTop: '-6px',
      height: '20px',
      width: '5px',
      zIndex: TIMELINE_LAYER + 1,
      borderRadius: '50%',
      cursor: 'default'
    },
    expectedCursor: {
      default: {
        width: '6px',
        position: 'absolute',
        height: '20px',
        transform: 'translateY(-27%)',
        backgroundColor: 'red',
        zIndex: TIMELINE_LAYER,
        borderRadius: '5px',
        cursor: 'pointer'
      },
      extraSmall: {
        width: '4px',
        height: '14px',
        transform: 'translateY(-25%)'
      }
    }
  };
}
