import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function VolumeLowIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="mask0_volume_low" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="5" width="15" height="16">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.82427 8.82243C5.9284 8.82243 6.02994 8.78992 6.1147 8.72943L11.2096 5.09388C11.5405 4.85771 12 5.0943 12 5.50088V19.5027C12 19.9093 11.5405 20.1459 11.2096 19.9097L6.1147 16.2741C6.02993 16.2136 5.9284 16.1811 5.82427 16.1811H3C2.44771 16.1811 2 15.7334 2 15.1811V9.82244C2 9.27015 2.44771 8.82243 3 8.82243H5.82427ZM14.25 9.5C13.8358 9.5 13.5 9.83579 13.5 10.25C13.5 10.6642 13.8358 11 14.25 11C14.9403 11 15.5 11.5596 15.5 12.25C15.5 12.9404 14.9403 13.5 14.25 13.5C13.8358 13.5 13.5 13.8358 13.5 14.25C13.5 14.6642 13.8358 15 14.25 15C15.7688 15 17 13.7688 17 12.25C17 10.7312 15.7688 9.5 14.25 9.5Z" fill="black" />
      </mask>
      <g mask="url(#mask0_volume_low)">
        <rect width="24" height="24" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default VolumeLowIcon;
