import { h } from 'preact';
import { connect } from './rxjs-connect';

export const breakpointSelector = ({
  systemInfo: { isMobile: mobile, isIOS: ios, isTablet: tablet },
  ui: {
    isExtraLargeTvScreen: extraLargeTv,
    isExtraLargeScreen: extraLarge,
    isLargeTvScreen: largeTv,
    isLargeScreen: large,
    isMediumScreen: medium,
    isSmallScreen: small,
    isExtraSmallScreen: extraSmall,
    width,
    height
  }
}) => ({
  mobile, ios, large, largeTv, medium, small, extraSmall, width, height, tablet, extraLarge, extraLargeTv
});

export function withBreakPoints(Comp) {
  function ResponsiveComp({
    extraLargeTv,
    extraLarge,
    largeTv,
    large,
    medium,
    small,
    extraSmall,
    mobile,
    tablet,
    ios,
    width,
    height,
    ...props
  }) {
    return (
      <Comp
        extraLarge={extraLarge}
        extraLargeTv={extraLargeTv}
        large={large}
        largeTv={largeTv}
        medium={medium}
        small={small}
        extraSmall={extraSmall}
        mobile={mobile}
        tablet={tablet}
        ios={ios}
        width={width}
        height={height}
        {...props}
      />
    );
  }

  return connect(breakpointSelector)(ResponsiveComp);
}
