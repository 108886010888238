import { h } from 'preact';
import { connect } from '../../../hoc';
import { Player } from '../../../index';
import { style, wrapperStyle } from './styles';

const className = 'ftv-magneto--ui';

/**
 * Base layer component for the player.
 * @param clickThrough when set to true enables clicking
 * on `special` ads (i.e Vpaid) that are covered by this layer.
 */
function Layer({ children, clickThrough }) {
  const pointerEvents = clickThrough ? 'none' : 'auto';

  return (
    <div className={className} style={{ ...style, pointerEvents }}>
      <div style={{ ...wrapperStyle }}>
        {children}
      </div>
    </div>
  );
}

const selector = ({ media: { isAd, isVpaid } }) => ({ isAd, clickThrough: isVpaid });
const ConnectedLayer = connect(selector)(Layer);

function LayerTop({ children, player }) {
  return (
    <Player.Provider value={player}>
      <ConnectedLayer>
        {children}
      </ConnectedLayer>
    </Player.Provider>
  );
}

export default LayerTop;
