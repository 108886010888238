import { h } from 'preact';
import { PRIMARY_FILL_COLOR } from '../../../../ui/theme/colors';
import { Icon } from '../../../../ui/components/common/svg/icons';

function TVLabel({
  message,
  style = {},
  focusable = false,
  opaque = false,
  icon,
  name = 'label'
}) {
  const LABEL_STYLE = {
    content: {
      padding: '8px 12px',
      height: 40,
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      display: 'inline-block',
      fontFamily: 'Brown',
      borderRadius: 4,
      color: 'black',
      lineHeight: '20px',
      backgroundColor: opaque ? 'transparent' : PRIMARY_FILL_COLOR
    },
    icon: {
      size: 32
    },
    gap: 24
  };

  return (
    <div
      role="presentation"
      name={name}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={focusable ? 0 : -1}
      style={{ ...LABEL_STYLE.content, ...style }}
    >
      {icon
      && (
      <span style={{ marginRight: LABEL_STYLE.gap }}>
        <Icon name={icon} size={LABEL_STYLE.icon} />
      </span>
      )}
      <span>{message}</span>
    </div>
  );
}

export default TVLabel;
