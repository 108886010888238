/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvBackLiveIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M68.1092 25.4095C68.8363 25.9128 68.8363 26.994 68.1092 27.4973L61.523 32.0567C60.6882 32.6346 59.5521 32.0328 59.5521 31.0128V28.1502H43.2232C42.0416 28.1502 41.99 28.1592 41.3854 28.2644L41.3208 28.2757C32.2371 29.8532 26.3856 35.7687 26.3856 45.0232C26.3856 55.4186 34.072 61.8605 44.8018 61.8605H59.0692C59.6662 61.8605 60.7489 62.4477 60.7489 63.7429C60.7489 65.0381 59.6561 65.625 59.0692 65.625H44.8018C31.926 65.625 22.5 57.4977 22.5 45.0232C22.5 33.6847 30.0813 26.0764 41.3208 24.4609C42.0725 24.3531 42.9185 24.3559 43.1768 24.3568L43.2232 24.3569H59.5521V21.894C59.5521 20.874 60.6882 20.2722 61.523 20.8501L68.1092 25.4095ZM45 52.5C49.1421 52.5 52.5 49.1421 52.5 45C52.5 40.8579 49.1421 37.5 45 37.5C40.8579 37.5 37.5 40.8579 37.5 45C37.5 49.1421 40.8579 52.5 45 52.5Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvBackLiveIcon;
