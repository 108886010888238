export const LABEL_WRAPPER_STYLE = {
  default: {
    fontFamily: 'Brown',
    fontWeight: 700,
    color: 'black',
    fontSize: 14,
    borderRadius: 4,
    height: 22,
    width: 'fit-content',
    verticalAlign: 'middle',
    alignItems: 'center',
    lineHeight: 2,
    display: 'flex'
  },
  'extraSmall+small': {
    fontSize: 12,
    height: 20
  },
  medium: {
    fontSize: 14,
    height: 24
  },
  'large+extraLarge': {
    fontSize: 18,
    height: 32
  },
  'largeTv+extraLargeTv': {
    height: 40,
    padding: '8px 12px',
    fontSize: 24,
    fontWeight: 400
  }
};

export const MARGIN_WRAPPER_STYLE = {
  default: {
    margin: '0 8px',
    display: 'flex',
    alignItems: 'center'
  },
  'largeTv+extraLargeTv': {
    margin: '0px'
  }
};
