const MAP = {
  'france.tv': { offer: 'francetv', product: 'francetv' },
  'francetelevisions.fr': { offer: 'etvous', product: 'francetv' },
  region: { offer: 'francetv', product: 'regions' },
  francebleu: { offer: 'francetv', product: 'regions' },
  la1ere: { offer: 'francetv', product: 'la1ere' },
  outremer: { offer: 'francetv', product: 'la1ere' },
  lumni: { offer: 'jeunesse', product: 'lumni' },
  franceinfo: { offer: 'info', product: 'ftvi' } // This is last to check for region and outremer first, and avoid domain conflict
};

export function mapDomain(domain) {
  const key = Object.keys(MAP).find((match) => domain.includes(match));

  return MAP[key || 'france.tv'];
}
