export const MINI_QUALITY = 'miniQuality';
export const ECO_QUALITY = 'ecoQuality';
export const STANDARD_QUALITY = 'standardQuality';
export const SUPERIOR_QUALITY = 'superiorQuality';
export const AUTOMATIC_QUALITY = 'automaticQuality';
export const LABEL_ECO_QUALITY = 'éco';

export const QUALITY_MAP = new Map()
  .set(MINI_QUALITY, {
    label: 'mini',
    getDescription: () => 'qualité minimale pour réduire l\'utilisation des données'
  })
  .set(ECO_QUALITY, {
    label: LABEL_ECO_QUALITY,
    getDescription: () => 'mode équilibré pour garder une qualité satisfaisante et réduire l\'utilisation des données',
    icon: 'eco',
    iconActive: 'eco-activated'
  })
  .set(STANDARD_QUALITY, {
    label: 'standard',
    getDescription: (useAlt) => (useAlt ? 'meilleure qualité disponible' : 'qualité intermédiaire'),
    icon: 'sd'
  })
  .set(SUPERIOR_QUALITY, {
    label: 'supérieure',
    getDescription: () => 'meilleure qualité disponible',
    icon: 'hd'
  });
