/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvTracksIconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42 83.25C64.7817 83.25 83.25 64.7817 83.25 42C83.25 19.2183 64.7817 0.75 42 0.75C19.2183 0.75 0.75 19.2183 0.75 42C0.75 64.7817 19.2183 83.25 42 83.25ZM17 25.75V55.75C17 57.1307 18.1193 58.25 19.5 58.25H52L59.2411 65.4911C60.0286 66.2786 61.375 65.7209 61.375 64.6072V58.25H64.5C65.8807 58.25 67 57.1307 67 55.75V25.75C67 24.3693 65.8807 23.25 64.5 23.25H19.5C18.1193 23.25 17 24.3693 17 25.75ZM51.375 47C50.3395 47 49.5 47.8395 49.5 48.875C49.5 49.9105 50.3395 50.75 51.375 50.75H57.625C58.6605 50.75 59.5 49.9105 59.5 48.875C59.5 47.8395 58.6605 47 57.625 47H51.375ZM23.25 42.625C23.25 41.5895 24.0895 40.75 25.125 40.75H31.375C32.4105 40.75 33.25 41.5895 33.25 42.625C33.25 43.6605 32.4105 44.5 31.375 44.5H25.125C24.0895 44.5 23.25 43.6605 23.25 42.625ZM25.125 47C24.0895 47 23.25 47.8395 23.25 48.875C23.25 49.9105 24.0895 50.75 25.125 50.75H43.875C44.9105 50.75 45.75 49.9105 45.75 48.875C45.75 47.8395 44.9105 47 43.875 47H25.125ZM37 42.625C37 41.5895 37.8395 40.75 38.875 40.75H57.625C58.6605 40.75 59.5 41.5895 59.5 42.625C59.5 43.6605 58.6605 44.5 57.625 44.5H38.875C37.8395 44.5 37 43.6605 37 42.625Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvTracksIconActive;
