import { h } from 'preact';
import { useState } from 'preact/hooks';
import { withBreakPoints } from '../../../hoc';
import { LABELED_BUTTON_STYLE, LABELED_ICON_WRAPPER_STYLE } from './styles';
import '../../../theme/styles/buttons.css';
import { useStyles } from '../../../hooks';
import { Icon } from '../svg/icons';

function LabeledButton({
  label,
  onClick,
  icon,
  iconSize = 44,
  finalRef,
  customStyle = {},
  customActiveStyle = {},
  customHoverStyle = {},
  iconCustomStyle = {},
  disableFocus = false,
  id
}) {
  const setStyles = useStyles();
  const [isActive, setIsActive] = useState(false);
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      id={id}
      ref={finalRef}
      className="ftv-magneto--labeled-button ftv-magneto--btn"
      style={{
        ...setStyles(LABELED_BUTTON_STYLE),
        ...customStyle,
        ...setStyles(isActive ? customActiveStyle : {}),
        ...setStyles(isHover ? customHoverStyle : {})
      }}
      onClick={onClick}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      role="button"
      tabIndex={disableFocus ? '-1' : '0'}
      onKeyDown={(e) => (e.keyCode === 13 || e.keyCode === 32 /* Enter or Spacebar */) && onClick(e)}
      ariaLabel={label}
    >
      {icon && (
      <div style={{ ...LABELED_ICON_WRAPPER_STYLE, ...iconCustomStyle }}>
        <Icon name={icon} height={iconSize} width={iconSize} />
      </div>
      )}
      <div>{label}</div>
    </div>
  );
}

export default withBreakPoints(LabeledButton);
