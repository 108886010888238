import { Subject, NEVER } from 'rxjs';
import { switchMap, take, catchError, withLatestFrom } from 'rxjs/operators';
import { embed } from '../../configuration';
import { requestAPI } from '../../utils/index';
import error from '../../error';
import { EMBED_ERROR_MESSAGE } from '../../error/types';
import { EMBED_BANNED_OPTIONS } from './types';
import { Disposable } from '..';

export default class EmbedController extends Disposable {
  constructor({ medias$, playerConfig$, origin }) {
    super();
    this.click$ = new Subject();
    this.embedKey$ = new Subject();
    this.errors$ = new Subject();
    this.siteId = EmbedController.resolveSiteID(origin);

    /* Fetching embed hash when the user click for the first time on embed button */
    this.fetch$ = this.createFetchStream(medias$, playerConfig$);
    this.fetch$.subscribe(this.embedKey$);
  }

  static resolveSiteID(origin) {
    return (origin === 'franceinfo') ? '13' : '7';
  }

  createFetchStream(medias$, playerConfig$) {
    return this.click$.pipe(
      withLatestFrom(medias$, playerConfig$),
      switchMap(([, media, playerOptions]) => this.getEmbedHash(media, playerOptions).pipe(
        take(1),
        catchError(() => {
          this.errors$.next({ error: error({ message: EMBED_ERROR_MESSAGE }) });
          return NEVER;
        })
      ))
    );
  }

  getEmbedHash({ meta }, options) {
    const playerOptions = { ...options };
    /* Remove options that make sense only in the context of the origin site */
    EMBED_BANNED_OPTIONS.forEach((option) => delete playerOptions[option]);

    const body = {
      video_id: meta.id,
      site_id: this.siteId,
      url_source: document.location.href,
      player_options: { ...playerOptions, autostart: false }
    };

    return requestAPI({
      url: embed.server,
      selector: ({ json }) => json.key,
      method: 'POST',
      body
    });
  }
}
