import { UNKNOWN } from './types';

// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const BROWSERS_REGEX = [{
  name: 'firefox',
  regexp: /^(?!.*SeaMonkey*).*Firefox\/([\d]+)/i
}, {
  name: 'samsung',
  regexp: /.*SamsungBrowser\/(\d+)\.(\d+)/i
}, {
  name: 'chrome',
  regexp: /^(?!.*(?:Chromium|Edg|OPR)\/\d+).*(?:Chrome|CriOS)\/([\d]+)/i
}, {
  name: 'orange',
  regexp: /SOPOpenBrowser/
},
{
  name: 'safari',
  regexp: /^(?!.*(?:Chromium|Chrome)\/\d+).*Version\/([\d]+).*Safari\/[\d.]+/i
}, {
  name: 'opera',
  regexp: /(?:OPR|Opera)\/([\d]+)/i
}, {
  name: 'ie',
  regexp: /MSIE\s+([\d]+)/i
}, {
  name: 'ie', // 11
  regexp: /Trident\/7.0;.+rv:([\d]+)/i
}, {
  name: 'edge',
  regexp: /Edg\/([\d]+)/i
}];

export const OS_REGEX = [{
  name: 'windows',
  regexp: /.+\(.*Windows\sNT\s([\d.]+).*\)/i
}, {
  name: 'tizen', // Smart TV
  regexp: /.+\(.*Tizen\s([\d.]+).*\)/i
}, {
  name: 'linux',
  regexp: /.+\(.*Linux(?!.*Android)[a-z0-9.,:;_ ]+\)/i
}, {
  name: 'macos',
  regexp: /.+\(.+Mac\sOS\sX\s([\d._]+).*\)/i
}, {
  name: 'android',
  regexp: /.+\(.+Android\s([\d.]+);[/a-z0-9\s-;]+\)/i
}, {
  name: 'ios',
  regexp: /.+\((?:iPad|iPhone|iPod(?:\stouch)?);[a-z\s]+([\d_]+)[a-z\s]+\)/i
}];

export const TABLET_REGEX = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
export const MOBILE_REGEX = /Mobile|iP(hone|od|ad)| Mobile Safari|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

export function parseUserAgent(regs) {
  return (navigator) => (identifier) => {
    const found = regs.find(({ regexp }) => navigator.userAgent.search(regexp) > -1);
    const version = found && navigator.userAgent.match(found.regexp)[1];

    return {
      [identifier]: found ? found.name : UNKNOWN,
      ...(version ? { [`${identifier}Version`]: version } : {})
    };
  };
}

export function getFullscreenSupport(doc) {
  return 'fullscreenEnabled' in doc
    || 'webkitFullscreenEnabled' in doc
    || 'mozFullScreenEnabled' in doc
    || 'msFullscreenEnabled' in doc;
}

export function matchUserAgent(regs) {
  return (navigator) => regs.some((regex) => navigator.userAgent.match(regex));
}

export function hasMSE(window) {
  return Boolean(window.MediaSource || window.WebKitMediaSource);
}

export function checkPipSupport(doc) {
  const video = doc.createElement('video');
  const chromeSupport = Boolean(doc.pictureInPictureEnabled);
  const safariSupport = (video.webkitSupportsPresentationMode && typeof video.webkitSetPresentationMode === 'function');
  return chromeSupport || safariSupport;
}
export function checkPipRestriction(isIOS, browser) {
  return isIOS && (browser === 'chrome' || browser === 'safari');
}

export function getTypeOfDevice(userAgent) {
  /*
  The ipad case is tricky because the user agent of Safari on iPadOS is the same as Safari on macOS.
  So in order to distinguish them we can detect the existence of 'TouchEvent'
*/
  if (userAgent.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent');
      return 'tablet';
    // eslint-disable-next-line
    } catch (e) {}
  }
  if (TABLET_REGEX.test(userAgent)) return 'tablet';
  if (MOBILE_REGEX.test(userAgent)) return 'mobile';
  return null;
}

const systemInfo = {
  hasMSE: hasMSE(window),
  isIOS: matchUserAgent([/iPhone/i, /iPod/i, /iPad/i])(navigator),
  isMobile: getTypeOfDevice(navigator.userAgent) === 'mobile',
  isTablet: getTypeOfDevice(navigator.userAgent) === 'tablet',
  ...parseUserAgent(BROWSERS_REGEX)(navigator)('browser'),
  ...parseUserAgent(OS_REGEX)(navigator)('os'),
  pipSupported: checkPipSupport(document),
  pipRestriction: checkPipRestriction(matchUserAgent([/iPhone/i, /iPod/i, /iPad/i])(navigator), matchUserAgent([/safari/i, /chrome/i])(navigator)),
  fullscreenSupported: getFullscreenSupport(document),
  isMac: matchUserAgent([/Macintosh/i])(navigator),
  platform: null
};

export default systemInfo;
