import { ZAPPING_INIT, ZAPPING_CHANGED, ZAPPING_VISIBLE, ZAPPING_TAB_CHANGED, ZAPPING_META_CHANGED } from '../types';

const initialState = {
  list: [],
  current: { tabIndex: -1, activeChannel: -1, lastTabIndex: -1, activeChannelId: '' },
  visible: false,
  metaOpened: false
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ZAPPING_INIT: {
      const { list } = payload;
      return ({ ...state, list });
    }
    case ZAPPING_CHANGED: {
      const { current } = payload;
      return ({ ...state, current });
    }
    case ZAPPING_VISIBLE: {
      const { visible } = payload;
      return ({ ...state, visible });
    }
    case ZAPPING_TAB_CHANGED: {
      const { tabIndex } = payload;
      return ({ ...state, current: { ...state.current, tabIndex, lastTabIndex: state.current.tabIndex } });
    }
    case ZAPPING_META_CHANGED: {
      const { metaOpened } = payload;
      return ({ ...state, metaOpened });
    }
    default: {
      return state;
    }
  }
};
