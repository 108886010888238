import { h } from 'preact';
import { Label } from '../common';
import { SECONDARY_FILL_COLOR, VIEWER_FILL_COLOR } from '../../theme/colors';
import { formatNumberOfViewer } from '../../utils/number';
import { withBreakPoints } from '../../hoc';
import { Icon } from '../common/svg/icons';

/* Get svg size according to the screen and orientation */

function ViewerLiveInfo({ connected, size }) {
  return (
    <Label
      message={`${formatNumberOfViewer(connected)} spectateurs`}
      style={{ backgroundColor: VIEWER_FILL_COLOR }}
      styleText={{ color: SECONDARY_FILL_COLOR }}
      icon={<Icon name="eye-live" height={size} width={size} color={SECONDARY_FILL_COLOR} />}
    />
  );
}

export default withBreakPoints(ViewerLiveInfo);
