import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function PauseIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >
      {/* eslint-disable-next-line react/no-unknown-property */}
      <mask id="mask0_pause" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="44" height="44">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46ZM17.3333 31.6667C17.3333 32.5871 18.0795 33.3333 19 33.3333C19.9205 33.3333 20.6667 32.5871 20.6667 31.6667V16.3333C20.6667 15.4129 19.9205 14.6667 19 14.6667C18.0795 14.6667 17.3333 15.4129 17.3333 16.3333V31.6667ZM27.3333 31.6667C27.3333 32.5871 28.0795 33.3333 29 33.3333C29.9205 33.3333 30.6667 32.5871 30.6667 31.6667V16.3333C30.6667 15.4129 29.9205 14.6667 29 14.6667C28.0795 14.6667 27.3333 15.4129 27.3333 16.3333V31.6667Z" fill="black" />
      </mask>
      <g mask="url(#mask0_pause)">
        <rect width="48" height="48" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default PauseIcon;
