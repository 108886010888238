import { h } from 'preact';

import { TV_SPINNER_WRAPPER_STYLE, TV_LOADER_IMAGE_PATH } from './styles';
import { connect } from '../../../ui/hoc';

function Spinner({ waiting, isIntro }) {
  if (!waiting && !isIntro) return null;

  return (
    <div style={TV_SPINNER_WRAPPER_STYLE}>
      <img
        width="124px"
        height="auto"
        src={TV_LOADER_IMAGE_PATH}
        alt=""
        style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translateY(-50%) translateX(-50%)' }}
      />
    </div>
  );
}

const selector = ({ ui: { waiting, isIntro } }) => ({
  waiting,
  isIntro
});

export default connect(selector)(Spinner);
