import { h } from 'preact';
import { svgDefaultDescriptor } from '../../../../utils';

/*   { duration, fn = () => { }, finish = () => { } } */
function SVGCreator({
  paths,
  viewBox = paths.viewBox || '0 0 40 40',
  frame,
  pathStyle = paths.style,
  width = 40,
  height = 40,
  ...props
}) {
  return (
    <svg
      viewBox={viewBox}
      width={width}
      height={height}
      {...svgDefaultDescriptor}
      {...props}
    >
      {
      (Number.isInteger(frame) ? [paths[frame]] : paths).map(({ path, color }) => (/* eslint-disable-next-line react/no-unknown-property */
        <path fill-rule="evenodd" fill={color} d={path} {...pathStyle} />
      ))
    }
    </svg>
  );
}

export default SVGCreator;
