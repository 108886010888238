export const PLAYBACK_DEFAULT_WRAPPER_STYLE = {
  display: 'flex'
};

export const PLAYBACK_CENTERED_WRAPPER_STYLE = {
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: 1,
  transition: 'opacity .3s ease-in-out'
};

export const PLAYBACK_HIDDEN = {
  opacity: 0,
  pointerEvents: 'none'
};
