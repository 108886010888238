import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { connect, withBreakPoints } from '../../hoc';
import { Button, Label } from '../common';
import { MAIN_GRADIENT_AD } from '../../theme/colors';
import {
  ADPAUSE_SCREEN,
  ADPAUSE_SCREEN_AD_LABEL,
  ADPAUSE_SCREEN_BACKGROUND,
  ADPAUSE_SCREEN_CLOSE_BUTTON_WRAPPER,
  ADPAUSE_SCREEN_CONTROLS_WRAPPER,
  ADPAUSE_SCREEN_FULLSCREEN_BUTTON_WRAPPER,
  ADPAUSE_SCREEN_RESUME_BUTTON_WRAPPER
} from './styles';
import { usePlayerContext, useStyles } from '../../hooks';
import PauserollResume from '../controls/pauserollresume';
import { DIALOG_LAYER } from '../../theme/constants';
import { USER_CLICK, USER_CLOSED_PAUSEROLL, USER_FULLSCREEN } from '../../../types';
import { EXP_CONTROL_ICON_STYLES } from '../controls/exp/styles';

function AdPauseOverlay({ pauseRollIsDisplayable, pauseRollContent, isFullscreen, isTv }) {
  const player = usePlayerContext();
  const setStyles = useStyles();

  const resumeButtonRef = useRef();

  const { size: iconSize } = setStyles(EXP_CONTROL_ICON_STYLES);

  useEffect(() => {
    if (pauseRollIsDisplayable) resumeButtonRef.current.children[0].focus();
  }, [pauseRollIsDisplayable, resumeButtonRef.current]);

  return (
    <div
      className="ftv-in-customizable adpause"
      style={{
        zIndex: DIALOG_LAYER,
        ...ADPAUSE_SCREEN,
        width: pauseRollIsDisplayable ? '100%' : '0px',
        height: pauseRollIsDisplayable ? '100%' : '0px',
        opacity: Number(pauseRollIsDisplayable),
        backgroundColor: pauseRollIsDisplayable ? 'black' : 'transparent',
        transition: pauseRollIsDisplayable
          ? 'background-color 0.5s ease-in, opacity 0.5s ease-in'
          : ''
      }}
    >
      <div
        style={{
          ...ADPAUSE_SCREEN_BACKGROUND,
          opacity: Number(pauseRollIsDisplayable),
          transition: pauseRollIsDisplayable
            ? 'background-image 2s ease-in, opacity 2s ease-in'
            : '',
          backgroundImage: pauseRollContent && pauseRollIsDisplayable ? `url("${pauseRollContent.image}")` : 'none',
          cursor: 'pointer'
        }}
        role="presentation"
      >
        <div style={{ backgroundImage: MAIN_GRADIENT_AD, width: '100%' }}>
          <div style={ADPAUSE_SCREEN_CONTROLS_WRAPPER}>
            <Label
              message="Publicité"
              style={setStyles(ADPAUSE_SCREEN_AD_LABEL)}
            />

            <div style={setStyles(ADPAUSE_SCREEN_CLOSE_BUTTON_WRAPPER)}>
              <Button
                hidden={isTv}
                type="icon"
                openingMode="click"
                label="fermer la publicité"
                ariaLabel="Fermer la publicité"
                forceLabel
                disableFocus={!pauseRollIsDisplayable}
                last
                zone="top"
                icon="close"
                name="btn-close-adpause"
                size={iconSize}
                ariaHidden={false}
                onClick={() => {
                  player.userEvents$.next({ action: USER_CLOSED_PAUSEROLL });
                }}
              />
            </div>

            <div style={setStyles(ADPAUSE_SCREEN_RESUME_BUTTON_WRAPPER)}>
              <PauserollResume innerRef={resumeButtonRef} disableFocus={!pauseRollIsDisplayable} />
            </div>
            <div style={setStyles(ADPAUSE_SCREEN_FULLSCREEN_BUTTON_WRAPPER)}>
              <Button
                hidden={isTv}
                label={`${isFullscreen ? 'quitter le ' : ''}plein écran (f)`}
                ariaLabel={isFullscreen ? 'quitter le mode plein écran' : 'plein écran'}
                ariaHidden="false"
                onClick={() => {
                  player.userEvents$.next({
                    action: USER_FULLSCREEN,
                    value: !isFullscreen,
                    source: USER_CLICK
                  });
                  player.fullscreen(!isFullscreen);
                }}
                last
                xs
                name="btn-fullscreen-adpause"
                icon={isFullscreen ? 'fullscreen-on' : 'fullscreen-off'}
                disableFocus={!pauseRollIsDisplayable}
                type="icon"
                size={iconSize}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const selector = (state) => {
  const {
    ad: {
      pauseRollIsDisplayable,
      pauseRollContent
    },
    ui: { isFullscreen },
    media: { isTv }
  } = state;
  return {
    pauseRollIsDisplayable,
    pauseRollContent,
    isFullscreen,
    isTv
  };
};

export default withBreakPoints(connect(selector)(AdPauseOverlay));
