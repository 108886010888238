export const TV_TIMELINE_WRAPPER_STYLE = {
  position: 'absolute',
  left: 0,
  top: -55,
  width: '100%'
};

export const TV_TIME_CURRENT_STYLE = {
  display: 'block',
  position: 'absolute',
  left: 0
};

export const TV_TIME_DURATION_STYLE = {
  display: 'block',
  position: 'absolute',
  right: 0,
  '-webkit-transform': 'translateY(-100%)'
};

export const TV_LIVE_KNOB = {
  backgroundColor: 'red',
  cursor: 'pointer',
  pointerEvents: 'auto'
};
