export default function setAnimationFrameInterval({ check, update, finish }, delay) {
  let canceled = false;

  (function wrappedFn(time = performance.now()) {
    (
      new Promise((res) => {
        window.requestAnimationFrame(() => res(performance.now()));
      })
        .then((timeDelta) => {
          if (canceled) {
            Promise.resolve();
            return;
          }

          let newTime = time;
          if (timeDelta - time > delay) {
            if (check()) {
              Promise.resolve(finish());
              return;
            }
            update();
            newTime = performance.now();
          }
          wrappedFn(newTime);
        }));
  }());

  return ({
    cancelAnimationFrameInterval: () => { canceled = true; }
  });
}
