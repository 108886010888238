import { NEVER, distinctUntilChanged, filter, fromEvent, interval, map, merge, startWith } from 'rxjs';
import GenericRenderer from '../generic';
import { safeTake } from '../../../utils/rx-utils';
import { RENDERER_READY } from '../types';
import RendererHTML from '../html/html';

export default class RendererAudio extends GenericRenderer {
  constructor(media) {
    super(media, null, null, NEVER);
    this.source = RendererAudio.createSource(document);
    this.tagElement = RendererAudio.createAudioElement(document, this.source);

    RendererAudio.createDurationStream(this.tagElement)
      .safeSubscribe(this, this.duration$);

    RendererAudio.createCurrentTimeStream(this.tagElement)
      .safeSubscribe(this, this.currentTime$);
  }

  // eslint-disable-next-line class-methods-use-this
  createErrorStream() {
    return NEVER;
  }

  startLoad() {
    this.tagElement.load();
  }

  static createSource(doc) {
    const source = doc.createElement('source');
    source.type = 'audio/mpeg';

    return source;
  }

  static createAudioElement(doc, sourceEl) {
    const audioEl = doc.createElement('audio');

    audioEl.volume = 0.5;
    audioEl.controls = false;
    audioEl.style.width = '100%';
    audioEl.style.height = '100%';
    audioEl.style.position = 'absolute';
    audioEl.style.backgroundColor = 'black';
    audioEl.crossOrigin = 'anonymous';

    audioEl.appendChild(sourceEl);

    return audioEl;
  }

  attachSource(url) {
    // Set the source URL and type
    this.source.src = url;
  }

  createRendererReadyStream() {
    return fromEvent(this.tagElement, 'loadstart')
      .pipe(
        safeTake(1),
        map(() => RENDERER_READY)
      );
  }

  async init(container, target$) {
    container.appendChild(this.tagElement);

    RendererHTML
      .createContentEventStream(this.tagElement)
      .safeSubscribe(this, this.videoOrAudioEventsIn$);

    this.createRendererReadyStream()
      .safeSubscribe(this, this.state$);

    this.videoOrAudioEventsOut$
      .safeSubscribe(this, target$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('volumechange', () => this.tagElement.muted))
      .safeSubscribe(this, this.muted$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('volumechange', () => this.tagElement.volume))
      .safeSubscribe(this, this.volume$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('ratechange', () => this.tagElement.playbackRate))
      .safeSubscribe(this, this.playbackRate$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('progress', () => RendererHTML.getBuffered(this.tagElement)))
      .safeSubscribe(this, this.buffered$);

    super.init();
  }

  static createDurationStream(tagElement) {
    return fromEvent(tagElement, 'durationchange')
      .pipe(
        filter(() => Number.isFinite(tagElement.duration)),
        map(() => tagElement.duration)
      );
  }

  static createCurrentTimeStream(tagElement) {
    return merge(
      interval(50),
      /* to update currentTime on 'seekend' to fix UI timeline flickering */
      fromEvent(tagElement, 'seeked')
    ).pipe(
      map(() => tagElement.currentTime),
      startWith(0),
      distinctUntilChanged()
    );
  }
}
