export const DEFAULT_STYLE = {
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  iconCenter: {
    animation: 'feedbackAnimation .64s'
  },
  iconRight: {
    animation: 'feedbackAnimation .64s',
    marginLeft: '50%'
  },
  iconLeft: {
    animation: 'feedbackAnimation .64s',
    marginRight: '50%'
  }
};

export const DEFAULT_ICON = {
  width: 120,
  height: 120
};
