import { NEVER, of, merge, fromEvent } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
/**
 * @description Use Navigator NetInfoAPI to retrieve current connection status.
 * NetInfo relies on underlying system layer (WiMAX|GSM|EDGE|LTE) to get efficient information.
 * @NOTE this API is not yet implemented in all browsers.
 * Credits: https://wicg.github.io/netinfo/#dfn-underlying-connection-technology
 */

export default class Network {
  static netInfoAPICompatible() {
    const connection = navigator.connection
      || navigator.mozConnection || navigator.webkitConnection;

    if (!connection) return NEVER;
    return merge(of(connection), fromEvent(connection, 'change').pipe(mapTo(connection)));
  }

  static connectionBandwidth() {
    return Network.netInfoAPICompatible()
      .pipe(map(({ downlink }) => downlink));
  }

  static connectionType() {
    return Network.netInfoAPICompatible()
      .pipe(map(({ effectiveType }) => effectiveType));
  }
}
