/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvRewind4Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM42.3334 46.2514V73.0816C42.3334 74.7408 40.7406 75.7673 39.4857 74.9167L18.8065 60.901C17.5555 60.053 17.5906 57.9823 18.8696 57.1898L39.5487 44.3754C40.8033 43.5979 42.3334 44.6288 42.3334 46.2514ZM66.7778 46.2514V73.0816C66.7778 74.7408 65.3298 75.7673 64.189 74.9167L45.3898 60.901C44.2524 60.053 44.2844 57.9823 45.4471 57.1898L64.2463 44.3754C65.3868 43.5979 66.7778 44.6288 66.7778 46.2514ZM38.0466 24.5067L36.2666 21.9667H33.1866L36.5066 26.5067L32.7266 31.6667H35.7466L38.0066 28.5067L40.2466 31.6667H43.3266L39.5466 26.4867L42.8666 21.9667H39.8466L38.0466 24.5067ZM55.0815 14.8667H52.6815L44.0415 25.9067V27.8267H51.9135V31.6667H55.0815V27.8267H57.8415V24.9467H55.0815V14.8667ZM51.9135 24.9467H48.6495L51.9135 20.7467V24.9467Z" fill="white" />
    </SVGWrapper>
  );
}

export default TvRewind4Icon;
