import { PLAYER_WARNING, PLAYER_WARNING_CLOSE } from '../types';

const initialState = {
  message: null
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case PLAYER_WARNING: {
      return { ...state, ...payload, show: true };
    }
    case PLAYER_WARNING_CLOSE: {
      return { ...state, show: false };
    }
    default: {
      return state;
    }
  }
};
