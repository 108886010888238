import { h } from 'preact';
import { usePlayerContext, useStyles } from '../../../hooks';
import { LabeledButton } from '../../common';
import { connect } from '../../../hoc';
import { SKIP_BTN_LAYER } from '../../../theme/constants';
import { PAUSEROLL_BTN_PLAY_STYLES } from './styles';
import { USER_PLAY, USER_PLAY_PAUSEROLL } from '../../../../types';

function PauserollResume({
  isTV,
  innerRef,
  style = {},
  disableFocus = false
}) {
  const player = usePlayerContext();
  const setStyles = useStyles();
  const customStyle = setStyles(PAUSEROLL_BTN_PLAY_STYLES);

  return (
    <div
      className="pauseroll-retake"
      type="button"
      style={{
        ...style,
        zIndex: SKIP_BTN_LAYER
      }}
      ref={innerRef}
    >
      <LabeledButton
        disableFocus={disableFocus}
        onClick={(e) => {
          e.stopPropagation();
          player.userEvents$.next({ action: USER_PLAY_PAUSEROLL, source: USER_PLAY });
          player.play({ userGesture: true });
        }}
        icon="pauserollPlay"
        customStyle={{
          ...customStyle,
          ...({ backgroundColor: 'rgba(240, 241, 243)' })
        }}
        customActiveStyle={
          !isTV ? { default: { backgroundColor: 'rgba(240, 241, 243, .85)' } } : {}
        }
        label="reprendre la vidéo"
        ariaLabel="Fermer la publicité et reprendre la vidéo"
        iconSize={customStyle.iconSize}
      />
    </div>
  );
}

const selector = ({ media: { isTv } }) => ({ isTv });
export default connect(selector)(PauserollResume);
