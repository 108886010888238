import { SKIP_BTN_LAYER } from '../../../../ui/theme/constants';

export const TV_SKYP_BTN_STYLE = {
  position: 'absolute',
  right: '80px',
  width: 'auto',
  zIndex: SKIP_BTN_LAYER,
  height: '72px',
  lineHeight: '72px',
  bottom: '50px',
  borderRadius: '72px',
  padding: '0px 36px',
  textAlign: 'center',
  textDecoration: 'none',
  fontFamily: 'Brown',
  fontSize: '30px',
  fontWeight: '400px'
};
