/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvRewind32Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >

      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM42.3334 46.2514V73.0816C42.3334 74.7408 40.7406 75.7673 39.4857 74.9167L18.8065 60.901C17.5555 60.053 17.5906 57.9823 18.8696 57.1898L39.5487 44.3754C40.8033 43.5979 42.3334 44.6288 42.3334 46.2514ZM66.7778 46.2514V73.0816C66.7778 74.7408 65.3298 75.7673 64.189 74.9167L45.3898 60.901C44.2524 60.053 44.2844 57.9823 45.4471 57.1898L64.2463 44.3754C65.3868 43.5979 66.7778 44.6288 66.7778 46.2514ZM33.822 21.84L32.042 19.3H28.962L32.282 23.84L28.502 29H31.522L33.782 25.84L36.022 29H39.102L35.322 23.82L38.642 19.3H35.622L33.822 21.84ZM46.8809 19.76L49.7409 16.6V15H40.6409V17.4H45.9009L43.0409 20.5V22.1C43.6009 21.86 44.1609 21.7 44.8009 21.7C45.4809 21.7 46.0809 21.88 46.5209 22.24C47.0809 22.7 47.4409 23.4 47.4409 24.22C47.4409 25.76 46.2809 26.8 44.9409 26.8C43.5409 26.8 42.6409 26.12 41.9809 25.04L39.8409 26.2C40.8809 28.26 42.8409 29.2 44.9609 29.2C47.9609 29.2 50.1409 27.04 50.1409 24.18C50.1409 22.08 48.7809 20.34 46.8809 19.76ZM56.6345 26.6L58.7745 24.56C60.3745 23.04 61.7745 21.56 61.7745 19.4C61.7745 16.94 59.8145 14.8 56.9945 14.8C54.3545 14.8 52.5745 16.52 51.9745 19.1L54.3145 19.7C54.6345 18.18 55.5745 17.22 56.8545 17.2C58.1145 17.2 59.0945 18.16 59.0945 19.4C59.0945 20.6 58.3145 21.5 56.9345 22.86L52.2745 27.4V29H61.7745V26.6H56.6345Z" fill="white" />
    </SVGWrapper>
  );
}

export default TvRewind32Icon;
