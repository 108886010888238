/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPauseIconActive({ height = 60, width = 60 }) {
  return (

    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M45 86.25C67.7817 86.25 86.25 67.7817 86.25 45C86.25 22.2183 67.7817 3.75 45 3.75C22.2183 3.75 3.75 22.2183 3.75 45C3.75 67.7817 22.2183 86.25 45 86.25ZM32.5 59.375C32.5 61.1009 33.8991 62.5 35.625 62.5C37.3509 62.5 38.75 61.1009 38.75 59.375V30.625C38.75 28.8991 37.3509 27.5 35.625 27.5C33.8991 27.5 32.5 28.8991 32.5 30.625V59.375ZM51.25 59.375C51.25 61.1009 52.6491 62.5 54.375 62.5C56.1009 62.5 57.5 61.1009 57.5 59.375V30.625C57.5 28.8991 56.1009 27.5 54.375 27.5C52.6491 27.5 51.25 28.8991 51.25 30.625V59.375Z" fill="#F8F8F8" />
    </SVGWrapper>

  );
}

export default TvPauseIconActive;
