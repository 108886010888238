/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';
import { BTN_ACTIVE_SCALE } from '../../../../../../ui-tv/components/common/tvbutton/const';

function TVPreviousActiveIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <mask
        id="mask0_7687_16960"
        style={{
          maskType: 'alpha'
        }}
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={84}
        height={84}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M45 86.25C22.2183 86.25 3.75 67.7817 3.75 45C3.75 22.2183 22.2183 3.75 45 3.75C67.7817 3.75 86.25 22.2183 86.25 45C86.25 67.7817 67.7817 86.25 45 86.25ZM29.5714 61C29.5714 62.2887 28.5481 63.3333 27.2857 63.3333C26.0233 63.3333 25 62.2887 25 61V28.3333C25 27.0447 26.0233 26 27.2857 26C28.5481 26 29.5714 27.0447 29.5714 28.3333V61ZM65 59.4014V30.1106C65 28.2992 63.0662 27.1786 61.5426 28.1071L36.4362 43.4083C34.9172 44.334 34.96 46.5946 36.5127 47.4598L61.6192 61.4495C63.1423 62.2982 65 61.1728 65 59.4014Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_7687_16960)" transform="scale(0.66)">
        <rect width={width * BTN_ACTIVE_SCALE} height={height * BTN_ACTIVE_SCALE} fill="#F8F8F8" />
      </g>
    </SVGWrapper>
  );
}

export default TVPreviousActiveIcon;
