const MAP = {
  k: [10000, 999999, 1000],
  M: [1000000, Number.POSITIVE_INFINITY, 1000000]
};

/**
 * Format number accordingly (separate thousand xxx xxx )
 * - 0 to 9999: show all
 * - 10k: 10 000 to 99 999
 * - 100k : 100 000 to 999 999
 * - 1M : 1 000 000 ...
 */
const formatNumberOfViewer = (val) => Object.entries(MAP).reduce((res, [key, [i1, i2, divisor]]) => ((val >= i1 && val <= i2) ? `${(val / divisor).toFixed(0)}${key}` : res), val.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$& ').replace(/\.0*/, ''));

export { formatNumberOfViewer };
