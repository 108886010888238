import { systemInfo } from '../utils';
import { isFontAvailabe, addFont } from './utils';

const isIE11 = systemInfo.browser === 'ie';
const format = isIE11 ? 'woff' : 'woff2';
const FONTS = [
  {
    name: 'Brown',
    load: async (name) => {
      const { default: base64 } = await import(/* webpackChunkName: "Brown" */`../ui/theme/fonts/FranceTVBrownSub-Regular.${format}`);
      return {
        name,
        base64,
        fontWeight: 'normal',
        format
      };
    }
  },
  {
    name: 'Brown',
    load: async (name) => {
      const { default: base64 } = await import(/* webpackChunkName: "Brown" */`../ui/theme/fonts/FranceTVBrownSub-Bold.${format}`);
      return {
        name,
        base64,
        fontWeight: 'bold',
        format
      };
    }
  },
  {
    name: 'Segoe UI',
    load: async (name) => {
      const { default: base64 } = await import(/* webpackChunkName: "SegoeUI" */`../ui/theme/fonts/SegoeUI.${format}`);

      return {
        name,
        base64,
        fontWeight: 'normal',
        format
      };
    }
  }
];

class CoreUI {
  constructor({ player, container = null }) {
    this.player = player;
    this.container = container;
  }

  // eslint-disable-next-line class-methods-use-this
  init() {
    throw new Error('CoreUI:init not implemented');
  }

  dispose(emptyContainer) {
    if (emptyContainer && this.wrapper) this.wrapper.remove();
    else if (this.container) this.container.remove();
  }

  static async checkFonts() {
    return Promise.all(FONTS
      .filter(({ name }) => !isFontAvailabe(name))
      .map(({ load, name }) => load(name)))
      .then((result) => result.forEach(addFont))
      .catch(() => Promise.resolve()); /* we want to resolve even in the case of an error */
  }
}

export default CoreUI;
