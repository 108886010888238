export const WARNING_STYLES = {
  wrapper: {
    color: 'white',
    position: 'absolute',
    width: '100%',
    height: '100%',
    fontFamily: 'Brown',
    fontWeight: 700,
    fontSize: '22px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  warningMessage: {
    textAlign: 'center',
    outline: 'none',
    userSelect: 'none',
    padding: '0 20px',
    width: '100%'
  },
  animation: {
    transition: 'opacity .4s ease-in-out'
  }
};
