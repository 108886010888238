import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { LabeledButton, OverlayScreen } from '../common';
import { OVERLAY_WRAPPER } from './styles';
import { USER_PIP, USER_CLICK } from '../../../types';
import { connect } from '../../hoc';
import { usePlayerContext } from '../../hooks';

function PIPView({ isPIP }) {
  const player = usePlayerContext();

  const pipButton = useCallback(() => (
    <div style={OVERLAY_WRAPPER}>
      <span>Lecture picture-in-picture activée</span>
      <LabeledButton
        label="désactiver"
        onClick={(e) => {
          e.stopPropagation();
          player.userEvents$.next({
            action: USER_PIP,
            value: false,
            source: USER_CLICK
          });
          player.requestPIP(false);
        }}
      />
    </div>
  ), []);

  return (
    isPIP ? (
      <OverlayScreen
        opaque
        component={pipButton}
      />
    ) : null
  );
}

const selector = ({ ui: { isPIP } }) => ({ isPIP });
export default connect(selector)(PIPView);
