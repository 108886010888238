function mergeObjects(obj1, obj2) {
  return Object.keys(obj2).reduce((obj, key) => {
    if (Array.isArray(obj2[key])) return { ...obj, [key]: obj2[key] };
    if (key in obj1 && obj1[key] !== null && obj2[key] !== null && typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
      return { ...obj, [key]: mergeObjects(obj1[key], obj2[key]) };
    }

    return { ...obj, [key]: obj2[key] };
  }, obj1);
}

export default function deepMerge(...objects) {
  return objects.reduce((merge, obj) => mergeObjects(merge, obj), {});
}
