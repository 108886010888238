const NS_STYLES = 'http://www.w3.org/ns/ttml#styling';
const DISPLAY_ALIGN_TO_CSS = {
  before: 'flex-start',
  center: 'center',
  after: 'flex-end'
};

export default class TTMLParser {
  static get styles() {
    return ({
      [`${NS_STYLES} color`]: (val) => [{ name: 'color', val: TTMLParser.parseColor(val) }],
      [`${NS_STYLES} backgroundColor`]: (val) => [{ name: 'backgroundColor', val: TTMLParser.parseColor(val) }],
      [`${NS_STYLES} textDecoration`]: (val) => [{ name: 'textDecoration', val: val[0] }],
      [`${NS_STYLES} fontStyle`]: (val) => [{ name: 'fontStyle', val }],
      [`${NS_STYLES} origin`]: (val, context) => [
        { name: 'top', val: `${(typeof val.h?.rh === 'number' ? 0.75 : val.h) * context.h}px` },
        { name: 'left', val: `${(typeof val.w?.rw === 'number' ? 0.33 : val.w) * context.w}px` },
        { name: 'position', val: 'absolute' }
      ],
      [`${NS_STYLES} extent`]: (val, context) => [
        { name: 'height', val: `${val.h * context.h}px` },
        { name: 'width', val: `${val.w * context.w}px` }
      ],
      [`${NS_STYLES} displayAlign`]: (val) => [
        { name: 'display', val: 'flex' },
        { name: 'flexDirection', val: 'column' },
        { name: 'justifyContent', val: DISPLAY_ALIGN_TO_CSS[val] }
      ]
    });
  }

  static parseColor(colorArr) {
    let result = 'rgb(255, 255, 255)';
    if (!Array.isArray(colorArr) || colorArr.length < 3) return result;

    if (colorArr.length === 4) {
      result = `rgba(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]}, ${colorArr[3] / 255})`;
    } else if (colorArr.length === 3) {
      result = `rgb(${colorArr[0]}, ${colorArr[1]}, ${colorArr[2]})`;
    }

    return result;
  }

  static processElement(domParent, isdElement, context) {
    let e;

    if (['body', 'div'].includes(isdElement.kind)) {
      e = document.createElement('div');
    }

    if (isdElement.kind === 'region') {
      e = document.createElement('div');
      e.className = 'ftv-magneto--region';
    }

    if (isdElement.kind === 'span') {
      e = document.createElement('span');
      if (isdElement.text && (isdElement.text.trim() !== '')) {
        e.textContent = isdElement.text;
      }
    }

    if (isdElement.kind === 'p') e = document.createElement('p');
    if (isdElement.kind === 'br') e = document.createElement('br');
    if (!e) throw new Error('Could not parse ISD element');

    if (isdElement.styleAttrs) {
      Object.keys(isdElement.styleAttrs).forEach((key) => {
        if (TTMLParser.styles[key]) {
          const styles = TTMLParser.styles[key](isdElement.styleAttrs[key], context);
          styles.forEach(({ name, val }) => { e.style[name] = val; });
        }
      });
    }

    domParent.appendChild(e);

    if (isdElement.contents) {
      Object.keys(isdElement.contents).forEach((i) => {
        TTMLParser.processElement(e, isdElement.contents[i]);
      });
    }
  }

  static parse(isd, context) {
    const rootContainer = document.createElement('div');
    if (isd.contents) {
      Object.keys(isd.contents).forEach((i) => {
        TTMLParser.processElement(rootContainer, isd.contents[i], context);
      });
    }

    return rootContainer;
  }
}
