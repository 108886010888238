import { h } from 'preact';
import { OVERLAY_STYLES as styles } from './styles';
import { OVERLAY_SCREEN_OPAQUE_BACKGROUND } from '../../../theme/colors';
import { useStyles } from '../../../hooks';

function OverlayScreen({ component: WrappedComp, opaque, style }) {
  const setStyle = useStyles();
  return (
    <div
      className="ftv-magneto--overlay"
      style={{
        ...styles.container,
        ...(opaque ? { backgroundColor: OVERLAY_SCREEN_OPAQUE_BACKGROUND } : {}),
        ...style
      }}
    >
      <div style={setStyle(styles.dialog)}>
        <WrappedComp />
      </div>
    </div>
  );
}

export default OverlayScreen;
