import { h } from 'preact';
import { SPINNER_SIZE, SPINNER_TIME_STEP, BASE_DOT_SIZE, BASE_DOT_STYLE } from './styles';

/* * * * * * * * * * * * * * * * * * *
 *      Dots final rotation 2π/12     *
 *                                    *
 *        o                o   o      *
 *    o       o                       *
 *                ==>   o         o   *
 *    o       o                       *
 *        o                o   o      *
 *                                    *
 * * * * * * * * * * * * * * * * * * */

const RADIUS = SPINNER_SIZE / 2;
const BASE_ANGLE = Math.PI / 3; /* 2π/6 */
const DELTA_ANGLE = BASE_ANGLE / 2;
const FINAL_DURATION_FACTORS = [1, 4, 4, 2, 1, 1]; /* faster transition for trailing dots */

const computeFinalDuration = (duration, index) => duration / FINAL_DURATION_FACTORS[index];

function Dots({ opacities, delta, ended }) {
  const finalRotate = ended ? 0 : DELTA_ANGLE;
  const deltaDot = BASE_DOT_SIZE / 2;
  const baseDuration = SPINNER_TIME_STEP * (ended ? 1.5 : 1);

  /**
   * position: absolute (0,0) is at (top, left) corner of parent relative div
   * => +RADIUS translation to center dots in spinner box
   */
  return (
    <div>
      {
        opacities
          .map((opacity) => opacity - delta)
          .map((_, i) => (
            <div style={{
              ...BASE_DOT_STYLE,
              backgroundColor: `rgba(255, 255, 255, ${opacities[i] - delta})`,
              transition: `all .${ended ? computeFinalDuration(baseDuration, i) : baseDuration}s ease-in`,
              top: ((Math.sin((BASE_ANGLE * i) - finalRotate) * RADIUS) + RADIUS) - deltaDot,
              left: ((Math.cos((BASE_ANGLE * i) - finalRotate) * RADIUS) + RADIUS) - deltaDot
            }}
            />
          ))
      }
    </div>
  );
}

export default Dots;
