import { h } from 'preact';
import { useCallback } from 'preact/hooks';
import { USER_CLICK, USER_KEYBOARD, USER_PANEL_CLICKED, USER_REQUEST_MEDIA_LOAD } from '../../../types';
import { usePlayerContext } from '../../../ui/hooks';
import { ZAP_PROGRAM_DEFAULT_IMAGE_SVG } from '../../../ui/components/zap/styles';
import { ACTIVE_ITEM_BACKGROUND } from '../../../ui/theme/colors';
import { PLAYLIST_NAME, ZAPPING_NAME } from '../../../ui/components/wrappers/Zap/constants';
import { withBreakPoints } from '../../../ui/hoc';
import { TV_ZAP_PROGRAM_CSA, TV_ZAP_PROGRAM_GRADIENT_BACKDROP, TV_ZAP_PROGRAM_LOGO, TV_ZAP_PROGRAM_MAIN_TITLE, TV_ZAP_PROGRAM_PREVIEW, TV_ZAP_PROGRAM_SECOND_TITLE, TV_ZAP_PROGRAM_TITLES, TV_ZAP_PROGRAM_WRAPPER } from './styles';
import { TVShortcutController } from '../../../core/shortcut/tvshortcut';
import { KEYCODE_GENERICS_MAP } from '../../../core/shortcut/types';

function Program({ metadata, active, activeProgramMetadata = {}, currentTab, programNumber,
  channels }) {
  const player = usePlayerContext();
  const PROGRAM_VISUAL_STYLE = {
    ...TV_ZAP_PROGRAM_GRADIENT_BACKDROP,
    backgroundImage: metadata?.image
      ? `${TV_ZAP_PROGRAM_GRADIENT_BACKDROP.backgroundImage},url("${metadata.image}")`
      : `${TV_ZAP_PROGRAM_GRADIENT_BACKDROP.backgroundImage},url("${ZAP_PROGRAM_DEFAULT_IMAGE_SVG}")`
  };

  const handleClick = useCallback((fromKeyboard) => {
    const source = fromKeyboard ? USER_KEYBOARD : USER_CLICK;

    if ([ZAPPING_NAME, PLAYLIST_NAME].includes(currentTab)) {
      const { id: src, name, options: config } = metadata;
      let tracking;
      switch (currentTab) {
        case ZAPPING_NAME:
          tracking = {
            diffusion: { mode: 'live_from_zapping' },
            tracking: {
              pageProvenance: '',
              zoneProvenance: 'zapette_player',
              positionVignette: '',
              playProvenance: 'zapette'
            }
          };
          break;
        case PLAYLIST_NAME:
          tracking = {
            /* position is the human readable version of the index, hence the +1 */
            diffusion: { mode: 'tunnel', position: programNumber + 1, length: channels.length },
            tracking: {
              pageProvenance: '',
              zoneProvenance: 'playlist_player',
              positionVignette: '',
              playProvenance: 'playlist'
            },
            comingNext: programNumber + 1 !== channels.length ? {
              program: channels[programNumber + 1]?.title ?? channels[programNumber + 1].config.program,
              title: channels[programNumber + 1]?.preTitle ?? channels[programNumber + 1].config.title
            } : {}
          };
          break;
        default:
          break;
      }

      player.userEvents$.next({
        action: USER_REQUEST_MEDIA_LOAD,
        value: {
          src,
          config: {
            ...config,
            name,
            intro: false,
            autostart: true,
            ...tracking
          }
        },
        source
      }, [metadata.id, currentTab]);
    }
    player.userEvents$.next({
      action: USER_PANEL_CLICKED,
      feature: currentTab,
      id: metadata.id,
      ...metadata.config
    });
  }, [metadata.id, activeProgramMetadata?.name]);

  return (
    <div
      style={{
        ...TV_ZAP_PROGRAM_WRAPPER,
        ...active && ({ background: ACTIVE_ITEM_BACKGROUND })
      }}
      onKeyDown={(e) => {
        const mappedKeyCode = TVShortcutController.getMappedKeyCode(KEYCODE_GENERICS_MAP, e.keyCode);

        if (
          e.keyCode !== 32 /* space */
          && e.keyCode !== 13 /* enter */
          && mappedKeyCode !== 32 /* space equivalent on remote */
          && mappedKeyCode !== 13 /* enter equivalent on remote */
        ) return;

        handleClick(true);
      }}
      onClick={handleClick}
      tabIndex={0}
      className="ftv-magneto-pointer ftv-magneto--selectable"
      role="radio"
      aria-checked={active}
      data-type={metadata.id}
    >
      <div style={TV_ZAP_PROGRAM_PREVIEW}>
        <div style={PROGRAM_VISUAL_STYLE}>
          {metadata.logo && (
          <div style={TV_ZAP_PROGRAM_LOGO}>
            <img
              width={44}
              height={44}
              src={metadata.logo}
              alt={metadata.name}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
            />
          </div>
          )}

          {metadata.iconCsa && (
          <div style={TV_ZAP_PROGRAM_CSA}>
            <img
              width={18}
              height={18}
              src={metadata.iconCsa}
              alt={metadata.name}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
            />
          </div>
          )}
        </div>
      </div>
      <div style={TV_ZAP_PROGRAM_TITLES}>
        <div style={TV_ZAP_PROGRAM_MAIN_TITLE}>
          {metadata.title}
        </div>
        <div style={TV_ZAP_PROGRAM_SECOND_TITLE}>
          {metadata.preTitle ? `${metadata.preTitle} ` : '' }
          {metadata.additionalTitle}
        </div>
      </div>
    </div>

  );
}

export default withBreakPoints(Program);
