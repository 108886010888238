import { h, cloneElement, toChildArray } from 'preact';
import { useEffect, useRef, useCallback, useState, useMemo } from 'preact/hooks';
import { connect, withBreakPoints, withError } from '../../hoc';
import { usePlayerContext, useStateRef } from '../../hooks';
import { useDialogContext } from '../../context/DialogContext';
import { elementIsWithin, systemInfo, VOID_FN } from '../../../utils';
import { UI_ACCESSIBILITY_VISIBILITY, UI_VISIBLE } from '../../../store/types';
import { computeVideoTitle } from '../../../utils/videoMetaUtils';

const HIDE_DELAY = 3000;

function InteractionHandler({
  children,
  style = { display: 'flex', flexDirection: 'row', height: '100%' },
  ...props
}) {
  const {
    displayUiAccessibility, forceHidden, forceVisible, canInteract, isInteracting, waitStart, /* UI store interactions */
    loaded, mobile, zappingEnabled, /* Media properties */
    isPlaying, isSeeking, isFullscreen, isAd, /* Store updates which trigger UI display when manipulating player API directly */
    liveLabel, pubLabel,
    videoTitle, subTitle, videoPreTitle, videoAdditionalTitle
  } = props;

  const showProps = [canInteract, isInteracting, forceHidden, zappingEnabled, isPlaying, isSeeking, isFullscreen];

  const player = usePlayerContext();
  const container = useRef(null);
  const timerRef = useRef(null); /* autohide timer */
  const [visible, setVisible, visibility] = useStateRef(false);
  const [ariaLive, setAriaLive] = useState('off');
  const nonInitRenderRef = useRef(false);

  const { computedTitle, computedSubtitle } = useMemo(
    () => {
      const { computedTitle: title, computedSubtitle: sTitle } = computeVideoTitle(videoTitle, subTitle, videoPreTitle, videoAdditionalTitle);
      return ({
        computedTitle: title,
        computedSubtitle: sTitle.replace(/(S|E)(\d+)/gi, (_, character, numberMatch) => (
          `${['s', 'S'].includes(character) ? 'Saison' : 'Épisode'} ${numberMatch}`
        ))
      });
    },
    [videoTitle, subTitle, videoPreTitle, videoAdditionalTitle]
  );

  /* .replace twice only take ~ .005ms to process */

  const playerMetaVocalization = useMemo(
    () => (isAd
      ? (!systemInfo.isMac ? pubLabel.replace(/sec/, 'secondes') : pubLabel && 'publicité').trim()
      : `${liveLabel.replace(/h/, 'heure').replace(/min/, 'minutes')} ${computedTitle} ${computedSubtitle}`.trim()),
    [pubLabel, liveLabel, computedTitle, computedSubtitle]
  );

  /* Dialog context */
  const dialogCtx = useDialogContext();
  const closeDialogFirst = (fn) => () => (dialogCtx.dialogOpened ? dialogCtx.closeAll() : fn());

  const onBlur = (e) => {
    // Prevent closing dialogs if the focus goes to a child
    if (elementIsWithin(e.target, e.relatedTarget)) {
      return;
    }

    if (dialogCtx.dialogOpened) {
      dialogCtx.closeAll();
    }
  };
  const canShow = useCallback(
    () => (loaded && canInteract && !forceHidden && !waitStart),
    [loaded, canInteract, forceHidden, waitStart]
  );

  const canHide = useCallback(
    () => (
      forceHidden
      || ((!forceVisible && !isInteracting) && (isPlaying || !loaded))
      || forceVisible
    ),
    [forceHidden, isInteracting, isPlaying, loaded, forceVisible]
  );

  const shouldAutoHide = useCallback(() => visible && !isInteracting && isPlaying, [visible, isPlaying, isInteracting]);
  const shouldAutoShow = useCallback(
    () => (canInteract || isInteracting) && showProps.some(Boolean),
    [canInteract, isInteracting, ...showProps]
  );

  const hide = () => canHide() && setVisible(false);
  const show = () => canShow() && setVisible(true);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    timerRef.current = null;
  };

  const autoHide = (delay) => {
    resetTimer(); /* clear timer to avoid multiple hide requests */
    timerRef.current = setTimeout(hide, delay);
  };

  const handleClick = () => (!isInteracting && Promise.resolve(player.handleClickEvent()));
  const handleTap = () => (visibility.current ? hide() : show());
  const handleMouseLeave = () => (!forceHidden && !forceVisible) && autoHide(50);

  const handleKeyEvent = (e) => {
    if (e.keyCode !== 9) {
      e.preventDefault();
    }
    player.handleKeyEvent(e.keyCode, { shiftKey: e.shiftKey });
    if (e.keyCode === 27 /* ESC */) { dialogCtx.closeAll(); }
  };

  useEffect(() => player.setInteractionLayer(container.current), []);
  useEffect(() => forceHidden && hide(), [forceHidden]);
  useEffect(() => forceVisible && show(), [forceVisible]);
  useEffect(() => shouldAutoShow() && show(), showProps);
  useEffect(() => !loaded && setVisible(false), [loaded]);
  useEffect(() => {
    if (isInteracting || !isPlaying || !visible || forceVisible) resetTimer();
    if (shouldAutoHide()) {
      autoHide(HIDE_DELAY);
    }
  }, [visible, isInteracting, isPlaying, forceVisible]);

  useEffect(() => {
    if (displayUiAccessibility) {
      show();
    }
  }, [displayUiAccessibility]);

  useEffect(() => displayUiAccessibility && autoHide(HIDE_DELAY), [displayUiAccessibility]);

  useEffect(() => {
    container.current.addEventListener('focusin', () => {
      player.focused$.next(true);
    });
  }, []);

  useEffect(() => {
    container.current.addEventListener('focusout', () => {
      player.store.dispatch({ type: UI_ACCESSIBILITY_VISIBILITY, payload: { displayUiAccessibility: false } });
    });
  }, []);

  useEffect(() => {
    player.store.dispatch({ type: UI_VISIBLE, payload: { hasUiVisible: visible } });
    player.trigger(UI_VISIBLE, { visible });
  }, [visible]);

  useEffect(() => {
    /** We set `aria-live` to polite whenever the value of `videoMeta`
     *  changes so the screen reader like Nvda detect changes and tells
     *  the user the lastly updated field.
     *  Ignore first render, we don't want `aria-live` to be 'polite'
     *  so the user won't be overwhelmed by screen reader whenever the
     *  `adMeta` is updated. The value of `isAd` take time to be updated
     *  compared to the value of `videoMeta`. (see dependency array of the
     *  useEffect)
     *  * */
    if (nonInitRenderRef.current) {
      if (!isAd) {
        setAriaLive('polite');
        setTimeout(() => {
          setAriaLive('off');
        }, 300);
      }
    } else {
      nonInitRenderRef.current = true;
    }
  }, [isAd, liveLabel, pubLabel, computedSubtitle, computedTitle]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      style={style}
      onClick={closeDialogFirst(mobile ? handleTap : handleClick)}
      onMouseLeave={mobile ? VOID_FN : handleMouseLeave}
      onMouseMove={mobile ? VOID_FN : show}
      onKeyDown={mobile || dialogCtx.dialogOpened ? VOID_FN : handleKeyEvent}
      role="region"
      className="ftv-in-customizable magneto-wrapper"
      ref={container}
      tabIndex="-1" /* eslint-disable-line */
      onBlur={onBlur}
      name="ftv-magneto-player"
      aria-label={playerMetaVocalization}
      aria-live={ariaLive}
    >
      {
          toChildArray(children)
            .map((child) => cloneElement(child, {
              ...child.props,
              UIVisible: visible,
              setUIVisibility: (val) => setVisible(val),
              handleTap
            }))
          }
    </div>

  );
}

const selector = ({
  playback: { isPlaying },
  zapping: { list, zappingEnabled = list.length > 0 },
  media: { isAd, isLive, isDVR, loaded,
    videoTitle,
    subTitle,
    videoPreTitle,
    videoAdditionalTitle
  },
  ui: {
    canInteract, forceVisible, forceHidden, hoverTimeline,
    isFullscreen, isInteracting, isSeeking, waitStart,
    displayUiAccessibility, liveLabel, pubLabel
  }
}) => ({
  forceHidden, forceVisible, canInteract, isInteracting, hoverTimeline,
  waitStart, loaded, isAd, isLive, isDVR, zappingEnabled,
  isPlaying, isSeeking, isFullscreen, displayUiAccessibility, liveLabel,
  pubLabel,
  videoTitle,
  subTitle,
  videoPreTitle,
  videoAdditionalTitle
});

export default withError(withBreakPoints(connect(selector)(InteractionHandler)));
