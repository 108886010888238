import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function StopIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >

      <mask id="mask_stop" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="44" height="44">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24 46C36.1503 46 46 36.1503 46 24C46 11.8497 36.1503 2 24 2C11.8497 2 2 11.8497 2 24C2 36.1503 11.8497 46 24 46ZM15.8333 14.6667C15.189 14.6667 14.6667 15.189 14.6667 15.8333V32.1667C14.6667 32.811 15.189 33.3333 15.8333 33.3333H32.1667C32.811 33.3333 33.3333 32.811 33.3333 32.1667V15.8333C33.3333 15.189 32.811 14.6667 32.1667 14.6667H15.8333Z" fill="black" />

      </mask>
      <g mask="url(#mask_stop)">
        <rect width="48" height="48" fill={color} />
      </g>
    </SVGWrapper>
  );
}

export default StopIcon;
