import { h } from 'preact';
import { useCallback, useEffect, useRef } from 'preact/hooks';
import { publicPath } from '../../../configuration';
import { USER_CLICK, USER_REPLAY } from '../../../types';
import { START_SCREEN_BUTTON_SIZE } from '../../../ui/components/info/styles';
import { TAP_LAYER } from '../../../ui/theme/constants';
import { connect } from '../../../ui/hoc';
import { START_SCREEN_PLAYBUTTON } from './styles';
import { TVButton } from '../common/tvbutton';
import { usePlayerContext } from '../../../ui/hooks';
import { TVShortcutController } from '../../../core/shortcut/tvshortcut';
import { KEYCODE_GENERICS_MAP, SELECT_SHORTCUT } from '../../../core/shortcut/types';

function StartScreen({
  waitStart,
  playButtonReady,
  backgroundImage,
  showRestartButtonOnStartScreen,
  width,
  height
}) {
  if (!waitStart) return null;

  const player = usePlayerContext();

  const startButtonRef = useRef();

  useEffect(() => {
    if (playButtonReady && startButtonRef.current) {
      const button = startButtonRef.current.querySelector('[name="replay"]') || startButtonRef.current.querySelector('[name="play"]');
      button?.focus();
    }
  }, [playButtonReady, startButtonRef.current]);

  const handlePlayButton = useCallback(() => {
    if (showRestartButtonOnStartScreen) {
      player.userEvents$.next({
        action: USER_REPLAY,
        source: USER_CLICK
      });
    }
    player.play({ userGesture: true });
  }, [player, showRestartButtonOnStartScreen]);

  return (
    <div
      style={{
        zIndex: TAP_LAYER
      }}
    >
      <div
        className="ftv--magneto-startscreen"
        style={{
          backgroundImage: `url"(${backgroundImage.match(/background\.jpg/) ? `${publicPath}${backgroundImage}` : backgroundImage}")`,
          backgroundSize: width < height ? 'contain' : 'cover',
          backgroundRepeat: 'no-repeat',
          width,
          height
        }}
      />
      <div style={START_SCREEN_PLAYBUTTON} ref={startButtonRef}>
        <TVButton
          name={showRestartButtonOnStartScreen ? 'replay' : 'play'}
          icon={showRestartButtonOnStartScreen ? 'replay' : 'play'}
          iconActive={showRestartButtonOnStartScreen ? 'replay-active' : 'play-active'}
          hidden={!playButtonReady}
          size={START_SCREEN_BUTTON_SIZE}
          ariaHidden="false"
          ariaLabel={showRestartButtonOnStartScreen ? 'regarder à nouveau' : 'lecture'}
          onClick={() => {
            handlePlayButton();
          }}
          onKeyDown={({ keyCode }) => {
            const mappedKeyCode = TVShortcutController.getMappedKeyCode(KEYCODE_GENERICS_MAP, keyCode);
            if (mappedKeyCode !== SELECT_SHORTCUT && mappedKeyCode !== 32) return;

            handlePlayButton();
          }}

        />
      </div>
    </div>

  );
}

const selector = ({
  media: { backgroundImage },
  ui: { playButtonReady, showRestartButtonOnStartScreen, width, height, waitStart }
}) => ({ backgroundImage, playButtonReady, showRestartButtonOnStartScreen, width, height, waitStart });
export default connect(selector)(StartScreen);
