import { h } from 'preact';
import { useStyles } from '../../../hooks';
import { SEPARATOR_STYLE } from './styles';

function Seperator({ style = {} }) {
  const setStyles = useStyles();

  return <div style={{ ...setStyles(SEPARATOR_STYLE), ...style }} />;
}

export default Seperator;
