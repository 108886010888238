import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function ChatActive({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_7876_4863" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="26" height="27">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.99997 5.70483C2.99997 4.211 4.21097 3 5.7048 3H19.229C20.7229 3 21.9339 4.211 21.9339 5.70483L21.9339 16.1081C21.9339 17.6019 20.7229 18.8129 19.229 18.8129H9.96563C9.43929 18.8129 8.93418 19.0204 8.55981 19.3904L4.06293 23.8344C3.88385 24.0113 3.61594 24.0634 3.38361 23.9663C3.15128 23.8693 3 23.6421 3 23.3904V16.1199L2.99997 16.1081V5.70483ZM8.30557 12.9871C9.16739 12.9871 9.86606 12.2884 9.86606 11.4266C9.86606 10.5647 9.16739 9.86609 8.30557 9.86609C7.44373 9.86609 6.74509 10.5647 6.74509 11.4266C6.74509 12.2884 7.44373 12.9871 8.30557 12.9871ZM12.4669 12.9871C13.3287 12.9871 14.0273 12.2884 14.0273 11.4266C14.0273 10.5647 13.3287 9.86609 12.4669 9.86609C11.605 9.86609 10.9064 10.5647 10.9064 11.4266C10.9064 12.2884 11.605 12.9871 12.4669 12.9871ZM18.1886 11.4266C18.1886 12.2884 17.49 12.9871 16.6281 12.9871C15.7663 12.9871 15.0677 12.2884 15.0677 11.4266C15.0677 10.5647 15.7663 9.86609 16.6281 9.86609C17.49 9.86609 18.1886 10.5647 18.1886 11.4266Z" fill="white" />
        <path d="M24 8.82578H26.0951C27.589 8.82578 28.8 10.0368 28.8 11.5306V21.9339L28.7999 21.9457V29.2161C28.7999 29.4679 28.6487 29.695 28.4163 29.7921C28.184 29.8892 27.9161 29.8371 27.737 29.6601L23.2401 25.2161C22.8657 24.8462 22.3606 24.6387 21.8343 24.6387H12.5709C11.0771 24.6387 9.86609 23.4277 9.86609 21.9339V21H20C22 21 24 19.5 24 17V8.82578Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7876_4863)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export { ChatActive };
