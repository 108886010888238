import { h } from 'preact';
import { WARNING_STYLES as styles } from './styles';
import { connect } from '../../hoc';

function Warning({ message, show }) {
  return (
    <div style={{ ...styles.wrapper, ...styles.animation, ...{ opacity: (show ? 1 : 0) } }}>
      <span style={styles.warningMessage} dangerouslySetInnerHTML={{ __html: message } /* eslint-disable-line */} />
    </div>
  );
}
const selector = ({ warning: { message, show } }) => ({ message, show });
export default connect(selector)(Warning);
