import { merge, fromEvent } from 'rxjs';
import { withLatestFrom, filter, map, mapTo } from 'rxjs/operators';

export default class SeekBlocker {
  constructor(videoEl, isAd$) {
    this.video = videoEl;

    const timeupdate$ = fromEvent(videoEl, 'timeupdate').pipe(
      map(SeekBlocker.getCurrentTime),
      filter(() => !videoEl.seeking)
    );

    const seeking$ = fromEvent(videoEl, 'seeking').pipe(
      map(SeekBlocker.getCurrentTime)
    );
    const ended$ = fromEvent(videoEl, 'ended').pipe(mapTo(0));

    const seekBlocker$ = SeekBlocker.createSeekBlockerStream(
      seeking$,
      timeupdate$,
      ended$,
      isAd$
    );

    seekBlocker$.subscribe((prev) => {
      this.video.currentTime = prev;
    });
  }

  static getCurrentTime({ target }) {
    return target.currentTime;
  }

  static createSeekBlockerStream(seeking$, timeupdate$, ended$, isAd$) {
    return seeking$.pipe(
      withLatestFrom(merge(timeupdate$, ended$), isAd$),
      filter(([a, b, { isAd }]) => isAd && Math.abs(b - a) > 0.01),
      map(([, prev]) => prev)
    );
  }
}
