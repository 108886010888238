import { h } from 'preact';
import { withBreakPoints, connect } from '../../../hoc';
import SVGCreator from '../../common/svg/SVGCreator';
import { FRANCETV_SOURCE, FRANCEINFO_SOURCE } from '../../../theme/svg';
import { SOURCE_BUTTON_STYLE, SOURCE_BUTTON_SIZE } from './styles';
import { getSizeFactor } from '../../../utils/tools';

const resolveLogo = (source) => (source === 'franceinfo' ? FRANCEINFO_SOURCE : FRANCETV_SOURCE);

function SourceButton({
  extraSmall,
  small,
  medium,
  large,
  originUrl,
  origin,
  isAd
}) {
  return originUrl && !isAd
  && !small && !medium && (
  <div
    role="presentation"
    style={SOURCE_BUTTON_STYLE}
    onClick={(e) => { e.stopPropagation(); window.open(originUrl); }}
  >
    <SVGCreator
      paths={resolveLogo(origin)}
      width={SOURCE_BUTTON_SIZE.width * getSizeFactor({ extraSmall, large })}
      height={SOURCE_BUTTON_SIZE.height * getSizeFactor({ extraSmall, large })}
    />
  </div>
  );
}

const selector = ({ ui: { originUrl, origin }, media: { isAd } }) => ({ originUrl, origin, isAd });
export default withBreakPoints(connect(selector)(SourceButton));
