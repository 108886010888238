import { merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { EVENT, RENDERER, ERROR, COMMAND, CONFIG, MEDIA } from './types';

export default class DebugController {
  constructor(player) {
    merge(
      player.playerConfig$.pipe(map((config) => [CONFIG, config])),
      player.mediaController.medias$.pipe(map((media) => [MEDIA, media])),
      player.events$.pipe(map((e) => {
        if (typeof e === 'object') {
          return [EVENT, ...Object.values(e)];
        }
        return [EVENT, e];
      })),
      player.rendererController.state$.pipe(map((e) => [RENDERER, e])),
      player.errors$.pipe(map((e) => [ERROR, e])),
      player.commandController.commands$.pipe(map((e) => [COMMAND, e]))
    ).subscribe((logs) => DebugController.log(...logs));
  }

  static log(type, ...args) {
    console.log('[%s] Magnetoscope/%s -', new Date().toISOString(), type, ...args); // eslint-disable-line no-console
  }
}
