import { h } from 'preact';
import Timeline from '../timeline';
import TVCommands from '../commands';
import HeaderInfos from '../info/HeaderInfos';
import { DAI_AD_TYPE } from '../../../ad/dai/types';
import { connect } from '../../../ui/hoc';

function TVSectionBottom({
  showCountdown, isAd, isLive, timeshifting, isShifting,
  isDAI, adType
}) {
  return (
    <div style={{ width: '100%', position: 'absolute', bottom: 0 }}>
      <HeaderInfos />
      {(
        (!isLive && !isAd && !showCountdown)
        || (timeshifting && !isAd && !showCountdown)
        || (isDAI && isAd && adType === DAI_AD_TYPE)
        || isShifting
      ) && <Timeline />}
      <TVCommands />
    </div>
  );
}

const selector = ({
  ad: { showCountdown, adType },
  media: { isAd, isDAI, isLive, timeshifting: { type: timeshifting, startOverTimeshifting: isShifting } }
}) => ({
  showCountdown, isAd, isLive, timeshifting, isShifting,
  isDAI, adType
});

export default connect(selector)(TVSectionBottom);
