import { SCROLLBAR_BACKGROUND_COLOR } from '../theme/colors';

export const SCROLLABLE_WRAPPER_STYLES = {
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  position: 'relative'
};

export const SCROLLABLE_CONTENT_STYLES = {
  height: '100%',
  width: '100%',
  padding: 0,
  position: 'relative',
  overflowX: 'hidden',
  overflowY: 'auto',
  userSelect: 'none'
};

export const SCROLLABLE_SCROLLBAR_WRAPPER_STYLES = {
  default: {
    position: 'absolute',
    width: 'unset',
    top: 0,
    right: 0,
    cursor: 'pointer',
    transition: 'opacity 0.15s linear',
    transform: 'translateX(-100%)'
  }
};

export const SCROLLABLE_SCROLLBAR_STYLES = {
  marginLeft: 3,
  background: SCROLLBAR_BACKGROUND_COLOR,
  borderRadius: 10,
  width: 4,
  height: '100%'
};
