/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvForward8Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >

      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM23.2222 73.0819V46.2517C23.2222 44.5925 24.6703 43.566 25.8111 44.4165L44.6103 58.4323C45.7476 59.2803 45.7157 61.3509 44.553 62.1435L25.7538 74.9579C24.6132 75.7353 23.2222 74.7045 23.2222 73.0819ZM47.6667 73.0819V46.2517C47.6667 44.5925 49.2595 43.566 50.5144 44.4165L71.1935 58.4323C72.4446 59.2803 72.4094 61.3509 71.1305 62.1435L50.4514 74.9579C49.1968 75.7353 47.6667 74.7045 47.6667 73.0819ZM39.7497 21.84L37.9697 19.3H34.8897L38.2097 23.84L34.4297 29H37.4497L39.7097 25.84L41.9497 29H45.0297L41.2497 23.82L44.5697 19.3H41.5497L39.7497 21.84ZM46.1086 24.72C46.1086 27.2 48.2886 29.2 51.1086 29.2C53.9286 29.2 56.1086 27.2 56.1086 24.72C56.1086 23.12 55.2486 21.88 54.0286 21.12C54.7486 20.46 55.2086 19.56 55.2086 18.5C55.2086 16.44 53.4286 14.8 51.1086 14.8C48.7886 14.8 47.0086 16.44 47.0086 18.5C47.0086 19.56 47.4486 20.46 48.1686 21.12C46.9686 21.88 46.1086 23.12 46.1086 24.72ZM51.1086 26.8C49.8086 26.8 48.8086 25.86 48.8086 24.6C48.8086 23.34 49.8086 22.4 51.1086 22.4C52.4086 22.4 53.4086 23.34 53.4086 24.6C53.4086 25.86 52.4086 26.8 51.1086 26.8ZM51.1086 20.1C50.2886 20.1 49.7086 19.5 49.7086 18.66C49.7086 17.82 50.2886 17.2 51.1086 17.2C51.9286 17.2 52.5086 17.82 52.5086 18.66C52.5086 19.5 51.9286 20.1 51.1086 20.1Z" fill="white" />

    </SVGWrapper>
  );
}

export default TvForward8Icon;
