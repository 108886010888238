import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

export function Forward10Icon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <mask id="mask0_4939_120078" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="2" width="28" height="30">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.4342 6.60457C21.8553 6.31308 21.8553 5.68704 21.4342 5.39556L17.6204 2.7554C17.137 2.42077 16.4792 2.76924 16.4792 3.35991V4.58477C9.73927 5.34041 4.5 11.0584 4.5 18.0001C4.5 25.4559 10.5442 31.5001 18 31.5001C25.4558 31.5001 31.5 25.4559 31.5 18.0001C31.5 14.2613 29.9802 10.8775 27.5246 8.43281C27.1096 8.01969 26.4424 8.05132 26.0311 8.46805C25.571 8.9342 25.6132 9.69162 26.0696 10.1614C28.038 12.1874 29.25 14.9522 29.25 18.0001C29.25 24.2133 24.2132 29.2501 18 29.2501C11.7868 29.2501 6.75 24.2133 6.75 18.0001C6.75 12.3026 10.9852 7.59443 16.4792 6.85199V8.64021C16.4792 9.23088 17.137 9.57935 17.6204 9.24472L21.4342 6.60457Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M21.2727 14.2501C22.9423 14.2501 24 15.6563 24 18.0001C24 20.3438 22.9423 21.7501 21.2727 21.7501C19.6031 21.7501 18.5453 20.3438 18.5453 18.0001C18.5453 15.6563 19.6031 14.2501 21.2727 14.2501ZM21.2727 20.5001C22.1126 20.5001 22.6 19.5105 22.6 18.0001C22.6 16.4896 22.1126 15.5001 21.2727 15.5001C20.4327 15.5001 19.9453 16.4896 19.9453 18.0001C19.9453 19.5105 20.4327 20.5001 21.2727 20.5001ZM14.7203 15.9792L13.1648 16.8542V15.5209L15.1351 14.3542H16.1203V20.3959H17.8313V21.6459H12.75V20.3959H14.7203V15.9792Z" fill="black" />
      </mask>
      <g mask="url(#mask0_4939_120078)">
        <rect width="36" height="36" fill={color} />
      </g>
    </SVGWrapper>
  );
}
