/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvBackLiveIconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M83.25 42C83.25 64.7817 64.7817 83.25 42 83.25C19.2183 83.25 0.75 64.7817 0.75 42C0.75 19.2183 19.2183 0.75 42 0.75C64.7817 0.75 83.25 19.2183 83.25 42ZM65.1092 24.4973C65.8363 23.994 65.8363 22.9128 65.1092 22.4095L58.523 17.8501C57.6882 17.2722 56.5521 17.874 56.5521 18.894V21.3569H40.2232L40.1768 21.3568C39.9186 21.3559 39.0725 21.3531 38.3208 21.4609C27.0813 23.0764 19.5 30.6847 19.5 42.0232C19.5 54.4977 28.926 62.625 41.8018 62.625H56.0692C56.6561 62.625 57.7489 62.0381 57.7489 60.7429C57.7489 59.4477 56.6662 58.8605 56.0692 58.8605H41.8018C31.072 58.8605 23.3856 52.4186 23.3856 42.0232C23.3856 32.7687 29.2371 26.8532 38.3208 25.2757L38.3854 25.2644C38.99 25.1592 39.0416 25.1502 40.2232 25.1502H56.5521V28.0128C56.5521 29.0328 57.6882 29.6346 58.523 29.0567L65.1092 24.4973ZM42 49.5C46.1421 49.5 49.5 46.1421 49.5 42C49.5 37.8579 46.1421 34.5 42 34.5C37.8579 34.5 34.5 37.8579 34.5 42C34.5 46.1421 37.8579 49.5 42 49.5Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvBackLiveIconActive;
