import { h } from 'preact';
import { withBreakPoints, connect } from '../../hoc';
import { PlaybackControl } from '../controls';
import { SECTION_CENTER_DEFAULT_STYLE } from './styles';

function SectionCenter({
  hidden, mobile, extraSmall, waiting, small
}) {
  return (
    <div style={SECTION_CENTER_DEFAULT_STYLE}>
      {/* show centered playback controls on mobile and "baby player" mode */
      (small || extraSmall) ? (
        <PlaybackControl
          centered
          hidden={waiting || hidden}
          mobile={mobile}
          extraSmall={extraSmall}
        />
      )
        : null
    }
    </div>
  );
}

const selector = ({ ui: { waiting } }) => ({ waiting });
export default withBreakPoints(connect(selector)(SectionCenter));
