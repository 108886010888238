import { h } from 'preact';
import { useState } from 'preact/hooks';
import { createAnimation } from '../../../utils/animation';
import IconButton from './IconButton';

const easeInOutQuad = (t) => (t < 0.5 ? 2 * t * t : -1 + ((4 - (2 * t)) * t));
function AnimatedButton({
  onClick,
  style,
  styleOverride,
  transition,
  size,
  ...props
}) {
  const [val, setVal] = useState(0);
  const [animation, setAnimation] = useState(null);

  const handleOnClick = () => {
    if (animation) return;
    setAnimation(createAnimation({
      duration: transition.duration,
      fn: (t) => setVal(easeInOutQuad(t) * transition.max),
      finish: () => setAnimation(null)
    }));
    onClick(); /* call original onClick fn */
  };

  return (
    <div style={{
      position: 'relative',
      width: size,
      height: size,
      ...styleOverride
    }}
    >
      <IconButton
        onClick={handleOnClick}
        style={{
          ...style,
          ...transition.apply(val)
        }}
        size={size}
        {...props}
      />
    </div>
  );
}

export default AnimatedButton;
