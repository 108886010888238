import { OVERLAY_COLOR_OPAQUE } from '../../../../theme/colors';

export const VOLUME_SLIDER_DEFAULT_DIALOG_STYLE = {
  flexDirection: 'column-reverse',
  display: 'flex',
  alignItems: 'center',
  background: 'transparent',
  borderRadius: 30,
  transition: 'background-color .33s ease'
};

export const VOLUME_SLIDER_OPENED_DIALOG_STYLE = {
  background: OVERLAY_COLOR_OPAQUE
};

export const VOLUME_SLIDER_DIALOG_CONTENT_STYLE = {
  opacity: 0,
  marginRight: 'initial',
  transition: 'all .2s ease-in-out',
  height: 'auto',
  maxHeight: 0,
  width: '100%',
  overflow: 'hidden'
};

export const VOLUME_SLIDER_OPENED_DIALOG_CONTENT_STYLE = {
  opacity: 1,
  maxHeight: 'none'
};

export const VOLUME_SLIDER_SIZE = {
  default: { cursorSize: 16, trackSize: 172 },
  medium: { cursorSize: 16, trackSize: 100 },
  small: { cursorSize: 12, trackSize: 70 }
};

export const VOLUME_BUTTON_SIZE = {
  extraSmall: { width: 16 },
  small: { width: 24 },
  medium: { width: 36 },
  'large+extraLarge': { width: 44 }
};
