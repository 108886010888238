/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPlaylistActiveIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <mask id="mask_playlist_active" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="30" height="30">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.665 30.33C23.7643 30.33 30.33 23.7643 30.33 15.665C30.33 7.56574 23.7643 1 15.665 1C7.56574 1 1 7.56574 1 15.665C1 23.7643 7.56574 30.33 15.665 30.33ZM8 9.29815V13.0352C8 13.2612 8.26014 13.4048 8.47344 13.2965L11.9892 11.5117C12.2067 11.4013 12.2127 11.1128 12 10.9947L8.48416 9.04255C8.27081 8.92408 8 9.06705 8 9.29815ZM24 11.3834C24 11.7423 23.7383 12.0334 23.4156 12.0334H14.4023C14.0795 12.0334 13.8179 11.7423 13.8179 11.3834C13.8179 11.0244 14.0795 10.7334 14.4023 10.7334L23.4156 10.7334C23.7383 10.7334 24 11.0244 24 11.3834ZM23.3771 17.2334C23.6998 17.2334 23.9615 16.9423 23.9615 16.5834C23.9615 16.2244 23.6998 15.9334 23.3771 15.9334L8.58443 15.9334C8.26166 15.9334 8 16.2244 8 16.5834C8 16.9423 8.26166 17.2334 8.58443 17.2334L23.3771 17.2334ZM23.3771 22C23.6998 22 23.9615 21.709 23.9615 21.35C23.9615 20.991 23.6998 20.7 23.3771 20.7L8.58443 20.7C8.26166 20.7 8 20.991 8 21.35C8 21.709 8.26166 22 8.58443 22L23.3771 22Z" fill="white" />
      </mask>
      <g mask="url(#mask_playlist_active)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>

    </SVGWrapper>
  );
}

export default TvPlaylistActiveIcon;
