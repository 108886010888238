import { h } from 'preact';

import { TV_MAIN_CONTENT, TV_MAIN_WRAPPER } from './styles';
import TVSectionBottom from './TVSectionBottom';
import { TVSectionTop } from './TVSectionTop';
import Spinner from '../spinner';
import { connect } from '../../../ui/hoc';
import TVSideOverlay from './TVSideOverlay';
import SubtitleContainer from './SubtitleContainer';
import { MAIN_GRADIENT_AD, MAIN_GRADIENT_TV } from '../../../ui/theme/colors';
import StartScreen from '../info/StartScreen';
import TVLiveSection from './TVLiveSection';
import TVAdInfos from '../info/TVAdInfos';
import TVAdPauseOverlay from './TVAdPauseOverlay';
import InteractionIcon from '../interaction-icon';

function TvMainWrapper({ UIVisible, isAd }) {
  return (
    <div style={{
      ...TV_MAIN_WRAPPER,
      backgroundImage: UIVisible ? ((isAd && MAIN_GRADIENT_AD) || MAIN_GRADIENT_TV) : ''
    }}
    >

      <TVLiveSection />
      {UIVisible && (
        <div style={TV_MAIN_CONTENT}>
          <TVSectionTop />
          <TVSectionBottom />
        </div>
      )}
      <TVAdInfos />
      <InteractionIcon />
      <TVSideOverlay />
      <Spinner />
      <SubtitleContainer />
      <StartScreen />
      <TVAdPauseOverlay />
    </div>
  );
}

export default connect(({ ui: { UIVisible }, media: { isAd } }) => ({ UIVisible, isAd }))(
  TvMainWrapper
);
