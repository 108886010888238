/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvStopIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M29.6877 27.5002C28.4796 27.5002 27.5002 28.4796 27.5002 29.6877V60.3127C27.5002 61.5209 28.4796 62.5002 29.6877 62.5002H60.3127C61.5209 62.5002 62.5002 61.5209 62.5002 60.3127V29.6877C62.5002 28.4796 61.5209 27.5002 60.3127 27.5002H29.6877Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvStopIcon;
