import { PRIMARY_FILL_COLOR } from '../../../ui/theme/colors';
import { UI_LAYER } from '../../../ui/theme/constants';

export const TITLE_WRAPPER = {
  position: 'absolute',
  width: '50%',
  height: 160,
  bottom: 180
};

export const NEXT_TITLE_WRAPPER = {
  ...TITLE_WRAPPER,
  right: 0
};

const TITLE = {
  color: PRIMARY_FILL_COLOR,
  margin: 0,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const H1 = {
  ...TITLE,
  fontSize: 50
};
export const H2 = {
  ...TITLE,
  fontSize: 30
};

export const H3 = {
  ...TITLE,
  fontSize: 20
};
export const TV_LOGO_WRAPPER = {
  largeTv: {
    position: 'absolute',
    bottom: 180,
    width: 150,
    height: 150
  },
  extraLargeTv: {
    position: 'absolute',
    bottom: 180,
    width: 200,
    height: 200
  }
};

export const START_SCREEN_PLAYBUTTON = {
  pointerEvents: 'initial',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: UI_LAYER - 1
};
export const ADS_INFOS_WRAPPER = {
  position: 'absolute',
  paddingLeft: 80,
  paddingRight: 80,
  paddingTop: 40
};
