import { RECOMMENDATIONS_CHANGED, RECOMMENDATIONS_DISPLAY_CHANGED, RECOMMENDATIONS_HIDE_VIDEO } from '../types';

const initialState = {
  hideVideo: false,
  shouldDisplay: false,
  cause: 'noReco',
  recommendations: [{
    image: '',
    badge: '',
    iconChannel: '',
    iconCsa: '',
    title: '',
    synopsis: '',
    subTitle: '',
    duration: '',
    metadata: ''
  }]
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case (RECOMMENDATIONS_DISPLAY_CHANGED): {
      const { shouldDisplay, cause } = payload;
      return { ...state, shouldDisplay, cause };
    }
    case RECOMMENDATIONS_CHANGED: {
      const { recommendations } = payload;
      return { ...state, recommendations };
    }
    case RECOMMENDATIONS_HIDE_VIDEO: {
      const { hideVideo } = payload;
      return { ...state, hideVideo };
    }
    default: {
      return state;
    }
  }
};
