/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvBackStartIconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M42 83.25C19.2183 83.25 0.75 64.7817 0.75 42C0.75 19.2183 19.2183 0.75 42 0.75C64.7817 0.75 83.25 19.2183 83.25 42C83.25 64.7817 64.7817 83.25 42 83.25ZM20.0454 22.4095C19.3182 22.9128 19.3182 23.994 20.0454 24.4973L26.6315 29.0567C27.4663 29.6346 28.6024 29.0328 28.6024 28.0128V25.1502H44.9313C46.1129 25.1502 46.1645 25.1592 46.7692 25.2644L46.8338 25.2757C55.9175 26.8532 61.7689 32.7687 61.7689 42.0232C61.7689 52.4186 54.0826 58.8605 43.3528 58.8605H29.0853C28.4883 58.8605 27.4056 59.4477 27.4056 60.7429C27.4056 62.0381 28.4984 62.625 29.0853 62.625H43.3528C56.2285 62.625 65.6545 54.4977 65.6545 42.0232C65.6545 30.6847 58.0733 23.0764 46.8338 21.4609C46.082 21.3531 45.236 21.3559 44.9778 21.3568L44.9313 21.3569H28.6024V18.894C28.6024 17.874 27.4663 17.2722 26.6315 17.8501L20.0454 22.4095Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvBackStartIconActive;
