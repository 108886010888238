import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';
import { SECONDARY_FILL_COLOR } from '../../../../theme/colors';

function CloseIcon({ height = 60, width = 60, color = SECONDARY_FILL_COLOR }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask id="mask0_close" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="2" width="12" height="12">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.05024 3.05073C3.31059 2.79038 3.7327 2.79038 3.99305 3.05073L8 7.05768L12.0069 3.05073C12.2673 2.79038 12.6894 2.79038 12.9498 3.05073C13.2101 3.31108 13.2101 3.73319 12.9498 3.99354L8.94281 8.00049L12.9497 12.0074C13.2101 12.2678 13.2101 12.6899 12.9497 12.9502C12.6894 13.2106 12.2673 13.2106 12.0069 12.9502L8 8.9433L3.99307 12.9502C3.73272 13.2106 3.31061 13.2106 3.05026 12.9502C2.78991 12.6899 2.78991 12.2678 3.05026 12.0074L7.05719 8.00049L3.05024 3.99354C2.78989 3.73319 2.78989 3.31108 3.05024 3.05073Z" fill="#F2F2F2" />
      </mask>
      <g mask="url(#mask0_close)">
        <rect width="16" height="16" fill={color} />
      </g>

    </SVGWrapper>
  );
}
export default CloseIcon;
