import { AD_PAUSEROLL } from '../../core/pauseroll/constants';
import { AD_TIME_LEFT, AD_COUNTDOWN, AD_STATUS_CHANGE, PAUSE_ROLL_IS_DISPLAYABLE, PAUSE_ROLL_CONTENT } from '../types';

const initialState = {
  adType: '',
  adTimeLeft: 0,
  countdown: 0,
  showCountdown: false,
  pauseRollIsDisplayable: false,
  pauseRollContent: ''
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AD_TIME_LEFT: {
      const { remainingTime } = payload;
      return { ...state, adTimeLeft: remainingTime };
    }
    case AD_COUNTDOWN: {
      const { time, showCountdown } = payload;
      return ({
        ...state,
        showCountdown,
        countdown: time
      });
    }
    case (AD_STATUS_CHANGE): {
      const { isAd, position } = payload;
      return ({
        ...state,
        showCountdown: isAd ? false : state.showCountdown,
        adTimeLeft: isAd ? state.adTimeLeft : 0,
        adType: isAd ? position : ''
      });
    }
    case (PAUSE_ROLL_IS_DISPLAYABLE): {
      const { pauseRollIsDisplayable } = payload;
      return {
        ...state,
        pauseRollIsDisplayable,
        adType: pauseRollIsDisplayable ? AD_PAUSEROLL : ''
      };
    }
    case (PAUSE_ROLL_CONTENT): {
      const { pauseRollContent } = payload;

      return {
        ...state,
        pauseRollContent
      };
    }
    default: {
      return state;
    }
  }
};
