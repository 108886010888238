import { h } from 'preact';
import { withBreakPoints, connect } from '../../hoc';
import { PlaybackControl, ExpControl, Timeline } from '../controls';
import { FLEX_50_VERTICAL_STYLE } from './styles';
import { useStyles } from '../../hooks';
import { DAI_AD_TYPE } from '../../../ad/dai/types';

const styles = {
  base: {
    default: {
      ...FLEX_50_VERTICAL_STYLE,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
    },
    'largeTv+extraLargeTv': {
      height: 'unset',
      flex: 'unset',
      flexDirection: 'column-reverse',
      gap: '24px'
    }
  },
  controls: {
    default: {
      display: 'flex',
      height: 55,
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      width: '100%',
      transition: 'opacity .2s ease-in-out, visibility .2s'
    },
    'largeTv+extraLargeTv': {
      justifyContent: 'start',
      gap: '76px',
      height: '90px',
      alignItems: 'center'
    }
  },
  timeline: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 15
  },
  hidden: {
    display: 'none'
  }
};

function SectionBottom({
  showCountdown,
  isTv, isAd, isLive, timeshifting, isShifting,
  hoverTimeline, isSeeking,
  hidden, extraSmall, small, isDAI, adType
}) {
  if (hidden) return null;

  const setStyles = useStyles();
  const timelineStyles = setStyles({
    default: {
      ...styles.timeline,
      display: (isLive || isAd || showCountdown) ? 'none' : 'flex',
      ...(timeshifting ? {
        display: 'flex',
        marginTop: 35
      } : {})
    },
    large: { marginTop: 50 },
    small: { marginTop: 5 },
    extraSmall: { marginTop: 0 },
    'largeTv+extraLargeTv': { marginTop: '24px' }
  });

  const controlsVisibility = isSeeking || (hoverTimeline && !isTv)
    ? { opacity: 0, visibility: 'hidden' } : { opacity: 1, visibility: 'visible' };

  return (
    <div
      style={{
        ...setStyles(styles.base),
        ...((small || extraSmall) && { gap: 15 })
      }}
    >
      <div style={{ ...setStyles(styles.controls), ...controlsVisibility }}>
        {/* hide bottom playback controls on mobile and "baby player" mode */
          (small || extraSmall)
            ? <div />
            : <PlaybackControl />
        }
        <ExpControl />
      </div>
      <div style={{
        ...timelineStyles,
        ...setStyles({
          '!medium': { marginTop: timelineStyles.marginTop - 5 }
        })
      }}
      >
        {
          (
            (!isLive && !isAd && !showCountdown)
              || (timeshifting && !isAd && !showCountdown)
              || (isDAI && isAd && adType === DAI_AD_TYPE)
              || isShifting
          ) && <Timeline />
        }
      </div>
    </div>
  );
}

const selector = ({
  ad: { showCountdown, adType },
  media: { isTv, isAd, isDAI, isLive, timeshifting: { type: timeshifting, startOverTimeshifting: isShifting } },
  ui: { hoverTimeline, isSeeking }

}) => ({
  showCountdown,
  isTv, isAd, isLive, timeshifting, isShifting,
  hoverTimeline, isSeeking, isDAI, adType
});

export default withBreakPoints(connect(selector)(SectionBottom));
