import { h } from 'preact';
import { useRef, useState, useEffect, useMemo } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../hooks';
import { connect, withBreakPoints } from '../../hoc';
import { Label, Titles } from '../common';
import {
  COMING_NEXT_TITLE,
  TITLE_WRAPPER,
  HEADER_WRAPPER,
  MAIN_TITLE_TRANSITION,
  MAIN_TITLE_TRANSITION_OUT,
  COMING_NEXT_TITLE_TRANSITION_START,
  COMING_NEXT_TITLE_TRANSITION,
  COMING_NEXT_TITLE_TRANSITION_OUT,
  COMING_NEXT_TITLE_LARGE,
  LOGO_WRAPPER
} from './styles';
import { UI_FORCE_VISIBILITY } from '../../../store/types';
import { LIVE_FILL_COLOR, PRIMARY_FILL_COLOR, OVERLAY_COLOR_OPAQUE, PRIMARY_TEXT_COLOR } from '../../theme/colors';
import ViewerLiveInfo from './ViewerLiveInfo';
import { computeInfoAfter, computeInfoMain, computeVideoTitle } from '../../../utils/videoMetaUtils';
import { selfOrKeyObject } from '../../../utils';

const TITLE_SWITCH_DELAY = 2000;
const COMING_NEXT_DELAY = 2300;

function HeaderInfos({
  containerVisible,
  id,
  isLive,
  isDVR,
  isAd,
  showTitle,
  forceShowTitle,
  videoLogo,
  numberViewers,
  displayedViewers,
  showViewers,
  comingNext = {},
  hasComingNext,
  large,
  small,
  extraSmall,
  extraLarge,
  largeTv,
  extraLargeTv,
  hidden,
  showCountdown,
  mobile,
  isFullscreen,
  isEmbed,
  isTv,
  width,
  medium,
  displayComingNextOnStart,
  startOverTimeshifting,
  liveLabel,
  waitStart,
  videoAdditionalTitle, videoTitle, videoPreTitle, subTitle, pubLabel
}) {
  const player = usePlayerContext();
  const transition = useRef(null);
  const [switchTitles, setSwitchTitles] = useState(false);
  const [switchActivated, setSwitchActivated] = useState(false);
  const [isBigScreenNotTv, setIsBigScreenNotTv] = useState(false);
  const setStyles = useStyles();

  const computedInfoMain = useMemo(
    () => computeInfoMain(waitStart, extraSmall, isLive, hasComingNext, displayComingNextOnStart),
    [waitStart, isLive, extraSmall, hasComingNext, displayComingNextOnStart]
  );

  const computedInfoAfter = useMemo(
    () => computeInfoAfter(waitStart, hasComingNext),
    [waitStart, hasComingNext]
  );

  const { computedTitle, computedSubtitle } = useMemo(
    () => computeVideoTitle(videoTitle, subTitle, videoPreTitle, videoAdditionalTitle),
    [videoTitle, subTitle, videoPreTitle, videoAdditionalTitle]
  );

  useEffect(() => {
    clearInterval(transition.current);
    setSwitchTitles(false);
    transition.current = null;
  }, [id]);

  useEffect(() => {
    setIsBigScreenNotTv((large || extraLarge) && !largeTv && !extraLargeTv);
  }, [large, extraLarge, largeTv, extraLargeTv]);

  useEffect(() => {
    if (!transition.current && containerVisible && hasComingNext && !isAd) {
      setSwitchActivated(true);

      transition.current = setTimeout(
        () => {
          setSwitchTitles(true);
          transition.current = null;
        },
        TITLE_SWITCH_DELAY
      );

      player.store.dispatch({
        type: UI_FORCE_VISIBILITY,
        payload: {
          forceVisible: !mobile
        } /* don't force visibility on mobile */
      });
    }
  }, [containerVisible]);

  useEffect(() => switchTitles && setTimeout(() => {
    setSwitchTitles(false);
    player.store.dispatch({
      type: UI_FORCE_VISIBILITY,
      payload: { forceVisible: false }
    });
    setSwitchActivated(false);
  }, COMING_NEXT_DELAY), [switchTitles]);

  const setTitleStyle = () => {
    const titleTransitionStyle = switchTitles
     && !isBigScreenNotTv
      ? MAIN_TITLE_TRANSITION_OUT
      : MAIN_TITLE_TRANSITION;
    const titleWidth = (isBigScreenNotTv && hasComingNext) ? ({ width: width / 3 }) : { width: width * (2 / 3) };

    return {
      opacity: 1,
      ...{ ...titleWidth, ...titleTransitionStyle }
    };
  };

  const setComingNextStyle = () => {
    const comingNextTransitionStyle = switchTitles ? COMING_NEXT_TITLE_TRANSITION : COMING_NEXT_TITLE_TRANSITION_START;
    const w = displayComingNextOnStart ? width : 0;

    return {
      ...(isBigScreenNotTv
        ? {
          ...COMING_NEXT_TITLE_LARGE,
          left: `${w / 3 + 30}px`,
          width: width / 3
        }
        : {
          ...COMING_NEXT_TITLE,
          ...(switchActivated
            ? comingNextTransitionStyle
            : COMING_NEXT_TITLE_TRANSITION_OUT)
        })
    };
  };

  if (hidden) return null;

  const shouldShowInfo = !isAd
    && showTitle
    && !showCountdown
    && (
      (hasComingNext && displayComingNextOnStart)
      || isEmbed || isDVR || isFullscreen || isTv || forceShowTitle
    );
  const LABEL_VIEWERS_BLOCK_STYLE = { display: 'flex', flexDirection: (extraSmall && !isFullscreen) ? 'column' : 'row' };

  const shouldDisplayLogo = Boolean(selfOrKeyObject(videoLogo, 'url')) && shouldShowInfo && isLive && !isAd;

  return (
    <div style={setStyles(HEADER_WRAPPER)}>
      {shouldDisplayLogo && (
        <div style={setStyles(LOGO_WRAPPER)}>
          <img
            src={videoLogo?.url || videoLogo}
            alt={videoLogo?.alt || 'channel logo'}
            style={{ width: '100%' }}
          />
        </div>
      )}
      <div className="ftv-magnetoscope--live-label" style={{ flexBasis: 'content' }}>

        <div style={LABEL_VIEWERS_BLOCK_STYLE}>
          { (!pubLabel || !isAd) && isLive && (

          <Label
            message={liveLabel}
            style={!startOverTimeshifting
              ? { backgroundColor: LIVE_FILL_COLOR, color: PRIMARY_TEXT_COLOR }
              : { backgroundColor: PRIMARY_FILL_COLOR, color: OVERLAY_COLOR_OPAQUE }}
          />
          )}

          {showViewers && numberViewers && displayedViewers
        && (isLive && !isAd) && (
        <ViewerLiveInfo
          connected={numberViewers}
          isFullscreen={isFullscreen}
          mobile={mobile}
          extraSmall={extraSmall}
          medium={medium}
          small={small}
          large={large}
          size={setStyles({
            'small+extraSmall': { size: 20 },
            medium: { size: 24 },
            'large+extraLarge': { size: 30 }
          }).size}
        />
          )}
        </div>

        {shouldShowInfo && (
        <div style={TITLE_WRAPPER}>
          <div style={setTitleStyle()}>
            <Titles
              info={computedInfoMain}
              title={computedTitle}
              subTitle={computedSubtitle}
              titleStyle={setStyles({
                extraSmall: { fontSize: '16px', fontWeight: 700, marginBottom: '4px' }
              })}
              subTitleStyle={setStyles({
                extraSmall: { fontSize: '12px', fontWeight: 400 }
              })}
            />
          </div>

            {!isAd
              && showTitle
              && hasComingNext
              && displayComingNextOnStart && (
                <div style={setComingNextStyle()}>
                  <Titles
                    info={computedInfoAfter}
                    title={comingNext.program}
                    subTitle={`${
                      comingNext.preTitle ? `${comingNext.preTitle} : ` : ''
                    }${comingNext.title}`}
                  />
                </div>
            )}
        </div>
        )}
      </div>
    </div>
  );
}

const selector = ({
  media: {
    id, videoLogo, isAd, isLive, comingNext, isDVR, numberViewers,
    timeshifting: { startOverTimeshifting },
    videoAdditionalTitle, videoTitle, videoPreTitle, subTitle, isTv
  },
  ad: { showCountdown },
  ui: {
    showTitle, forceShowTitle, isFullscreen, originUrl, forceVisible, introEnabled, isIntro, showViewers, displayedViewers, displayComingNextOnStart,
    liveLabel, waitStart, pubLabel
  }
}) => ({
  id,
  showTitle,
  forceShowTitle,
  videoLogo,
  displayedViewers,
  numberViewers,
  showViewers,
  isAd,
  isLive,
  isDVR,
  isTv,
  comingNext,
  hasComingNext: Boolean(comingNext?.title && comingNext?.program),
  showCountdown,
  isFullscreen,
  isEmbed: Boolean(originUrl),
  forceVisible,
  introEnabled,
  isIntro,
  displayComingNextOnStart,
  startOverTimeshifting,
  liveLabel,
  waitStart,
  videoAdditionalTitle, videoTitle, videoPreTitle, subTitle, pubLabel
});

export default withBreakPoints(connect(selector)(HeaderInfos));
