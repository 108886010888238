import systemInfo from '../../utils/systemInfo';
import { PLATFORM, UI_CONFIG } from '../types';

const initialState = {
  ...systemInfo
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UI_CONFIG: {
      const {
        showAd, pip, isLive
      } = payload;
      return ({
        ...state,
        pipSupported: systemInfo.pipSupported && !systemInfo.pipRestriction && pip && !(showAd && !isLive)
      });
    }

    case PLATFORM: {
      return { ...state, platform: payload.platform };
    }

    default:
      return state;
  }
};
