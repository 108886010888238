/* eslint-disable no-cond-assign, no-continue */
const ESCAPE = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&lrm;': '\u200e',
  '&rlm;': '\u200f',
  '&nbsp;': '\u00a0'
};

const TAG_NAME = {
  c: 'p',
  i: 'i',
  b: 'b',
  u: 'u',
  ruby: 'ruby',
  rt: 'rt',
  v: 'span',
  lang: 'span'
};

const tagNames = Object.values(TAG_NAME);

const TAG_ANNOTATION = { v: 'title', lang: 'lang' };
const NEEDS_PARENT = { rt: 'ruby' };

export default class VTTParser {
  static createElement(type, annotation) {
    const tagName = TAG_NAME[type];
    if (!tagName) return null;

    const element = window.document.createElement(tagName);
    try {
      element.localName = tagName;
    } catch (e) { /* localName is a readOnly property on most recent browsers */ }

    const name = TAG_ANNOTATION[type];
    if (name && annotation) element[name] = annotation.trim();
    return element;
  }

  static unescape1(e) {
    return ESCAPE[e];
  }

  static unescape(_m, _s) {
    let m = _m;
    let s = _s;

    while ((m = s.match(/&(amp|lt|gt|lrm|rlm|nbsp);/))) {
      s = s.replace(m[0], VTTParser.unescape1);
    }

    return s;
  }

  static shouldAdd(current, element) {
    return !NEEDS_PARENT[element.localName]
           || NEEDS_PARENT[element.localName] === current.localName;
  }

  static nextToken(input, consumeInput) {
    if (!input) return null;

    const m = input.match(/^([^<]*)(<[^>]+>?)?/);
    const result = (m[1] ? m[1] : m[2]);
    consumeInput(input.substr(result.length));

    return result;
  }

  static parseContent(raw) {
    const rootDiv = window.document.createElement('div');
    const tagStack = [];

    let input = raw; let t; let m; let current = rootDiv;
    const consumeInput = (newVal) => { input = newVal; };

    while ((t = VTTParser.nextToken(input, consumeInput)) !== null) {
      if (t[0] === '<') {
        if (t[1] === '/') {
          if (tagStack.length
              && tagStack[tagStack.length - 1] === t.substr(2).replace('>', '')) {
            tagStack.pop();
            current = current.parentNode;
          }
          continue;
        }

        m = t.match(/^<([^.\s/0-9>]+)(\.[^\s\\>]+)?([^>\\]+)?(\\?)>?$/);
        if (!m) continue;

        const node = VTTParser.createElement(m[1], m[3]);

        if (!node) continue;
        if (!VTTParser.shouldAdd(current, node)) continue;
        if (m[2]) node.className = m[2].substr(1).replace('.', ' ');

        tagStack.push(m[1]);
        current.appendChild(node);
        current = node;
        continue;
      }

      const tokens = t.split('\n');
      if (t !== '\n' && tokens.length > 1) {
        // eslint-disable-next-line no-loop-func
        tokens.forEach((tk, i) => {
          current.appendChild(window.document.createTextNode(VTTParser.unescape(m, tk)));
          if (i < tokens.length - 1 && !tagNames.includes(current.tagName.toLowerCase())) {
            current.appendChild(document.createElement('br'));
          }
        });
      } else {
        current.appendChild(window.document.createTextNode(VTTParser.unescape(m, t)));
      }
    }

    return rootDiv;
  }

  static parse(cuetext) {
    if (!cuetext) return null;
    return VTTParser.parseContent(cuetext);
  }
}
