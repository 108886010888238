import { START_SCREEN_BUTTON_SIZE } from '../../../ui/components/info/styles';

const ICON_MARGIN = Math.round(START_SCREEN_BUTTON_SIZE / 2);
export const INTERACTION_ICON_STYLE = {
  position: 'absolute',
  left: `calc(calc(50%) - ${ICON_MARGIN}px)`,
  top: `calc(calc(50%) - ${ICON_MARGIN}px)`
};

export const SPEED_ICONS = {
  '-64': 'tv-rewind-64',
  '-32': 'tv-rewind-32',
  '-16': 'tv-rewind-16',
  '-4': 'tv-rewind-4',
  1: 'tv-play-active',
  4: 'tv-forward-4',
  16: 'tv-forward-16',
  32: 'tv-forward-32',
  64: 'tv-forward-64'
};
