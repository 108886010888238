/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvTracksIconOutline({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.75 29.75H66.25V57.25H23.75L23.75 29.75ZM20 28.5C20 27.1193 21.1193 26 22.5 26H67.5C68.8807 26 70 27.1193 70 28.5V58.5C70 59.8807 68.8807 61 67.5 61H64.375V67.3572C64.375 68.4709 63.0286 69.0286 62.2411 68.2411L55 61H22.5C21.1193 61 20 59.8807 20 58.5V28.5ZM54.375 49.75C53.3395 49.75 52.5 50.5895 52.5 51.625C52.5 52.6605 53.3395 53.5 54.375 53.5H60.625C61.6605 53.5 62.5 52.6605 62.5 51.625C62.5 50.5895 61.6605 49.75 60.625 49.75H54.375ZM26.25 45.375C26.25 44.3395 27.0895 43.5 28.125 43.5H34.375C35.4105 43.5 36.25 44.3395 36.25 45.375C36.25 46.4105 35.4105 47.25 34.375 47.25H28.125C27.0895 47.25 26.25 46.4105 26.25 45.375ZM28.125 49.75C27.0895 49.75 26.25 50.5895 26.25 51.625C26.25 52.6605 27.0895 53.5 28.125 53.5H46.875C47.9105 53.5 48.75 52.6605 48.75 51.625C48.75 50.5895 47.9105 49.75 46.875 49.75H28.125ZM40 45.375C40 44.3395 40.8395 43.5 41.875 43.5H60.625C61.6605 43.5 62.5 44.3395 62.5 45.375C62.5 46.4105 61.6605 47.25 60.625 47.25H41.875C40.8395 47.25 40 46.4105 40 45.375Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvTracksIconOutline;
