import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function Flag({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_7588_35674" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="7" y="1" width="18" height="30">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 2C7.25 1.58579 7.58579 1.25 8 1.25C8.41421 1.25 8.75 1.58579 8.75 2V2.32688C8.75 2.42862 8.81165 2.52021 8.90591 2.5585L24.2823 8.80515C24.5685 8.92142 24.7541 9.20128 24.7499 9.51017C24.7457 9.81906 24.5526 10.0938 24.2633 10.2022L8.91222 15.9589C8.81464 15.9955 8.75 16.0888 8.75 16.193L8.75 30C8.75 30.4142 8.41421 30.75 8 30.75C7.58579 30.75 7.25 30.4142 7.25 30V2ZM9.09409 4.254C9.01702 4.22269 8.92942 4.23177 8.8604 4.27823C8.79138 4.32468 8.75 4.40243 8.75 4.48562V14.057C8.75 14.139 8.79021 14.2158 8.8576 14.2625C8.92499 14.3092 9.01101 14.3199 9.08778 14.2911L21.3391 9.69682C21.4356 9.66066 21.4999 9.56909 21.5013 9.46613C21.5027 9.36316 21.4409 9.26988 21.3455 9.23112L9.09409 4.254Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7588_35674)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export { Flag };
