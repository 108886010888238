import { PLAYLIST_CHANGED } from '../types';

const initalState = {
  playlist: [],
  playlistIdsAsString: ''
};

export default (state = initalState, { type, payload }) => {
  switch (type) {
    case PLAYLIST_CHANGED: {
      const { playlist } = payload;
      return { ...state, playlist, playlistIdsAsString: playlist.reduce((s, { src }) => `${s},${src}`, '') };
    }
    default: {
      return state;
    }
  }
};
