import { h } from 'preact';
import TVLabel from '../common/label/TVLabel';
import { connect } from '../../../ui/hoc';
import { ADS_INFOS_WRAPPER } from './styles';

function TVAdInfos({ isAd, showCountdown, pubLabel }) {
  return ((isAd || showCountdown) && pubLabel && (
    <div style={ADS_INFOS_WRAPPER}>
      <TVLabel message={pubLabel} />
    </div>
  ));
}

const selector = ({
  media: { isAd },
  ad: { showCountdown },
  ui: { pubLabel }
}) => ({
  isAd,
  showCountdown,
  pubLabel
});
export default connect(selector)(TVAdInfos);
