import { from, of } from 'rxjs';
import systemInfo from './systemInfo';
import Logger from './logger';
import { AUTO_PLAY_POLICY_ERROR } from '../error/types';
import { VIDEO, IMAGE } from './media';
import { TV_PLATFORMS } from '../core/dom/types';
import { PLATFORM_TV } from '../core/media/types';

export default class VideoUnlocker {
  constructor({ max }) {
    const pool = [...Array(max).keys()].map(() => VideoUnlocker.createVideoElement(document));

    const { isMobile, browser } = systemInfo;
    const isSafari = browser === 'safari';
    this.getAutoplay = ({ showAd, preferences: { mute = false } = {}, platform }) => this.checkAutoPlay(showAd, mute, platform);
    this.videoTag = null;
    this.getVideoTag = this.createVideoGetter(pool);
    this.unlock = VideoUnlocker.createUnlocker(pool, { isMobile, isSafari });
  }

  checkAutoPlay(showAd, mute, platform) {
    /**
     * autoplay is considered disabled on mobile or tablet by default
     * Check if browser disables autoplay support before first user interaction :
     * If the play Promise succeeds it'll be pending so we need to race it.
     */
    // if (mobile) return of(false);

    if (PLATFORM_TV.includes(platform)) return from(of(true));

    const vid = this.getVideoTag({ mute, platform });
    /* MP4 BLOB to avoid Safari and FF not handling calling play() on video with empty src */
    vid.src = URL.createObjectURL(VIDEO);

    vid.muted = mute && !showAd;

    const enabled = Promise.resolve(vid.play())
      .then(() => {
        URL.revokeObjectURL(vid.src);
        return true;
      })
      .catch((e) => {
        Logger.warn(AUTO_PLAY_POLICY_ERROR, e);
        return false;
      });

    if (systemInfo.isIOS && systemInfo.isMobile) {
      /* Reset video src and hard reload to avoid empty video as FS on tunnel video */
      vid.src = '';
      vid.load();
    }

    return from(enabled);
  }

  static createUnlocker(_pool, { isMobile, isSafari }) {
    /**
     * since getVideoTag shifts the original pool array :
     * unlock needs a copy of the original array ([..._pool])
     */
    const pool = [..._pool];
    let unlocked = !(isMobile || isSafari);

    return () => {
      if (unlocked) return Promise.resolve();
      return new Promise((resolve) => {
        pool
          .filter((videoEl) => !videoEl.dirty)
          .forEach((videoEl) => videoEl.load());
        unlocked = true;
        resolve();
      });
    };
  }

  createVideoGetter(videoEls) {
    return ({ mute = false, platform = '' } = {}) => {
      if (TV_PLATFORMS.includes(platform) && this.videoTag) {
        return this.videoTag;
      }
      this.videoTag = ((videoEls.length > 0) ? videoEls.shift() : VideoUnlocker.createVideoElement(document));

      this.videoTag.setAttribute('muted', mute);
      this.videoTag.muted = mute;

      /* In webviewes on Android, the system loads a default poster if there is none.
         This default poster has no cross-origin allowance, thus crashing the video tag.
         We force a 1px black image as poster to avoid this bug.
      */
      this.videoTag.setAttribute('poster', IMAGE);
      return this.videoTag;
    };
  }

  static createVideoElement(doc) {
    const videoEl = doc.createElement('video');

    videoEl.setAttribute('playsinline', true);
    videoEl.volume = 1;

    videoEl.controls = false;
    videoEl.style.width = '100%';
    videoEl.style.height = '100%';
    videoEl.style.position = 'absolute';
    videoEl.style.backgroundColor = 'black';
    videoEl.crossOrigin = 'anonymous';

    return videoEl;
  }
}
