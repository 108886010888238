import { VOID_FN } from '../../utils';

export const createAnimation = ({ duration, fn = VOID_FN, finish = VOID_FN }) => {
  let request;
  function wrappedAnimation({ now, start = now }) {
    if (now - start >= duration) {
      fn(1);
      return finish();
    }
    fn(((now - start) / duration).toFixed(2));
    request = window.requestAnimationFrame((t) => wrappedAnimation({ now: t, start }));
    return request;
  }
  request = window.requestAnimationFrame((now) => wrappedAnimation({ now }));

  return ({
    cancel: () => window.cancelAnimationFrame(request),
    id: request
  });
};
