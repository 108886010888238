import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function Text10Icon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
    >

      <mask id="mask0_text10" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="2" width="28" height="30">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.5531 23.4722L19.6271 22.3056V28.1944H17V29.8611H23.7751V28.1944H21.4937V20.1389H20.1802L17.5531 21.6944V23.4722Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M32 25C32 21.875 30.5897 20 28.3636 20C26.1374 20 24.7271 21.875 24.7271 25C24.7271 28.125 26.1374 30 28.3636 30C30.5897 30 32 28.125 32 25ZM30.1334 25C30.1334 27.0139 29.4835 28.3333 28.3636 28.3333C27.2436 28.3333 26.5937 27.0139 26.5937 25C26.5937 22.9861 27.2436 21.6667 28.3636 21.6667C29.4835 21.6667 30.1334 22.9861 30.1334 25Z" fill="black" />
      </mask>
      <g mask="url(#mask0_text10)">
        <rect width="48" height="48" fill={color} />
      </g>

    </SVGWrapper>

  );
}

export default Text10Icon;
