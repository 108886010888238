import { PRIMARY_FILL_COLOR } from '../../../ui/theme/colors';

export const ERROR_STYLES = {
  wrapper: {
    margin: 'auto',
    fontFamily: 'Brown',
    fontWeight: 400,
    fontSize: '18px',
    padding: '0 80px'
  },
  errorCode: {
    default: { display: 'block' }
  },
  errorMessage: {
    display: 'block',
    width: '100%',
    margin: '40px 0'
  },
  errorButtons: { textAlign: 'center' },
  errorButton: {
    default: {
      marginRight: 20,
      marginLeft: 20,
      marginTop: 0,
      display: 'inline-block',
      lineHeight: '27px',
      border: '1.75px solid #F0F1F3',
      borderRadius: 72,
      color: PRIMARY_FILL_COLOR
    },
    extraLarge: {
      height: 72,
      fontSize: 30,
      lineHeight: '42px',
      padding: '12px 30px'
    },
    large: {
      height: 48,
      fontSize: 20,
      lineHeight: '27px',
      padding: '8px 20px'
    }
  }
};
