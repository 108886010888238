/* eslint-disable react/no-unknown-property */
import { Fragment, h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvForward10IconActive({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 84 84"
      fill="none"
    >
      <Fragment>
        <path d="M49.6668 42.0253C49.6668 44.5427 48.8545 46.192 47.4545 46.192C46.0546 46.192 45.2422 44.5427 45.2422 42.0253C45.2422 39.508 46.0546 37.8587 47.4545 37.8587C48.8545 37.8587 49.6668 39.508 49.6668 42.0253Z" fill="#F8F8F8" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M83.25 42C83.25 64.7817 64.7817 83.25 42 83.25C19.2183 83.25 0.75 64.7817 0.75 42C0.75 19.2183 19.2183 0.75 42 0.75C64.7817 0.75 83.25 19.2183 83.25 42ZM47.7237 20.9927C48.4254 21.4773 48.4254 22.5179 47.7237 23.0025L41.3674 27.3913C40.5617 27.9476 39.4653 27.3683 39.4653 26.3864V23.4138C30.3087 24.648 23.25 32.4746 23.25 41.9457C23.25 52.2741 31.6447 60.6469 42 60.6469C52.3553 60.6469 60.75 52.2741 60.75 41.9457C60.75 36.8791 58.7299 32.283 55.4493 28.9151C54.6886 28.1342 54.6183 26.8751 55.3852 26.1002C56.0707 25.4075 57.1827 25.3549 57.8743 26.0417C61.9669 30.1055 64.5 35.7305 64.5 41.9457C64.5 54.3398 54.4264 64.3872 42 64.3872C29.5736 64.3872 19.5 54.3398 19.5 41.9457C19.5 30.4062 28.2321 20.901 39.4653 19.6449V17.6088C39.4653 16.6269 40.5617 16.0476 41.3674 16.6039L47.7237 20.9927ZM52.0001 42.0253C52.0001 38.1191 50.2372 35.7753 47.4545 35.7753C44.6719 35.7753 42.909 38.1191 42.909 42.0253C42.909 45.9316 44.6719 48.2753 47.4545 48.2753C50.2372 48.2753 52.0001 45.9316 52.0001 42.0253ZM33.9414 40.1156L36.5339 38.6573V46.0184H33.2501V48.1017H41.719V46.0184H38.8672V35.9489H37.2253L33.9414 37.8934V40.1156Z" fill="#F8F8F8" />

      </Fragment>
    </SVGWrapper>
  );
}

export default TvForward10IconActive;
