/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvForward4Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >

      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM23.2222 73.0819V46.2517C23.2222 44.5925 24.6703 43.566 25.8111 44.4165L44.6103 58.4323C45.7476 59.2803 45.7157 61.3509 44.553 62.1435L25.7538 74.9579C24.6132 75.7353 23.2222 74.7045 23.2222 73.0819ZM47.6667 73.0819V46.2517C47.6667 44.5925 49.2595 43.566 50.5144 44.4165L71.1935 58.4323C72.4446 59.2803 72.4094 61.3509 71.1305 62.1435L50.4514 74.9579C49.1968 75.7353 47.6667 74.7045 47.6667 73.0819ZM38.0466 24.5067L36.2666 21.9667H33.1866L36.5066 26.5067L32.7266 31.6667H35.7466L38.0066 28.5067L40.2466 31.6667H43.3266L39.5466 26.4867L42.8666 21.9667H39.8466L38.0466 24.5067ZM55.0815 14.8667H52.6815L44.0415 25.9067V27.8267H51.9135V31.6667H55.0815V27.8267H57.8415V24.9467H55.0815V14.8667ZM51.9135 24.9467H48.6495L51.9135 20.7467V24.9467Z" fill="white" />
    </SVGWrapper>
  );
}

export default TvForward4Icon;
