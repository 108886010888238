import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { connect, withBreakPoints } from '../../../hoc';
import { embed } from '../../../../configuration';
import {
  EMBED_POPUP_TITLE,
  EMBED_POPUP_TEXTAREA,
  EMBED_POPUP_TEXTAREA_SELECTED,
  EMBED_POPUP_COPIED,
  EMBED_POPUP_WRAPPER
} from './styles';
import { useStyles } from '../../../hooks';
import { TEXT_LEVEL_2_FONT_SIZE } from '../../../styles';
import { VOID_FN } from '../../../../utils';

function EmbedScreen({
  embedKey,
  copied,
  copyEmbedIframe,
  onCopyFail = VOID_FN
}) {
  const textArea = useRef(null);
  const setStyles = useStyles();

  const embedIframe = `<iframe width="560" height="315" src="${embed.iframe}${embedKey}" frameborder="0" scrolling="no" allowfullscreen></iframe>`;
  const copyEmbedLink = (e) => {
    e.stopPropagation();
    textArea.current.select();
    document.execCommand('copy');
    copyEmbedIframe();
  };

  useEffect(() => { if (!embedKey) onCopyFail(); }, [embedKey]);

  return (
    embedKey
    && (
    <div
      style={setStyles(EMBED_POPUP_WRAPPER)}
    >
      <div style={{ ...setStyles(EMBED_POPUP_TITLE) }}>code à integrer</div>
      <div
        style={{
          ...setStyles(TEXT_LEVEL_2_FONT_SIZE),
          ...EMBED_POPUP_TEXTAREA
        }}
        onClick={(e) => {
          copyEmbedLink(e);
        }}
        role="presentation"
      >
        <span style={copied ? EMBED_POPUP_TEXTAREA_SELECTED : {}}>{embedIframe}</span>
        <textarea
          className="embed-container"
          style={{ height: 0, width: 0, opacity: 0 }}
          value={embedIframe}
          ref={textArea}
        />
      </div>
      <div style={setStyles(EMBED_POPUP_COPIED)}>
        <span hidden={!copied}>copié !</span>
      </div>
    </div>
    )
  );
}

const selector = ({ ui: { embedKey }, media: { embedError } }) => ({ embedKey, embedError });
export default withBreakPoints(connect(selector)(EmbedScreen));
