import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function LeftArrowIcon({ height = 60, width = 60, color = 'white', ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >

      <mask id="mask0_left_arrow" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="4" y="2" width="7" height="12">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.81307 2.14645C10.0083 1.95118 10.3249 1.95118 10.5202 2.14645C10.7154 2.34171 10.7154 2.65829 10.5202 2.85355L5.33792 8L10.5202 13.1464C10.7154 13.3417 10.7154 13.6583 10.5202 13.8536C10.3249 14.0488 10.0083 14.0488 9.81307 13.8536L4.23402 8.2357C4.16921 8.17044 4.13695 8.08507 4.13721 7.9998C4.13705 7.91466 4.16931 7.82946 4.23402 7.7643L9.81307 2.14645Z" fill="#F2F2F2" />

      </mask>
      <g mask="url(#mask0_left_arrow)">
        <rect width="16" height="16" fill={color} />
      </g>
    </SVGWrapper>
  );
}
export default LeftArrowIcon;
