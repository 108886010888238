export const NON_PUBBABLE_PAUSEROLL = 'non_pubbable_pauseroll';
export const NON_PUBBABLE = 'non_pubbable';
export const NON_PAUSEROLLABLE = 'non_pauserollable';
export const PAUSEROLL = 'pauseroll';
export const PAUSEROLL_AD_BLOCKED = 'adblocage';
export const PAUSEROLL_PLAY = 'lecture';
export const PAUSEROLL_TIMER = 'compteur';
export const PAUSEROLL_NO_PUB = 'no_pub';
export const PAUSEROLL_CAPPING_PREROLL = 'capping_preroll';
export const PAUSEROLL_CAPPING_PAUSEROLL = 'capping_pauseroll';
export const PAUSEROLL_ACTIVITY = 'activite';
export const PAUSEROLL_LEAVE_TAB = 'onglet';
export const PAUSEROLL_CAPPING_MAX = 'capping_max';
export const PAUSEROLL_CAUSE_EMPTY = '';
export const PAUSEROLL_CAUSE_PUB = 'lecture_pub';

export const PAUSEROLL_FLAG_STOP_EVENTS = [
  NON_PUBBABLE_PAUSEROLL, NON_PUBBABLE, NON_PAUSEROLLABLE,
  PAUSEROLL_AD_BLOCKED, PAUSEROLL_CAPPING_MAX, PAUSEROLL_NO_PUB
];
export const PAUSEROLL_FLAG = [PAUSEROLL_CAPPING_PREROLL, PAUSEROLL_CAPPING_PAUSEROLL, PAUSEROLL_CAUSE_PUB];

export const PAUSEROLL_SHOW_MARGIN = 1 * 1000; // 1s
export const PAUSEROLL_SERVER_TIME_MULTIPLIER = 1000; // 1s to ms

export const AD_PAUSEROLL = 'pauseroll';
