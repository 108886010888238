export const FULLSCREEN_SHORTCUT = 'fullscreenShortcut';
export const PLAY_PAUSE_SHORTCUT = 'playpauseShortcut';
export const STOP_SHORTCUT = 'stopShortcut';
export const MUTE_SHORTCUT = 'muteShortcut';
export const FORWARD_SHORTCUT = 'forwardShortcut';
export const REWIND_SHORTCUT = 'rewindShortcut';
export const VOLUME_UP_SHORTCUT = 'volumeUpShortcut';
export const VOLUME_DOWN_SHORTCUT = 'volumeDownShortcut';
export const PIP_SHORTCUT = 'pipShortcut'; // I
export const AUDIO_TRACK_SHORTCUT = 'audioTrackShortcut';
export const CAPTIONS_SHORTCUT = 'captionsShortcut';
export const SPEED_SHORTCUT = 'speedShortcut';
export const CLICKTHROUGH_SHORTCUT = 'clickthroughShortcut';
export const FULLSCREEN_DBL_CLICK_SHORTCUT = 'fullscreenDoubleClickShortcut';
export const PLAY_PAUSE_CLICK_SHORTCUT = 'playPauseClickShortcut';
export const CLOSE_ZAPPING_SHORTCUT = 'closeZappingShortcut';
export const TIMELINE_PERCENT_SHORTCUT = 'timelinePercentShortcut';
export const FOCUS_NEXT_ELEMENT = 'focusNextElement';
export const FOCUS_PREVIOUS_ELEMENT = 'focusPreviousElement';
export const KEYBOARD_FOCUS = 'keyboardFocus';
export const FORWARD_PERCENT_SHORTCUT = 'forwardPercentShortcut';
export const REWIND_PERCENT_SHORTCUT = 'rewindPercentShortcut';
export const SELECT_SHORTCUT = 'selectShortcut';
export const TV_FORWARD = 'forward';
export const TV_REWIND = 'rewind';

export const SHORTCUT_MAP = {
  70: FULLSCREEN_SHORTCUT, // F
  32: PLAY_PAUSE_SHORTCUT, // SPACE
  75: PLAY_PAUSE_SHORTCUT, // K
  77: MUTE_SHORTCUT, // M
  39: FORWARD_SHORTCUT, // ->
  37: REWIND_SHORTCUT, // <-
  38: VOLUME_UP_SHORTCUT, // ⬆️
  40: VOLUME_DOWN_SHORTCUT, // ⬇️
  73: PIP_SHORTCUT, // I
  66: AUDIO_TRACK_SHORTCUT, // B
  67: CAPTIONS_SHORTCUT, // C
  88: SPEED_SHORTCUT, // X
  48: TIMELINE_PERCENT_SHORTCUT, // 0
  49: TIMELINE_PERCENT_SHORTCUT, // 1
  50: TIMELINE_PERCENT_SHORTCUT, // 2
  51: TIMELINE_PERCENT_SHORTCUT, // 3
  52: TIMELINE_PERCENT_SHORTCUT, // 4
  53: TIMELINE_PERCENT_SHORTCUT, // 5
  54: TIMELINE_PERCENT_SHORTCUT, // 6
  55: TIMELINE_PERCENT_SHORTCUT, // 7
  56: TIMELINE_PERCENT_SHORTCUT, // 8
  57: TIMELINE_PERCENT_SHORTCUT, // 9
  96: TIMELINE_PERCENT_SHORTCUT, // 0
  97: TIMELINE_PERCENT_SHORTCUT, // 1
  98: TIMELINE_PERCENT_SHORTCUT, // 2
  99: TIMELINE_PERCENT_SHORTCUT, // 3
  100: TIMELINE_PERCENT_SHORTCUT, // 4
  101: TIMELINE_PERCENT_SHORTCUT, // 5
  102: TIMELINE_PERCENT_SHORTCUT, // 6
  103: TIMELINE_PERCENT_SHORTCUT, // 7
  104: TIMELINE_PERCENT_SHORTCUT, // 8
  105: TIMELINE_PERCENT_SHORTCUT, // 9
  9: KEYBOARD_FOCUS, // TAB
  [FOCUS_NEXT_ELEMENT]: FOCUS_NEXT_ELEMENT, // TAB
  [FOCUS_PREVIOUS_ELEMENT]: FOCUS_PREVIOUS_ELEMENT, // SHIFT + TAB
  playPauseClickShortcut: PLAY_PAUSE_CLICK_SHORTCUT,
  clickthroughShortcut: CLICKTHROUGH_SHORTCUT,
  fullscreenDoubleClickShortcut: FULLSCREEN_DBL_CLICK_SHORTCUT,
  closeZappingShortcut: CLOSE_ZAPPING_SHORTCUT,
  speedShortcut: SPEED_SHORTCUT,
  [STOP_SHORTCUT]: STOP_SHORTCUT,
  [FORWARD_PERCENT_SHORTCUT]: FORWARD_PERCENT_SHORTCUT,
  [REWIND_PERCENT_SHORTCUT]: REWIND_PERCENT_SHORTCUT
};

export const NUMERIC_SHORTCUT_MAP = {
  48: 0, // 0
  49: 10, // 1
  50: 20, // 2
  51: 30, // 3
  52: 40, // 4
  53: 50, // 5
  54: 60, // 6
  55: 70, // 7
  56: 80, // 8
  57: 90, // 9
  96: 0, // 0
  97: 10, // 1
  98: 20, // 2
  99: 30, // 3
  100: 40, // 4
  101: 50, // 5
  102: 60, // 6
  103: 70, // 7
  104: 80, // 8
  105: 90 // 9
};

export const BUTTON_CLASS = 'ftv-magneto--btn';
export const NO_FUCUS_RING = 'no-focus-ring';
export const PLAY_BUTTON_NAME = 'btn-play';
export const SLIDER_CLASS = 'ftv-magneto--slider';
export const SLIDER_NAME = 'slider-timeline';
export const BUTTON_NAME_PAUSE = 'pause';
export const BUTTON_NAME_PLAY = 'play';
export const BUTTON_NAME_STOP = 'stop';
export const BUTTON_NAME_RESUME_PAUSEROLL = 'pauseroll-retake';
export const SELECTOR_PAUSEROLL = '.adpause';

export const LEFT_ARROW_KEYCODE = 37;
export const UP_ARROW_KEYCODE = 38;
export const RIGHT_ARROW_KEYCODE = 39;
export const DOWN_ARROW_KEYCODE = 40;
export const SELECT_KEYCODE = 13;

export const ENTER_BUTTTONS_LIST = ['VK_ENTER', 'Enter', 13, 57397];

export const TV_OWN_KEYCODE_GENERICS_MAP = [
  /* left */ { mapTo: LEFT_ARROW_KEYCODE, key: [37, 'VK_LEFT', 'Left', 'ArrowLeft'] },
  /* up */ { mapTo: UP_ARROW_KEYCODE, key: [38, 'VK_UP', 'Up', 'ArrowUp'] },
  /* right */ { mapTo: RIGHT_ARROW_KEYCODE, key: [39, 'VK_RIGHT', 'Right', 'ArrowRight'] },
  /* down */ { mapTo: DOWN_ARROW_KEYCODE, key: [40, 'VK_DOWN', 'Down', 'ArrowDown'] },
  /* OK/Enter */ { mapTo: SELECT_KEYCODE, key: ENTER_BUTTTONS_LIST }
];

export const KEYCODE_GENERICS_MAP = [
  /* play_pause */ { mapTo: 32, key: ['VK_PLAY_PAUSE', 179, 'XF86PlayBack', 10252, 'U+280C', 'MediaPlayPause'] },
  /* pause */ { mapTo: 32, key: ['Pause', 'XF86AudioPause', 'VK_PAUSE', 35, 19, 'MediaPause'] },
  /* play */ { mapTo: 32, key: ['U+019F', 'XF86AudioPlay', 'VK_PLAY', 415, 36, 'MediaPlay'] },
  /* forward */ { mapTo: TV_FORWARD, key: ['MediaFastForward', 'XF86AudioNext', 'VK_FAST_FWD', 228, 34, 417] },
  /* rewind */ { mapTo: TV_REWIND, key: ['MediaRewind', 'XF86AudioRewind', 'VK_REWIND', 227, 33, 412] },
  /* next */ { mapTo: FORWARD_PERCENT_SHORTCUT, key: ['XF86NextChapter', 176, 'MediaTrackNext'] },
  /* previous */ { mapTo: REWIND_PERCENT_SHORTCUT, key: ['XF86PreviousChapter', 177, 'MediaTrackPrevious'] },
  /* select */ { mapTo: SELECT_SHORTCUT, key: ['VK_ENTER', 'Enter', 13, 57397] },
  /* stop */ { mapTo: STOP_SHORTCUT, key: ['MediaStop', 'XF86AudioStop', 'VK_STOP', 178, 83, 413] }

  // TODO: should be handled in Components
  // /* back */ { mapTo: 32, key: ['RCUBack', 'VK_BACK_SPACE', 'U+01CD', 65, 8, 166,
  // 'U+0042', 461, 'VK_BACK', 57396, 'XF86Back', 'BrowserBack', 'GoBack'] },
];
export const BACK_BTN_SHORTCUTS = [
  'RCUBack',
  'VK_BACK_SPACE',
  'U+01CD',
  65,
  8,
  166,
  'U+0042',
  461,
  'VK_BACK',
  57396,
  'XF86Back',
  'BrowserBack',
  'GoBack',
  'Back'
];
/* IDs */
export const BTN_BACK_HTML_ID = 'btn-error-back';
export const BTN_RETRY_HTML_ID = 'btn-error-retry';
export const ERROR_CONTAINER_CLASS = 'ftv-magneto--container-error';
export const TV_REMOTE_SPEEDS = [-64, -32, -16, -4, 1, 4, 16, 32, 64];
export const TV_DEFAULT_SPEED_INDEX = TV_REMOTE_SPEEDS.indexOf(1);
export const TV_DEFAULT_SPEED = TV_REMOTE_SPEEDS[TV_DEFAULT_SPEED_INDEX];
export const TV_SPEED_INTERVAL = 250; // milliseconds
export const TV_SPEED_JUMP = 7.5; // seconds
export const TV_REMOTE_SPEED_CODES = KEYCODE_GENERICS_MAP.filter(({ mapTo }) => [TV_FORWARD, TV_REWIND].includes(mapTo));
export const TV_INTERACTION_ICON_DURATION = 500; // ms
