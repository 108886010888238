import { Fragment, h } from 'preact';
import { connect } from '../../../ui/hoc';
import { COMING_NEXT, NEXT } from '../../../types';
import { UI_SKIP_INTRO_CLICKED, UI_SKIP_RECAP_CLICKED } from '../../../store/types';
import { SKIP_BTN_INTRO_OVERRIDE } from '../../../ui/components/common/buttons/styles';
import { TVSkipButton } from '../common/skipbutton';

function TVSkipButtonContainer({
  isAd,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next
}) {
  return (
    <Fragment>
      {(!isAd && (
      <TVSkipButton
        duration={skipIntroDuration}
        timecode={skipIntroTimeCode}
        label="passer le générique"
        show={showSkipIntro}
        style={SKIP_BTN_INTRO_OVERRIDE}
        clickEvent={UI_SKIP_INTRO_CLICKED}
      />
      )) }
      {(!isAd && (
      <TVSkipButton
        duration={skipRecapDuration}
        timecode={skipRecapTimeCode}
        label="passer le récap"
        show={showSkipRecap}
        clickEvent={UI_SKIP_RECAP_CLICKED}
      />
      )) }
      {(!isAd && next && (
      <TVSkipButton
        label="vidéo suivante"
        show={showComingNext}
        clickEvent={{ name: NEXT, payload: COMING_NEXT }}
        seek={false}
      />
      ))}
    </Fragment>
  );
}

const selector = ({
  media: {
    isAd,
    skipIntro: { timecode: skipIntroTimeCode, duration: skipIntroDuration },
    skipRecap: { timecode: skipRecapTimeCode, duration: skipRecapDuration }
  },
  ui: { showSkipIntro, showSkipRecap, showComingNext, next }
}) => ({
  isAd,
  skipIntroDuration,
  skipIntroTimeCode,
  showSkipIntro,
  showSkipRecap,
  skipRecapDuration,
  skipRecapTimeCode,
  showComingNext,
  next
});

const TVSkipButtonWrapper = connect(selector)(TVSkipButtonContainer);
export {
  TVSkipButtonWrapper
};
