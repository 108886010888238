import { QUALITY_MAP, STANDARD_QUALITY, SUPERIOR_QUALITY } from '../core/quality/types';

export const mapQualities = (qualities) => qualities
  .filter(({ key }) => QUALITY_MAP.has(key))
  .map(({ key, levels, level }) => {
    const { getDescription, label, icon, iconActive } = QUALITY_MAP.get(key);
    const description = getDescription(key === STANDARD_QUALITY && !qualities.find((q) => q.key === SUPERIOR_QUALITY));

    return { label, description, levels, level, icon, iconActive };
  });
