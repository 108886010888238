import { textStyleOnXLines } from '../../styles';
import { ACTIVE_ITEM_BACKGROUND, LABELS_FILL_COLOR, PRIMARY_FILL_COLOR } from '../../theme/colors';

export const ZAP_PROGRAM_WRAPPER = {
  default: {
    display: 'flex',
    gap: 16,
    padding: 16,
    alignItems: 'center',
    fontFamily: 'Brown'
  },
  small: {
    gap: 12,
    padding: 12
  }
};

export const ZAP_PROGRAM_PREVIEW = {
  default: {
    top: 0,
    left: 0,
    width: 100,
    minWidth: 100,
    backgroundColor: '#5C6770',
    borderRadius: 3,
    overflow: 'hidden',
    aspectRatio: '16/9'
  },
  small: {
    width: 77,
    minWidth: 77
  }
};

export const ZAP_PROGRAM_DEFAULT_IMAGE_SVG = `data:image/svg+xml;base64,${btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="98" height="57" viewBox="0 0 98 57" fill="none">
<path d="M48.9769 76.2039C75.5429 76.2039 97.0789 54.6679 97.0789 28.1019C97.0789 1.53597 75.5429 -20 48.9769 -20C22.411 -20 0.875 1.53597 0.875 28.1019C0.875 54.6679 22.411 76.2039 48.9769 76.2039Z" fill="#172128"/>
<path d="M48.9836 61.2192C67.2736 61.2192 82.1005 46.3922 82.1005 28.1023C82.1005 9.81229 67.2736 -5.01465 48.9836 -5.01465C30.6936 -5.01465 15.8667 9.81229 15.8667 28.1023C15.8667 46.3922 30.6936 61.2192 48.9836 61.2192Z" fill="url(#paint0_radial_7912_52935)"/>
<path d="M48.8268 45.3358C58.2615 45.3358 65.9098 37.6875 65.9098 28.2528C65.9098 18.8182 58.2615 11.1699 48.8268 11.1699C39.3922 11.1699 31.7439 18.8182 31.7439 28.2528C31.7439 37.6875 39.3922 45.3358 48.8268 45.3358Z" fill="#172128"/>
<defs>
  <radialGradient id="paint0_radial_7912_52935" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(48.9836 28.1023) scale(33.1169)">
    <stop offset="0.580893" stop-color="#2E373E"/>
    <stop offset="0.875213" stop-color="#444C52"/>
    <stop offset="1" stop-color="#5C6770"/>
  </radialGradient>
</defs>
</svg>`)}`;

export const ZAP_PROGRAM_GRADIENT_BACKDROP = {
  width: '100%',
  height: '100%',
  backgroundImage: 'linear-gradient(90deg, rgba(0,0,0,0.7) 0%,  rgba(0,0,0,0.6) 5%, rgba(0,0,0,0) 35%, rgba(0,212,255,0) 100%)',
  display: 'flex',
  backgroundSize: 'cover',
  alignItems: 'center'
};

export const ZAP_PROGRAM_TITLES = {
  default: {
    paddingTop: 6,
    paddingBottom: 6
  },
  small: {
    paddingTop: 4,
    paddingBottom: 4
  }
};

export const ZAP_PROGRAM_MAIN_TITLE = {
  default: {
    fontSize: 16,
    color: PRIMARY_FILL_COLOR,
    fontFamily: 'Brown',
    ...textStyleOnXLines(1)
  },
  small: {
    fontSize: 12
  },
  'largeTv+extraLargeTv': { fontSize: 24 }
};

export const ZAP_PROGRAM_SECOND_TITLE = {
  default: {
    fontSize: 14,
    marginTop: 6,
    fontWeight: 400,
    color: LABELS_FILL_COLOR,
    fontFamily: 'Brown',
    ...textStyleOnXLines(1)
  },
  small: {
    fontSize: 12,
    marginTop: 4
  },
  'largeTv+extraLargeTv': { fontSize: 20 }
};

export const ZAP_SWITCH_WRAPPER = {
  default: { padding: 16 },
  small: { padding: 12 }
};

export const ZAP_SWITCH_FLEX = {
  default: {
    display: 'flex',
    background: 'rgb(44, 55, 60)',
    cursor: 'pointer',
    borderRadius: 8,
    overflow: 'hidden'
  }
};

export const ZAP_SWITCH_ITEM = {
  default: {
    flex: 1,
    textAlign: 'center',
    fontSize: 16,
    padding: 6,
    fontWeight: 400,
    color: PRIMARY_FILL_COLOR,
    fontFamily: 'Brown',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  small: {
    fontSize: 12,
    padding: 2,
    fontWeight: 700
  }
};

export const ZAP_SWITCH_ITEM_ACTIVE = {
  backgroundColor: ACTIVE_ITEM_BACKGROUND,
  borderRadius: 9
};
