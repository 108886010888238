import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function BackStartIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
    >
      <mask id="mask0_back_start" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="28" height="27">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M17.6016 27.7968C23.8148 27.7968 28.2657 24.0665 28.2657 18.047C28.2657 12.6881 24.8773 9.26262 19.6173 8.34914C19.2311 8.28207 19.2235 8.2765 18.5157 8.2765H7.77545V6.07995H18.5157C18.6212 6.07995 19.1506 6.07321 19.6173 6.14014C26.1257 7.07564 30.5157 11.4813 30.5157 18.047C30.5157 25.2705 25.0575 29.9767 17.6016 29.9767H9.3399C9.00005 29.9767 8.36724 29.6368 8.36724 28.8868C8.36724 28.1368 8.9942 27.7968 9.3399 27.7968H17.6016Z" fill="black" />
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M4.10523 7.89845C3.68416 7.60696 3.68416 6.98093 4.10523 6.68944L7.91901 4.04929C8.40239 3.71466 9.06028 4.06312 9.06028 4.65379V9.9341C9.06028 10.5248 8.40239 10.8732 7.91901 10.5386L4.10523 7.89845Z" fill="black" />
      </mask>
      <g mask="url(#mask0_back_start)">
        <rect width="36" height="36" fill={color} />
      </g>

    </SVGWrapper>

  );
}

export default BackStartIcon;
