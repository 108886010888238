import { Subject, timer, merge, pipe } from 'rxjs';
import { map, take, buffer, exhaustMap, filter } from 'rxjs/operators';
import {
  FULLSCREEN_SHORTCUT,
  FULLSCREEN_DBL_CLICK_SHORTCUT,
  PLAY_PAUSE_SHORTCUT,
  PLAY_PAUSE_CLICK_SHORTCUT,
  MUTE_SHORTCUT,
  FORWARD_SHORTCUT,
  REWIND_SHORTCUT,
  VOLUME_UP_SHORTCUT,
  VOLUME_DOWN_SHORTCUT,
  PIP_SHORTCUT,
  AUDIO_TRACK_SHORTCUT,
  CAPTIONS_SHORTCUT,
  CLICKTHROUGH_SHORTCUT,
  SPEED_SHORTCUT,
  SHORTCUT_MAP,
  CLOSE_ZAPPING_SHORTCUT,
  TIMELINE_PERCENT_SHORTCUT,
  NUMERIC_SHORTCUT_MAP,
  STOP_SHORTCUT,
  REWIND_PERCENT_SHORTCUT,
  FORWARD_PERCENT_SHORTCUT
} from './types';
import {
  USER_PIP, USER_FULLSCREEN, USER_AUDIO_TRACK_CHANGED,
  USER_TEXT_TRACK_CHANGED, USER_MUTE, USER_PLAY, USER_PAUSE,
  USER_FAST_FORWARD, USER_FAST_REWIND, USER_SHORTCUT, USER_CLICK,
  USER_PLAYBACKRATE_CHANGE,
  USER_SEEK,
  USER_VOLUME_CHANGE
} from '../../types';
import { NO_LABEL } from '../tracks/types';
import { DAI_AD_TYPE } from '../../ad/dai/types';
import { AD_PAUSEROLL } from '../pauseroll/constants';
import { Disposable } from '..';

export default class ShortcutController extends Disposable {
  constructor({ player }) {
    super();
    this.stream$ = new Subject();
    this.click$ = new Subject();
    this.shortcuts$ = new Subject();

    const clicks$ = ShortcutController
      .createClicksStream(this.click$, player)
      .pipe(this.mapKeyCode());

    ShortcutController
      .createShortcutStream(
        clicks$,
        this.stream$.pipe(this.mapKeyCode()),
        player
      )
      .subscribe(this.shortcuts$);

    this.shortcuts$.subscribe((shortcut) => ShortcutController.handleShortcuts(shortcut, player));
  }

  // eslint-disable-next-line class-methods-use-this
  mapKeyCode() {
    return pipe();
  }

  static createShortcutStream(clicks$, stream$, player) {
    return merge(clicks$, stream$).pipe(
      map((shortcut) => ({ shortcut, state: player.store.getState() })),
      filter(({ shortcut, state }) => {
        /**
         * filter out forward / rewind if live / ad / midroll-countdown
         * filter out play / pause shortcuts if live stream
         * filter out pip shortcut if ad / pipSupported (if pip is not supported by the browser)
         * filter out captions shortcut if subtitlesAvailable is empty
         * filter out audio track shortcut if audiosAvailable contains more than one track
         */
        switch (SHORTCUT_MAP[shortcut]) {
          case FORWARD_SHORTCUT:
          case REWIND_SHORTCUT:
            return (state.media.isLive ? state.media?.timeshifting?.type : true)
                  && !state.media.isAd && !state.ad.countdown;
          case STOP_SHORTCUT:
            return state.media.isLive && !state.media?.timeshifting?.type;
          case SPEED_SHORTCUT:
            return !state.media.isLive && !state.media.isDVR && !state.media.isAd && !state.ad.countdown;
          case PLAY_PAUSE_SHORTCUT:
          case PLAY_PAUSE_CLICK_SHORTCUT:
            return state.media.isLive ? state.media?.timeshifting?.type : true;
          case PIP_SHORTCUT:
            return !state.ad.countdown && !state.media.isAd && state.systemInfo.pipSupported;
          case CAPTIONS_SHORTCUT:
            return state.media.subtitlesAvailable.length > 0;
          case AUDIO_TRACK_SHORTCUT:
            return state.media.audiosAvailable.length > 1;
          default:
            return true;
        }
      }),
      map(({ shortcut }) => shortcut)
    );
  }

  static createClicksStream(click$, player) {
    return click$.pipe(
      buffer(click$.pipe(exhaustMap(() => merge(click$, timer(250)).pipe(take(1))))),
      map((clicks) => {
        const {
          media: { isAd },
          zapping: { metaOpened: zappingOpened },
          ad: { pauseRollIsDisplayable }
        } = player.store.getState();
        /* handle double clicks */
        if (clicks.length > 1) return FULLSCREEN_DBL_CLICK_SHORTCUT;
        if (isAd || pauseRollIsDisplayable) return CLICKTHROUGH_SHORTCUT;
        if (zappingOpened) return CLOSE_ZAPPING_SHORTCUT;

        return PLAY_PAUSE_CLICK_SHORTCUT;
      })
    );
  }

  static seekPercentTen = (duration, currentTime, forward = true) => {
    const seekedTime = forward
      ? currentTime + (0.1 * duration)
      : currentTime - (0.1 * duration);

    return Math.min(Math.max(seekedTime, 0), duration);
  };

  static handleShortcuts(keyCode, player) {
    const {
      playback: { isPlaying, rate, currentTime },
      ui: { isFullscreen, isPIP },
      media: {
        audioSelected,
        audiosAvailable,
        subtitleSelected,
        subtitlesAvailable,
        duration
      },
      volume: { muted, level },
      ad: { adType }
    } = player.store.getState();

    switch (SHORTCUT_MAP[keyCode]) {
      case TIMELINE_PERCENT_SHORTCUT: {
        const percent = NUMERIC_SHORTCUT_MAP[keyCode];
        player.userEvents$.next({ action: USER_SEEK, source: USER_SHORTCUT, value: percent });
        player.seek((duration / 100) * percent);
        break;
      }
      case FORWARD_PERCENT_SHORTCUT:
      case REWIND_PERCENT_SHORTCUT: {
        const seekValue = ShortcutController.seekPercentTen(
          duration,
          currentTime,
          FORWARD_PERCENT_SHORTCUT === keyCode
        );
        player.seek(seekValue, true);
        player.userEvents$.next({
          action: USER_SEEK,
          source: USER_SHORTCUT,
          value: ((seekValue * 100) / duration)
        });
        break;
      }
      case CLICKTHROUGH_SHORTCUT:
        if (adType === DAI_AD_TYPE) {
          player.adClick('dai');
        } else if (adType === AD_PAUSEROLL) {
          player.adClick(AD_PAUSEROLL);
        } else {
          player.adClick('freewheel');
        }
        break;
      case CLOSE_ZAPPING_SHORTCUT:
        player.zappingClose();
        break;
      case PLAY_PAUSE_CLICK_SHORTCUT:
      case PLAY_PAUSE_SHORTCUT: {
        const source = keyCode === PLAY_PAUSE_CLICK_SHORTCUT ? USER_CLICK : USER_SHORTCUT;
        if (isPlaying) {
          player.pause();
          player.userEvents$.next({ action: USER_PAUSE, source });
        } else {
          player.play({ userGesture: true });
          player.userEvents$.next({ action: USER_PLAY, source });
        }
        break;
      }
      case STOP_SHORTCUT: {
        player.stop();
        break;
      }
      case MUTE_SHORTCUT:
        player.mute(!muted);

        player.userEvents$.next({
          action: USER_MUTE,
          value: !muted,
          source: USER_SHORTCUT
        });
        break;
      case FULLSCREEN_SHORTCUT:
      case FULLSCREEN_DBL_CLICK_SHORTCUT: {
        const source = keyCode === FULLSCREEN_DBL_CLICK_SHORTCUT ? USER_CLICK : USER_SHORTCUT;
        player.fullscreen(!isFullscreen);
        player.userEvents$.next({ action: USER_FULLSCREEN, value: !isFullscreen, source });
        break;
      }
      case FORWARD_SHORTCUT:
        player.forward();

        player.userEvents$.next({
          action: USER_FAST_FORWARD,
          source: USER_SHORTCUT
        });
        break;
      case REWIND_SHORTCUT:
        player.rewind();

        player.userEvents$.next({
          action: USER_FAST_REWIND,
          source: USER_SHORTCUT
        });
        break;
      case VOLUME_UP_SHORTCUT:
        player.userEvents$.next({ action: USER_VOLUME_CHANGE, source: USER_SHORTCUT, value: level });
        if (level < 1) {
          const newVolume = level + 0.1;
          player.volume(Math.round(newVolume * 10) / 10);
        }
        break;
      case VOLUME_DOWN_SHORTCUT:
        player.userEvents$.next({ action: USER_VOLUME_CHANGE, source: USER_SHORTCUT, value: level });
        if (level > 0) {
          const newVolume = level - 0.1;
          player.volume(Math.round(newVolume * 10) / 10);
        }
        break;
      case PIP_SHORTCUT:
        player.requestPIP(!isPIP);

        player.userEvents$.next({
          action: USER_PIP,
          value: !isPIP,
          source: USER_SHORTCUT
        });
        break;
      case AUDIO_TRACK_SHORTCUT: {
        const nextAudioTrackIndex = audioSelected + 1 === audiosAvailable.length ? 0 : audioSelected + 1;
        player.setAudioTrack(nextAudioTrackIndex);

        const nextAudioTrack = audiosAvailable.find((a) => a.index === nextAudioTrackIndex);
        player.userEvents$.next({
          action: USER_AUDIO_TRACK_CHANGED,
          source: USER_SHORTCUT,
          value: nextAudioTrack.label
        });
        break;
      }
      case CAPTIONS_SHORTCUT: {
        const nextTextTrackIndex = subtitleSelected + 1 === subtitlesAvailable.length ? -1 : subtitleSelected + 1;
        player.setSubtitleTrack(nextTextTrackIndex);

        const nextSubtitleTrack = subtitlesAvailable.find((s) => s.index === nextTextTrackIndex);
        player.userEvents$.next({
          action: USER_TEXT_TRACK_CHANGED,
          source: USER_SHORTCUT,
          value: nextSubtitleTrack ? nextSubtitleTrack.label : NO_LABEL
        });
        break;
      }
      case SPEED_SHORTCUT: {
        const newRate = rate >= 1.75 ? 1 : rate + 0.25;
        player.speed(newRate);

        player.userEvents$.next({
          action: USER_PLAYBACKRATE_CHANGE,
          source: USER_SHORTCUT,
          value: { prevRate: rate, newRate }
        });
        break;
      }
      default:
        break;
    }
  }
}
