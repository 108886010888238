export const EMBED_BANNED_OPTIONS = [
  'zapping',
  'comingNext',
  'next',
  'startTimeCode',
  'diffusion',
  'consent',
  'tracking',
  'publicId'
];
