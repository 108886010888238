import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function NextIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
    >
      <mask id="mask0_18_497" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="4" y="4" width="21" height="20">
        <path d="M24.5 21.2781L24.5 6.63264C24.5 5.74692 23.5519 5.18424 22.7744 5.6086L9.95967 12.6034C9.16711 13.036 9.14531 14.1663 9.92059 14.6292L22.7353 22.2798C23.513 22.7441 24.5 22.1838 24.5 21.2781Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.25004 4.66667C5.89437 4.66667 6.41671 5.18901 6.41671 5.83334L6.41671 22.1667C6.41671 22.811 5.89437 23.3333 5.25004 23.3333C4.60571 23.3333 4.08337 22.811 4.08337 22.1667L4.08338 5.83334C4.08338 5.18901 4.60571 4.66667 5.25004 4.66667Z" fill="white" />
        <path d="M24.5 21.2781L24.5 6.63264C24.5 5.74692 23.5519 5.18424 22.7744 5.6086L9.95967 12.6034C9.16711 13.036 9.14531 14.1663 9.92059 14.6292L22.7353 22.2798C23.513 22.7441 24.5 22.1838 24.5 21.2781Z" stroke="white" strokeWidth="3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.25004 4.66667C5.89437 4.66667 6.41671 5.18901 6.41671 5.83334L6.41671 22.1667C6.41671 22.811 5.89437 23.3333 5.25004 23.3333C4.60571 23.3333 4.08337 22.811 4.08337 22.1667L4.08338 5.83334C4.08338 5.18901 4.60571 4.66667 5.25004 4.66667Z" stroke="white" strokeWidth="3" />
      </mask>
      <g mask="url(#mask0_18_497)">
        <rect width="28" height="28" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default NextIcon;
