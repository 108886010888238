import { of, from, pipe, timer, race, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import error from '../error';
import {
  WEBSERVICE_TIMEOUT_ERROR,
  WEBSERVICE_BAD_RESPONSE_ERROR,
  WEBSERVICE_SERVER_ERROR
} from '../error/definitions';

const TIMEOUT_VALUE = 10000;

export const webservice = {
  createRequestValidator(formatError) {
    /* Handle success responses 2xx and redirects 3xx */
    return pipe(switchMap((response) => (response.ok ? of(response) : throwError(() => formatError(response)))));
  },

  /* TODO: Use https://www.learnrxjs.io/operators/utility/timeout.html */
  withTimeout(timeoutValue, input$) {
    return race(
      input$,
      timer(timeoutValue).pipe(map(() => WEBSERVICE_TIMEOUT_ERROR))
    );
  },

  handleFetchError() {
    /* handle fetch error (ERR_CONNECTION_REFUSED, ERR_NAME_NOT_RESOLVED, SyntaxError, etc.) */
    return pipe(catchError((e) => of({
      ...(e.name === 'SyntaxError' ? WEBSERVICE_BAD_RESPONSE_ERROR : WEBSERVICE_SERVER_ERROR),
      payload: e.toString()
    })));
  },

  handleResponse(response, responseType) {
    const { ok, status } = response;

    if (status === 502 || !response[responseType]) {
      return of(response).pipe(map((resTyped) => ({ [responseType]: resTyped, ok, status })));
    }

    if (status === 204) {
      return of({ [responseType]: null, ok, status });
    }

    return from(response[responseType]()).pipe(map((resTyped) => ({ [responseType]: resTyped, ok, status })));
  },

  requestAPI({
    url,
    selector = (x) => x,
    formatError = error,
    method = 'GET',
    body = {},
    headers = {},
    responseType = 'json'
  }) {
    const params = {
      method,
      ...(method === 'POST') ? { body: JSON.stringify(body) } : undefined,
      crossdomain: true,
      headers
    };

    return webservice.withTimeout(TIMEOUT_VALUE, from(fetch(url, params)))
      .pipe(
        switchMap((response) => webservice.handleResponse(response, responseType)),
        webservice.handleFetchError(),
        webservice.createRequestValidator(formatError),
        map(selector)
      );
  }
};

export const selectJson = ({ json }) => json;
