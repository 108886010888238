import { h, Fragment } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import Selectable from '../../common/menu/Selectable';
import { VOID_FN } from '../../../../utils';
import { FEEDBACK_VALIDATION_OVERLAY_STYLE, FEEDBACK_VALIDATION_STYLE } from './styles';
import { BUTTON_RESET } from '../../common/buttons/styles';
import { THIRD_TEXT_COLOR } from '../../../theme/colors';
import SlidableSlide from '../../common/menu/SlidableSlide';
import SlideContent from '../../common/menu/SlideContent';
import Seperator from '../../common/menu/Seperator';
import { useStyles } from '../../../hooks';
import { isValidateKeyboard } from '../../../utils/keyboard';
import { TEXT_LEVEL_3_FONT_SIZE } from '../../../styles';

function Reporter({
  source,
  onSelect = VOID_FN,
  onClick = VOID_FN,
  submit: { onSubmit, label: submitLabel },
  active,
  closeIconRef,
  focusable = false,
  isFullscreen
}) {
  const setStyles = useStyles();
  const [val, setVal] = useState(null);
  const submitRef = useRef(null);

  const handleSelect = (value) => {
    onSelect(value);
    setVal(value === val ? null : value);
  };

  /* unselect radio button when reporter is not active */
  useEffect(() => (!active && setVal(null)), [active]);
  useEffect(() => (active && onClick()), [active]);

  return (
    <Fragment>
      <SlidableSlide
        style={!isFullscreen
          ? setStyles({
            default: { maxHeight: 'calc(100% - 48px)' },
            small: { maxHeight: 'calc(100% - 40px)' },
            extraSmall: { maxHeight: 'calc(100% - 40px)' }
          })
          : {}}
      >
        <SlideContent>
          {source.map(({ label, value }, i) => (
            <Selectable
              key={label}
              focusable={focusable}
              label={label}
              index={i + 1}
              totalCount={source.length}
              active={val === value}
              radioButton
              onSelect={() => handleSelect(value)}
              closeOnActiveSelect={false}
            />
          ))}
        </SlideContent>
      </SlidableSlide>
      <div
        style={setStyles({
          default: FEEDBACK_VALIDATION_STYLE,
          small: FEEDBACK_VALIDATION_OVERLAY_STYLE,
          extraSmall: { height: 45 }
        })}
      >
        <Seperator style={{ marginLeft: 4 }} />
        <div
          role="button"
          className="ftv-magneto--focusable-item"
          disabled={!val}
          tabIndex={val ? 0 : -1}
          aria-hidden={!val}
          aria-label="Envoyer votre signalement"
          style={{
            ...BUTTON_RESET,
            ...setStyles({
              default: {
                padding: '10px',
                paddingRight: 30,
                height: '40px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }
            }),
            ...setStyles(TEXT_LEVEL_3_FONT_SIZE),
            ...(val ? { color: THIRD_TEXT_COLOR, cursor: 'pointer' } : { cursor: 'default' })
          }}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => {
            if (!val) return;
            onSubmit(val);
          }}
          onKeyDown={(e) => {
            if (isValidateKeyboard(e)) {
              onSubmit(val);
              closeIconRef.current.focus();
              e.preventDefault();
            }
          }}
          ref={submitRef}
        >
          {submitLabel}
        </div>
      </div>
    </Fragment>
  );
}

export default Reporter;
