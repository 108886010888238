import { TIMESHIFTING_AUTO_CONTROLLER_NAME, TIMESHIFTING_CONTROLLER_NAME, TIMESHIFTING_TYPE_AUTO, TIMESHIFTING_TYPE_MANUEL } from '../../../../core/timeshifting/types';
import { USER_PAUSE, USER_PLAY, USER_STOP } from '../../../../types';
import { PLAY_TO_PAUSE, REPLAY_BUTTON_PATH, STOP_BUTTON_PATH } from '../../../theme/svg';

export const PLAYBACK_MATCH_STATE = {
  PAUSE: {
    svg: PLAY_TO_PAUSE,
    fn: 'pause',
    userEvent: USER_PAUSE,
    label: 'pause (k)'
  },
  PLAY: {
    svg: PLAY_TO_PAUSE,
    fn: 'play',
    userEvent: USER_PLAY,
    label: 'lecture (k)'
  },
  REPLAY: {
    svg: REPLAY_BUTTON_PATH,
    fn: 'restart',
    label: 'rejouer'
  },
  STOP: {
    svg: STOP_BUTTON_PATH,
    fn: 'stop',
    userEvent: USER_STOP,
    label: 'stop'
  }
};

export const TIMESHIFTING_TYPE_MAP = {
  [TIMESHIFTING_TYPE_AUTO]: TIMESHIFTING_AUTO_CONTROLLER_NAME,
  [TIMESHIFTING_TYPE_MANUEL]: TIMESHIFTING_CONTROLLER_NAME
};
