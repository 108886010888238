import { TIMESHIFTING_AUTO_DURATION, TIMESHIFTING_TYPE_AUTO } from '../../../../core/timeshifting/types';
import { publicPath } from '../../../../configuration';
import { UI_HOVER_TIMELINE, UI_SEEKING } from '../../../../store/types';
import { BACKGROUND, USER_CLICK, USER_SEEK, USER_SEEK_START, USER_TIMESHIFTING_BACK_TO_LIVE, USER_TIMESHIFTING_SEEK } from '../../../../types';
import { asPercent } from '../../../../utils';
import { secondsToHHMMSS } from '../../../utils';

// TODO: Move to new file
export const EMPTY_THUMBNAIL = { url: '', x: 0, y: 0, title: '', additionalTitle: '' };

export const computeThumbnail = ({ progress, spritesheets, spritesheetLength, width, height }) => {
  if (!spritesheets.images) {
    return EMPTY_THUMBNAIL;
  }

  /**
   * Find the current spritesheet relative to the timeline's percentage progress value
   * and find the index of the current thumbail in the given spritesheet.
   */
  const { columns, lines, images } = spritesheets;
  const spriteSheetIndex = Math.floor(spritesheetLength * progress);
  const thumbnailIndex = Math.round(
    (spritesheetLength * progress - spriteSheetIndex)
      * ((columns * lines) - 1)
  );

  /**
   * The thumbnail object is as follows :
   * url: url of the spritesheet in which the thumbnail is
   * x, y: positions in pixels of the thumbnail in the spritesheet
   * width, height: size of the thumbnail image
   */
  return {
    url: images[spriteSheetIndex],
    x: (thumbnailIndex % columns) * width,
    y: Math.floor(thumbnailIndex / columns) * height
  };
};

export const findCurrentProgram = ({ imagesEpg, width, height, seekTime }) => {
  if (!imagesEpg.length) {
    return null;
  }

  const index = imagesEpg.findIndex(({ programDistance }) => programDistance >= seekTime);

  // We need to subtract the index by 1 as then >= seekTime is the next hovered
  const nextIndex = (index - 1 < 0 ? 0 : index - 1);
  const currentProgram = imagesEpg[index < 0 ? imagesEpg.length - 1 : nextIndex];

  return {
    ...currentProgram,
    url: currentProgram.imageUrl && `${currentProgram.imageUrl.replace(/\?.*/, '')}?w=${width}&h=${height}`,
    y: width,
    x: height
  };
};

const MARGIN_HIGHLIGHT_HOVER = 60;

export const searchHighlight = (highlights, seekTime) => {
  const index = highlights.findIndex(({ programDistance }) => (
    programDistance + MARGIN_HIGHLIGHT_HOVER >= seekTime
    && programDistance - MARGIN_HIGHLIGHT_HOVER <= seekTime
  ));
  if (index < 0) {
    return null;
  }
  return highlights[index];
};

export const findHoveredHightlightVisual = ({ imagesHL, width, height, seekTime }) => {
  const currentProgram = searchHighlight(imagesHL, seekTime);
  if (!currentProgram) { return null; }
  const defaultImageUrl = `${publicPath}${BACKGROUND}`;

  return {
    ...currentProgram,
    url: (currentProgram.imageUrl && `${currentProgram.imageUrl.replace(/\?.*/, '')}?w=${width}&h=${height}`) || defaultImageUrl,
    y: width,
    x: height,
    highlights: true
  };
};

export const computeSeekTime = (progress, duration, computedDuration, timeshifting) => {
  let seekTo = computedDuration * progress;

  if (timeshifting === TIMESHIFTING_TYPE_AUTO) {
    seekTo = computedDuration * (1 - progress);
    return duration - seekTo;
  }
  /* For replays : substract 2 seconds to computed seekTo to avoid cursor flickering */
  const position = seekTo >= duration ? duration - 2 : seekTo;
  return position;
};

export const computeSeekPos = (duration, computedDuration, timeShifting, currentTime, time) => {
  if (timeShifting === TIMESHIFTING_TYPE_AUTO) {
    return Math.abs((duration - computedDuration - currentTime) / computedDuration) * 100;
  }
  return (time / computedDuration) * 100;
};

export const onStartSlide = (player) => {
  player.pause();
  player.store.dispatch({ type: UI_SEEKING, payload: { isSeeking: true } });
  player.userEvents$.next({ action: USER_SEEK_START, source: USER_CLICK });
};

export const onStopSlide = ({ pos, max, duration, computedDuration, timeshifting, player, startOverTimeshifting, imagesHL }) => {
  const progress = (pos / max);

  const seekTo = computeSeekTime(progress, duration, computedDuration, timeshifting);

  const highlight = searchHighlight(imagesHL, duration - seekTo);
  if (highlight) {
    player.seek(Math.abs(duration - highlight.programDistance), true);
    player.userEvents$.next({ action: USER_TIMESHIFTING_SEEK, value: 'tempsforts', source: USER_CLICK });
  } else {
    player.seek(seekTo, true);
    player.userEvents$.next({ action: timeshifting ? USER_TIMESHIFTING_SEEK : USER_SEEK, value: Math.round(progress * 100), source: USER_CLICK });
  }
  if (startOverTimeshifting && pos === max) player.userEvents$.next({ action: USER_TIMESHIFTING_BACK_TO_LIVE, value: 'seek', source: USER_CLICK });
};

export const resolveprimaryTrackStyles = (timeshifting, startOverTimeshifting) => (
  (timeshifting && !startOverTimeshifting) ? { opacity: 0, animation: 'startOverTimeShiftingLineAnimationOut .35s' } : { opacity: 1 }
);
export const resolveSliderStyle = (timeshifting, startOverTimeshifting) => {
  if (!timeshifting) return { opacity: 1 };
  return startOverTimeshifting ? { opacity: 1, zIndex: 3, animation: 'startOverTimeShiftingAnimationIn .35s' } : { animation: 'startOverTimeShiftingAnimationOut .15s', opacity: 0 };
};

export const computeSpritesheetLength = (spritesheets, duration) => {
  const { images, interval, columns, lines } = spritesheets;

  /*
    The last spritesheet does not have exactly a 100 thumbnails like the others.
    We remove the filling black thumbnails from our computations.
  */
  const percentFullInLastSpriteSheet = (Math.ceil(duration / interval) % (columns * lines)) / 100;
  /* number of full spritesheets + percentage of the last one */
  return images.length - 1 + percentFullInLastSpriteSheet;
};

export const setHoverTimeline = ({ store, hoverTimeline, dialogOpened }) => store.dispatch({
  type: UI_HOVER_TIMELINE,
  payload: {
    hoverTimeline,
    isInteracting: hoverTimeline || dialogOpened
  }
});

export const resolveBubbleLabel = (seektimer, dur, shifting) => {
  if (shifting === TIMESHIFTING_TYPE_AUTO) return `- ${secondsToHHMMSS(Math.abs(dur - seektimer))}`;
  return (shifting ? `- ${secondsToHHMMSS(Math.abs(seektimer - dur))}` : `${secondsToHHMMSS(seektimer)}`);
};

export const computeThumbnailSize = (spritesheets) => {
  const { columns, lines, width: totalWidth, height: totalHeight } = spritesheets;

  const width = totalWidth / columns;
  const height = totalHeight / lines;

  return { width, height };
};

export const getThumbnailSizeFromSpritesheets = (spritesheets) => (!spritesheets || !Object.keys(spritesheets).length
  ? { width: 0, height: 0 }
  : computeThumbnailSize(spritesheets));

export const computeEPGSegmentsWidths = (markers) => [
  ...markers
    .reduce(
      (final, marker, i, arr) => (i === 0
        ? [asPercent(marker, TIMESHIFTING_AUTO_DURATION)]
        : [...final, asPercent(marker - arr[i - 1], TIMESHIFTING_AUTO_DURATION)]),
      []
    ),
  asPercent(TIMESHIFTING_AUTO_DURATION - markers[markers.length - 1], TIMESHIFTING_AUTO_DURATION)
].reverse();
