import {
  OVERLAY_COLOR_OPAQUE
} from '../../../ui/theme/colors';
import { TRACKS_LAYER } from '../../../ui/theme/constants';

export const TV_MAIN_WRAPPER = {
  position: 'relative',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  fontFamily: 'Brown'
};

export const TV_MAIN_CONTENT = {
  position: 'absolute',
  left: 80,
  right: 80,
  top: 40,
  bottom: 40
};

export const TV_SIDE_OVERLAY_WRAPPER = {
  height: '100%',
  width: '30%',
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: OVERLAY_COLOR_OPAQUE,
  zIndex: TRACKS_LAYER,
  padding: 40
};

export const TV_SIDE_OVERLAY_WRAPPER_PROGRAM_LIST = {
  height: '100%',
  width: '30%',
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: OVERLAY_COLOR_OPAQUE,
  zIndex: TRACKS_LAYER
};
