import { h } from 'preact';
import { connect } from '../../hoc';
import { usePlayerContext, useStyles } from '../../hooks';
import {
  ZAP_SWITCH_FLEX,
  ZAP_SWITCH_ITEM,
  ZAP_SWITCH_ITEM_ACTIVE,
  ZAP_SWITCH_WRAPPER
} from './styles';
import { ZAPPING_TAB_CHANGED } from '../../../store/types';
import { USER_CLICK, USER_KEYBOARD, USER_PANEL_ZAPPING_SWITCH_CLICKED } from '../../../types';
import { ZAPPING_NAME } from '../wrappers/Zap/constants';

function ProgramTypeSwitch({ activeTab, list, show, wrapperRef = null }) {
  if (!show) return null;

  const setStyles = useStyles();
  const player = usePlayerContext();

  const changeTabHandled = (tabIndex, fromKeyboard) => {
    player.store.dispatch({
      type: ZAPPING_TAB_CHANGED,
      payload: { tabIndex }
    });
    player.userEvents$.next({
      action: USER_PANEL_ZAPPING_SWITCH_CLICKED,
      value: { from: activeTab, to: tabIndex },
      source: fromKeyboard ? USER_KEYBOARD : USER_CLICK
    });
  };

  return (
    <div style={setStyles(ZAP_SWITCH_WRAPPER)} ref={wrapperRef}>
      <div style={setStyles(ZAP_SWITCH_FLEX)}>
        {list.map(({ title }, index) => (
          <div
            role="switch"
            key={`${title}-${activeTab}`}
            aria-checked={index === activeTab}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.keyCode === 13 || e.keyCode === 32 /* Enter or Spacebar */) changeTabHandled(index, true);
            }}
            className="ftv-magneto--focusable-item"
            onClick={() => changeTabHandled(index)}
            style={{
              ...setStyles(ZAP_SWITCH_ITEM),
              ...(index === activeTab ? ZAP_SWITCH_ITEM_ACTIVE : {})
            }}
          >
            {title}
          </div>
        ))}
      </div>
    </div>
  );
}

const selector = ({
  ui: { panelLiveOption: { currentTab } },
  zapping: {
    list,
    current: { tabIndex: activeTab, activeChannel },
    metaOpened
  }
}) => ({
  activeTab,
  activeChannel,
  metaOpened,
  list,
  show: currentTab === ZAPPING_NAME && list.length > 1
});

export default connect(selector)(ProgramTypeSwitch);
