import {
  SECONDARY_FILL_COLOR,
  OVERLAY_COLOR_TRANSPARENT,
  OVERLAY_COLOR_OPAQUE,
  SECONDARY_TEXT_COLOR,
  PRIMARY_FILL_COLOR,
  LABELS_FILL_COLOR
} from '../../../theme/colors';
import { FTV_TIMING_FUNCTION_IN, EMBED_LAYER, SETTINGS_LAYER } from '../../../theme/constants';

import { SPACING_A, SPACING_C, TEXT_LEVEL_1_FONT_SIZE, TEXT_LEVEL_2_FONT_SIZE } from '../../../styles';

export const EMBED_POPUP_WRAPPER = {
  default: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    pointerEvents: 'auto',
    color: LABELS_FILL_COLOR,
    padding: '20px 24px ',
    fontFamily: 'Brown'
  },
  extraSmall: { padding: '20px', lineHeight: 'inherit', justifyContent: 'flex-start' },
  small: { justifyContent: 'flex-start' }

};

export const EMBED_POPUP_TITLE = {
  default: {

    marginBottom: 10,
    color: PRIMARY_FILL_COLOR,
    ...TEXT_LEVEL_1_FONT_SIZE.default
  },
  extraSmall: {
    ...TEXT_LEVEL_1_FONT_SIZE.extraSmall,
    marginBottom: SPACING_A.extraSmall.size
  },
  small: { ...TEXT_LEVEL_1_FONT_SIZE.small,
    marginBottom: SPACING_A.small.size },
  medium: { ...TEXT_LEVEL_1_FONT_SIZE.medium,
    marginBottom: SPACING_A.medium.size },
  large: { ...TEXT_LEVEL_1_FONT_SIZE.large,
    marginBottom: SPACING_A.medium.size },
  extraLarge: { ...TEXT_LEVEL_1_FONT_SIZE.extraLarge,
    marginBottom: SPACING_A.extraLarge.size }

};

export const EMBED_POPUP_TEXTAREA = {
  borderRadius: 30,
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  boxSizing: 'content-box',
  border: 0,
  height: 'auto',
  outline: 'none',
  color: LABELS_FILL_COLOR
};

export const EMBED_POPUP_TEXTAREA_SELECTED = {
  background: 'black'
};

export const EMBED_POPUP_COPIED = {
  default: {
    fontWeight: 700,
    textAlign: 'center',
    ...TEXT_LEVEL_2_FONT_SIZE.default
  },
  extraSmall: {
    ...TEXT_LEVEL_2_FONT_SIZE.extraSmall,
    marginTop: SPACING_C.extraSmall.size,
    marginBottom: SPACING_C.extraSmall.size
  },
  small: { ...TEXT_LEVEL_2_FONT_SIZE.small,
    marginBottom: SPACING_C.small.size,
    marginTop: SPACING_C.small.size
  },
  medium: { ...TEXT_LEVEL_2_FONT_SIZE.medium,
    marginBottom: SPACING_C.medium.size,
    marginTop: SPACING_C.medium.size
  },
  large: { ...TEXT_LEVEL_2_FONT_SIZE.large,
    marginBottom: SPACING_C.large.size,
    marginTop: SPACING_C.large.size
  },
  extraLarge: { ...TEXT_LEVEL_2_FONT_SIZE.extraLarge,
    marginBottom: SPACING_C.extraLarge.size,
    marginTop: SPACING_C.extraLarge.size
  }
};

export const EMBED_DIALOG_CONTENT_OPENED_STYLE = {
  background: OVERLAY_COLOR_TRANSPARENT,
  position: 'absolute',
  maxHeight: '100%',
  opacity: 1,
  zIndex: EMBED_LAYER,
  transition: 'opacity .22s ease-in-out'
};

export const EMBED_DEFAULT_DIALOG_STYLE = {
  display: 'flex',
  background: 'transparent',
  color: SECONDARY_FILL_COLOR,
  overflow: 'hidden',
  transition: 'opacity .33s ease-in-out'
};

export const EMBED_DIALOG_CONTENT_STYLE = {
  transition: 'none',
  width: '100%',
  padding: '0',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  opacity: 0,
  maxHeight: 0,
  overflow: 'hidden'
};

export const SOURCE_BUTTON_STYLE = {
  pointerEvents: 'auto',
  cursor: 'pointer',
  marginRight: 10,
  padding: '10px 0'
};

export const SOURCE_BUTTON_SIZE = {
  width: 96,
  height: 16.5
};

export const SETTINGS_CONTAINER_STYLE = {
  position: 'relative'
};

export const SETTINGS_WRAPPER_STYLE = {
  padding: 0,
  maxHeight: 500,
  maxWidth: 500,
  width: 0,
  overflow: 'hidden',
  borderRadius: 20,
  position: 'absolute',
  marginRight: 'initial',
  transition: `all .25s ${FTV_TIMING_FUNCTION_IN}`,
  height: 'auto',
  zIndex: SETTINGS_LAYER,
  pointerEvents: 'auto',
  background: OVERLAY_COLOR_OPAQUE,
  top: -6,
  right: -6,
  transitionProperty: 'max-width, width, opacity',
  transitionDuration: '.25s',
  transitionTimingFunction: FTV_TIMING_FUNCTION_IN
};

export const FEEDBACK_VALIDATION_STYLE = {
  fontFamily: 'Brown',
  background: OVERLAY_COLOR_OPAQUE,
  fontWeight: 'normal',
  fontSize: '85%',
  textAlign: 'right',
  color: SECONDARY_TEXT_COLOR,
  marginBottom: -10
};

export const FEEDBACK_VALIDATION_OVERLAY_STYLE = {
  fontFamily: 'Brown',
  fontWeight: 'normal',
  fontSize: '85%',
  textAlign: 'right',
  width: '100%',
  height: 45,
  color: SECONDARY_TEXT_COLOR,
  marginBottom: 0,
  bottom: 0
};

export const FEEDBACK_THANKS_TEXT_STYLE = {
  fontFamily: 'Brown',
  fontWeight: 'bold',
  position: 'relative',
  margin: '-20px 0 10px 10px'
};

export const NAVBAR_STYLE = {
  default: { fontSize: 28, padding: '0px 10px 0px 0px' },
  small: { fontSize: 22, padding: '0px 10px 0px 0px' },
  extraSmall: { fontSize: 16, padding: '0px 10px 0px 0px' },
  large: { fontSize: 28, padding: '0px 10px 0px 0px' },
  extraLarge: { fontSize: 28, padding: '0px 10px 0px 0px' }
};

export const NAVBAR_TITLE_STYLE = {
  default: { fontSize: '28px' },
  small: { fontSize: '20px' },
  extraSmall: { fontSize: '16px' }
};

export const ECO_STYLES_FOOTER = {
  lineHeight: '150%',
  color: SECONDARY_TEXT_COLOR
};
