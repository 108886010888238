import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { connect } from '../../../ui/hoc';
import Position from '../../../ui/utils/Position';
import TimeDisplay from '../../../ui/components/controls/timeline/TimeDisplay';
import { TIMELINE_TIME_HOUR_STYLE } from '../../../ui/components/controls/timeline/styles';
import { secondsToHHMMSS } from '../../../ui/utils';
import { dateToHhS } from '../../../ui/utils/time';
import { TIMESHIFTING_AUTO_CONTROLLER_NAME, TIMESHIFTING_AUTO_DURATION, TIMESHIFTING_CONTROLLER_NAME, TIMESHIFTING_TYPE_AUTO, TIMESHIFTING_TYPE_MANUEL } from '../../../core/timeshifting/types';
import {
  computeEPGSegmentsWidths,
  computeSeekTime,
  onStartSlide,
  onStopSlide,
  setHoverTimeline,
  resolveprimaryTrackStyles,
  resolveSliderStyle
} from '../../../ui/components/controls/timeline/utils';
import { usePlayerContext } from '../../../ui/hooks';
import { useDialogContext } from '../../../ui/context/DialogContext';
import { Slider } from '../../../ui/components/common';
import { asPercent } from '../../../utils';
import { SLIDER_BUFFERED_COLOR, SLIDER_TIMELINE_LIVE_COLOR } from '../../../ui/theme/colors';
import LiveCursor from '../../../ui/components/controls/timeline/LiveCursor';
import { TV_LIVE_KNOB, TV_TIMELINE_WRAPPER_STYLE, TV_TIME_CURRENT_STYLE, TV_TIME_DURATION_STYLE } from './style';

const CURSOR_SIZE = 24;
const STROKE_SIZE = 8;

function TimelineComponent({
  currentTime,
  duration,
  buffered,
  isSeeking,
  expectedStartOverDuration,
  timeshifting,
  broadcastedAt,
  startOverTimeshifting,
  programMarkers,
  isEPGDirty,
  imagesHL
}) {
  const player = usePlayerContext();
  const dialogCtx = useDialogContext();

  const isTimeshiftingAuto = TIMESHIFTING_TYPE_AUTO === timeshifting;
  const isTimeshiftingManuel = TIMESHIFTING_TYPE_MANUEL === timeshifting;
  const computedDuration = isTimeshiftingAuto ? TIMESHIFTING_AUTO_DURATION : duration;

  const [computedBuffer, setComputedBuffer] = useState(0);
  const [time, setTime] = useState(currentTime);
  const [seekTime, setSeekTime] = useState(0);
  const [segments, setSegments] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentSegment, setCurrentSegment] = useState(0);

  useEffect(() => setComputedBuffer(buffered), [computedDuration]);
  useEffect(() => setTime(isSeeking ? seekTime : currentTime), [isSeeking, currentTime, seekTime]);

  const onSlide = (pos, max) => {
    const progress = (pos / max);
    const nextSeekTime = computeSeekTime(progress, duration, computedDuration, timeshifting);
    setSeekTime(nextSeekTime);
  };

  const containerMargin = 110;

  const value = timeshifting && !startOverTimeshifting
    ? 100
  // In case of timeshifting auto, the slider should represent the 4 hour window,
  // so take the current delay to the live head, and substract it to 4h.s
    : ((isTimeshiftingAuto ? (TIMESHIFTING_AUTO_DURATION - (duration - time)) : time) / computedDuration) * 100;

  useEffect(() => {
    if (programMarkers.length) {
      let markers;

      if (programMarkers.length === 1 && programMarkers[0] >= TIMESHIFTING_AUTO_DURATION) {
        markers = [...programMarkers];
      } else {
        markers = programMarkers.filter(((marker) => marker < TIMESHIFTING_AUTO_DURATION));
      }
      const index = markers.findIndex((seg) => asPercent(seg, TIMESHIFTING_AUTO_DURATION) > (100 - value));
      setCurrentIndex(index);
      setCurrentSegment(index === -1 ? 0 : (markers.length - index));
      setSegments(computeEPGSegmentsWidths(markers));
    }
  }, [duration, value]);

  const useEpgTimeline = isTimeshiftingAuto && !isEPGDirty && !!segments.length;

  return (
    <div style={TV_TIMELINE_WRAPPER_STYLE}>
      <Position>
        <TimeDisplay
          time={timeshifting ? broadcastedAt : time}
          displayFn={timeshifting ? dateToHhS : secondsToHHMMSS}
          hidden={isTimeshiftingAuto}
          style={{
            ...TV_TIME_CURRENT_STYLE,
            ...timeshifting ? TIMELINE_TIME_HOUR_STYLE.default : {}
          }}
        />

        <Slider
          multiSegments={useEpgTimeline}
          maxValue={computedDuration}
          value={value}
          /* buffer is used in two way : for the buffering stream and for the live stream */
          buffer={timeshifting ? 100 : computedBuffer}
          strokeSize={STROKE_SIZE}
          cursorSize={CURSOR_SIZE}
          onStartSlide={() => onStartSlide(player)}
          onSlide={onSlide}
          onStopSlide={(pos, max) => onStopSlide({
            pos, max, duration, computedDuration, timeshifting, player, startOverTimeshifting, imagesHL
          })}
          onFocus={() => setHoverTimeline({ store: player.store, hoverTimeline: true, dialogOpened: dialogCtx.dialogOpened })}
          onBlur={() => setHoverTimeline({ store: player.store, hoverTimeline: false, dialogOpened: dialogCtx.dialogOpened })}
          slices={segments}
          currentSegment={currentSegment}
          currentIndex={currentIndex}
          styles={{
            container: {
              marginLeft: !isTimeshiftingAuto || timeshifting === null ? containerMargin : 0,
              marginRight: !timeshifting ? containerMargin : 0,
              position: 'relative'
            }
          }}
          primaryTrackStyles={{ ...resolveprimaryTrackStyles(timeshifting, startOverTimeshifting) }}
          secondaryTrackStyles={{ backgroundColor: timeshifting ? SLIDER_TIMELINE_LIVE_COLOR : SLIDER_BUFFERED_COLOR, left: '0px' }}
          sliderStyle={{ ...resolveSliderStyle(timeshifting, startOverTimeshifting) }}
          name="slider-timeline"
          disableFocus={false}
          trackSize={{ height: 8 }}
        >

          {/* LIVE POSITION INDICATORS  */}
          {timeshifting && (
          <Fragment>
            { isTimeshiftingAuto && (
            <LiveCursor /* KNOB */
              position={{
                ...{ left: duration > expectedStartOverDuration ? '100%' : `${((duration / expectedStartOverDuration) * 100)}%` },
                ...(timeshifting && !startOverTimeshifting ? { left: 'calc(100% - 1px)' } : {})
              }}
              strokeSize={STROKE_SIZE}
              cursorSize={CURSOR_SIZE}
              onClick={() => (
                startOverTimeshifting
                    && player.backToLive(TIMESHIFTING_TYPE_AUTO
                      ? TIMESHIFTING_AUTO_CONTROLLER_NAME
                      : TIMESHIFTING_CONTROLLER_NAME)
              )}
              style={startOverTimeshifting ? TV_LIVE_KNOB : {}}
              live
              timeshifting={(isTimeshiftingAuto && useEpgTimeline) || isTimeshiftingManuel}
            />
            )}

            { isTimeshiftingManuel && (
            <LiveCursor /* KNOB */
              position={{
                ...{ left: duration > expectedStartOverDuration ? '100%' : `${((duration / expectedStartOverDuration) * 100)}%` },
                ...(timeshifting && !startOverTimeshifting ? { left: '100%' } : {})
              }}
              strokeSize={STROKE_SIZE}
              cursorSize={CURSOR_SIZE}
              onClick={() => (
                startOverTimeshifting
                    && player.backToLive(TIMESHIFTING_TYPE_AUTO
                      ? TIMESHIFTING_AUTO_CONTROLLER_NAME
                      : TIMESHIFTING_CONTROLLER_NAME)
              )}
              style={startOverTimeshifting ? TV_LIVE_KNOB : {}}
              live
            />
            )}
          </Fragment>
          )}
        </Slider>

        <TimeDisplay
          time={computedDuration}
          hidden={timeshifting}
          style={TV_TIME_DURATION_STYLE}
        />
      </Position>
    </div>
  );
}

const selector = (store) => {
  const { media, playback, ui, highlights } = store;
  const { currentTime, buffered } = playback;
  const { isSeeking, hoverTimeline, timelineLabel } = ui;

  const {
    duration,
    startOverLive,
    expectedStartOverDuration,
    timeshifting: {
      type: timeshifting,
      startOverTimeshifting
    },
    broadcastedAt,
    programMarkers,
    isEPGDirty
  } = media;

  return ({
    duration,
    currentTime,
    isSeeking,
    buffered,
    hoverTimeline,
    startOverLive,
    expectedStartOverDuration,
    timeshifting,
    broadcastedAt,
    startOverTimeshifting,
    programMarkers,
    timelineLabel,
    isEPGDirty,
    ...highlights
  });
};

const Timeline = connect(selector)(TimelineComponent);

export default Timeline;
