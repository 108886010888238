import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function FullscreenOnIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <mask id="mask0_fullscreen_on" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="3" width="14" height="10">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33337 5.8335C1.33337 6.10964 1.55723 6.3335 1.83337 6.3335H5.33337C5.70156 6.3335 6.00004 6.03502 6.00004 5.66683V3.8335C6.00004 3.55735 5.77618 3.3335 5.50004 3.3335C5.2239 3.3335 5.00004 3.55735 5.00004 3.8335V5.3335H1.83337C1.55723 5.3335 1.33337 5.55735 1.33337 5.8335ZM14.1667 5.3335C14.4429 5.3335 14.6667 5.55735 14.6667 5.8335C14.6667 6.10964 14.4429 6.3335 14.1667 6.3335H11C10.6319 6.3335 10.3334 6.03502 10.3334 5.66683V3.8335C10.3334 3.55735 10.5572 3.3335 10.8334 3.3335C11.1095 3.3335 11.3334 3.55735 11.3334 3.8335V5.3335H14.1667ZM1.83337 10.6668C1.55723 10.6668 1.33337 10.443 1.33337 10.1668C1.33337 9.89069 1.55723 9.66683 1.83337 9.66683H5.33337C5.70156 9.66683 6.00004 9.96531 6.00004 10.3335V12.1668C6.00004 12.443 5.77618 12.6668 5.50004 12.6668C5.2239 12.6668 5.00004 12.443 5.00004 12.1668V10.6668H1.83337ZM14.1667 9.66683C14.4429 9.66683 14.6667 9.89069 14.6667 10.1668C14.6667 10.443 14.4429 10.6668 14.1667 10.6668H11.3334V12.1668C11.3334 12.443 11.1095 12.6668 10.8334 12.6668C10.5572 12.6668 10.3334 12.443 10.3334 12.1668V10.3335C10.3334 9.96531 10.6319 9.66683 11 9.66683H14.1667Z" fill="black" />
      </mask>
      <g mask="url(#mask0_fullscreen_on)">
        <rect width="16" height="16" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default FullscreenOnIcon;
