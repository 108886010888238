import { h } from 'preact';
import { withBreakPoints } from '../../hoc';
import { HeaderInfos, ExtraInfos } from '../info';
import { SECTION_TOP_DEFAULT_STYLE } from './styles';
import { useStyles } from '../../hooks';

function SectionTop({ hidden, containerVisible, isTv }) {
  const setStyle = useStyles();
  return (
    <div className="magneto--section-top" style={setStyle(SECTION_TOP_DEFAULT_STYLE)}>
      <HeaderInfos hidden={hidden} containerVisible={containerVisible} />
      {!hidden && !isTv && <ExtraInfos />}
    </div>
  );
}

export default withBreakPoints(SectionTop);
