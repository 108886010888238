import { BehaviorSubject, ReplaySubject, Subject, combineLatest, interval, of, pipe } from 'rxjs';
import { catchError, delay, distinctUntilChanged, distinctUntilKeyChanged, filter, map, mapTo, skip, startWith, switchMap, take, throttleTime, withLatestFrom } from 'rxjs/operators';
import { MESSAGE_EPG_DEFAULT, WEBSERVICE_TIMEOUT_ERROR } from '../../error/definitions';

import { dateToSecond } from '../../ui/utils/time';

import Media from '../media/Media';
import { TIMESHIFTING_DURATION_INCREASE } from '../renderer/types';
import { getApiUrlByPlatform, removeQueryParams, requestAPI } from '../../utils';
import { timeFromNow } from '../../utils/time';
import { selectJson } from '../../utils/webservice';
import { formatJsonError } from '../../error/formats';
import { sortBroadcastedAt } from '../../utils/streams';
import { Disposable } from '..';
import { EPG_CURRENT_CHANGED, EPG_CURRENT_START, TIMESHIFTING_AUTO_DURATION, TIMESHIFTING_FAKE_LIVE_BROADCASTED_AT, TIMESHIFTING_FLICKERING_TIME_MARGIN } from '../timeshifting/types';
import { metaExpireDateInSeconds } from '../timeshifting/utils';
import { EPG_REQUEST_META } from './types';

export default class EpgController extends Disposable {
  constructor(player) {
    super();
    this.segments$ = new Subject();
    this.segmentPositions$ = new Subject();
    this.epg$ = new Subject();
    this.metaRetry$ = new BehaviorSubject(false);
    this.isDirty$ = new BehaviorSubject(false);
    this.request$ = new Subject();
    this.currentMetadata$ = new ReplaySubject(1);
    this.currentProgramIndex$ = new Subject();
    this.programPositions$ = new Subject();
    this.metadatas$ = new Subject();
    this.isEpgable$ = new ReplaySubject(1);
    this.init(player);

    EpgController.createCurrentMetadataStream({
      currentProgramIndex$: this.currentProgramIndex$,
      metadatas$: this.metadatas$
    }).subscribe(this.currentMetadata$);

    this.currentMetadata$.pipe(
      skip(1),
      withLatestFrom(this.metaRetry$),
      filter(([, isMetaEpgDirty]) => !isMetaEpgDirty)
    ).subscribe(() => {
      player.events$.next(EPG_CURRENT_CHANGED);
      /* We need to delay EPG_CURRENT_START for a short of time to avoid data error on estat tracking for stop -> start */
      setTimeout(() => player.events$.next(EPG_CURRENT_START), 0);
    });
  }

  init(player) {
    const {
      mediaController: { medias$ },
      rendererController: { currentTime$, duration$ },
      domController: { dimensions$ },
      playerConfig$
    } = player;

    EpgController.fetchEpg({
      request$: this.request$,
      medias$,
      metadatas$: this.metadatas$,
      metaRetry$: this.metaRetry$,
      dimensions$,
      playerConfig$
    }).subscribe(this.epg$);

    EpgController
      /* Potatoes program */
      .createMetadataStream(this.epg$, EpgController.segmentMap, this.isDirty$)
      .subscribe(this.segments$);

    EpgController
      .createMetadataStream(this.epg$, EpgController.programMap, this.isDirty$)
      .subscribe(this.metadatas$);

    EpgController
      .createUpdateMetaStream(this.metadatas$)
      .subscribe(this.request$);

    EpgController
      .setupMetaRetry(medias$, this.metaRetry$)
      .subscribe(this.request$);

    EpgController
      .createCurrentProgramIndexStream(this.metadatas$, currentTime$, duration$)
      .subscribe(this.currentProgramIndex$);

    EpgController.createProgramPositionStream(duration$, this.metadatas$)
      .subscribe(this.programPositions$);

    EpgController.createProgramPositionStream(duration$, this.segments$)
      .subscribe(this.segmentPositions$);

    EpgController.createIsEpgableStream(medias$)
      .subscribe(this.isEpgable$);

    /* Force request to metadata on every new media,
       will then be done automatically as we go through programs for the same media
    */
    medias$.pipe(mapTo(EPG_REQUEST_META)).subscribe(this.request$);
  }

  static createCurrentMetadataStream({ currentProgramIndex$, metadatas$ }) {
    return currentProgramIndex$.pipe(
      withLatestFrom(metadatas$),
      map(([currentProgramIndex, metadatas]) => metadatas[currentProgramIndex]),
      filter(Boolean),
      distinctUntilKeyChanged('id')
    );
  }

  static createIsEpgableStream(medias$) {
    return medias$.pipe(map(({ video: { is_epgable: isEpgable } }) => isEpgable));
  }

  /**
   * Indentify the current program
   * @param {*} metadatas$
   */
  static createCurrentProgramIndexStream(metadatas$, currentTime$, duration$) {
    return combineLatest(
      duration$,
      currentTime$
    ).pipe(
      filter(([duration]) => duration >= 0),
      map(([duration, currentTime]) => Math.max(duration - currentTime, 0)),
      withLatestFrom(metadatas$),
      /* Make sure that index is not flickering [1-2-1] */
      map(([distanceToLive, metadatas]) => ({
        index: metadatas.findIndex(({ broadcastedAt }) => timeFromNow(broadcastedAt) >= distanceToLive),
        forwardIndex: metadatas.findIndex(({ broadcastedAt }) => (
          timeFromNow(broadcastedAt) >= distanceToLive - (TIMESHIFTING_DURATION_INCREASE + TIMESHIFTING_FLICKERING_TIME_MARGIN)
        ))
      })),
      /* We consider the changes if we have a succession of the same id with the margin */
      filter(({ index, forwardIndex }) => index === forwardIndex),
      map(({ index }) => index),
      distinctUntilChanged()
    );
  }

  static mapMetas({ media, metas, previousMetas, isDirty$ }) {
    if (!metas.length) {
      const liveMetas = [{
        ...media.meta,
        expectedDuration: Number.MAX_SAFE_INTEGER,
        /**
         * Dirty tricks: this is a fake live broadcasted_at,
         * whe need to substract with -100 to make sure that the current live program is
         * on the index of 0 to handle the fake live metas when epg call failed
         */
        broadcastedAt: (new Date().getTime() / 1000) - TIMESHIFTING_FAKE_LIVE_BROADCASTED_AT,
        markers: media.markers
      }];

      // if there are no metas, we populate with the values from the current live
      if (!previousMetas.length) {
        isDirty$.next(true);
        return liveMetas;
      }
      isDirty$.next(false);

      // if all the metas are outdated, we add the current live metas on top for the live part
      if ((new Date().getTime() / 1000) > metaExpireDateInSeconds(previousMetas)) {
        liveMetas[0].broadcastedAt = metaExpireDateInSeconds(previousMetas);
        return [...liveMetas, ...previousMetas];
      }
      // else we keep the data we currently have, until we get fresh metas
      return previousMetas;
    }
    isDirty$.next(false);
    return metas;
  }

  static createProgramPositionStream(duration$, metadatas$) {
    return duration$.pipe(
      withLatestFrom(metadatas$),
      map(([duration, metadatas]) => metadatas
        .map(({ title, additional_title: additionalTitle, broadcastedAt, image_url: imageUrl }) => {
          const broadcastedAtToNow = timeFromNow(broadcastedAt);
          return ({
            title,
            additionalTitle,
            imageUrl,
            // The last segment will be constructed in the UI
            programDistance: broadcastedAtToNow <= duration ? broadcastedAtToNow : TIMESHIFTING_AUTO_DURATION
          });
        })
        .filter(({ programDistance }) => programDistance && programDistance >= 0))
    );
  }

  static sortMapMetadata() {
    return pipe(
      map((metas) => metas.map((m) => ({
        ...m,
        broadcastedAt: m.broadcastedAt || dateToSecond(m.broadcasted_at),
        expectedDuration: m.expectedDuration || m.expected_duration
      }))),
      sortBroadcastedAt()
    );
  }

  static fetchEpg({ request$, medias$, metadatas$, metaRetry$, dimensions$, playerConfig$ }) {
    const initiatedMetadatas$ = metadatas$.pipe(startWith([]));
    return request$.pipe(
      withLatestFrom(medias$, dimensions$, playerConfig$),
      filter(([request, { video: { is_epgable: isEpgable } }]) => (
        isEpgable && request === EPG_REQUEST_META
      )),
      switchMap(([, media, { width, height }, { webservices, env }]) => {
        metaRetry$.next(false); /* whe need to reset to be sure to have a clean retry */
        const { meta, config: { videoProductId, embedCode, platform }, userCountryCode: country } = media;
        const mode = media.config?.diffusion?.mode;
        const currentEnv = media.config?.env || env;
        const url = getApiUrlByPlatform('epg', webservices, platform);

        const apiUrl = Media.constructGatewayUrl(url, {
          src: meta?.id,
          mode,
          videoProductId,
          width,
          height,
          country,
          embedCode,
          platform,
          env: currentEnv
        });
        const cleanUrl = removeQueryParams(apiUrl, ['country_code', 'browser_version', 'player_version', 'os_version']);
        return requestAPI({
          url: cleanUrl,
          selector: selectJson,
          formatError: formatJsonError(MESSAGE_EPG_DEFAULT)
        }).pipe(
          catchError((e) => {
            if (e.type === WEBSERVICE_TIMEOUT_ERROR.type) { metaRetry$.next(true); }
            return of([]);
          }),
          withLatestFrom(initiatedMetadatas$, medias$)
        );
      })
    );
  }

  static segmentMap(segments) {
    return segments.map((segment) => segment.programs[segment.programs.length - 1]);
  }

  static programMap(segments) {
    return segments.flatMap((segment) => segment.programs);
  }

  static createMetadataStream(epg$, metaMapFunction, isDirty$) {
    return epg$.pipe(
      map(([metas, previousMetas, media]) => [
        metaMapFunction(metas),
        previousMetas,
        media
      ]),
      map(([metas, previousMetas, media]) => EpgController.mapMetas({ media, metas, previousMetas, isDirty$ })),
      EpgController.sortMapMetadata()
    );
  }

  static createUpdateMetaStream(metadatas$) {
    const date$ = interval(5000).pipe(map(() => new Date().getTime() / 1000));
    return combineLatest([date$, metadatas$])
      .pipe(
        filter(([currentDate, metadatas]) => metadatas.length > 0
          && currentDate >= (metaExpireDateInSeconds(metadatas) - 300)),
        map(() => EPG_REQUEST_META),
        throttleTime(600000)
      );
  }

  static setupMetaRetry(medias$, metaRetry$) {
    return medias$.pipe( // we reset the retry stream each time we get metadatas
      switchMap(() => metaRetry$.pipe(
        filter(Boolean),
        delay(20000), // We wait 20s after an error to retry
        take(6), // We retry for 2min max, so 5 times 20 sec calls
        map(() => EPG_REQUEST_META)
      ))
    );
  }
}
