import { h } from 'preact';
import { usePlayerContext } from '../../../../ui/hooks';
import { PRIMARY_FILL_COLOR } from '../../../../ui/theme/colors';
import {
  USER_AUDIO_TRACK_CHANGED,
  USER_CLICK,
  USER_TEXT_TRACK_CHANGED
} from '../../../../types';
import { Icon } from '../../../../ui/components/common/svg/icons';
import {
  DOT,
  ICON,
  ICON_WRAPPER,
  LABEL,
  LABEL_ICON_WRAPPER,
  TRACK_CONTENT,
  TRACK_WRAPPER
} from './styles';
import { TVShortcutController } from '../../../../core/shortcut/tvshortcut';
import { KEYCODE_GENERICS_MAP } from '../../../../core/shortcut/types';

function Track({ label, language, index, isActive, id, icon }) {
  const player = usePlayerContext();

  return (
    <div style={TRACK_WRAPPER}>
      <div style={TRACK_CONTENT}>
        {isActive && <div style={DOT} />}
        <span
          style={LABEL_ICON_WRAPPER}
          tabIndex={0}
          role="radio"
          data-type={id}
          aria-checked={isActive}
          className="ftv-magneto--selectable"
          onKeyDown={(e) => {
            const mappedKeyCode = TVShortcutController.getMappedKeyCode(KEYCODE_GENERICS_MAP, e.keyCode);

            if (
              e.keyCode !== 32 /* space */
              && e.keyCode !== 13 /* enter */
              && mappedKeyCode !== 32 /* space equivalent on remote */
              && mappedKeyCode !== 13 /* enter equivalent on remote */
            ) return;

            if (id === 'audio') {
              player.userEvents$.next({
                action: USER_AUDIO_TRACK_CHANGED,
                source: USER_CLICK,
                value: { label, language }
              });
              player.setAudioTrack(index);
            } else if (id === 'subtitle') {
              player.userEvents$.next({
                action: USER_TEXT_TRACK_CHANGED,
                source: USER_CLICK,
                value: { label, language }
              });
              player.setSubtitleTrack(index);
            }
          }}
        >
          <div style={LABEL}>
            {label}
            <div style={ICON_WRAPPER}>
              <Icon
                name={icon}
                width={24}
                height={24}
                color={PRIMARY_FILL_COLOR}
                style={ICON}
              />
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default Track;
