import {
  USER_PANEL_HIGHLIGHTS_PROGRAM_CLICKED,
  USER_PANEL_PLAYLIST_PROGRAM_CLICKED,
  USER_PANEL_ZAPPING_PROGRAM_CLICKED,
  USER_REMOTE_CHAT_CLICKED,
  USER_REMOTE_HIGHLIGHTS_CLICKED,
  USER_REMOTE_ZAPPING_CLICKED
} from '../../../../types';

export const CHAT_NAME = 'chat';
export const HIGHLIGHTS_NAME = 'highlights';
export const ZAPPING_NAME = 'zapping';
export const PLAYLIST_NAME = 'playlist';
export const TRACKS_NAME = 'languages';
export const EMPTY_ACTIVE_NAME = '';

export const LIVE_OPTION_PANEL_USER_EVENTS_TO_FEATURE = {
  [USER_REMOTE_ZAPPING_CLICKED]: ZAPPING_NAME,
  [USER_REMOTE_CHAT_CLICKED]: CHAT_NAME,
  [USER_REMOTE_HIGHLIGHTS_CLICKED]: HIGHLIGHTS_NAME
};

export const PROGRAM_CLICKED_EVENTS = {
  [ZAPPING_NAME]: USER_PANEL_ZAPPING_PROGRAM_CLICKED,
  [HIGHLIGHTS_NAME]: USER_PANEL_HIGHLIGHTS_PROGRAM_CLICKED,
  [PLAYLIST_NAME]: USER_PANEL_PLAYLIST_PROGRAM_CLICKED
};

export const ACTION_ZAPPING_TAB_CLICKED = 'zappingTabClicked';
export const ACTION_LIVE_PANEL_CLOSED = 'livePanelClosed';
export const ACTION_LIVE_PANEL_OPENED = 'livePanelOpened';
export const ACTION_LIVE_PANEL_ZAPPING_CLICKED = 'zappingClicked';

export const DEFAULT_CLOSE_ACTION = 'close';

export const TITLES_MAPPING = {
  [ZAPPING_NAME]: 'Tous les directs',
  [HIGHLIGHTS_NAME]: 'Les temps forts',
  [PLAYLIST_NAME]: 'Du même programme'
};
