/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvPlaylistIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <mask id="mask_playlist" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="22" y="25" width="46" height="37">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M67.3916 46.6407C67.3916 47.6504 66.6557 48.4688 65.7479 48.4688L24.1436 48.4688C23.2358 48.4688 22.4999 47.6503 22.4999 46.6407C22.4999 45.631 23.2358 44.8126 24.1436 44.8126L65.7479 44.8126C66.6557 44.8126 67.3916 45.6311 67.3916 46.6407Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M67.3916 60.0469C67.3916 61.0565 66.6557 61.875 65.7479 61.875L24.1436 61.875C23.2358 61.875 22.4999 61.0565 22.4999 60.0469C22.4999 59.0372 23.2358 58.2187 24.1436 58.2187L65.7479 58.2188C66.6557 58.2188 67.3916 59.0372 67.3916 60.0469Z" fill="black" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M67.4999 32.0157C67.4999 33.0254 66.764 33.8438 65.8562 33.8438L40.5064 33.8438C39.5986 33.8438 38.8627 33.0254 38.8627 32.0157C38.8627 31.0061 39.5986 30.1876 40.5064 30.1876L65.8562 30.1876C66.764 30.1876 67.4999 31.0061 67.4999 32.0157Z" fill="black" />
        <path d="M22.4999 36.6615V26.1511C22.4999 25.5011 23.2615 25.099 23.8616 25.4322L33.7498 30.9227C34.348 31.2549 34.3312 32.0661 33.7196 32.3765L23.8314 37.3965C23.2315 37.701 22.4999 37.2972 22.4999 36.6615Z" fill="black" />
      </mask>
      <g mask="url(#mask_playlist)">
        <rect width="90" height="90" fill="black" />
        <rect width="90" height="90" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export default TvPlaylistIcon;
