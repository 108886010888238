import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function RemoteControl({ height = 60, width = 60, ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <mask id="mask0_7588_35673" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="7" y="1" width="18" height="30">
        <path d="M12.8 23C12.8 23.8387 13.1226 24.602 13.6506 25.1726L13.0846 25.7387C12.412 25.023 12 24.0596 12 23C12 22.0305 12.3449 21.1415 12.9188 20.4491L13.4876 21.0179C13.057 21.563 12.8 22.2515 12.8 23Z" fill="white" />
        <path d="M13.4491 26.0812L14.0179 25.5124C14.563 25.943 15.2515 26.2 16 26.2C16.7485 26.2 17.437 25.943 17.9821 25.5124L18.5509 26.0812C17.8585 26.6551 16.9695 27 16 27C15.0305 27 14.1415 26.6551 13.4491 26.0812Z" fill="white" />
        <path d="M18.9155 25.7387L18.3494 25.1726C18.8774 24.602 19.2 23.8387 19.2 23C19.2 22.2515 18.943 21.563 18.5124 21.0179L19.0812 20.4491C19.6551 21.1415 20 22.0305 20 23C20 24.0596 19.588 25.023 18.9155 25.7387Z" fill="white" />
        <path d="M18.7387 20.0846L18.1726 20.6506C17.602 20.1227 16.8387 19.8 16 19.8C15.1613 19.8 14.398 20.1226 13.8274 20.6506L13.2613 20.0845C13.977 19.412 14.9404 19 16 19C17.0596 19 18.023 19.412 18.7387 20.0846Z" fill="white" />
        <path d="M16 25C17.1046 25 18 24.1046 18 23C18 21.8954 17.1046 21 16 21C14.8954 21 14 21.8954 14 23C14 24.1046 14.8954 25 16 25Z" fill="white" />
        <path d="M11 6C11 5.44772 11.4477 5 12 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H12C11.4477 7 11 6.55228 11 6Z" fill="white" />
        <path d="M18 6C18 5.44772 18.4477 5 19 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H19C18.4477 7 18 6.55228 18 6Z" fill="white" />
        <path d="M19 10C18.4477 10 18 10.4477 18 11C18 11.5523 18.4477 12 19 12H20C20.5523 12 21 11.5523 21 11C21 10.4477 20.5523 10 20 10H19Z" fill="white" />
        <path d="M11 11C11 10.4477 11.4477 10 12 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H12C11.4477 12 11 11.5523 11 11Z" fill="white" />
        <path d="M19 15C18.4477 15 18 15.4477 18 16C18 16.5523 18.4477 17 19 17H20C20.5523 17 21 16.5523 21 16C21 15.4477 20.5523 15 20 15H19Z" fill="white" />
        <path d="M11 16C11 15.4477 11.4477 15 12 15H13C13.5523 15 14 15.4477 14 16C14 16.5523 13.5523 17 13 17H12C11.4477 17 11 16.5523 11 16Z" fill="white" />
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1C7.89543 1 7 1.89543 7 3V29C7 30.1046 7.89543 31 9 31H23C24.1046 31 25 30.1046 25 29V3C25 1.89543 24.1046 1 23 1H9ZM23 2.5H9C8.72386 2.5 8.5 2.72386 8.5 3V29C8.5 29.2761 8.72386 29.5 9 29.5H23C23.2761 29.5 23.5 29.2761 23.5 29V3C23.5 2.72386 23.2761 2.5 23 2.5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7588_35673)">
        <rect width="32" height="32" fill="black" />
        <rect width="32" height="32" fill="white" />
      </g>
    </SVGWrapper>
  );
}

export { RemoteControl };
