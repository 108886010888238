/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvBackStartIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path d="M46.3528 61.8605C57.0826 61.8605 64.7689 55.4186 64.7689 45.0232C64.7689 35.7687 58.9175 29.8532 49.8338 28.2757C49.1668 28.1598 49.1537 28.1502 47.9313 28.1502H29.3836V24.3569H47.9313C48.1135 24.3569 49.0278 24.3453 49.8338 24.4609C61.0733 26.0764 68.6545 33.6847 68.6545 45.0232C68.6545 57.4977 59.2285 65.625 46.3528 65.625H32.0853C31.4984 65.625 30.4056 65.0381 30.4056 63.7429C30.4056 62.4477 31.4883 61.8605 32.0853 61.8605H46.3528Z" fill="#F8F8F8" />
      <path d="M23.0454 27.4973C22.3182 26.994 22.3182 25.9128 23.0454 25.4095L29.6315 20.8501C30.4663 20.2722 31.6024 20.874 31.6024 21.894V31.0128C31.6024 32.0328 30.4663 32.6346 29.6315 32.0567L23.0454 27.4973Z" fill="#F8F8F8" />
    </SVGWrapper>
  );
}

export default TvBackStartIcon;
