import { exhaustMap, map, filter, take, mapTo, skip } from 'rxjs/operators';
import { ReplaySubject, merge, timer } from 'rxjs';
import { Disposable } from '..';

const RETRYABLE_ERRORS = [2005, 2006];
const TIME_RETRY_3X02 = 60000;

export default class RetryController extends Disposable {
  constructor({ errors$ }) {
    super();
    this.retry$ = new ReplaySubject(1);
    merge(
      RetryController.createRetryStream({ errors$ }),
      RetryController.createRetry3x02Stream({ errors$ })
    ).subscribe(this.retry$);
  }

  static createRetryStream({ errors$ }) {
    return errors$
      .pipe(
        filter(({ error: { code, refresh } }) => RETRYABLE_ERRORS.includes(code) && refresh),
        map(({ error: { refresh } }) => refresh),
        exhaustMap((refresh) => timer(refresh))
      );
  }

  static createRetry3x02Stream({ errors$ }) {
    const withRetry$ = errors$.pipe(
      filter(({ error: { code } }) => /3[0-9]02/.test(`${code}`)),
      mapTo(TIME_RETRY_3X02)
    );

    /**
     * Retry immediatly for on first error
     * Then retry 2 times with delay TIME_RETRY_3X02 on each error
     */
    return merge(
      withRetry$.pipe(take(1)),
      withRetry$.pipe(skip(1), take(2), exhaustMap((refresh) => timer(refresh)))
    ).pipe(mapTo(true));
  }
}
