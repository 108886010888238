import { h } from 'preact';
import './pins.css';
import { useState } from 'preact/hooks';
import { usePlayerContext, useStyles } from '../../../../hooks';
import { withBreakPoints } from '../../../../hoc';
import { BACKGROUND, USER_CLICK, USER_PINS_CLICKED } from '../../../../../types';
import { TIMELINE_LAYER } from '../../../../theme/constants';
import { isValidateKeyboard } from '../../../../utils/keyboard';
import HighlightVisual from './HighlightVisual';
import { HIGHLIGHTS_VISUSAL_CHANGED } from '../../../../../store/types';
import { publicPath } from '../../../../../configuration';
import { EMPTY_THUMBNAIL } from '../utils';
import { Icon } from '../../../common/svg/icons';
import { HIGHLIGHTS_PINS_HOVERED_SIZE, HIGHLIGHTS_PINS_SIZE } from '../styles';

function Pins({
  small,
  pins = [],
  duration,
  disableFocus = true,
  programVisualSize,
  hoverTimeline,
  mini,
  thumbnailSize,
  leftThumb,
  isSeeking,
  pinsHovered,
  timelineHighlightThumbnail
}) {
  if (!pins.length && duration <= 0) {
    return null;
  }

  const [highlightThumbnail, setHighlightThumbnail] = useState(EMPTY_THUMBNAIL);

  const size = useStyles()(pinsHovered ? HIGHLIGHTS_PINS_HOVERED_SIZE : HIGHLIGHTS_PINS_SIZE);

  const player = usePlayerContext();

  const handleClick = (e, programDistance, pinsIndex) => {
    e.stopPropagation();
    e.preventDefault();
    player.userEvents$.next({
      action: USER_PINS_CLICKED,
      value: pinsIndex,
      source: USER_CLICK
    });

    player.seek(Math.abs(duration - programDistance));
  };

  const onHover = (currentProgram, width, height, index) => {
    const defaultImageUrl = `${publicPath}${BACKGROUND}`;

    const nextProgram = {
      ...currentProgram,
      pinsIndex: index,
      url: (currentProgram.imageUrl && `${currentProgram.imageUrl.replace(/\?.*/, '')}?w=${width}&h=${height}`) || defaultImageUrl,
      y: width,
      x: height
    };

    setHighlightThumbnail(nextProgram);
    player.store.dispatch({
      type: HIGHLIGHTS_VISUSAL_CHANGED,
      payload: {
        highlightThumbnail: nextProgram,
        pinsHovered: true
      }
    });
  };

  return !!pins.length && (
    <div className="ftv-magneto-pins--container">
      { !pinsHovered && highlightThumbnail?.url && (
      <HighlightVisual
        mini={mini}
        width={programVisualSize.width}
        height={programVisualSize.height}
        thumbnailSize={thumbnailSize}
        thumbnail={highlightThumbnail}
        hoverTimeline={hoverTimeline}
        isSeeking={isSeeking}
        left={leftThumb}
      />
      )}
      {pins.map(({ position, programDistance, pinsIndex, ...remaining }) => {
        const className = `ftv-magneto-pins${small ? '-small' : ''}${pinsHovered && pinsIndex === timelineHighlightThumbnail?.pinsIndex ? '-hover' : ''}`;
        return (
          <div
            className={className}
            style={{ left: `${position}%` }}
            role="button"
            onClick={(e) => handleClick(e, programDistance, pinsIndex)}
            tabIndex={disableFocus ? '-1' : '0'}
            onKeyDown={(e) => {
              if (isValidateKeyboard(e)) {
                handleClick(e, programDistance, pinsIndex);
              }
            }}
            onMouseEnter={() => {
              onHover(remaining, programVisualSize.width, programVisualSize.height, pinsIndex);
            }}
            onMouseLeave={() => {
              setHighlightThumbnail(EMPTY_THUMBNAIL);
              player.store.dispatch({
                type: HIGHLIGHTS_VISUSAL_CHANGED,
                payload: {
                  highlightThumbnail: EMPTY_THUMBNAIL,
                  pinsHovered: false
                }
              });
            }}
            zIndex={TIMELINE_LAYER + 2}
          >
            <Icon name="pins" {...size} />
          </div>
        );
      })}
    </div>
  );
}

export default withBreakPoints(Pins);
