/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvRewind8Icon({ height = 90, width = 90 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M90 45C90 69.8528 69.8528 90 45 90C20.1472 90 0 69.8528 0 45C0 20.1472 20.1472 0 45 0C69.8528 0 90 20.1472 90 45ZM42.3334 46.2514V73.0816C42.3334 74.7408 40.7406 75.7673 39.4857 74.9167L18.8065 60.901C17.5555 60.053 17.5906 57.9823 18.8696 57.1898L39.5487 44.3754C40.8033 43.5979 42.3334 44.6288 42.3334 46.2514ZM66.7778 46.2514V73.0816C66.7778 74.7408 65.3298 75.7673 64.189 74.9167L45.3898 60.901C44.2524 60.053 44.2844 57.9823 45.4471 57.1898L64.2463 44.3754C65.3868 43.5979 66.7778 44.6288 66.7778 46.2514ZM39.7497 21.84L37.9697 19.3H34.8897L38.2097 23.84L34.4297 29H37.4497L39.7097 25.84L41.9497 29H45.0297L41.2497 23.82L44.5697 19.3H41.5497L39.7497 21.84ZM46.1086 24.72C46.1086 27.2 48.2886 29.2 51.1086 29.2C53.9286 29.2 56.1086 27.2 56.1086 24.72C56.1086 23.12 55.2486 21.88 54.0286 21.12C54.7486 20.46 55.2086 19.56 55.2086 18.5C55.2086 16.44 53.4286 14.8 51.1086 14.8C48.7886 14.8 47.0086 16.44 47.0086 18.5C47.0086 19.56 47.4486 20.46 48.1686 21.12C46.9686 21.88 46.1086 23.12 46.1086 24.72ZM51.1086 26.8C49.8086 26.8 48.8086 25.86 48.8086 24.6C48.8086 23.34 49.8086 22.4 51.1086 22.4C52.4086 22.4 53.4086 23.34 53.4086 24.6C53.4086 25.86 52.4086 26.8 51.1086 26.8ZM51.1086 20.1C50.2886 20.1 49.7086 19.5 49.7086 18.66C49.7086 17.82 50.2886 17.2 51.1086 17.2C51.9286 17.2 52.5086 17.82 52.5086 18.66C52.5086 19.5 51.9286 20.1 51.1086 20.1Z" fill="white" />
    </SVGWrapper>
  );
}

export default TvRewind8Icon;
