import { ReplaySubject, fromEvent, of, NEVER, merge, interval, from } from 'rxjs';
import { map, filter, scan, mapTo, switchMap, take, distinctUntilChanged, skip, debounceTime, catchError, delay, retry, withLatestFrom } from 'rxjs/operators';

import { safeTake } from '../../../utils/rx-utils';
import GenericRenderer from '../generic';
import { systemInfo, FairPlay } from '../../../utils';
import { RENDERER_READY, RENDERER_LIVE_STREAM_DETECTED, HD_BANDWIDTH, TIMESHIFTING_DURATION_INCREASE, LIVE_STALLED_TIMEOUT } from '../types';
import {
  AKAMAI_ERROR_HEADER,
  AKAMAI_GEO_TYPE,
  AKAMAI_LTOKEN_TYPE,
  AKAMAI_MON_ICUID_DEL_HEADER,
  AKAMAI_STOKEN_TYPE,
  RENDERER_403_ERROR,
  RENDERER_DEFAULT_ERROR,
  RENDERER_GEO_403_ERROR,
  RENDERER_MEDIA_ERROR,
  RENDERER_NETWORK_ERROR,
  RENDERER_TOKEN_403_ERROR,
  RENDERER_WITH_VPN_NOT_AUTH
} from '../../../error/definitions';
import {
  TIMESHIFTING_TYPE_MANUEL,
  TIMESHIFTING_TYPE_AUTO
} from '../../timeshifting/types';
import { PLAYBACK_CANPLAYTHROUGH } from '../../../store/types';
import { SMARTTV } from '../../media/types';

const KIND_MAP = {
  textTracks: 'subtitles',
  audioTracks: 'audio'
};

export default class RendererHTML extends GenericRenderer {
  constructor(media, playerConfig, videoUnlocker, isAd$) {
    super(media, playerConfig, videoUnlocker, isAd$);
    this.playerConfig = playerConfig;
    this.createDurationStream(this.tagElement, this.isLive$, this.timeshiftable$, this.suspendDuringMidroll$)
      .safeSubscribe(this, this.duration$);
  }

  async init(container, target$, media) {
    const { video: { drm, duration, is_live: isLive } } = media;
    // this.tagElement = this.videoUnlocker.getVideoTag(this.config);
    this.videoInfos = { duration, isLive };
    container.appendChild(this.tagElement);

    /* PIP */
    this.tagElement.canPIP = this.setupPipReadyStream({
      videoEl: this.tagElement,
      pipSupported: systemInfo.pipSupported
    });

    /* Fairplay DRM */
    if (drm?.token && FairPlay.isFairPlaySupported(this.tagElement)) {
      const certificate = await FairPlay.getCertificate(drm.token, drm.fairplayLaUrlCertificates);

      FairPlay.getLicense({
        token: drm.token,
        url: drm.fairplayLaUrlLicenses,
        certificate,
        tagElement: this.tagElement
      }).subscribe((/* { license, session } */) => {
        // const license = new Uint8Array(licenseBuffer);
        // session.update(license);
      });
    }

    RendererHTML
      .createContentEventStream(this.tagElement)
      .safeSubscribe(this, this.videoOrAudioEventsIn$);

    RendererHTML
      .createIE11PatchStream({ videoOrAudioEvents$: this.videoOrAudioEventsIn$, sysInfo: systemInfo })
      .safeSubscribe(this, this.videoOrAudioEventsIn$);

    this.videoOrAudioEventsOut$.safeSubscribe(this, target$);

    this.createRendererReadyStream().safeSubscribe(this, this.state$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('volumechange', () => this.tagElement.muted))
      .safeSubscribe(this, this.muted$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('volumechange', () => this.tagElement.volume))
      .safeSubscribe(this, this.volume$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('ratechange', () => this.tagElement.playbackRate))
      .safeSubscribe(this, this.playbackRate$);

    this.videoOrAudioEventsOut$
      .pipe(RendererHTML.createOperator('progress', () => RendererHTML.getBuffered(this.tagElement)))
      .safeSubscribe(this, this.buffered$);

    RendererHTML.fixCurrentTimeTimeshiftingWith0(
      RendererHTML.createCurrentTimeStream(this.tagElement, this.suspendDuringMidroll$),
      this.timeshiftable$
    )
      .safeSubscribe(this, this.currentTime$);

    /* detect if stream isLive in the case of raw URL given to gateway */
    this.detectLiveStream(media)
      .safeSubscribe(this, this.state$);

    super.init(); /* GenericRenderer::init */
  }

  createRendererReadyStream() {
    return fromEvent(this.tagElement, RendererHTML.resolveRendererReadyTrigger(this.config))
      .pipe(safeTake(1), mapTo(RENDERER_READY));
  }

  dispose() {
    super.dispose();
  }

  createErrorStream() {
    return merge(
      this.createFromVideoErrorStream(),
      // Calling the blob source outside dash.js creates an error (404) on Tizen OS, so we do not handle this error there
      (this.playerConfig.platform === SMARTTV && this.playerConfig.env.device === 'samsung') ? NEVER : this.createConnectionLostErrorStream()
    );
  }

  createFromVideoErrorStream() {
    return fromEvent(this.tagElement, 'error')
      .pipe(
        /**
         * We will try to fetch the source to fill other info from error
         * because base video error does not contains more error information (like 403,...).
         * Use fetch instead of requestAPI to avoid error catched on the wrapper
         */
        switchMap(() => from(fetch(this.tagElement.src))
          .pipe(map((error) => ({ headers: error.headers, code: error.status })))),
        map(({ headers, code }) => RendererHTML.formatErrors({ payload: {
          originalError: this.tagElement.error,
          networkDetails: { headers, code }
        } }))
      );
  }

  createConnectionLostErrorStream() {
    return this.isLive$.pipe(
      filter((isLive) => isLive),
      switchMap(() => merge(
        fromEvent(this.tagElement, 'suspend'),
        systemInfo.isMobile && systemInfo.browser === 'safari'
          ? fromEvent(this.tagElement, 'waiting').pipe(
            skip(1), /* 1st waiting is triggered when the video start loading */
            delay(LIVE_STALLED_TIMEOUT)
          )
          : NEVER
      ).pipe(
        switchMap(() => from(fetch(this.tagElement.src)).pipe(
          delay(LIVE_STALLED_TIMEOUT),
          retry(5)
        )),
        catchError(() => of(RendererHTML.formatErrors({
          payload: { originalError: { code: 2 } }
        })))
      ))
    );
  }

  async attachSource(source, { isDVR, timeshiftable }) {
    this.tagElement.src = source;
    of(this.startPosition).pipe(
      /* on iOS Safari, we need to wait for the canplaythrough to set startPosition */
      switchMap(() => this.videoOrAudioEventsOut$.pipe(
        filter((evt) => evt === (systemInfo.isIOS || systemInfo.browser === 'safari' ? PLAYBACK_CANPLAYTHROUGH : 'loadedmetadata')),
        skip((systemInfo.isIOS || systemInfo.browser === 'safari' ? 1 : 0)),
        switchMap(() => this.duration$.pipe(filter((d) => d > 0)))
      )),
      take(1)
    ).safeSubscribe(this, (duration) => {
      this.tagElement.currentTime = timeshiftable && this.videoInfos.isLive
        ? duration : this.startPosition;
    });

    RendererHTML
      .createBitrateChangeStream({ videoEl: this.tagElement })
      .safeSubscribe(this, this.bitrate$);

    /**
     * Safari fails to compute a startover's actual duration
    * -> parse m3u8 manifest manually in order to update duration
    */
    const { isIOS, browser } = systemInfo;
    if ((isDVR || timeshiftable === TIMESHIFTING_TYPE_MANUEL) && (Boolean(isIOS) || browser === 'safari')) {
      const { default: ParserM3U8 } = await import(/* webpackChunkName: "m3u8-parser" */ '../parser/m3u8');
      (new ParserM3U8({ source })).child$
        .pipe(map(({ manifest }) => ParserM3U8.calculateDurationFromPlaylist(manifest)))
        .safeSubscribe(this, this.duration$);
    }
  }

  attachTracks() {
    /* In order to retrieve natively parsed text/audio tracks on IOS */
    RendererHTML
      .createTrackStream({ videoEl: this.tagElement, type: 'textTracks' })
      .safeSubscribe(this, this.textTracks$);

    RendererHTML
      .createTrackStream({ videoEl: this.tagElement, type: 'audioTracks' })
      .safeSubscribe(this, this.audioTracks$);
  }

  setAudioTrack(index) {
    Object.keys(this.tagElement.audioTracks).forEach((i) => {
      const enabled = (index === this.tagElement.audioTracks[i].index);
      this.tagElement.audioTracks[i].enabled = enabled;
    });
  }

  setupPipReadyStream({ videoEl, pipSupported }) {
    if (!pipSupported) return NEVER;
    const pipReady$ = new ReplaySubject(1);
    /* PIP is considered ready on loadedmetadata */
    fromEvent(videoEl, 'loadedmetadata').safeSubscribe(this, pipReady$);

    return pipReady$;
  }

  static createTrackStream({ videoEl, type }) {
    /* tracks may sometimes be undefined */
    if (!videoEl[type]) return NEVER;
    return fromEvent(videoEl[type], 'addtrack').pipe(
      /**
       * Hotfix track.mode=showing even we process the remaining flow setting to disable
       * The tracks is reset on default after (caused by the hard .load())
       * with some safari browser version the track.mode set to disable but not at the right moment
       */
      /**
       * As we changed the renderer from html to hls this fix is not applicable anymore (the bug is showing on live dai stream)
       * notes: html rendrer is used for safari drm and safari mobile src/core/renderer/types.js (RENDERER_CONFIG)
       */
      // switchMap((e) => (
      //   e.track.kind === 'subtitles'
      //   && !(systemInfo.isIOS && systemInfo.isMobile && systemInfo.browser === 'safari')
      //     ? of(e).pipe(delay(100))
      //     : of(e)
      // )),
      map(({ track }) => {
        /* hotfix for SAFARI & IOS to disable default textTrack */
        track.mode = 'disabled'; /* eslint-disable-line */
        return track;
      }),
      scan((tracks, track) => {
        // happens on safari to have empty values, should ignore them
        if (!(track.label || track.language)) {
          return tracks;
        }
        return (tracks.some(({ label, language, kind }) => (
          track.label === label
          && track.language === language
          && track.kind === kind
        ))) ? tracks : [...tracks, track];
      }, []),
      debounceTime(100),
      map((tracks) => RendererHTML.parseTracks(tracks, KIND_MAP[type]))
    );
  }

  static parseTracks(tracks, type) {
    return tracks.map(({ label: name, language: lang, kind }, index) => ({
      index,
      name,
      lang,
      kind: kind || type
    }));
  }

  static createIE11PatchStream({ videoOrAudioEvents$, sysInfo }) {
    /*
     * Internet Explorer does not trigger the play event when replaying the same content
     * because when content is ended, paused state is not set to true.
     * This code triggers a play event when replaying a content.
     */
    return (sysInfo.browser === 'ie' && sysInfo.browserVersion === '11')
      ? videoOrAudioEvents$
        .pipe(
          filter((evt) => evt === 'ended'),
          switchMap(() => videoOrAudioEvents$.pipe(
            filter((evt) => evt === 'seeking'),
            mapTo('play')
          ))
        )
      : NEVER;
  }

  static createBitrateChangeStream({ videoEl }) {
    /**
     * Native video tag doesn't emit any events concerning bitrate change -
     * => spoof using videoHeight property
     */
    return fromEvent(videoEl, 'timeupdate').pipe(
      map(() => videoEl.videoHeight),
      distinctUntilChanged(),
      map((videoHeight) => ({
        bandwidth: (videoHeight >= 720) ? HD_BANDWIDTH : 0
      }))
    );
  }

  createTimeshiftingDuration() {
    /** We initiate the duration at 0,
     * as some controllers (ie. freewheel) can need a duration before we start loading the media,
     * thus, before any canplaythrough event.
    */

    return merge(
      of(0),
      fromEvent(this.tagElement, 'progress').pipe(
        withLatestFrom(this.isPreroll$),
        filter(([, isPreroll]) => this.tagElement.buffered.length && !isPreroll),
        take(1),
        map(() => this.tagElement.buffered.end(0)),
        switchMap((startDuration) => interval(TIMESHIFTING_DURATION_INCREASE * 1000).pipe(
          scan(
            (dur) => dur + TIMESHIFTING_DURATION_INCREASE,
            startDuration
          )
        )),
        distinctUntilChanged()
      )
    );
  }

  detectLiveStream(media) {
    /**
     * DVR streams will sometimes have Infinity duration with RendererHTML :
     * - this will mislead Magneto into thinking we're in a live setting
     */
    if (media.isDVR) return NEVER;

    return fromEvent(this.tagElement, 'durationchange').pipe(
      filter(() => !Number.isFinite(this.tagElement.duration)),
      safeTake(1),
      mapTo(RENDERER_LIVE_STREAM_DETECTED)
    );
  }

  static resolveRendererReadyTrigger({ preload }) {
    /**
     * If preload option is set to false (either by config, or automatically),
     * canplay event will usually not trigger (for HLS and Dash).
     * We need to map the loadstart event which triggers earlier in order to
     * succesfully get a RENDERER_READY state. If preload is true, we can safely
     * wait for the canplay event.
     */
    if (systemInfo.isIOS || systemInfo.browser === 'safari') {
      return 'loadedmetadata';
    }
    return (preload ? 'loadedmetadata' : 'loadstart');
  }

  static getHeader(networkDetails, header) {
    return networkDetails?.headers?.get && networkDetails.headers?.get(header);
  }

  static formatErrors({ payload = { originalError: { code: undefined }, networkDetails: { code: undefined } } }) {
    /* https://developer.mozilla.org/en-US/docs/Web/API/MediaError/code */
    const { networkDetails, originalError } = payload;
    const mediaErrorCode = originalError?.code;
    let error = {};

    switch (mediaErrorCode) {
      case 1: /* MEDIA_ERR_ABORTED */
      case 3: /* MEDIA_ERR_DECODE */ {
        error = RENDERER_MEDIA_ERROR;
        break;
      }
      case 2: /* MEDIA_ERR_NETWORK */ {
        error = RENDERER_NETWORK_ERROR;
        break;
      }
      case 4: /* MEDIA_ERR_SRC_NOT_SUPPORTED */
      default: {
        if (networkDetails.code === 403) {
          error = RendererHTML.resolveAkamaiErrorFor(RendererHTML, networkDetails);
        } else {
          error = RENDERER_DEFAULT_ERROR;
        }
        break;
      }
    }

    return ({
      ...error,
      code: error.code + 300, /* RendererHTML error range 43xx */
      payload,
      description: mediaErrorCode
    });
  }

  static resolveAkamaiErrorFor(renderer, networkDetails) {
    // sometimes x-error-type has value "geo stoken"
    const [header] = (renderer.getHeader(networkDetails, AKAMAI_ERROR_HEADER) || '').split(' ');
    switch (header) {
      case AKAMAI_GEO_TYPE:
        return RENDERER_GEO_403_ERROR;
      case AKAMAI_STOKEN_TYPE:
      case AKAMAI_LTOKEN_TYPE:
        return RENDERER_TOKEN_403_ERROR;
      default: {
        const akamaiMonIcuidHeader = renderer.getHeader(networkDetails, AKAMAI_MON_ICUID_DEL_HEADER);
        if (akamaiMonIcuidHeader === '903458' || akamaiMonIcuidHeader === '903457') {
          return RENDERER_WITH_VPN_NOT_AUTH;
        }
      }
    }
    return RENDERER_403_ERROR;
  }

  static onPlayPause(tagElement) {
    return merge(fromEvent(tagElement, 'pause'), fromEvent(tagElement, 'play'));
  }

  static fixCurrentTimeTimeshiftingWith0(currentTime$, timeshiftable$) {
    /**
     * In safari when we seek on timeshifting the videoElemet.currentTime
     * send a value with zero 0 and then with the rigth seeked value
     * So to fix this flickering we will filter the currentTime lower than 1s
     */
    return timeshiftable$.pipe(switchMap((timeshiftable) => (
      timeshiftable === TIMESHIFTING_TYPE_AUTO
      && systemInfo.browser === 'safari'
        ? currentTime$.pipe(filter((currentTime) => currentTime >= 1)) : currentTime$)));
  }
}
