import { deepMerge } from '../../../../utils';
import { textStyleOnXLines } from '../../../styles';
import { PRIMARY_FILL_COLOR, PRIMARY_TEXT_COLOR } from '../../../theme/colors';

export const TIMELINE_WRAPPER_STYLE = {
  default: {
    display: 'flex',
    width: '100%',
    marginRight: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative'
  },
  large: {
    marginRight: 12
  },
  extraSmall: {
    marginRight: 8
  }
};

export const TIME_DISPLAY_WRAPPER_STYLE = {
  default: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Brown',
    fontWeight: 700,
    color: PRIMARY_TEXT_COLOR
  },
  'largeTv+extraLargeTv': {
    color: PRIMARY_FILL_COLOR
  }
};

export const TIMELINE_BUBBLE_SEEK_STYLE = {
  default: {
    backgroundColor: '#ffffff',
    display: 'flex',
    textAlign: 'center',
    transition: 'opacity .15s ease-in-out',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0
  },
  'largeTv+extraLargeTv': {
    backgroundColor: PRIMARY_FILL_COLOR
  }
};
export const TIMELINE_BUBBLE_LIVE_STYLE = {
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0
};

export const TIMELINE_BUBBLE_SEEK_CONTENT_STYLE = {
  fontFamily: 'Brown',
  fontWeight: 700,
  color: '#232323'
};
export const TIMELINE_BUBBLE_LIVE_CONTENT_STYLE = {
  default: {
    fontFamily: 'Brown',
    fontWeight: 700,
    color: 'white'
  },
  'largeTv+extraLargeTV': {
    color: PRIMARY_FILL_COLOR
  }
};

export const TIMELINE_THUMBNAIL_STYLE = {
  display: 'block',
  flexShrink: 0
};

export const TIMELINE_POPUP_STYLE = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  pointerEvents: 'none'
};

export const TIMELINE_TIME_HOUR_STYLE = {
  default: {
    fontSize: 20,
    fontFamily: 'Brown',
    fontWeight: 'normal',
    width: 'unset'
  },
  small: { fontSize: 14 }
};

export const TIMELINE_TOOLTIP_LABEL_STYLE = {
  default: { top: -21, transform: 'translateX(-50%)' },
  large: { top: -35 },
  small: { top: -15 }
};

export const TIMELINE_LIVE_STYLE_TOOLTIP = {
  default: {
    width: 'unset', height: 15.19, borderRadius: 3, fontSize: 8.19223
  },
  'small+medium': {
    height: 12, borderRadius: 1.5, fontSize: 6
  },
  large: {
    height: 22, borderRadius: 3, fontSize: 12
  }
};

export const TOOLTIP_AUTO_CONTAINER_STYLES = {
  default: {
    maxWidth: 192,
    transform: 'translateY(-231%)',
    width: 192,
    maxHeight: 108,
    gap: 10
  },
  small: {
    maxWidth: 128,
    width: 128,
    maxHeight: 72,
    transform: 'translateY(-232%)'
  },
  large: {
    maxWidth: 384,
    width: 384,
    maxHeight: 216,
    transform: 'translateY(-181%)'
  },
  extraLarge: {
    maxWidth: 576,
    width: 576,
    maxHeight: 324,
    transform: 'translateY(-154.5%)'
  }
};

export const HIGHLIGHTS_LABEL_STYLES = {
  default: {
    position: 'absolute',
    top: 8,
    left: 8,
    fontFamily: 'Brown',
    fontStyle: 'normal',
    fontWeight: 700,
    borderRadius: 4,
    textAlign: 'center',
    padding: '0px 0px',
    fontSize: '12px'
  },
  small: {
    padding: '1px 2px'
  },
  'large+extraLarge': {
    padding: '0px 4px',
    fontSize: '20px'
  }
};

export const HIGHLIGHTS_LABEL_TEXT_STYLES = {
  'extraSmall+small': 12,
  medium: 14,
  'large+extraLarge': 18
};
export const HIGHLIGHTS_PINS_SIZE = {
  'extraLarge+large+medium': { width: 32, height: 28 },
  small: { width: 28, height: 26 }
};
export const HIGHLIGHTS_PINS_HOVERED_SIZE = {
  'extraLarge+large+medium': { width: 48, height: 40 },
  small: { width: 40, height: 34 }
};

export const EPG_PROGRAM_VISUAL_SIZE = {
  default: { width: 192 },
  large: { width: 384 },
  small: { width: 128 },
  extraLarge: { width: 576 }
};

export const SPRITES_METADATA_TITLE_PROGRAM_STYLES = deepMerge(EPG_PROGRAM_VISUAL_SIZE, {
  default: {
    fontSize: 22,
    textAlign: 'center',
    margin: 'auto',
    paddingBottom: 3,
    lineHeight: '29px',
    ...textStyleOnXLines(1)
  },
  small: { fontSize: 14, lineHeight: '19px' },
  'large+extraLarge': { fontSize: 32, lineHeight: '39px' }
});

export const SPRITES_METADATA_TITLE_VIDEO_STYLES = deepMerge(EPG_PROGRAM_VISUAL_SIZE, {
  default: {
    textAlign: 'center',
    margin: 'auto',
    paddingBottom: 3,
    fontSize: 16,
    lineHeight: '21px',
    ...textStyleOnXLines(2),
    whiteSpace: 'inherit'
  },
  small: {
    fontSize: 12,
    lineHeight: '19px',
    ...textStyleOnXLines(1)
  },
  'large+extraLarge': { fontSize: 24, lineHeight: '31px' }
});
