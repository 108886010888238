import { OVERLAY_SCREEN_BACKGROUND } from '../../../theme/colors';
import { OVERLAY_LAYER } from '../../../theme/constants';

export const OVERLAY_STYLES = {
  container: {
    color: 'white',
    position: 'absolute',
    width: '100%',
    height: '100%',
    fontFamily: 'Brown',
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: OVERLAY_LAYER,
    backgroundColor: OVERLAY_SCREEN_BACKGROUND
  },
  dialog: {
    default: {
      textAlign: 'center',
      outline: 'none',
      padding: '0 5px',
      width: '100%',
      userSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
      MsUserSelect: 'none'
    },
    medium: { fontSize: '16px' },
    extraSmall: { fontSize: '14px' },
    large: { fontSize: '22px' },
    extraLarge: { fontSize: '22px' }
  }
};
