import { h } from 'preact';
import { useMemo, useRef, useState, useEffect } from 'preact/hooks';
import { usePlayerContext } from '../../../hooks';
import { withBreakPoints, connect } from '../../../hoc';
import ControlButton from './ControlButton';
import DialogButton from './DialogButton';
import FeedbackButton from './FeedbackButton';
import AnimatedButton from './AnimatedButton';
import ButtonLabel from './ButtonLabel';
import { computeButtonSize } from '../../../utils/tools';
import {
  DEFAULT_BUTTON_SIZE,
  DIALOG_BUTTON_TRANSITION_DURATION
} from './styles';
import {
  UI_IS_INTERACTING,
  UI_TOGGLE_HOVER_LABEL,
  UI_VISIBLE
} from '../../../../store/types';
import MorphButton from './MorphButton';
import { VOID_FN } from '../../../../utils';
import { useDialogContext } from '../../../context/DialogContext';
import { DIALOG_LAYER } from '../../../theme/constants';
import IconButton from './IconButton';

const setInteraction = ({ store, isInteracting }) => {
  if (isInteracting) {
    store.dispatch({
      type: UI_VISIBLE,
      payload: { hasUiVisible: true }
    });
  }

  store.dispatch({
    type: UI_IS_INTERACTING,
    payload: { isInteracting }
  });
};

const resolveAlignment = ({ first, last }) => {
  if (last) return 'right';
  return (first ? 'left' : 'center');
};

function Button({
  hidden,
  type = 'control',
  svg,
  svgActive,
  size = DEFAULT_BUTTON_SIZE,
  style = {},
  labelStyle = {},
  large,
  medium,
  mobile,
  mobileFriendly,
  xs = false,
  xl = false,
  isTv,
  first,
  last,
  label,
  zone = 'bottom',
  onClick = VOID_FN,
  onKeyDown = VOID_FN,
  onClose = VOID_FN,
  onOpen = VOID_FN,
  fnFeedback = VOID_FN,
  onHover = VOID_FN,
  transition = () => ({ max: 0, apply: VOID_FN }),
  children,
  small,
  name,
  showChildren,
  forceLabel,
  zIndex = DIALOG_LAYER,
  activeIconRef,
  iconRef,
  dialogAriaLabel,
  ...extraProps
}) {
  const { store } = usePlayerContext();
  const dialogCtx = useDialogContext();

  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  const opened = useRef(false);
  const { ui: { showHover: hoverOnStore } } = store.getState();

  if (hidden || (type === 'dialog' && !children)) return null;

  const computedSize = computeButtonSize({
    size, medium, large, xs, mobileFriendly
  });

  const isLabelVisible = useMemo(
    () => (focus && (type !== 'dialog' || !opened.current)) || hover,
    [hover, type, focus]
  );

  useEffect(() => {
    if (!hover) setFocus(false);
  }, [hover]);

  const showLabel = () => {
    const baseCondition = !mobile && label && hoverOnStore;
    return extraProps.openingMode === 'click' ? ((baseCondition && !opened) || forceLabel) : baseCondition;
  };

  const commonProps = {
    svg,
    viewBox: svg || '',
    svgActive,
    style,
    forceLabel,
    styleOverride: {
      ...{
        marginLeft: (first && !xl && !isTv) ? -computedSize / 4 : 0,
        marginRight: (last && !xl && !isTv) ? -computedSize / 4 : 0,
        marginTop: zone === 'top' && !xl ? -computedSize / 4 : 0
      }
    },
    onClick,
    onKeyDown,
    onClose,
    onOpen,
    size,
    hover,
    onInteract: (isInteracting) => !dialogCtx.dialogOpened && setInteraction({ store, isInteracting }),
    onHover: (isHover) => { onHover(); setHover(isHover); },
    medium,
    xl,
    showChildren,
    iconRef,
    activeIconRef,
    dialogAriaLabel,
    handleFocusWithin: () => setFocus(true),
    handleBlurWithin: () => setFocus(false)
  };

  const commonStyle = {
    position:
      type === 'dialog' && (mobile || (opened && !isLabelVisible))
        ? 'static'
        : 'relative',
    zIndex
  };

  const toggleDialogWithAnimation = () => {
    setTimeout(() => {
      opened.current = !opened.current;
      onClick();
    }, opened ? DIALOG_BUTTON_TRANSITION_DURATION : 0);
  };

  return (
    /**
     * Dialogs will sometimes expand to the player's full bounding box,
     * -> remove relative positionning so that a dialog with absolute
     * positionning resolves width: 100% to the next relative positionned div
     */
    <div
      style={commonStyle}
      onMouseEnter={() => store.dispatch({ type: UI_TOGGLE_HOVER_LABEL, payload: { showHover: true } })}
    >
      {
        showLabel()
        && (
        <ButtonLabel
          label={label}
          alignX={resolveAlignment({ first, last })}
          visible={isLabelVisible}
          xs={xs}
          zone={zone}
          style={labelStyle}
        />
        )
      }
      {
        (() => {
          switch (type) {
            case 'dialog': {
              return (
                <DialogButton
                  {...commonProps}
                  {...extraProps}
                  id={label} /* used in DialogContext */
                  onClick={toggleDialogWithAnimation}
                  name={name}
                >
                  {opened && children}
                </DialogButton>
              );
            }

            case 'feedback': {
              return (
                <FeedbackButton
                  {...commonProps}
                  mobileFriendly={mobileFriendly}
                  mobile={mobile}
                  transition={transition}
                  fnFeedback={fnFeedback}
                  name={name}
                  {...extraProps}
                />
              );
            }

            case 'animated': {
              return (
                <AnimatedButton
                  {...commonProps}
                  mobileFriendly={mobileFriendly}
                  mobile={mobile}
                  transition={transition}
                  name={name}
                  {...extraProps}
                />
              );
            }

            case 'morph': {
              return (
                <MorphButton
                  {...commonProps}
                  mobileFriendly={mobileFriendly}
                  mobile={mobile}
                  name={name}
                  {...extraProps}
                />
              );
            }
            case 'icon': {
              return (
                <IconButton
                  {...commonProps}
                  mobileFriendly={mobileFriendly}
                  mobile={mobile}
                  name={name}
                  {...extraProps}
                />
              );
            }

            default: {
              return (
                <ControlButton
                  {...commonProps}
                  mobileFriendly={mobileFriendly}
                  mobile={mobile}
                  name={name}
                  {...extraProps}
                />
              );
            }
          }
        })()
      }
    </div>
  );
}

const selector = ({ ui: { showHover, focusedFields }, media: { isTv } }) => ({ showHover, focusedFields, isTv });
export default withBreakPoints(connect(selector)(Button));
