import { h, render, createContext } from 'preact';
import { dialogContextController, DialogContext } from './context/DialogContext';
import Spinner from './components/spinner';
import './theme/styles/global.css';
import Main from './components/wrappers/Main';
import InteractionHandler from './components/wrappers/InteractionHandler';
import LayerTop from './components/common/layers/LayerTop';
import { connect, withBreakPoints } from './hoc';
import AdPauseOverlay from './components/wrappers/AdPauseOverlay';
import CoreUI from './CoreUI';
import PanelLiveOption from './components/wrappers/Zap/PanelLiveOption';

import { useStyles } from './hooks';
import { SIDEBAR_SIZE_LARGE, SIDEBAR_SIZE_MEDIUM, TRANSITION_DURATION } from './theme/colors';

export const Player = createContext();
const getHeightFromRatio = (width) => {
  // Video ratio 16:9
  const aspectRatio = 9 / 16;
  const height = width * aspectRatio;
  return height;
};

function PlayerApp({ noUI, pauseRollIsDisplayable, panelLiveOption, width, height }) {
  const { sidebarSize } = useStyles()({
    default: { sidebarSize: `${SIDEBAR_SIZE_MEDIUM}%` },
    small: { sidebarSize: '100%' },
    'large+extraLarge': { sidebarSize: `${SIDEBAR_SIZE_LARGE}%` }
  });

  const isPortrait = width < height;

  const videoHeight = isPortrait && panelLiveOption.show
    ? getHeightFromRatio(width)
    : '100%';

  return !noUI ? (
    <DialogContext.Provider value={dialogContextController}>
      <InteractionHandler
        style={{
          height: videoHeight,
          transition: `width ${TRANSITION_DURATION}ms ease-in-out, height ${TRANSITION_DURATION}ms ease-in-out`,
          width:
            !isPortrait && panelLiveOption.show
              ? `calc(100% - ${sidebarSize})`
              : '100%'
        }}
      >
        {!pauseRollIsDisplayable ? (
          <Main panelLiveOption={panelLiveOption} />
        ) : (
          <div />
        )}

        <AdPauseOverlay />
      </InteractionHandler>
      <PanelLiveOption
        panelLiveOption={panelLiveOption}
        panelHeight={isPortrait && Math.floor(height - videoHeight)}
      />
    </DialogContext.Provider>
  ) : (
    <Spinner />
  );
}

const PlayerAppWithBreakPoints = withBreakPoints(PlayerApp);

const PlayerAppWithStore = connect(
  ({
    ui: { isFullscreen, panelLiveOption, width, height },
    zapping: { list, zappingEnabled = list.length > 0 },
    ad: { pauseRollIsDisplayable }
  }) => ({
    isFullscreen, zappingEnabled, pauseRollIsDisplayable, width, height,
    panelLiveOption: { ...panelLiveOption, show: panelLiveOption.show }
  })
)(PlayerAppWithBreakPoints);

export default class PlayerUI extends CoreUI {
  constructor({ player, noUI, container = null }) {
    super({ player, container });
    this.noUI = noUI;
  }

  init() {
    this.wrapper = document.createElement('div');
    this.container.appendChild(this.wrapper);

    render(
      <LayerTop player={this.player}>
        <PlayerAppWithStore player={this.player} noUI={this.noUI} container={this.container} />
      </LayerTop>,
      this.wrapper
    );
  }
}
