import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function SDIcon({ height = 60, width = 60, color = 'white', ...props }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <mask id="mask0_sd" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="3" width="14" height="8">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M0.275811 5.32172C0.275811 6.60794 1.23563 7.13114 2.15133 7.47995C2.97876 7.79605 3.69587 8.00315 3.69587 8.50456C3.69587 8.90786 3.32077 9.16946 2.79121 9.16946C2.14029 9.16946 1.46732 8.80976 1.05911 8.42826L0 9.64907C0.706077 10.3249 1.699 10.8481 2.9236 10.8481C4.54537 10.8481 5.68171 9.82347 5.68171 8.39556C5.68171 7.53445 5.20731 6.85864 4.50124 6.43354C3.53038 5.85583 2.26165 5.72503 2.26165 5.19092C2.26165 4.88572 2.57056 4.67862 3.17734 4.67862C3.6738 4.67862 4.15923 4.85302 4.54537 5.10372L5.46106 3.77391C4.73292 3.2943 3.98271 3 3.01186 3C1.53351 3 0.275811 3.83931 0.275811 5.32172Z" fill="#B4B4B4" />

        {/* eslint-disable-next-line react/no-unknown-property */}
        <path d="M8.86991 8.99506V4.85302H9.86283C10.591 4.85302 11.0323 5.01652 11.3743 5.31082C11.8156 5.69233 12.0693 6.28093 12.0693 6.92404C12.0693 7.56715 11.8156 8.15575 11.3743 8.53726C11.0323 8.83156 10.591 8.99506 9.86283 8.99506H8.86991ZM6.93924 10.7391H9.80767C11.0323 10.7391 11.8376 10.4775 12.4665 10.0197C13.4153 9.32207 14 8.18845 14 6.92404C14 5.65963 13.4153 4.52602 12.4665 3.82841C11.8376 3.3706 11.0323 3.109 9.80767 3.109H6.93924V10.7391Z" fill="#B4B4B4" />
      </mask>
      <g mask="url(#mask0_sd)">
        <rect width="14" height="14" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default SDIcon;
