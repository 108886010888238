/* eslint-disable react/no-unknown-property */
import { Fragment, h } from 'preact';
import SVGWrapper from '../../SVGWrapper';

function TvNextIcon({ height = 60, width = 60 }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 90 90"
      fill="none"
    >
      <Fragment>
        <path d="M25 30.9638V59.2085C25 60.9167 26.8577 62.0018 28.3808 61.1834L53.4873 47.6934C55.04 46.8591 55.0827 44.6792 53.5638 43.7866L28.4574 29.0319C26.9338 28.1365 25 29.2171 25 30.9638Z" fill="#F8F8F8" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M62.7143 63C61.4519 63 60.4286 61.9926 60.4286 60.75V29.25C60.4286 28.0074 61.4519 27 62.7143 27C63.9767 27 65 28.0074 65 29.25V60.75C65 61.9926 63.9767 63 62.7143 63Z" fill="#F8F8F8" />
      </Fragment>
    </SVGWrapper>

  );
}

export default TvNextIcon;
