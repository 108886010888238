/* global tv */
import { NEVER, of, fromEvent, merge } from 'rxjs';
import { filter, distinctUntilChanged, switchMap, mapTo, withLatestFrom, startWith, delay, map } from 'rxjs/operators';

const requiredFocus = {
  init({ adRequireFocus }, slotEvent$, currentSlot$, isAd$) {
    const isEnabled$ = adRequireFocus ? requiredFocus.createIsEnabledStream(isAd$) : NEVER;
    const onFocus$ = requiredFocus.createOnFocusStream(isEnabled$);
    const onBlur$ = requiredFocus.createOnBlurStream(
      isEnabled$,
      requiredFocus.createCursorOverVpaidStream(slotEvent$)
    );

    onBlur$.pipe(withLatestFrom(currentSlot$)).subscribe(([, slot]) => slot.pause());
    onFocus$.pipe(withLatestFrom(currentSlot$), delay(100)).subscribe(([, slot]) => slot.resume());
  },

  createIsEnabledStream(isAd$) {
    return isAd$.pipe(map(({ isAd }) => isAd));
  },

  createOnFocusStream(isEnabled$) {
    return isEnabled$
      .pipe(switchMap((enabled) => (enabled ? fromEvent(window, 'focus') : NEVER)));
  },

  createOnBlurStream(isEnabled$, cursorOverVpaid$) {
    return isEnabled$.pipe(
      switchMap((enabled) => (enabled ? fromEvent(window, 'blur') : NEVER)),
      withLatestFrom(cursorOverVpaid$.pipe(filter((over) => !over)))
    );
  },

  createCursorOverVpaidStream(slotEvent$) {
    return slotEvent$.pipe(
      filter((evt) => evt === tv.freewheel.SDK.EVENT_AD_IMPRESSION),
      switchMap(() => {
        const vpaidElem = document.getElementsByClassName('fw_vpaid_slot')[0];
        if (!vpaidElem) return of(false);

        return merge(
          fromEvent(vpaidElem, 'mouseover').pipe(mapTo(true)),
          fromEvent(vpaidElem, 'mouseout').pipe(mapTo(false))
        ).pipe(distinctUntilChanged());
      }),
      startWith(false)
    );
  }
};

export default requiredFocus;
