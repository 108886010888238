import { h } from 'preact';
import SVGWrapper from '../SVGWrapper';

function ForwardIcon({ height = 60, width = 60, color = 'white' }) {
  return (
    <SVGWrapper
      width={width}
      height={height}
      viewBox="0 0 44 44"
      fill="none"
    >
      <mask id="mask0_forward" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="3" width="34" height="36">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.1974 8.07221C26.712 7.71595 26.712 6.9508 26.1974 6.59453L21.5361 3.36768C20.9453 2.95869 20.1412 3.38459 20.1412 4.10652V5.60358C11.9035 6.52713 5.5 13.5158 5.5 22C5.5 31.1127 12.8873 38.5 22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 17.4304 36.6424 13.2947 33.6412 10.3067C33.134 9.8018 32.3185 9.84047 31.8158 10.3498C31.2534 10.9195 31.305 11.8453 31.8628 12.4194C34.2686 14.8957 35.75 18.2749 35.75 22C35.75 29.594 29.5939 35.75 22 35.75C14.4061 35.75 8.25 29.594 8.25 22C8.25 15.0365 13.4264 9.28205 20.1412 8.37461V10.5602C20.1412 11.2822 20.9453 11.7081 21.5361 11.2991L26.1974 8.07221Z" fill="black" />

      </mask>
      <g mask="url(#mask0_forward)">
        <rect width="44" height="44" fill={color} />
      </g>
    </SVGWrapper>

  );
}

export default ForwardIcon;
