import { h } from 'preact';
import { TOGGLE_BUTTON_BAR, TOGGLE_BUTTON_HANDLE, TOGGLE_CHECK_ICON_SIZE } from './styles';
import { FTV_ACTIVE_COLOR, FTV_DISABLED_COLOR } from '../../../theme/colors';
import { withBreakPoints } from '../../../hoc';
import { VOID_FN } from '../../../../utils';
import { Icon } from '../svg/icons';
import { useStyles } from '../../../hooks';

/**
 * onToggle: callback receives toggle value
 */
function ToggleButton({
  val = false,
  onToggle = VOID_FN
}) {
  const setStyles = useStyles();
  const iconProps = setStyles(TOGGLE_CHECK_ICON_SIZE);
  return (
    <div
      onClick={(e) => { e.stopPropagation(); onToggle(val); }}
      role="presentation"
      style={setStyles(TOGGLE_BUTTON_BAR)}
    >
      <div
        style={{
          ...setStyles(TOGGLE_BUTTON_HANDLE),
          transform: val ? 'translateX(calc(100% + 2px))' : 'translateX(0)',
          backgroundColor: val ? FTV_ACTIVE_COLOR : FTV_DISABLED_COLOR
        }}
      >
        {val && (
          <Icon
            name="check"
            {...iconProps}
          />
        )}
      </div>
    </div>
  );
}

export default withBreakPoints(ToggleButton);
